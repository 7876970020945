import { getConf } from '@retail/config';

import { LANGUAGES } from './languages';

export const getLocale = (language: string) =>
  LANGUAGES[language] || LANGUAGES.en;

const LANGUAGE_KEY = getConf('LANGUAGE_KEY');

export function i18nDetectLng() {
  let language = localStorage.getItem(LANGUAGE_KEY);

  if (!Object.keys(LANGUAGES).includes(language)) {
    language = LANGUAGE_KEY;
  }

  return language.toLowerCase();
}
