import retailGlobal from '@retail/global';
import * as retailDotenv from '@retail/dotenv';
import { loadConfig } from '@retail/config';

if (__IS_STANDALONE__) {
  require('./antd.global.less');
  // NOTE: uncomment if need to remove bottom-right dev-libs button
  // document.querySelector<HTMLElement>('[dev-libs]').style.display = 'none';
}

const ENV = __IS_STANDALONE__
  ? process.env.ENV
  : retailGlobal.get<{ ENV?: string }>('__config')?.ENV;

loadConfig(retailDotenv.load(ENV));
