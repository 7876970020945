import { ApolloProvider } from '@apollo/client';
import { useDevAuthToken } from '@retail/dev-auth';
import { ConfigProvider, notification } from 'antd';
import { RouterProvider } from 'react-router-dom';

import { GQL_CLIENT } from '~/apollo/gql-client';
import { UserProvider } from '~/providers/UserProvider';

import { ANTD_EXTERNAL_VERSION_LESS } from './constants/antd';
import './i18n';
import { router } from './router';

notification.config({
  top: 55,
  prefixCls: `${ANTD_EXTERNAL_VERSION_LESS}-notification`
});

export default function Root() {
  useDevAuthToken();

  return (
    <ConfigProvider prefixCls={ANTD_EXTERNAL_VERSION_LESS}>
      <ApolloProvider client={GQL_CLIENT}>
        <UserProvider>
          <RouterProvider router={router} />
        </UserProvider>
      </ApolloProvider>
    </ConfigProvider>
  );
}
