export const ROOT = {
  ROUTE: '/:language',
  LINK: ({ language }) => `/${language}`
};

export const CHAT_BOT = {
  ROUTE: `/chatbot`,
  ROUTE_RELATIVE: `${ROOT.ROUTE}/chatbot`,
  LINK: ({ language }) => `${ROOT.LINK({ language })}/chatbot`
};

export const CHAT_BOT_IMPORT_CONTEXT = {
  ROUTE: '/import-context',
  ROUTE_RELATIVE: `${CHAT_BOT.ROUTE_RELATIVE}/import-context`,
  LINK: ({ language }) => `${CHAT_BOT.LINK({ language })}/import-context`
};

export const CHAT_BOT_AI_SETTINGS = {
  ROUTE: '/ai-settings',
  ROUTE_RELATIVE: `${CHAT_BOT.ROUTE_RELATIVE}/ai-settings`,
  LINK: ({ language }) => `${CHAT_BOT.LINK({ language })}/ai-settings`
};
