import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInteger: any;
  Json: any;
  LocalDateTime: any;
  Long: any;
  Map_RefurbishmentServiceType_List_RefurbishmentServiceNameScalar: any;
  Map_RetailNotificationEntityType_IntegerScalar: any;
  Map_String_BigDecimalScalar: any;
  Map_String_List_WheelScalar: any;
  Map_String_Map_String_OptionProjectionScalar: any;
  Map_String_ObjectScalar: any;
  Map_String_OpeningHoursDTOScalar: any;
  Map_String_PriceModifierPayloadScalar: any;
  Map_String_RetailOutboundPaymentOptionProjectionScalar: any;
  Map_String_StringScalar: any;
  Map_String_SurveyAggregationAnswerProjectionScalar: any;
  Map_UUID_LongScalar: any;
  Map_UUID_URLScalar: any;
  Map_WheelPlacementType_RimDetailsProjectionScalar: any;
  Map_WheelPlacementType_RimDetailsScalar: any;
  Map_WheelPlacementType_TireDetailsProjectionScalar: any;
  Map_WheelPlacementType_TireDetailsScalar: any;
  ObjectScalar: any;
  UNREPRESENTABLE: any;
  UUID: any;
};

export type AbExperimentUpdateProjectionInput = {
  condition?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  environment?: InputMaybe<Scalars['String']>;
  expireAt?: InputMaybe<Scalars['String']>;
  gaExperimentId?: InputMaybe<Scalars['String']>;
  multiStep?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['String']>;
  trafficPercents?: InputMaybe<Scalars['Int']>;
};

export type AbTestingExperimentAuthor = {
  __typename?: 'AbTestingExperimentAuthor';
  email?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
};

export type AbTestingExperimentInputInput = {
  condition?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  environment?: InputMaybe<Scalars['String']>;
  expireAt?: InputMaybe<Scalars['String']>;
  gaExperimentId?: InputMaybe<Scalars['String']>;
  multiStep: Scalars['Boolean'];
  name: Scalars['String'];
  nextStepVariants?: InputMaybe<Array<InputMaybe<RetailAbTestingNextStepVariantProjectionInput>>>;
  startAt: Scalars['String'];
  trafficPercents: Scalars['Int'];
  variants?: InputMaybe<Array<InputMaybe<RetailAbTestingVariantProjectionInput>>>;
  viewNames: Array<InputMaybe<Scalars['String']>>;
};

export type AccountDataDto = {
  __typename?: 'AccountDataDTO';
  accountDetails?: Maybe<AccountDetailsDto>;
  createdBy?: Maybe<Scalars['Long']>;
  createdOn?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
};

export type AccountDetailsDto = {
  __typename?: 'AccountDetailsDTO';
  bankName?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  blz?: Maybe<Scalars['String']>;
  holder?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type AccountDetailsDtoInput = {
  bankName?: InputMaybe<Scalars['String']>;
  bic?: InputMaybe<Scalars['String']>;
  blz?: InputMaybe<Scalars['String']>;
  holder?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
};

export type AccountingDatePendingTaskProjection = {
  __typename?: 'AccountingDatePendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  cancellationReasonComment?: Maybe<Scalars['String']>;
  cashOutReason?: Maybe<Scalars['String']>;
  cashOutRequestDate?: Maybe<Scalars['String']>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refundAmount?: Maybe<PriceDto>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type AdDetailsProjection = {
  __typename?: 'AdDetailsProjection';
  ad?: Maybe<AdProjection>;
  damages?: Maybe<DamagesProjection>;
  estimatedCosts?: Maybe<Array<Maybe<RetailPricingCostDto>>>;
  retailAd?: Maybe<RetailAdProjection>;
  serviceBookImages?: Maybe<Array<Maybe<ImageProjection>>>;
  technicalInspectionCertificateImages?: Maybe<Array<Maybe<ImageProjection>>>;
  vehicleRegistration?: Maybe<ImageProjection>;
};

export type AdImageProjectionInput = {
  composites?: InputMaybe<Array<InputMaybe<CompositeImageProjectionInput>>>;
};

export type AdItemProjection = {
  __typename?: 'AdItemProjection';
  ad?: Maybe<AdProjection>;
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customDiscountMinorUnits?: Maybe<Scalars['Long']>;
  customDiscountType?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  obsolete?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderItemRefund?: Maybe<Scalars['Long']>;
  parentId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  retailAd?: Maybe<RetailAdProjection>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type AdProjection = {
  __typename?: 'AdProjection';
  adType?: Maybe<AdType>;
  autoimported: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  composites?: Maybe<Array<Maybe<CompositeImageProjection>>>;
  contact?: Maybe<SellerContactDto>;
  countryCode?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documentInventoryItems?: Maybe<DocumentInventoryItemConnection_DocumentInventoryItem>;
  exclusive?: Maybe<Scalars['Boolean']>;
  firstPublishedAt?: Maybe<Scalars['String']>;
  highlights?: Maybe<Array<Maybe<HighlightDto>>>;
  id?: Maybe<Scalars['UUID']>;
  inShowroom?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  location?: Maybe<SellerAddressDto>;
  longitude?: Maybe<Scalars['Float']>;
  manualImportDate?: Maybe<Scalars['String']>;
  pathTitle?: Maybe<Scalars['String']>;
  pdfs?: Maybe<Array<Maybe<PdfDto>>>;
  price?: Maybe<Price>;
  priceType?: Maybe<PriceType>;
  published?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['String']>;
  seller?: Maybe<SellerDto>;
  sellerId?: Maybe<Scalars['UUID']>;
  source?: Maybe<Source>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  unpublishedAt?: Maybe<Scalars['String']>;
  unpublishedReason?: Maybe<UnpublishedReason>;
  updated?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  vehicle?: Maybe<VehicleProjection>;
  version?: Maybe<Scalars['Int']>;
  videos?: Maybe<Array<Maybe<AdVideoDto>>>;
  zipcode?: Maybe<Scalars['String']>;
};

export enum AdType {
  Merchant = 'MERCHANT',
  PrivateListing = 'PRIVATE_LISTING',
  PrivateSales = 'PRIVATE_SALES',
  Retail = 'RETAIL'
}

export type AdUpdateProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  inShowroom?: InputMaybe<Scalars['Boolean']>;
  pdfs?: InputMaybe<Array<InputMaybe<PdfDtoInput>>>;
  price?: InputMaybe<PriceInput>;
  published?: InputMaybe<Scalars['Boolean']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  videos?: InputMaybe<Array<InputMaybe<AdVideoDtoInput>>>;
};

export type AdVehicleProjectionInput = {
  bodyType?: InputMaybe<Scalars['Int']>;
  builtYear?: InputMaybe<Scalars['Int']>;
  carAttrAccidentBool?: InputMaybe<Scalars['Boolean']>;
  carPreOwnerCount?: InputMaybe<Scalars['Int']>;
  carType?: InputMaybe<CarType>;
  categorizedCustomEquipment?: InputMaybe<Array<InputMaybe<CategorizedCustomEquipmentInput>>>;
  co2Value?: InputMaybe<Scalars['Int']>;
  emissionSticker?: InputMaybe<Scalars['String']>;
  equipments?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  fuelConsumption?: InputMaybe<FuelConsumptionDtoInput>;
  fuelType?: InputMaybe<Scalars['Int']>;
  gearType?: InputMaybe<Scalars['Int']>;
  handoverKeyCount?: InputMaybe<Scalars['Int']>;
  huCertificate?: InputMaybe<Scalars['Int']>;
  inspectionExpiryDate?: InputMaybe<Scalars['String']>;
  lastServiceOn?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<MileageDtoInput>;
  originalEngine?: InputMaybe<Scalars['Boolean']>;
  subType?: InputMaybe<Scalars['String']>;
  subTypeExtra?: InputMaybe<Scalars['String']>;
  taxRating?: InputMaybe<Scalars['Int']>;
  tuv?: InputMaybe<TuvdtoInput>;
  wasInCommercialUse?: InputMaybe<Scalars['Boolean']>;
  wheelSets?: InputMaybe<WheelSetsDtoInput>;
};

export type AdVideoDto = {
  __typename?: 'AdVideoDTO';
  adId?: Maybe<Scalars['UUID']>;
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  updated?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export type AdVideoDtoInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  created?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  updated?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<Scalars['String']>;
};

export type AddressTaxDetailsDto = {
  __typename?: 'AddressTaxDetailsDto';
  number?: Maybe<Scalars['String']>;
};

export type AddressTaxDetailsProjection = {
  __typename?: 'AddressTaxDetailsProjection';
  number?: Maybe<Scalars['String']>;
};

export type AddressTaxDetailsProjectionInput = {
  number?: InputMaybe<Scalars['String']>;
};

export type AgentAnswerTemplateProjection = {
  __typename?: 'AgentAnswerTemplateProjection';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  teams?: Maybe<Array<Maybe<LiveChatAgentTeamProjection>>>;
};

export type AgentUnreadChatsCountProjection = {
  __typename?: 'AgentUnreadChatsCountProjection';
  agentExists: Scalars['Boolean'];
  unreadCount: Scalars['Int'];
};

export type AiSettingProjection = {
  __typename?: 'AiSettingProjection';
  aiHistoryMessageLimit?: Maybe<Scalars['Int']>;
  contextQuestionLimit?: Maybe<Scalars['Int']>;
  contextQuestionMaxTokens?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<RetailUserDto>;
  id?: Maybe<Scalars['UUID']>;
  locale?: Maybe<Scalars['String']>;
  maxTokens?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['String']>;
  similarityScoreThreshold?: Maybe<Scalars['Int']>;
  systemMessage?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['Float']>;
  topP?: Maybe<Scalars['Float']>;
  useLastPredefinedMessage?: Maybe<Scalars['Boolean']>;
  userMessageMaxTokens?: Maybe<Scalars['Int']>;
  versionName?: Maybe<Scalars['String']>;
};

export type AiSettingsCreateProjectionInput = {
  aiHistoryMessageLimit?: InputMaybe<Scalars['Int']>;
  contextQuestionLimit?: InputMaybe<Scalars['Int']>;
  contextQuestionMaxTokens?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  maxTokens?: InputMaybe<Scalars['Int']>;
  model?: InputMaybe<Scalars['String']>;
  similarityScoreThreshold?: InputMaybe<Scalars['Int']>;
  systemMessage?: InputMaybe<Scalars['String']>;
  temperature?: InputMaybe<Scalars['Float']>;
  topP?: InputMaybe<Scalars['Float']>;
  useLastPredefinedMessage?: InputMaybe<Scalars['Boolean']>;
  userMessageMaxTokens?: InputMaybe<Scalars['Int']>;
};

export type AiSettingsSearchProjectionInput = {
  locale?: InputMaybe<Scalars['String']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type AmountInfo = {
  __typename?: 'AmountInfo';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
};

export type ApplyPriceUpdateProjectionInput = {
  dataImportId?: InputMaybe<Scalars['UUID']>;
  priceUpdateType?: InputMaybe<PriceUpdateType>;
};

export enum ApprovalLevel {
  Level_1 = 'LEVEL_1',
  Level_2 = 'LEVEL_2',
  Level_3 = 'LEVEL_3'
}

export type AssigneeSearchProjection = {
  __typename?: 'AssigneeSearchProjection';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type AttachmentUploadPresignedUrlProjection = {
  __typename?: 'AttachmentUploadPresignedUrlProjection';
  httpMethod?: Maybe<Scalars['String']>;
  internalFilename?: Maybe<Scalars['String']>;
  presignedUrl?: Maybe<Scalars['String']>;
};

export type AutoheroChatBotLiveAgentLocalisedCountersProjection = {
  __typename?: 'AutoheroChatBotLiveAgentLocalisedCountersProjection';
  agentReplyPendingChatsCount?: Maybe<Scalars['Int']>;
  customerReplyPendingChatsCount?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
};

export type AutoheroChatBotLiveAgentProjection = {
  __typename?: 'AutoheroChatBotLiveAgentProjection';
  activeChatsCount?: Maybe<Scalars['Int']>;
  activeWhatsAppChatsCount?: Maybe<Scalars['Int']>;
  boUserId?: Maybe<Scalars['UUID']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  locale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  localisedCounters?: Maybe<Array<Maybe<AutoheroChatBotLiveAgentLocalisedCountersProjection>>>;
  replyTimeoutMinutes?: Maybe<Scalars['Int']>;
  replyWhatsAppTimeoutMinutes?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type AutoheroChatBotLiveChatProjection = {
  __typename?: 'AutoheroChatBotLiveChatProjection';
  agentId?: Maybe<Scalars['UUID']>;
  chatType?: Maybe<Scalars['String']>;
  conversationId?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  customerFullName?: Maybe<Scalars['String']>;
  customerUserId?: Maybe<Scalars['UUID']>;
  dialogId?: Maybe<Scalars['UUID']>;
  dialogProperties?: Maybe<Scalars['Map_String_ObjectScalar']>;
  id?: Maybe<Scalars['UUID']>;
  latestAgentActivityTs?: Maybe<Scalars['String']>;
  latestUserActivityTs?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  phoneNumber?: Maybe<Scalars['String']>;
  readByAgent: Scalars['Boolean'];
  selectedTopics?: Maybe<Array<Maybe<AutoheroLiveChatTopicProjection>>>;
  status?: Maybe<Scalars['String']>;
};

export type AutoheroLiveChatTopicProjection = {
  __typename?: 'AutoheroLiveChatTopicProjection';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export enum AutoheroUserType {
  AnonymousMobileAppUser = 'ANONYMOUS_MOBILE_APP_USER',
  RegularUser = 'REGULAR_USER'
}

export enum AutomaticVerificationReason {
  BasedOnPreviousOrder = 'BASED_ON_PREVIOUS_ORDER',
  HighCreditScore = 'HIGH_CREDIT_SCORE'
}

export type AuxUserDto = {
  __typename?: 'AuxUserDTO';
  autoheroUserType?: Maybe<AutoheroUserType>;
  billingAddressId?: Maybe<Scalars['UUID']>;
  carRegistrationAddressId?: Maybe<Scalars['UUID']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryAddressId?: Maybe<Scalars['UUID']>;
  deviceId?: Maybe<Scalars['String']>;
  doiSentOn?: Maybe<Scalars['String']>;
  doiVerifiedOn?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  loginTime?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  registeredFrom?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Maybe<RoleDto>>>;
  salutation?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  userRegistrationSource?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['Int']>;
  verified: Scalars['Boolean'];
};

export type AvailabilityConditionCreateProjectionInput = {
  name: Scalars['String'];
  parameters: ConditionParametersProjectionInput;
  type: ConditionType;
};

export type AvailabilityConditionProjection = {
  __typename?: 'AvailabilityConditionProjection';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  parameters: ConditionParametersProjection;
  type: ConditionType;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type AvailabilityRuleCreateProjectionInput = {
  active: Scalars['Boolean'];
  conditions: Array<InputMaybe<AvailabilityConditionCreateProjectionInput>>;
  expression: Scalars['Map_String_ObjectScalar'];
  productId: Scalars['UUID'];
};

export type AvailabilityRuleProjection = {
  __typename?: 'AvailabilityRuleProjection';
  active: Scalars['Boolean'];
  conditions: Array<Maybe<AvailabilityConditionProjection>>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn: Scalars['String'];
  expression: Scalars['Map_String_ObjectScalar'];
  id: Scalars['UUID'];
  productId: Scalars['UUID'];
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type AvailabilityRuleUpdateProjectionInput = {
  active: Scalars['Boolean'];
  conditions: Array<InputMaybe<AvailabilityConditionCreateProjectionInput>>;
  expression: Scalars['Map_String_ObjectScalar'];
};

export type AwsImageUploadRequestProjectionInput = {
  contentType?: InputMaybe<Scalars['String']>;
};

export type AwsImageUploadResponseProjection = {
  __typename?: 'AwsImageUploadResponseProjection';
  fileName?: Maybe<Scalars['String']>;
  formData?: Maybe<Scalars['Map_String_StringScalar']>;
  host?: Maybe<Scalars['String']>;
  id?: Maybe<ImageIdDto>;
};

export type AwsS3AdVideoPresignedUrlDto = {
  __typename?: 'AwsS3AdVideoPresignedUrlDTO';
  adId?: Maybe<Scalars['UUID']>;
  fileName?: Maybe<Scalars['String']>;
  httpMethod?: Maybe<Scalars['String']>;
  presignedUrl?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AwsS3AdVideoPresignedUrlRequestDtoInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  fileName?: InputMaybe<Scalars['String']>;
  httpMethod?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type AwsS3PresignedUrlDto = {
  __typename?: 'AwsS3PresignedUrlDTO';
  fileName?: Maybe<Scalars['String']>;
  httpMethod?: Maybe<Scalars['String']>;
  presignedUrl?: Maybe<Scalars['String']>;
};

export type AwsS3PresignedUrlRequestDtoInput = {
  fileName?: InputMaybe<Scalars['String']>;
  httpMethod?: InputMaybe<Scalars['String']>;
};

export type AwsUploadCredentialsDto = {
  __typename?: 'AwsUploadCredentialsDTO';
  cdn?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  formData?: Maybe<Scalars['Map_String_StringScalar']>;
  host?: Maybe<Scalars['String']>;
};

export type AwsUploadCredentialsProjection = {
  __typename?: 'AwsUploadCredentialsProjection';
  awsUploadCredentialsDTOS?: Maybe<Array<Maybe<AwsUploadCredentialsDto>>>;
};

export type AwsUploadCredentialsRequestDtoInput = {
  documentTypeId?: InputMaybe<Scalars['UUID']>;
  fileExt?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
};

export type AwsUploadCredentialsRequestProjectionInput = {
  uploadCredentialsRequestDTOs?: InputMaybe<Array<InputMaybe<AwsUploadCredentialsRequestDtoInput>>>;
};

export type BpmTaxTaskProjection = {
  __typename?: 'BPMTaxTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type BankAccountDetailsProjection = {
  __typename?: 'BankAccountDetailsProjection';
  accountDataId?: Maybe<Scalars['UUID']>;
  bic?: Maybe<Scalars['String']>;
  holder?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
};

export type BankAccountDetailsProjectionInput = {
  accountDataId?: InputMaybe<Scalars['UUID']>;
  bic?: InputMaybe<Scalars['String']>;
  holder?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
};

export type BankAccountDetailsWithDocumentsProjection = {
  __typename?: 'BankAccountDetailsWithDocumentsProjection';
  accountDataId?: Maybe<Scalars['UUID']>;
  bic?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Maybe<RetailDocumentProjection>>>;
  holder?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
};

export type BankDetailsPendingTaskProjection = {
  __typename?: 'BankDetailsPendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  claim?: Maybe<RetailClaimProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  cost?: Maybe<ClaimCostWrapperProjection>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderCanceledOn?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refundAmount?: Maybe<PriceDto>;
  repeatAt?: Maybe<Scalars['String']>;
  requestedAmount?: Maybe<Scalars['Long']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type BankTransferBankAccountDetailsProjection = {
  __typename?: 'BankTransferBankAccountDetailsProjection';
  TYPE?: Maybe<Scalars['String']>;
  accountDataId?: Maybe<Scalars['UUID']>;
  accountNumber?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  clearingNumber?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Maybe<RetailDocumentProjection>>>;
  holder?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type BatchProjection_AwsS3AdVideoPresignedUrlRequestDtoInput = {
  entities?: InputMaybe<Array<InputMaybe<AwsS3AdVideoPresignedUrlRequestDtoInput>>>;
};

export type BatchProjection_AwsS3PresignedUrlRequestDtoInput = {
  entities?: InputMaybe<Array<InputMaybe<AwsS3PresignedUrlRequestDtoInput>>>;
};

export type BatchProjection_CarRegistrationActionImportCreateProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<CarRegistrationActionImportCreateProjectionInput>>>;
};

export type BatchProjection_ChatbotQuestionImportCreateProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<ChatbotQuestionImportCreateProjectionInput>>>;
};

export type BatchProjection_ClaimCostDistributionProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<ClaimCostDistributionProjectionInput>>>;
};

export type BatchProjection_HighlightProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<HighlightProjectionInput>>>;
};

export type BatchProjection_InventorySourcingImportCreateProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<InventorySourcingImportCreateProjectionInput>>>;
};

export type BatchProjection_OfflineLeadsNewsletterSubscriptionImportProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<OfflineLeadsNewsletterSubscriptionImportProjectionInput>>>;
};

export type BatchProjection_PriceUpdateItemImportCreateProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<PriceUpdateItemImportCreateProjectionInput>>>;
};

export type BatchProjection_ProfitAndLossImportCreateProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<ProfitAndLossImportCreateProjectionInput>>>;
};

export type BatchProjection_RetailAdDeliveryOptionProjection = {
  __typename?: 'BatchProjection_RetailAdDeliveryOptionProjection';
  entities?: Maybe<Array<Maybe<RetailAdDeliveryOptionProjection>>>;
};

export type BatchProjection_RetailDocumentInventoryImportCreateProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<RetailDocumentInventoryImportCreateProjectionInput>>>;
};

export type BatchProjection_RetailDocumentPackageUpdateImportProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<RetailDocumentPackageUpdateImportProjectionInput>>>;
};

export type BatchProjection_RetailInternalTransferPaymentProjection = {
  __typename?: 'BatchProjection_RetailInternalTransferPaymentProjection';
  entities?: Maybe<Array<Maybe<RetailInternalTransferPaymentProjection>>>;
};

export type BatchProjection_RetailPaymentImportCreateProjectionInput = {
  entities?: InputMaybe<Array<InputMaybe<RetailPaymentImportCreateProjectionInput>>>;
};

export type BatchProjection_RetailTransitionInternalTransferDtoInput = {
  entities?: InputMaybe<Array<InputMaybe<RetailTransitionInternalTransferDtoInput>>>;
};

export type BatchProjection_UuidInput = {
  entities?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
};

export type BrakeConditionProjection = {
  __typename?: 'BrakeConditionProjection';
  condition?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

export type BranchDto = {
  __typename?: 'BranchDTO';
  area?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  partner?: Maybe<Scalars['ObjectScalar']>;
  phone?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected?: Maybe<Scalars['Boolean']>;
  street?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type BranchDeliveryOptionRequestDtoInput = {
  branchTypes?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  cursor?: InputMaybe<Scalars['Int']>;
  direction?: InputMaybe<Direction>;
  excludedBranches?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  fetchSize?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  pointToCalculateDistanceFrom?: InputMaybe<LatLngInput>;
  pointToSearchBranches?: InputMaybe<LatLngInput>;
};

export type BranchEntryCheckCarSupportedProjection = {
  __typename?: 'BranchEntryCheckCarSupportedProjection';
  isSupported?: Maybe<Scalars['Boolean']>;
};

export type BranchEntryCheckComplementaryProjection = {
  __typename?: 'BranchEntryCheckComplementaryProjection';
  adminUser?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['String']>;
  deliveryLocation?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type BranchEntryCheckDetailsProjection = {
  __typename?: 'BranchEntryCheckDetailsProjection';
  adId?: Maybe<Scalars['UUID']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['LocalDateTime']>;
  data?: Maybe<BranchEntrySubmissionDto>;
  id?: Maybe<Scalars['UUID']>;
  images?: Maybe<Scalars['Map_UUID_URLScalar']>;
  location?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['Int']>;
  mostRecent: Scalars['Boolean'];
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  plannedHandoverDate?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['UUID']>;
  refNumber?: Maybe<Scalars['String']>;
  status?: Maybe<BranchEntryCheckStatus>;
  statusSetBy?: Maybe<Scalars['UUID']>;
  statusSetOn?: Maybe<Scalars['LocalDateTime']>;
  stockNumber?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type BranchEntryCheckSearchItemDto = {
  __typename?: 'BranchEntryCheckSearchItemDTO';
  adId?: Maybe<Scalars['UUID']>;
  country?: Maybe<Scalars['String']>;
  entryCheckDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  location?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  plannedHandoverDate?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['UUID']>;
  refNumber?: Maybe<Scalars['String']>;
  status?: Maybe<BranchEntryCheckStatus>;
  stockNumber?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export enum BranchEntryCheckStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type BranchEntryCheckSubmissionResultProjection = {
  __typename?: 'BranchEntryCheckSubmissionResultProjection';
  id?: Maybe<Scalars['UUID']>;
};

export type BranchEntrySubmissionDto = {
  __typename?: 'BranchEntrySubmissionDTO';
  comment?: Maybe<Scalars['String']>;
  commentPhotos?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  damages?: Maybe<Array<Maybe<DamageDto>>>;
  exterior?: Maybe<ExteriorDto>;
  general?: Maybe<GeneralDto>;
  hasDamages?: Maybe<Scalars['Boolean']>;
  interior?: Maybe<InteriorDto>;
};

export type BranchEntrySubmissionDtoInput = {
  comment?: InputMaybe<Scalars['String']>;
  commentPhotos?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  damages?: InputMaybe<Array<InputMaybe<DamageDtoInput>>>;
  exterior?: InputMaybe<ExteriorDtoInput>;
  general?: InputMaybe<GeneralDtoInput>;
  hasDamages?: InputMaybe<Scalars['Boolean']>;
  interior?: InputMaybe<InteriorDtoInput>;
};

export type BranchForSelectionSearchProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  branchTypes?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  distance?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Int']>;
  retailCountry?: InputMaybe<Scalars['String']>;
};

export type BranchProjection = {
  __typename?: 'BranchProjection';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  branchHead?: Maybe<Scalars['String']>;
  capacityPlanningEnabled: Scalars['Boolean'];
  carShippingProvider?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  defaultDocumentStorageId?: Maybe<Scalars['Int']>;
  directionsHtml?: Maybe<Scalars['String']>;
  documentShippingProvider?: Maybe<Scalars['Int']>;
  gmapsLarge?: Maybe<Scalars['String']>;
  gmapsLink?: Maybe<Scalars['String']>;
  handoverAppointmentsEnabled: Scalars['Boolean'];
  handoverProtocolEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  navigationInfo?: Maybe<Scalars['String']>;
  openingHoursMerchantText?: Maybe<Scalars['String']>;
  openingHoursText?: Maybe<Scalars['String']>;
  overbookingEnabled: Scalars['Boolean'];
  partner?: Maybe<PartnerDto>;
  phone?: Maybe<Scalars['String']>;
  premiumHandlingEnabledOn?: Maybe<Scalars['String']>;
  priorityBookingEnabled: Scalars['Boolean'];
  privateSaleEnabled?: Maybe<Scalars['Boolean']>;
  refurbishmentPartnerId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  twilioRetriesEnabled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['Int']>;
  videoUploadEnabled: Scalars['Boolean'];
  zipcode?: Maybe<Scalars['String']>;
};

export type BranchSearchingResult_DocumentBranchProjection = {
  __typename?: 'BranchSearchingResult_DocumentBranchProjection';
  hubId?: Maybe<Scalars['Int']>;
  nodes: Array<Maybe<DocumentBranchProjection>>;
  totalCount: Scalars['Long'];
};

export type CamundaSearchProjection2Input = {
  filter?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sorts?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
};

export type CancelRequestDto = {
  __typename?: 'CancelRequestDTO';
  cancelPeriod?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelType?: Maybe<Scalars['String']>;
  subCancelReason?: Maybe<Scalars['String']>;
};

export type CancellationRequestedTaskProjection = {
  __typename?: 'CancellationRequestedTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  cancelPeriod?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelType?: Maybe<Scalars['String']>;
  cancellationRequestedDate?: Maybe<Scalars['String']>;
  carHandoverOnDate?: Maybe<Scalars['String']>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  documentsRegistrationReadyDate?: Maybe<Scalars['String']>;
  documentsRequestedDate?: Maybe<Scalars['String']>;
  finalDocumentHandoverDate?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  hasTradeInItem?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderFinanceRejectReasons?: Maybe<Array<Maybe<OrderFinanceRejectReasonProjection>>>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  registrationInitiatedDate?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  returnDeliveryType?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  subCancelReason?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type CancellationTaskProjection = {
  __typename?: 'CancellationTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  cancellationReasonComment?: Maybe<Scalars['String']>;
  cashOutReason?: Maybe<Scalars['String']>;
  cashOutRequestDate?: Maybe<Scalars['String']>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refundAmount?: Maybe<PriceDto>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type CarDtoInput = {
  gps?: InputMaybe<GpsDtoInput>;
  hasFirstAidKit?: InputMaybe<Scalars['Boolean']>;
  hasFloorMat?: InputMaybe<Scalars['Boolean']>;
  hasKey?: InputMaybe<Scalars['Boolean']>;
  hasSecondKey?: InputMaybe<Scalars['Boolean']>;
  hasSecurityTriangle?: InputMaybe<Scalars['Boolean']>;
  hasSpareTire?: InputMaybe<Scalars['Boolean']>;
  hasTireFit?: InputMaybe<Scalars['Boolean']>;
  hasTrunkCover?: InputMaybe<Scalars['Boolean']>;
  hasWarningVest?: InputMaybe<Scalars['Boolean']>;
  millage?: InputMaybe<Scalars['Int']>;
  primaryWheels?: InputMaybe<WheelDtoInput>;
  radio?: InputMaybe<RadioDtoInput>;
  secondaryWheels?: InputMaybe<WheelDtoInput>;
};

export type CarDeregistrationTaskProjection = {
  __typename?: 'CarDeregistrationTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  documentsRegistrationReadyDate?: Maybe<Scalars['String']>;
  documentsRequestedDate?: Maybe<Scalars['String']>;
  finalDocumentHandoverDate?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  registrationInitiatedDate?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  returnDeliveryType?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type CarDetailsStoreAdPlainProjection = {
  __typename?: 'CarDetailsStoreAdPlainProjection';
  adId?: Maybe<Scalars['UUID']>;
  ahMainImageUrl?: Maybe<Scalars['String']>;
  builtYear?: Maybe<Scalars['Int']>;
  co2Value?: Maybe<Scalars['Float']>;
  demandScore?: Maybe<Scalars['Float']>;
  downPriceMargin?: Maybe<Price>;
  drivetrain?: Maybe<Scalars['String']>;
  energyEfficiencyClass?: Maybe<Scalars['String']>;
  financedPrice?: Maybe<Price>;
  firstFinancedPrice?: Maybe<Price>;
  firstPrice?: Maybe<Price>;
  fuelConsumption?: Maybe<FuelConsumptionDto>;
  fuelType?: Maybe<Scalars['Int']>;
  gearType?: Maybe<Scalars['Int']>;
  isComingSoon?: Maybe<Scalars['Boolean']>;
  mainImageUrl?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  mileage?: Maybe<MileageDto>;
  model?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  published?: Maybe<Scalars['Boolean']>;
  registration?: Maybe<Scalars['String']>;
  retailCountry?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  subType?: Maybe<Scalars['String']>;
  subTypeExtra?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatType?: Maybe<Scalars['Int']>;
  vehicleId?: Maybe<Scalars['Long']>;
  vin?: Maybe<Scalars['String']>;
};

export type CarRegistrationActionImportCreateProjectionInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export type CarRegistrationActionProjection = {
  __typename?: 'CarRegistrationActionProjection';
  actionCanonical?: Maybe<Scalars['String']>;
  actionHumanReadable?: Maybe<Scalars['String']>;
  applied: Scalars['Boolean'];
  appliedOn?: Maybe<Scalars['String']>;
  canBeApplied: Scalars['Boolean'];
  eta?: Maybe<Scalars['String']>;
  reasonNotApplicable?: Maybe<Scalars['String']>;
};

export type CarRegistrationItemProjection = {
  __typename?: 'CarRegistrationItemProjection';
  carRegistration?: Maybe<RetailCarRegistrationProjection>;
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customDiscountMinorUnits?: Maybe<Scalars['Long']>;
  customDiscountType?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  obsolete?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderItemRefund?: Maybe<Scalars['Long']>;
  parentId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type CarRegistrationStatusDictionaryDto = {
  __typename?: 'CarRegistrationStatusDictionaryDTO';
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export enum CarRegistrationType {
  AhDigitalCarRegistration = 'AH_DIGITAL_CAR_REGISTRATION',
  AhDigitalCarRegistrationWithCustomPlate = 'AH_DIGITAL_CAR_REGISTRATION_WITH_CUSTOM_PLATE',
  AhRegistration = 'AH_REGISTRATION',
  AhRegistrationTemporary = 'AH_REGISTRATION_TEMPORARY',
  AhRegistrationWithLicensePlate = 'AH_REGISTRATION_WITH_LICENSE_PLATE',
  SelfRegistration = 'SELF_REGISTRATION'
}

export type CarReturnHandoverAppointmentTaskProjection = {
  __typename?: 'CarReturnHandoverAppointmentTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  carReturnDate?: Maybe<Scalars['String']>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  handoverDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  returnDeliveryType?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type CarReturnedTaskProjection = {
  __typename?: 'CarReturnedTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  carReturnDate?: Maybe<Scalars['String']>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  handoverDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  returnDeliveryType?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export enum CarType {
  Ctte = 'CTTE',
  Vp = 'VP'
}

export type CarTypeBasedPriceModifierProjection = {
  __typename?: 'CarTypeBasedPriceModifierProjection';
  active: Scalars['Boolean'];
  carType: Scalars['String'];
  createdBy: Scalars['UUID'];
  createdOn?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  pnlCostPrice?: Maybe<PriceDetailsProjection>;
  price?: Maybe<PriceDetailsProjection>;
  priceMinorUnits: Scalars['Int'];
  priceModifierInstance: Scalars['String'];
  productId: Scalars['UUID'];
  updatedOn?: Maybe<Scalars['String']>;
};

export type CarTypeBasedPriceModifierUpdateProjectionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  grossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  priceMinorUnits?: InputMaybe<Scalars['Int']>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
};

export type CareCallTaskProjection = {
  __typename?: 'CareCallTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  carHandoverOnDate?: Maybe<Scalars['String']>;
  carReservedOnDate?: Maybe<Scalars['String']>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderContractSignedOn?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type CashOutRefundApprovalPendingTaskProjection = {
  __typename?: 'CashOutRefundApprovalPendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  cancellationReasonComment?: Maybe<Scalars['String']>;
  cashOutReason?: Maybe<Scalars['String']>;
  cashOutRequestDate?: Maybe<Scalars['String']>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refundAmount?: Maybe<PriceDto>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type CategorizedCustomEquipment = {
  __typename?: 'CategorizedCustomEquipment';
  categoryId?: Maybe<Scalars['UUID']>;
  customEquipments?: Maybe<Array<Maybe<CustomEquipment>>>;
};

export type CategorizedCustomEquipmentInput = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  customEquipments?: InputMaybe<Array<InputMaybe<CustomEquipmentInput>>>;
};

export type CategorizedEquipmentsDto = {
  __typename?: 'CategorizedEquipmentsDTO';
  categoryId?: Maybe<Scalars['UUID']>;
  categoryName?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<EquipmentItemDto>>>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  translationKey?: Maybe<Scalars['String']>;
};

export type ChangeDocumentPackageStatusInput = {
  deliveredOn?: InputMaybe<Scalars['String']>;
  estimatedDeliveryDate?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  pickedUpOn?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
};

export type ChangeDocumentPackageStatusProjection = {
  __typename?: 'ChangeDocumentPackageStatusProjection';
  documentInventoryItems?: Maybe<ResultConnection_DocumentInventoryItem>;
  documentInventoryPackages?: Maybe<ResultConnection_DocumentInventoryPackageProjection>;
};

export type ChangeDocumentPackageStatusResult = ChangeDocumentPackageStatusProjection | DocumentPackageAlreadyCanceledProblem | DocumentPackageAlreadyDeliveredProblem | DocumentPackageEmptyDeliveredOnProblem | DocumentPackageEmptyPickedUpOnProblem | DocumentPackageSameStatusProblem | DocumentPackagesGeneralProblem;

export type ChangeInput = {
  id?: InputMaybe<Scalars['UUID']>;
  value: Scalars['Boolean'];
};

export type ChangelogProjection = {
  __typename?: 'ChangelogProjection';
  action?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Long']>;
  newValue?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['UUID']>;
  objectType?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentType?: Maybe<Scalars['String']>;
};

export type ChatbotLiveChatAgentDataProjection = {
  __typename?: 'ChatbotLiveChatAgentDataProjection';
  activeChats?: Maybe<Array<Maybe<AutoheroChatBotLiveChatProjection>>>;
  agent?: Maybe<AutoheroChatBotLiveAgentProjection>;
  archivedChats?: Maybe<Array<Maybe<AutoheroChatBotLiveChatProjection>>>;
  availableTopics?: Maybe<Array<Maybe<AutoheroLiveChatTopicProjection>>>;
};

export type ChatbotLiveChatAgentDataRequestProjectionInput = {
  activeLimit?: InputMaybe<Scalars['Int']>;
  archiveLimit?: InputMaybe<Scalars['Int']>;
};

export type ChatbotQuestionCreateProjectionInput = {
  answer?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
};

export type ChatbotQuestionDto = {
  __typename?: 'ChatbotQuestionDTO';
  answer?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  question?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
};

export type ChatbotQuestionImportCreateProjectionInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export type ChatbotQuestionUpdateProjectionInput = {
  answer?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
};

export type CheckoutPaymentDetailsProjection = {
  __typename?: 'CheckoutPaymentDetailsProjection';
  directPaymentMethod?: Maybe<Scalars['String']>;
  directPaymentOption?: Maybe<Scalars['String']>;
};

export type CheckoutPaymentDetailsUpdateProjectionInput = {
  directPaymentOption?: InputMaybe<Scalars['String']>;
};

export enum CheckoutSource {
  Autohero = 'AUTOHERO',
  Backoffice = 'BACKOFFICE'
}

export type ClaimChangelogResponseProjection = {
  __typename?: 'ClaimChangelogResponseProjection';
  claimId?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Long']>;
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeNumber?: Maybe<Scalars['String']>;
};

export type ClaimCostCompensationProjection = {
  __typename?: 'ClaimCostCompensationProjection';
  accountId?: Maybe<Scalars['UUID']>;
  approveStatus?: Maybe<Scalars['String']>;
  approvedBy?: Maybe<UserProjection>;
  approvedOn?: Maybe<Scalars['String']>;
  claimCostNumber?: Maybe<Scalars['String']>;
  claimId?: Maybe<Scalars['UUID']>;
  costCurrencyCode?: Maybe<Scalars['String']>;
  costMinorUnits?: Maybe<Scalars['Long']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  editState?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  retailOutboundPayment?: Maybe<RetailOutboundPaymentProjection>;
};

export type ClaimCostCompensationUpdateProjectionInput = {
  accountDataId?: InputMaybe<Scalars['UUID']>;
  approvalStatus?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  requestBankDetails?: InputMaybe<Scalars['Boolean']>;
};

export type ClaimCostDistributionProjectionInput = {
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  subClaimId?: InputMaybe<Scalars['UUID']>;
};

export type ClaimCostWrapperProjection = {
  __typename?: 'ClaimCostWrapperProjection';
  approvalLevel?: Maybe<Scalars['String']>;
  approvalLevelSetBy?: Maybe<UserProjection>;
  approvalLevelSetOn?: Maybe<Scalars['String']>;
  costCurrencyCode?: Maybe<Scalars['String']>;
  costMinorUnits?: Maybe<Scalars['Long']>;
  costType?: Maybe<Scalars['String']>;
  costTypeCategory?: Maybe<Scalars['String']>;
  customerDecision?: Maybe<Scalars['String']>;
  customerDecisionSetBy?: Maybe<UserProjection>;
  customerDecisionSetOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  level?: Maybe<ApprovalLevel>;
  number?: Maybe<Scalars['String']>;
  orderItemId?: Maybe<Scalars['UUID']>;
};

export type ClaimDetailsProjection = {
  __typename?: 'ClaimDetailsProjection';
  claimCostId?: Maybe<Scalars['UUID']>;
};

export type ClaimDetailsProjectionInput = {
  claimCostId?: InputMaybe<Scalars['UUID']>;
};

export type ClaimPartialRefundPendingTaskProjection = {
  __typename?: 'ClaimPartialRefundPendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  claim?: Maybe<RetailClaimProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  cost?: Maybe<ClaimCostWrapperProjection>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type ClaimPartialRefundProjection = {
  __typename?: 'ClaimPartialRefundProjection';
  accountDataId?: Maybe<Scalars['UUID']>;
  accountType?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  state?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type ClaimProofPendingTaskProjection = {
  __typename?: 'ClaimProofPendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  claim?: Maybe<RetailClaimProjection>;
  claimGroup?: Maybe<RetailClaimGroupProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type ClaimProofSubmittedTaskProjection = {
  __typename?: 'ClaimProofSubmittedTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  claim?: Maybe<RetailClaimProjection>;
  claimGroup?: Maybe<RetailClaimGroupProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type ClosedReasonProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
};

export enum ColorCoding {
  Green = 'GREEN',
  Grey = 'GREY',
  Orange = 'ORANGE',
  Red = 'RED',
  Yellow = 'YELLOW'
}

export type ColorDto = {
  __typename?: 'ColorDTO';
  metallic?: Maybe<Scalars['Boolean']>;
  outside?: Maybe<Scalars['Int']>;
};

export type CommentProjection = {
  __typename?: 'CommentProjection';
  comment: Scalars['String'];
  createdBy?: Maybe<UserProjection>;
  createdById?: Maybe<Scalars['UUID']>;
  date: Scalars['String'];
  id: Scalars['UUID'];
};

export type CommentTagProjection = {
  __typename?: 'CommentTagProjection';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum CompanyType {
  BgbSocietyGbr = 'BGB_SOCIETY_GBR',
  CivilLawPartnershipGbr = 'CIVIL_LAW_PARTNERSHIP_GBR',
  Cooperative = 'COOPERATIVE',
  EntrepreneurialCorporation = 'ENTREPRENEURIAL_CORPORATION',
  Foundation = 'FOUNDATION',
  GeneralPartnership = 'GENERAL_PARTNERSHIP',
  LimitedPartnership = 'LIMITED_PARTNERSHIP',
  Llc = 'LLC',
  LlcAndLimitedPartnership = 'LLC_AND_LIMITED_PARTNERSHIP',
  PublicCompany = 'PUBLIC_COMPANY',
  RegisteredAssociation = 'REGISTERED_ASSOCIATION',
  RegisteredSoleProprietorship = 'REGISTERED_SOLE_PROPRIETORSHIP'
}

export type CompileOrderEmailTemplateProjectionInput = {
  orderId?: InputMaybe<Scalars['UUID']>;
  placeholders?: InputMaybe<Array<InputMaybe<OrderHistoryEmailPlaceholderInput>>>;
  templateId?: InputMaybe<Scalars['UUID']>;
};

export type CompiledOrderEmailTemplateProjection = {
  __typename?: 'CompiledOrderEmailTemplateProjection';
  id?: Maybe<Scalars['UUID']>;
  locale?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['String']>;
  replyRequired?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CompleteQuestionRequestProjectionInput = {
  contextQuestionLimit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  maxTokens?: InputMaybe<Scalars['Int']>;
  model?: InputMaybe<Scalars['String']>;
  selectedTopic?: InputMaybe<Scalars['String']>;
  similarityScoreThreshold?: InputMaybe<Scalars['Int']>;
  stockNumber?: InputMaybe<Scalars['String']>;
  systemMessage?: InputMaybe<Scalars['String']>;
  temperature?: InputMaybe<Scalars['Float']>;
  topP?: InputMaybe<Scalars['Float']>;
  userMessage?: InputMaybe<Scalars['String']>;
  userMessageMaxTokens?: InputMaybe<Scalars['Int']>;
};

export type CompleteQuestionResponseProjection = {
  __typename?: 'CompleteQuestionResponseProjection';
  aiResponse?: Maybe<Scalars['String']>;
  completionContext?: Maybe<Array<Maybe<CompletionContextProjection>>>;
  tokenStats?: Maybe<TokenStatsProjection>;
};

export type CompletionContextProjection = {
  __typename?: 'CompletionContextProjection';
  context?: Maybe<Scalars['String']>;
  derivedTopic?: Maybe<Scalars['String']>;
  similarityScore?: Maybe<Scalars['Float']>;
};

export type CompletionTextProjection = {
  __typename?: 'CompletionTextProjection';
  id?: Maybe<Scalars['UUID']>;
  text?: Maybe<Scalars['String']>;
};

export type CompositeImageProjection = {
  __typename?: 'CompositeImageProjection';
  adId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  images?: Maybe<Array<Maybe<ImageProjection>>>;
  lastModified?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CompositeImageProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  createdAt?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  images?: InputMaybe<Array<InputMaybe<ImageProjectionInput>>>;
  lastModified?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ConditionParametersProjection = {
  __typename?: 'ConditionParametersProjection';
  abTestName?: Maybe<Scalars['String']>;
  featureName?: Maybe<Scalars['String']>;
  featureUsageDateSource?: Maybe<FeatureUsageDateSource>;
  registrationCountry?: Maybe<Scalars['String']>;
};

export type ConditionParametersProjectionInput = {
  abTestName?: InputMaybe<Scalars['String']>;
  featureName?: InputMaybe<Scalars['String']>;
  featureUsageDateSource?: InputMaybe<FeatureUsageDateSource>;
  registrationCountry?: InputMaybe<Scalars['String']>;
};

export enum ConditionType {
  AbTest = 'AB_TEST',
  Feature = 'FEATURE',
  RegistrationCountry = 'REGISTRATION_COUNTRY'
}

export type CreateAgentAnswerTemplateProjectionInput = {
  assignToTeamIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  content?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateDocumentInventoryTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateDocumentInventoryTypePayload = {
  __typename?: 'CreateDocumentInventoryTypePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  documentInventoryType?: Maybe<DocumentInventoryType>;
  errors?: Maybe<CreateDocumentInventoryTypeValidationError>;
  query?: Maybe<GqlRetailCarDocumentsService>;
};

export type CreateDocumentInventoryTypeValidationError = {
  __typename?: 'CreateDocumentInventoryTypeValidationError';
  message?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['ObjectScalar']>;
};

export type CreateDocumentPackageInput = {
  deliveryAddress: DocumentInventoryPackageAddressInput;
  documentCarItems: Array<InputMaybe<Scalars['UUID']>>;
  orderId: Scalars['UUID'];
  shippingProvider: Scalars['Int'];
  trackingCode?: InputMaybe<Scalars['String']>;
};

export type CreateDocumentPackageProjection = {
  __typename?: 'CreateDocumentPackageProjection';
  documentInventoryPackages: ResultConnection_DocumentInventoryPackageProjection;
};

export type CreateDocumentPackageResult = CreateDocumentPackageProjection | DocumentItemsInPackageProblem | DocumentPackageConflictProblem | DocumentPackageEmptyBranchNameProblem | DocumentPackageEmptyFirstNameProblem | DocumentPackageEmptyLastNameProblem | DocumentPackageNoHouseNumAddressProblem | DocumentPackageZeroItemsProblem | DocumentPackagesGeneralProblem;

export type CreateExternalFinanceRiskEvaluationProjectionInput = {
  contractType?: InputMaybe<Scalars['String']>;
  employmentStatus?: InputMaybe<Scalars['String']>;
  monthlyNetIncomeMinorUnits?: InputMaybe<Scalars['Long']>;
  sameEmployerOverTimePeriod?: InputMaybe<Scalars['Boolean']>;
};

export type CreateOrUpdateExternalFinancingCustomerDetailsProjectionInput = {
  bankAccountOpenDate?: InputMaybe<Scalars['String']>;
  cityOfRegistration?: InputMaybe<Scalars['String']>;
  companyType?: InputMaybe<CompanyType>;
  currentJobStartDate?: InputMaybe<Scalars['String']>;
  housing?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  idCardNumber?: InputMaybe<Scalars['String']>;
  idExpiryDate?: InputMaybe<Scalars['String']>;
  idType?: InputMaybe<Scalars['String']>;
  incomeCurrency?: InputMaybe<Scalars['String']>;
  isCompanyRegisteredLongEnough?: InputMaybe<Scalars['Boolean']>;
  maritalStatus?: InputMaybe<Scalars['String']>;
  moveIntoCurrentHomeDate?: InputMaybe<Scalars['String']>;
  primaryUseOfVehicle?: InputMaybe<PrimaryUseOfVehicle>;
  taxCode?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateExternalFinancingOptionsProjectionInput = {
  ahOpeningFees?: InputMaybe<Scalars['BigDecimal']>;
  damageInsurance?: InputMaybe<Scalars['String']>;
  externalFinancingOptionProviderData?: InputMaybe<Array<InputMaybe<ExternalFinancingOptionProviderDataDtoInput>>>;
  externalFinancingRefNumber?: InputMaybe<Scalars['String']>;
  financingProvider?: InputMaybe<Scalars['String']>;
  gapInsurance?: InputMaybe<Scalars['String']>;
  ppi?: InputMaybe<Scalars['String']>;
};

export type CreateOrderHistoryEmailEventProjectionInput = {
  attachments?: InputMaybe<Array<InputMaybe<OrderEmailEventAttachmentCreateProjectionInput>>>;
  customerEmail?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  recipientEmail?: InputMaybe<Scalars['String']>;
  replyRequired?: InputMaybe<Scalars['Boolean']>;
  subject?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['UUID']>;
  templateName?: InputMaybe<Scalars['String']>;
};

export enum CreateReason {
  CarPurchase = 'CAR_PURCHASE',
  FeesCollection = 'FEES_COLLECTION',
  ItemsPurchase = 'ITEMS_PURCHASE'
}

export type CreateRetailFeatureProjectionInput = {
  condition?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
  description?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateRetailOutboundPaymentProjectionInput = {
  accountDataId?: InputMaybe<Scalars['UUID']>;
  accountDetails?: InputMaybe<RetailAccountDetailsCreateProjectionInput>;
  accountingDate?: InputMaybe<Scalars['String']>;
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  description?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  paymentIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  paymentType?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<RetailOutboundPaymentPurpose>;
  reason?: InputMaybe<RetailOutboundPaymentReasonProjectionInput>;
  status?: InputMaybe<RetailOutboundPaymentStatus>;
  typedAccountDetails?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
};

export type CreatedByProjection = {
  __typename?: 'CreatedByProjection';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type CreditNoteCreateProjectionInput = {
  items?: InputMaybe<Array<InputMaybe<CreditNoteItemCreateProjectionInput>>>;
  orderId?: InputMaybe<Scalars['UUID']>;
  reason?: InputMaybe<Scalars['String']>;
};

export type CreditNoteDistributionProjection = {
  __typename?: 'CreditNoteDistributionProjection';
  amount?: Maybe<RetailFinancePriceProjection>;
  id?: Maybe<Scalars['UUID']>;
  items?: Maybe<Array<Maybe<CreditNoteItemDistributionProjection>>>;
  reason?: Maybe<Scalars['String']>;
  tempId?: Maybe<Scalars['Int']>;
};

export type CreditNoteDistributionProjectionInput = {
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  id?: InputMaybe<Scalars['UUID']>;
  items?: InputMaybe<Array<InputMaybe<CreditNoteItemDistributionProjectionInput>>>;
  reason?: InputMaybe<Scalars['String']>;
  tempId?: InputMaybe<Scalars['Int']>;
};

export type CreditNoteItemCreateProjectionInput = {
  amountMinorUnits: Scalars['Long'];
  invoiceId?: InputMaybe<Scalars['UUID']>;
  orderItemId?: InputMaybe<Scalars['UUID']>;
};

export type CreditNoteItemDistributionProjection = {
  __typename?: 'CreditNoteItemDistributionProjection';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  creditNoteItemId?: Maybe<Scalars['UUID']>;
  invoiceId?: Maybe<Scalars['UUID']>;
  orderItemId?: Maybe<Scalars['UUID']>;
};

export type CreditNoteItemDistributionProjectionInput = {
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  creditNoteItemId?: InputMaybe<Scalars['UUID']>;
  invoiceId?: InputMaybe<Scalars['UUID']>;
  orderItemId?: InputMaybe<Scalars['UUID']>;
};

export type CreditNoteItemProjection = {
  __typename?: 'CreditNoteItemProjection';
  amount?: Maybe<PriceDto>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<InvoiceItemExternalType>;
  id?: Maybe<Scalars['UUID']>;
  orderItemId?: Maybe<Scalars['UUID']>;
  vatKey?: Maybe<Scalars['String']>;
};

export type CreditNoteNumberPendingTaskProjection = {
  __typename?: 'CreditNoteNumberPendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  cancellationReasonComment?: Maybe<Scalars['String']>;
  cashOutReason?: Maybe<Scalars['String']>;
  cashOutRequestDate?: Maybe<Scalars['String']>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refundAmount?: Maybe<PriceDto>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type CreditNoteOutboundPaymentRefDistributionProjection = {
  __typename?: 'CreditNoteOutboundPaymentRefDistributionProjection';
  amount?: Maybe<RetailFinancePriceProjection>;
  creditNoteTempId?: Maybe<Scalars['Int']>;
  refundTempId?: Maybe<Scalars['Int']>;
};

export type CreditNoteOutboundPaymentRefDistributionProjectionInput = {
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  creditNoteTempId?: InputMaybe<Scalars['Int']>;
  refundTempId?: InputMaybe<Scalars['Int']>;
};

export type CreditNoteOutboundPaymentRefProjection = {
  __typename?: 'CreditNoteOutboundPaymentRefProjection';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  id?: Maybe<Scalars['UUID']>;
  outboundPayment?: Maybe<RetailOutboundPaymentProjection>;
};

export type CreditNotePendingTaskProjection = {
  __typename?: 'CreditNotePendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  cancellationReasonComment?: Maybe<Scalars['String']>;
  cashOutReason?: Maybe<Scalars['String']>;
  cashOutRequestDate?: Maybe<Scalars['String']>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refundAmount?: Maybe<PriceDto>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type CreditNoteProjection = {
  __typename?: 'CreditNoteProjection';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceId?: Maybe<Scalars['UUID']>;
  invoicedOn?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<CreditNoteItemProjection>>>;
  number?: Maybe<Scalars['String']>;
  oracleAccountingDate?: Maybe<Scalars['String']>;
  oracleFilePresignedUrl?: Maybe<Scalars['String']>;
  oracleGrossAmount?: Maybe<PriceDto>;
  oracleIssueDate?: Maybe<Scalars['String']>;
  oracleTransactionalNumber?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  outboundPaymentRefs?: Maybe<Array<Maybe<CreditNoteOutboundPaymentRefProjection>>>;
  reason?: Maybe<Scalars['String']>;
  state?: Maybe<CreditNoteState>;
  totalAmount?: Maybe<PriceDto>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Scalars['String']>;
};

export enum CreditNoteState {
  Canceled = 'CANCELED',
  CancellationPending = 'CANCELLATION_PENDING',
  Open = 'OPEN'
}

export type CreditNoteUpdateProjectionInput = {
  invoicedOn?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type CreditRiskDataDto = {
  __typename?: 'CreditRiskDataDTO';
  averageDaysOverdrawn?: Maybe<Scalars['Float']>;
  countryCode?: Maybe<Scalars['String']>;
  creditRiskRate?: Maybe<Scalars['Float']>;
  creditScore?: Maybe<Scalars['Int']>;
  creditScoreProviderType?: Maybe<Scalars['String']>;
  creditScoreRange?: Maybe<Scalars['String']>;
  encashmentPayments?: Maybe<AmountInfo>;
  gamblingExpenses?: Maybe<AmountInfo>;
  hasCreditCard?: Maybe<Scalars['Boolean']>;
  monthlyNetIncome?: Maybe<AmountInfo>;
  unemployedBenefits?: Maybe<AmountInfo>;
};

export type CreditScoreResponseDataProjection = {
  __typename?: 'CreditScoreResponseDataProjection';
  provider?: Maybe<Scalars['String']>;
  riskRate?: Maybe<Scalars['Float']>;
  scoreRange?: Maybe<Scalars['String']>;
  scoreValue?: Maybe<Scalars['Int']>;
};

export type CurrencyCodeProjection = {
  __typename?: 'CurrencyCodeProjection';
  country?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
};

export type CustomEquipment = {
  __typename?: 'CustomEquipment';
  groupId?: Maybe<Scalars['Long']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CustomEquipmentInput = {
  groupId?: InputMaybe<Scalars['Long']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CustomerAddressCreateProjectionInput = {
  additionalAddress?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type CustomerAddressProjection = {
  __typename?: 'CustomerAddressProjection';
  additionalAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  info?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type CustomerAddressUpdateProjectionInput = {
  additionalAddress?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type CustomerDtoInput = {
  hasPowerOfAttorneyForPickUp?: InputMaybe<Scalars['Boolean']>;
  idNumber?: InputMaybe<Scalars['String']>;
};

export type CustomerProjection = {
  __typename?: 'CustomerProjection';
  billingAddress?: Maybe<CustomerAddressProjection>;
  carRegistrationAddress?: Maybe<CustomerAddressProjection>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<CustomerAddressProjection>;
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  loginTime?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  registeredFrom?: Maybe<Scalars['Int']>;
  salutation?: Maybe<Scalars['Int']>;
  userType?: Maybe<Scalars['Int']>;
  verified: Scalars['Boolean'];
};

export type CustomerSearchProjection = {
  __typename?: 'CustomerSearchProjection';
  companyName?: Maybe<Scalars['String']>;
  customerType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type CustomerUserCreateProjectionInput = {
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registeredFrom?: InputMaybe<Scalars['Int']>;
};

export type CustomerUserUpdateProjectionInput = {
  billingAddressId?: InputMaybe<Scalars['UUID']>;
  carRegistrationAddressId?: InputMaybe<Scalars['UUID']>;
  country?: InputMaybe<Scalars['String']>;
  deliveryAddressId?: InputMaybe<Scalars['UUID']>;
  email?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registeredFrom?: InputMaybe<Scalars['Int']>;
  salutation?: InputMaybe<Scalars['Int']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type DamageCompensationPendingTaskProjection = {
  __typename?: 'DamageCompensationPendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  claim?: Maybe<RetailClaimProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  cost?: Maybe<ClaimCostWrapperProjection>;
  createdOn?: Maybe<Scalars['String']>;
  damageCompensationDescription?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type DamageDto = {
  __typename?: 'DamageDTO';
  carPart?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  damageType?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['UUID']>;
};

export type DamageDtoInput = {
  carPart?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  damageType?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['UUID']>;
};

export type DamageImageDto = {
  __typename?: 'DamageImageDTO';
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
};

export type DamageTypeProjection = {
  __typename?: 'DamageTypeProjection';
  translationKey?: Maybe<Scalars['String']>;
};

export type DamagesProjection = {
  __typename?: 'DamagesProjection';
  brakes?: Maybe<Array<Maybe<BrakeConditionProjection>>>;
  retailAd?: Maybe<Array<Maybe<RetailAdDamageProjection>>>;
};

export type DataValidationDto = {
  __typename?: 'DataValidationDTO';
  errors?: Maybe<Scalars['Json']>;
  input?: Maybe<Scalars['Json']>;
  processable: Scalars['Boolean'];
  stockNumber?: Maybe<Scalars['String']>;
};

export type DeleteDocumentInventoryItemInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

export type DeleteDocumentInventoryItemPayload = {
  __typename?: 'DeleteDocumentInventoryItemPayload';
  ad?: Maybe<AdProjection>;
  clientMutationId?: Maybe<Scalars['String']>;
  documentInventoryItem?: Maybe<DocumentInventoryItem>;
};


export type DeleteDocumentInventoryItemPayloadAdArgs = {
  adId: Scalars['UUID'];
};

export type DeliveryEta = {
  __typename?: 'DeliveryETA';
  deliveryETAType?: Maybe<Scalars['String']>;
};

export type DeliveryEtaProjection = {
  __typename?: 'DeliveryEtaProjection';
  daysForDelivery?: Maybe<Scalars['Int']>;
  deliveryEtaRange?: Maybe<DeliveryEtaRangeDto>;
  deliveryEtaType?: Maybe<Scalars['String']>;
};

export type DeliveryEtaRangeDto = {
  __typename?: 'DeliveryEtaRangeDTO';
  earliest?: Maybe<Scalars['String']>;
  latest?: Maybe<Scalars['String']>;
};

export type DeliveryPrice = {
  __typename?: 'DeliveryPrice';
  amount?: Maybe<Scalars['Long']>;
  currency?: Maybe<Scalars['String']>;
  netAmount?: Maybe<Scalars['Long']>;
  vatAmount?: Maybe<Scalars['Long']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type DeliveryRouteProjection = {
  __typename?: 'DeliveryRouteProjection';
  destinationAddress?: Maybe<RetailRouteAddressProjection>;
  items?: Maybe<Array<Maybe<RetailRouteItemProjection>>>;
  originAddress?: Maybe<RetailRouteAddressProjection>;
};

export type DialogAnswerProjection = {
  __typename?: 'DialogAnswerProjection';
  context?: Maybe<Scalars['Map_String_ObjectScalar']>;
  defined?: Maybe<Scalars['Boolean']>;
  display?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  isTopic?: Maybe<Scalars['Boolean']>;
  questionType?: Maybe<Scalars['String']>;
  rateable?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
};

export type DialogHistoryRequestProjectionInput = {
  conversationId?: InputMaybe<Scalars['UUID']>;
  latestMessageTsLimit?: InputMaybe<Scalars['String']>;
  liveChatId?: InputMaybe<Scalars['UUID']>;
};

export type DialogProjection = {
  __typename?: 'DialogProjection';
  completionText?: Maybe<CompletionTextProjection>;
  conversationAction?: Maybe<Scalars['String']>;
  conversationId?: Maybe<Scalars['UUID']>;
  dialogAnswer?: Maybe<DialogAnswerProjection>;
  id?: Maybe<Scalars['UUID']>;
  liveChatId?: Maybe<Scalars['UUID']>;
  properties?: Maybe<Scalars['Map_String_ObjectScalar']>;
  ts?: Maybe<Scalars['String']>;
  userText?: Maybe<Scalars['String']>;
};

export type DigitalCarRegistrationProcessingErrorProjection = {
  __typename?: 'DigitalCarRegistrationProcessingErrorProjection';
  description: Scalars['String'];
};

export type DigitalCarRegistrationProjection = {
  __typename?: 'DigitalCarRegistrationProjection';
  accountData?: Maybe<AccountDataDto>;
  assignedTo?: Maybe<Scalars['UUID']>;
  assignedToUser?: Maybe<RetailUserDto>;
  bankAccountDataId?: Maybe<Scalars['UUID']>;
  carRegistrationAddress?: Maybe<RetailAddressDto>;
  carRegistrationAddressId?: Maybe<Scalars['UUID']>;
  createdOn: Scalars['String'];
  customer?: Maybe<RetailCustomerProjection>;
  customerId?: Maybe<Scalars['UUID']>;
  evbNumber?: Maybe<Scalars['String']>;
  handoverDate?: Maybe<Scalars['String']>;
  history?: Maybe<Array<DigitalCarRegistrationStatusHistoryProjection>>;
  holderPlaceOfBirth?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  licensePlate?: Maybe<LicensePlateProjection>;
  licensePlatePin?: Maybe<Scalars['String']>;
  lowEmissionVehicle?: Maybe<Scalars['Boolean']>;
  oldLicensePlate?: Maybe<LicensePlateProjection>;
  oldLicensePlateFrontSecurityCode?: Maybe<Scalars['String']>;
  oldLicensePlateRearSecurityCode?: Maybe<Scalars['String']>;
  orderId: Scalars['UUID'];
  registeredLicensePlateNumber?: Maybe<Scalars['String']>;
  registrationCertificate1Code?: Maybe<Scalars['String']>;
  registrationCertificate1CodeSecurityCode?: Maybe<Scalars['String']>;
  registrationCertificate2Number?: Maybe<Scalars['String']>;
  registrationCertificate2NumberSecurityCode?: Maybe<Scalars['String']>;
  registrationDate?: Maybe<Scalars['String']>;
  registrationType: CarRegistrationType;
  retailOrder?: Maybe<RetailOrderDto>;
  status: Scalars['String'];
  stockNumber: Scalars['String'];
  updatedOn?: Maybe<Scalars['String']>;
  vehicleTaxesCollectedPeriod?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type DigitalCarRegistrationQaDataProjection = {
  __typename?: 'DigitalCarRegistrationQADataProjection';
  cityOfBirth?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  digitalCarRegistrationId?: Maybe<Scalars['UUID']>;
  doctoralDegree?: Maybe<Scalars['String']>;
  evbNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fuelType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  licensePlatePin?: Maybe<Scalars['String']>;
  oldLicensePlate?: Maybe<Scalars['String']>;
  oldLicensePlateFrontSecurityCode?: Maybe<Scalars['String']>;
  oldLicensePlateRearSecurityCode?: Maybe<Scalars['String']>;
  registrationAddressCity?: Maybe<Scalars['String']>;
  registrationAddressHouseNumber?: Maybe<Scalars['String']>;
  registrationAddressStreet?: Maybe<Scalars['String']>;
  registrationAddressZipCode?: Maybe<Scalars['String']>;
  registrationCertificate1Code?: Maybe<Scalars['String']>;
  registrationCertificate1CodeSecurityCode?: Maybe<Scalars['String']>;
  registrationCertificate2Number?: Maybe<Scalars['String']>;
  registrationCertificate2NumberSecurityCode?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
  vehicleTaxesCollectedPeriod?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type DigitalCarRegistrationQaDataUpdateProjectionInput = {
  cityOfBirth?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  doctoralDegree?: InputMaybe<Scalars['String']>;
  evbNumber?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fuelType?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  licensePlate?: InputMaybe<Scalars['String']>;
  licensePlatePin?: InputMaybe<Scalars['String']>;
  oldLicensePlate?: InputMaybe<Scalars['String']>;
  oldLicensePlateFrontSecurityCode?: InputMaybe<Scalars['String']>;
  oldLicensePlateRearSecurityCode?: InputMaybe<Scalars['String']>;
  registrationAddressCity?: InputMaybe<Scalars['String']>;
  registrationAddressHouseNumber?: InputMaybe<Scalars['String']>;
  registrationAddressStreet?: InputMaybe<Scalars['String']>;
  registrationAddressZipCode?: InputMaybe<Scalars['String']>;
  registrationCertificate1Code?: InputMaybe<Scalars['String']>;
  registrationCertificate1CodeSecurityCode?: InputMaybe<Scalars['String']>;
  registrationCertificate2Number?: InputMaybe<Scalars['String']>;
  registrationCertificate2NumberSecurityCode?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  vehicleTaxesCollectedPeriod?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
};

export type DigitalCarRegistrationStatusHistoryProjection = {
  __typename?: 'DigitalCarRegistrationStatusHistoryProjection';
  date: Scalars['String'];
  error?: Maybe<DigitalCarRegistrationProcessingErrorProjection>;
  status: Scalars['String'];
  step: Scalars['String'];
};

export type DigitalCarRegistrationUpdateProjectionInput = {
  assignedTo?: InputMaybe<Scalars['UUID']>;
  carRegistrationAddressId?: InputMaybe<Scalars['UUID']>;
  evbNumber?: InputMaybe<Scalars['String']>;
  holderPlaceOfBirth?: InputMaybe<Scalars['String']>;
  licensePlate?: InputMaybe<LicensePlateProjectionInput>;
  licensePlateNumber?: InputMaybe<Scalars['String']>;
  licensePlatePin?: InputMaybe<Scalars['String']>;
  oldLicensePlate?: InputMaybe<LicensePlateProjectionInput>;
  oldLicensePlateFrontSecurityCode?: InputMaybe<Scalars['String']>;
  oldLicensePlateRearSecurityCode?: InputMaybe<Scalars['String']>;
  registrationCertificate1Code?: InputMaybe<Scalars['String']>;
  registrationCertificate1CodeSecurityCode?: InputMaybe<Scalars['String']>;
  registrationCertificate2Number?: InputMaybe<Scalars['String']>;
  registrationCertificate2NumberSecurityCode?: InputMaybe<Scalars['String']>;
  vehicleTaxesCollectedPeriod?: InputMaybe<Scalars['String']>;
};

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type DisableDocumentInventoryTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
};

export type DisableDocumentInventoryTypePayload = {
  __typename?: 'DisableDocumentInventoryTypePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  documentInventoryType?: Maybe<DocumentInventoryType>;
  query?: Maybe<GqlRetailCarDocumentsService>;
};

export type DiscountCreateProjectionInput = {
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  invoiceId?: InputMaybe<Scalars['UUID']>;
  orderId?: InputMaybe<Scalars['UUID']>;
};

export type DiscountDto = {
  __typename?: 'DiscountDTO';
  amount?: Maybe<PriceDto>;
  amountMinorUnits?: Maybe<Scalars['Long']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  creditNoteNumber?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceId?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type DiscountItemOptions = {
  __typename?: 'DiscountItemOptions';
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DiscountItemPriceDto = {
  __typename?: 'DiscountItemPriceDTO';
  currencyCode?: Maybe<Scalars['String']>;
  minorUnits?: Maybe<Scalars['Long']>;
};

export type DiscountItemPriceDtoInput = {
  currencyCode?: InputMaybe<Scalars['String']>;
  minorUnits?: InputMaybe<Scalars['Long']>;
};

export type DocumentBranchProjection = {
  __typename?: 'DocumentBranchProjection';
  address2?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  documentContact?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type DocumentInventoryItem = {
  __typename?: 'DocumentInventoryItem';
  id: Scalars['UUID'];
  location?: Maybe<DocumentLocation>;
  name: Scalars['String'];
};

export type DocumentInventoryItemConnection_DocumentInventoryItem = {
  __typename?: 'DocumentInventoryItemConnection_DocumentInventoryItem';
  nodes?: Maybe<Array<Maybe<DocumentInventoryItem>>>;
  totalCount?: Maybe<Scalars['Long']>;
};

export type DocumentInventoryItemLocationFilterInput = {
  orderId?: InputMaybe<Scalars['UUID']>;
  statusIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DocumentInventoryItemsFilterInput = {
  location?: InputMaybe<DocumentLocationFilterInput>;
  orderId: Scalars['UUID'];
  statusIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DocumentInventoryPackageAddressInput = {
  address: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  branchName?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  contactPerson?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  deliveryLocationId?: InputMaybe<Scalars['Int']>;
  documentContact?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  houseNum?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  shippingCompanyName?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type DocumentInventoryPackageAddressProjection = {
  __typename?: 'DocumentInventoryPackageAddressProjection';
  address: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  contactPerson?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  documentContact?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  houseNum?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  shippingCompanyName?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type DocumentInventoryPackageFilterInput = {
  orderId: Scalars['UUID'];
};

export type DocumentInventoryPackageProjection = {
  __typename?: 'DocumentInventoryPackageProjection';
  changelog: PageDto_ChangelogProjection;
  createdAt: Scalars['String'];
  deliveredOn?: Maybe<Scalars['String']>;
  deliveryAddress: DocumentInventoryPackageAddressProjection;
  documentInventoryItems?: Maybe<ResultConnection_DocumentInventoryItem>;
  estimatedDeliveryDate?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  originAddress: DocumentInventoryPackageAddressProjection;
  packageNumber: Scalars['Int'];
  pickedUpOn?: Maybe<Scalars['String']>;
  shippingProvider?: Maybe<ShippingProviderProjection>;
  status: Scalars['String'];
  trackingCode?: Maybe<Scalars['String']>;
};

export type DocumentInventoryType = {
  __typename?: 'DocumentInventoryType';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<DocumentInventoryTypeState>;
};

export type DocumentInventoryTypeConnection_DocumentInventoryType = {
  __typename?: 'DocumentInventoryTypeConnection_DocumentInventoryType';
  nodes?: Maybe<Array<Maybe<DocumentInventoryType>>>;
  totalCount?: Maybe<Scalars['Long']>;
};

export enum DocumentInventoryTypeState {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type DocumentItemInPackageProblem = {
  __typename?: 'DocumentItemInPackageProblem';
  documentInventoryPackage?: Maybe<DocumentInventoryPackageProjection>;
  message?: Maybe<Scalars['String']>;
};

export type DocumentItemsInPackageProblem = {
  __typename?: 'DocumentItemsInPackageProblem';
  documentInventoryPackage?: Maybe<DocumentInventoryPackageProjection>;
  message?: Maybe<Scalars['String']>;
};

export type DocumentLocation = {
  __typename?: 'DocumentLocation';
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  zipcode: Scalars['String'];
};

export type DocumentLocationFilterInput = {
  locationId?: InputMaybe<Scalars['Int']>;
  notNull?: InputMaybe<Scalars['Boolean']>;
};

export type DocumentPackageAlreadyCanceledProblem = {
  __typename?: 'DocumentPackageAlreadyCanceledProblem';
  isCanceled?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageAlreadyDeliveredProblem = {
  __typename?: 'DocumentPackageAlreadyDeliveredProblem';
  isDelivered?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageConflictProblem = {
  __typename?: 'DocumentPackageConflictProblem';
  documentItems?: Maybe<ResultConnection_DocumentInventoryItem>;
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageEmptyBranchNameProblem = {
  __typename?: 'DocumentPackageEmptyBranchNameProblem';
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageEmptyDeliveredOnProblem = {
  __typename?: 'DocumentPackageEmptyDeliveredOnProblem';
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageEmptyFirstNameProblem = {
  __typename?: 'DocumentPackageEmptyFirstNameProblem';
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageEmptyLastNameProblem = {
  __typename?: 'DocumentPackageEmptyLastNameProblem';
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageEmptyPickedUpOnProblem = {
  __typename?: 'DocumentPackageEmptyPickedUpOnProblem';
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageNoHouseNumAddressProblem = {
  __typename?: 'DocumentPackageNoHouseNumAddressProblem';
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageNotOpenProblem = {
  __typename?: 'DocumentPackageNotOpenProblem';
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackageSameStatusProblem = {
  __typename?: 'DocumentPackageSameStatusProblem';
  message?: Maybe<Scalars['String']>;
  sameStatus?: Maybe<Scalars['String']>;
};

export type DocumentPackageZeroItemsProblem = {
  __typename?: 'DocumentPackageZeroItemsProblem';
  message?: Maybe<Scalars['String']>;
};

export type DocumentPackagesGeneralProblem = {
  __typename?: 'DocumentPackagesGeneralProblem';
  errorCode?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum DocumentSignInProcessType {
  DigitalCarRegistration = 'DIGITAL_CAR_REGISTRATION'
}

export type DocumentsDtoInput = {
  hasCOCDocument?: InputMaybe<Scalars['Boolean']>;
  hasCarRegistrationDocument?: InputMaybe<Scalars['Boolean']>;
  hasCertifiedChequeReceived?: InputMaybe<Scalars['Boolean']>;
  hasInstantTransferReceived?: InputMaybe<Scalars['Boolean']>;
  hasLicensePlate?: InputMaybe<Scalars['Boolean']>;
  hasOtherDocuments?: InputMaybe<Scalars['Boolean']>;
  hasOwnershipDocument?: InputMaybe<Scalars['Boolean']>;
  hasServiceBook?: InputMaybe<Scalars['Boolean']>;
  hasVehicleInspection?: InputMaybe<Scalars['Boolean']>;
  otherDocuments?: InputMaybe<Scalars['String']>;
};

export type DocumentsReturnedDeregistrationTaskProjection = {
  __typename?: 'DocumentsReturnedDeregistrationTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  documentsRegistrationReadyDate?: Maybe<Scalars['String']>;
  documentsRequestedDate?: Maybe<Scalars['String']>;
  finalDocumentHandoverDate?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  registrationInitiatedDate?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  returnDeliveryType?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type DocumentsReturnedTaskProjection = {
  __typename?: 'DocumentsReturnedTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  documentsRegistrationReadyDate?: Maybe<Scalars['String']>;
  documentsRequestedDate?: Maybe<Scalars['String']>;
  finalDocumentHandoverDate?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  registrationInitiatedDate?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  returnDeliveryType?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type DownPaymentItemProjection = {
  __typename?: 'DownPaymentItemProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customDiscountMinorUnits?: Maybe<Scalars['Long']>;
  customDiscountType?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  downPayment?: Maybe<DownPaymentProjection>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  obsolete?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderItemRefund?: Maybe<Scalars['Long']>;
  parentId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type DownPaymentProjection = {
  __typename?: 'DownPaymentProjection';
  active: Scalars['Boolean'];
  country: Scalars['String'];
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode: Scalars['String'];
  customDiscount?: Maybe<PriceProjection>;
  customDiscountType?: Maybe<Scalars['String']>;
  deactivatedOn?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  discount?: Maybe<PriceProjection>;
  id: Scalars['UUID'];
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceDescription: Scalars['String'];
  invoiceName?: Maybe<Scalars['String']>;
  partnerName: Scalars['String'];
  pnlCostPrice?: Maybe<PriceDetailsProjection>;
  price: PriceDetailsProjection;
  priceAfterDiscount?: Maybe<PriceProjection>;
  priceDetails?: Maybe<Array<Maybe<PriceDetailsProjection>>>;
  productType: Scalars['String'];
  publishedScope: PublishedScopeType;
  quantity: Scalars['Int'];
  recommended?: Maybe<Scalars['Boolean']>;
  refId: Scalars['String'];
  shippingRequired: Scalars['Boolean'];
  subProductDetails?: Maybe<RetailSubProductDetailsProjection>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type DownPaymentUpdateProjectionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  defaulted?: InputMaybe<Scalars['Boolean']>;
  grossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  internalReferenceName?: InputMaybe<Scalars['String']>;
  invoiceDescription?: InputMaybe<Scalars['String']>;
  invoiceName?: InputMaybe<Scalars['String']>;
  partnerName?: InputMaybe<Scalars['String']>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  publishedScope?: InputMaybe<PublishedScopeType>;
  quantity?: InputMaybe<Scalars['Int']>;
  recommended?: InputMaybe<Scalars['Boolean']>;
  shippingRequired?: InputMaybe<Scalars['Boolean']>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
};

export type ElasticCustomerProjection = {
  __typename?: 'ElasticCustomerProjection';
  customerType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
};

export type ElasticOpportunityProjection = {
  __typename?: 'ElasticOpportunityProjection';
  id?: Maybe<Scalars['String']>;
  leadUuid?: Maybe<Scalars['UUID']>;
  uuid?: Maybe<Scalars['UUID']>;
};

export type ElasticOrderFinancingProjection = {
  __typename?: 'ElasticOrderFinancingProjection';
  financingType?: Maybe<Scalars['String']>;
  flowType?: Maybe<Scalars['String']>;
  gap?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  ppi?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};

export type ElasticPriceProjection = {
  __typename?: 'ElasticPriceProjection';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export type ElasticWarrantyProjection = {
  __typename?: 'ElasticWarrantyProjection';
  id?: Maybe<Scalars['UUID']>;
  level?: Maybe<Scalars['String']>;
  monthsCovered?: Maybe<Scalars['Int']>;
};

export type EmailReplyPendingTaskProjection = {
  __typename?: 'EmailReplyPendingTaskProjection';
  SENT_ON_KEY?: Maybe<Scalars['String']>;
  TEMPLATE_NAME_KEY?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  carHandoverOnDate?: Maybe<Scalars['String']>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sentOn?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
};

export type EmailReplyReviewTaskProjection = {
  __typename?: 'EmailReplyReviewTaskProjection';
  SENT_ON_KEY?: Maybe<Scalars['String']>;
  TEMPLATE_NAME_KEY?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  carHandoverOnDate?: Maybe<Scalars['String']>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sentOn?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
};

export type EnableDocumentInventoryTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
};

export type EnableDocumentInventoryTypePayload = {
  __typename?: 'EnableDocumentInventoryTypePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  documentInventoryType?: Maybe<DocumentInventoryType>;
  query?: Maybe<GqlRetailCarDocumentsService>;
};

export type EquipmentItemDto = {
  __typename?: 'EquipmentItemDTO';
  dataSource?: Maybe<Scalars['Int']>;
  equipmentType?: Maybe<EquipmentType>;
  groupId?: Maybe<Scalars['Long']>;
  id?: Maybe<Scalars['Long']>;
  name?: Maybe<Scalars['String']>;
  translationKey?: Maybe<Scalars['String']>;
};

export enum EquipmentType {
  Custom = 'CUSTOM',
  Vin = 'VIN'
}

export type EsOrderProjection = {
  __typename?: 'EsOrderProjection';
  adId?: Maybe<Scalars['UUID']>;
  cancelDescription?: Maybe<Scalars['String']>;
  cancelPeriod?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelRequestSubReason?: Maybe<Scalars['String']>;
  cancelType?: Maybe<Scalars['String']>;
  canceledOn?: Maybe<Scalars['String']>;
  carInBranch?: Maybe<Scalars['Boolean']>;
  carRetailReady?: Maybe<Scalars['Boolean']>;
  completedOn?: Maybe<Scalars['String']>;
  contractSignedOn?: Maybe<Scalars['String']>;
  conversionType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customer?: Maybe<ElasticCustomerProjection>;
  daysInInventory?: Maybe<Scalars['Long']>;
  deliveredOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  documentRequests?: Maybe<Scalars['Int']>;
  exitCheckState?: Maybe<Scalars['String']>;
  financing?: Maybe<ElasticOrderFinancingProjection>;
  flowVersion?: Maybe<Scalars['String']>;
  handoverDateSet?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  licensePlate?: Maybe<Scalars['String']>;
  manualImportDate?: Maybe<Scalars['String']>;
  opportunity?: Maybe<ElasticOpportunityProjection>;
  orderNumber?: Maybe<Scalars['String']>;
  ordersOnStock?: Maybe<Scalars['Int']>;
  overallOverdue?: Maybe<Scalars['Int']>;
  overdueBranchDelivery?: Maybe<Scalars['Long']>;
  overdueMaxEta?: Maybe<Scalars['Long']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  paymentTypeColorCoding?: Maybe<ColorCoding>;
  pendingVerificationOn?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  salesAgentAssignedTo?: Maybe<UserProjection>;
  secondaryWheelsAdded?: Maybe<Scalars['Boolean']>;
  secondaryWheelsAvailable?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  stateColorCoding?: Maybe<ColorCoding>;
  stockNumber?: Maybe<Scalars['String']>;
  subCancelReason?: Maybe<Scalars['String']>;
  totalPriceGross?: Maybe<ElasticPriceProjection>;
  tradeIn?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  verifiedOn?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  warranty?: Maybe<ElasticWarrantyProjection>;
};

export type ExitCheckCommentProjection = {
  __typename?: 'ExitCheckCommentProjection';
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['LocalDateTime']>;
  exitCheckId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['LocalDateTime']>;
};

export type ExitCheckCommentsProjection = {
  __typename?: 'ExitCheckCommentsProjection';
  comments?: Maybe<Array<Maybe<ExitCheckCommentProjection>>>;
};

export type ExitCheckProjection = {
  __typename?: 'ExitCheckProjection';
  adId?: Maybe<Scalars['UUID']>;
  branchData?: Maybe<BranchProjection>;
  completedOn?: Maybe<Scalars['LocalDateTime']>;
  createdOn?: Maybe<Scalars['LocalDateTime']>;
  id?: Maybe<Scalars['UUID']>;
  partnerData?: Maybe<RefurbishmentPartnerDto>;
  preparedStatusDate?: Maybe<Scalars['LocalDateTime']>;
  repairEta?: Maybe<Scalars['String']>;
  state?: Maybe<ExitCheckState>;
  stockNumber?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['LocalDateTime']>;
  vin?: Maybe<Scalars['String']>;
};

export enum ExitCheckState {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Ordered = 'ORDERED',
  Prepared = 'PREPARED',
  RepairsCompleted = 'REPAIRS_COMPLETED',
  RepairsInProgress = 'REPAIRS_IN_PROGRESS',
  RepairsNeeded = 'REPAIRS_NEEDED'
}

export type ExportItem = {
  __typename?: 'ExportItem';
  daysOnline?: Maybe<Scalars['Int']>;
  dealerId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  firstPublishedAt?: Maybe<Scalars['String']>;
  inProgress?: Maybe<Scalars['Boolean']>;
  lastPublishingErrors?: Maybe<Array<Maybe<PublishingError>>>;
  links?: Maybe<Array<Maybe<LinkProjection>>>;
  marketplace?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ExportSectionProjection = {
  __typename?: 'ExportSectionProjection';
  exportItems?: Maybe<Array<Maybe<ExportItem>>>;
};

export enum ExportTaxAdvantageType {
  Bpm = 'BPM',
  Dk = 'DK',
  Nova = 'NOVA'
}

export type ExteriorDto = {
  __typename?: 'ExteriorDTO';
  backLeftRimType?: Maybe<RimType>;
  backLeftTireType?: Maybe<TireType>;
  backRightRimType?: Maybe<RimType>;
  backRightTireType?: Maybe<TireType>;
  exteriorPhotos?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  frontLeftRimType?: Maybe<RimType>;
  frontLeftTireType?: Maybe<TireType>;
  frontRightRimType?: Maybe<RimType>;
  frontRightTireType?: Maybe<TireType>;
  hasLicensePlateFront?: Maybe<Scalars['Boolean']>;
  hasLicensePlateRear?: Maybe<Scalars['Boolean']>;
  isAntennaAvailable?: Maybe<Scalars['Boolean']>;
  isExteriorClean?: Maybe<Scalars['Boolean']>;
  overviewFrontPhoto?: Maybe<Scalars['UUID']>;
  overviewRearPhoto?: Maybe<Scalars['UUID']>;
  roofPhoto?: Maybe<Scalars['UUID']>;
  sideLeftPhoto?: Maybe<Scalars['UUID']>;
  sideRightPhoto?: Maybe<Scalars['UUID']>;
};

export type ExteriorDtoInput = {
  backLeftRimType?: InputMaybe<RimType>;
  backLeftTireType?: InputMaybe<TireType>;
  backRightRimType?: InputMaybe<RimType>;
  backRightTireType?: InputMaybe<TireType>;
  exteriorPhotos?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  frontLeftRimType?: InputMaybe<RimType>;
  frontLeftTireType?: InputMaybe<TireType>;
  frontRightRimType?: InputMaybe<RimType>;
  frontRightTireType?: InputMaybe<TireType>;
  hasLicensePlateFront?: InputMaybe<Scalars['Boolean']>;
  hasLicensePlateRear?: InputMaybe<Scalars['Boolean']>;
  isAntennaAvailable?: InputMaybe<Scalars['Boolean']>;
  isExteriorClean?: InputMaybe<Scalars['Boolean']>;
  overviewFrontPhoto?: InputMaybe<Scalars['UUID']>;
  overviewRearPhoto?: InputMaybe<Scalars['UUID']>;
  roofPhoto?: InputMaybe<Scalars['UUID']>;
  sideLeftPhoto?: InputMaybe<Scalars['UUID']>;
  sideRightPhoto?: InputMaybe<Scalars['UUID']>;
};

export type ExternalFinanceRiskEvaluationProjection = {
  __typename?: 'ExternalFinanceRiskEvaluationProjection';
  contractType?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  monthlyNetIncomeMinorUnits?: Maybe<Scalars['Long']>;
  orderId?: Maybe<Scalars['UUID']>;
  result?: Maybe<Scalars['String']>;
  sameEmployerOverTimePeriod?: Maybe<Scalars['Boolean']>;
};

export type ExternalFinancingApplicationScoringResultProjection = {
  __typename?: 'ExternalFinancingApplicationScoringResultProjection';
  borrowerType?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  externalFinancingApplicationScoringCriteriaId?: Maybe<Scalars['UUID']>;
  externalFinancingApplicationScoringCriteriaValue?: Maybe<Scalars['BigDecimal']>;
  externalFinancingApplicationScoringCriteriaVersion?: Maybe<Scalars['Long']>;
  id?: Maybe<Scalars['UUID']>;
  ltiScore?: Maybe<Scalars['BigDecimal']>;
  ltiToFinancingApplicationScoringClusterId?: Maybe<Scalars['UUID']>;
  ltiToFinancingApplicationScoringClusterValue?: Maybe<Scalars['BigDecimal']>;
  ltiToFinancingApplicationScoringClusterVersion?: Maybe<Scalars['Long']>;
  orderFinancingId?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type ExternalFinancingCustomerDetailsProjection = {
  __typename?: 'ExternalFinancingCustomerDetailsProjection';
  bankAccountOpenDate?: Maybe<Scalars['String']>;
  cityOfRegistration?: Maybe<Scalars['String']>;
  companyType?: Maybe<CompanyType>;
  currentJobStartDate?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['UUID']>;
  housing?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  idCardNumber?: Maybe<Scalars['String']>;
  idExpiryDate?: Maybe<Scalars['String']>;
  idType?: Maybe<Scalars['String']>;
  incomeCurrency?: Maybe<Scalars['String']>;
  isCompanyRegisteredLongEnough?: Maybe<Scalars['Boolean']>;
  maritalStatus?: Maybe<Scalars['String']>;
  moveIntoCurrentHomeDate?: Maybe<Scalars['String']>;
  orderFinancingId?: Maybe<Scalars['UUID']>;
  primaryUseOfVehicle?: Maybe<PrimaryUseOfVehicle>;
  taxCode?: Maybe<Scalars['String']>;
};

export type ExternalFinancingEvaluationV2Projection = {
  __typename?: 'ExternalFinancingEvaluationV2Projection';
  evaluationId?: Maybe<Scalars['UUID']>;
  rejectReason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ExternalFinancingLeasingCompanyProjection = {
  __typename?: 'ExternalFinancingLeasingCompanyProjection';
  address?: Maybe<RetailAddressProjection>;
  addressId?: Maybe<Scalars['UUID']>;
  billToCustomerNumber?: Maybe<Scalars['String']>;
  billToSiteNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
};

export type ExternalFinancingLeasingDetailsCreateProjectionInput = {
  leasingCompanyId?: InputMaybe<Scalars['UUID']>;
  orderFinancingId?: InputMaybe<Scalars['UUID']>;
};

export type ExternalFinancingLeasingDetailsProjection = {
  __typename?: 'ExternalFinancingLeasingDetailsProjection';
  id?: Maybe<Scalars['UUID']>;
  leasingCompany?: Maybe<ExternalFinancingLeasingCompanyProjection>;
  orderFinancingId?: Maybe<Scalars['UUID']>;
};

export type ExternalFinancingLeasingDetailsUpdateProjectionInput = {
  leasingCompanyId?: InputMaybe<Scalars['UUID']>;
};

export type ExternalFinancingOptionProviderDataDto = {
  __typename?: 'ExternalFinancingOptionProviderDataDTO';
  externalFinancingRefNumber?: Maybe<Scalars['String']>;
  financingProvider?: Maybe<Scalars['String']>;
};

export type ExternalFinancingOptionProviderDataDtoInput = {
  externalFinancingRefNumber?: InputMaybe<Scalars['String']>;
  financingProvider?: InputMaybe<Scalars['String']>;
};

export type ExternalFinancingOptionsProjection = {
  __typename?: 'ExternalFinancingOptionsProjection';
  ahOpeningFees?: Maybe<Scalars['BigDecimal']>;
  damageInsurance?: Maybe<Scalars['String']>;
  externalFinancingOptionProviderData?: Maybe<Array<Maybe<ExternalFinancingOptionProviderDataDto>>>;
  externalFinancingRefNumber?: Maybe<Scalars['String']>;
  financingProvider?: Maybe<Scalars['String']>;
  gapInsurance?: Maybe<Scalars['String']>;
  ppi?: Maybe<Scalars['String']>;
};

export type ExternalFinancingOptionsValuesProjection = {
  __typename?: 'ExternalFinancingOptionsValuesProjection';
  options?: Maybe<Scalars['Map_String_Map_String_OptionProjectionScalar']>;
};

export type ExternalFinancingPreEvaluationV2ProjectionInput = {
  coBorrower?: InputMaybe<PersistExternalRiskUserInfoProjectionInput>;
  mainBorrower: PersistExternalRiskUserInfoProjectionInput;
};

export enum ExternalPaymentSystem {
  Adyen = 'ADYEN',
  AdyenPaymentLink = 'ADYEN_PAYMENT_LINK'
}

export type ExternalRiskUserInfoProjection = {
  __typename?: 'ExternalRiskUserInfoProjection';
  accountHolder?: Maybe<Scalars['String']>;
  additionalIncomeMinorUnits?: Maybe<Scalars['Long']>;
  bankAccountOpenDate?: Maybe<Scalars['String']>;
  borrowerType?: Maybe<Scalars['String']>;
  contractValidFor?: Maybe<Scalars['Boolean']>;
  debtRatio?: Maybe<Scalars['Float']>;
  employedBy?: Maybe<Scalars['String']>;
  employmentContractType?: Maybe<Scalars['String']>;
  employmentEndDate?: Maybe<Scalars['String']>;
  employmentStartDate?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  housingConstruction?: Maybe<Scalars['String']>;
  housingType?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  incomeFromSubsidiesMinorUnits?: Maybe<Scalars['Long']>;
  incomeSpread?: Maybe<Scalars['Int']>;
  jobTitle?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<Scalars['String']>;
  mortgageOrRentPaymentMinorUnits?: Maybe<Scalars['Long']>;
  movingDate?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  numberOfDependents?: Maybe<Scalars['Int']>;
  numberOfOtherDependents?: Maybe<Scalars['Int']>;
  numberOfOtherLoans?: Maybe<Scalars['Int']>;
  orderFinancingId: Scalars['UUID'];
  otherLoanPaymentsMinorUnits?: Maybe<Scalars['Long']>;
  pep?: Maybe<Scalars['Boolean']>;
  placeOfBirth?: Maybe<Scalars['String']>;
  previousAddressMovingDate?: Maybe<Scalars['String']>;
  primaryIncomeMinorUnits?: Maybe<Scalars['Long']>;
  residentSinceDate?: Maybe<Scalars['String']>;
  sameEmployerOverTimePeriod?: Maybe<Scalars['Boolean']>;
  sector?: Maybe<Scalars['String']>;
  seniority?: Maybe<Scalars['String']>;
  separationAllowanceMinorUnits?: Maybe<Scalars['Long']>;
  separationOfGoodsInMarriage?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  userId: Scalars['UUID'];
  zipcodeOfBirth?: Maybe<Scalars['String']>;
};

export enum Feature {
  HasAbs = 'HAS_ABS',
  HasAccelerationSkidControl = 'HAS_ACCELERATION_SKID_CONTROL',
  HasAdaptiveCruiseController = 'HAS_ADAPTIVE_CRUISE_CONTROLLER',
  HasAdaptiveHeadlights = 'HAS_ADAPTIVE_HEADLIGHTS',
  HasAirbag = 'HAS_AIRBAG',
  HasAirConditioning = 'HAS_AIR_CONDITIONING',
  HasAirSuspension = 'HAS_AIR_SUSPENSION',
  HasAlarmSystem = 'HAS_ALARM_SYSTEM',
  HasAlloyRims = 'HAS_ALLOY_RIMS',
  HasAllWheelDrive = 'HAS_ALL_WHEEL_DRIVE',
  HasAmbientLighting = 'HAS_AMBIENT_LIGHTING',
  HasAndroidPhoneConnection = 'HAS_ANDROID_PHONE_CONNECTION',
  HasAppleCarplay = 'HAS_APPLE_CARPLAY',
  HasAutomaticAirCondition = 'HAS_AUTOMATIC_AIR_CONDITION',
  HasAutomaticHeadlightControl = 'HAS_AUTOMATIC_HEADLIGHT_CONTROL',
  HasAuxiliaryHeater = 'HAS_AUXILIARY_HEATER',
  HasAxleLoadIncrease = 'HAS_AXLE_LOAD_INCREASE',
  HasBacklightLed = 'HAS_BACKLIGHT_LED',
  HasBiXenonLights = 'HAS_BI_XENON_LIGHTS',
  /** @deprecated Deprecated */
  HasBlindSpotAssist = 'HAS_BLIND_SPOT_ASSIST',
  HasBlockHeater = 'HAS_BLOCK_HEATER',
  HasBluetooth = 'HAS_BLUETOOTH',
  HasBrakeAssist = 'HAS_BRAKE_ASSIST',
  HasCargoSpacePartition = 'HAS_CARGO_SPACE_PARTITION',
  HasCarCommunication = 'HAS_CAR_COMMUNICATION',
  HasCdMultichanger = 'HAS_CD_MULTICHANGER',
  HasCenterArmRest = 'HAS_CENTER_ARM_REST',
  HasCentralLocking = 'HAS_CENTRAL_LOCKING',
  HasChassisControlSystem = 'HAS_CHASSIS_CONTROL_SYSTEM',
  /** @deprecated Deprecated */
  HasClimatisation = 'HAS_CLIMATISATION',
  HasCocDocument = 'HAS_COC_DOCUMENT',
  HasComingHomeLeavingHome = 'HAS_COMING_HOME_LEAVING_HOME',
  HasCorneringLights = 'HAS_CORNERING_LIGHTS',
  HasCoTwo = 'HAS_CO_TWO',
  HasCrosswindAssist = 'HAS_CROSSWIND_ASSIST',
  HasCrossTrafficAssist = 'HAS_CROSS_TRAFFIC_ASSIST',
  HasCruiseControl = 'HAS_CRUISE_CONTROL',
  HasDaytimeRunningLights = 'HAS_DAYTIME_RUNNING_LIGHTS',
  /** @deprecated Deprecated */
  HasDetachableTowHook = 'HAS_DETACHABLE_TOW_HOOK',
  HasDieselParticulateFilter = 'HAS_DIESEL_PARTICULATE_FILTER',
  HasDigitalRadio = 'HAS_DIGITAL_RADIO',
  HasDimmingInteriorMirror = 'HAS_DIMMING_INTERIOR_MIRROR',
  HasDistanceWarner = 'HAS_DISTANCE_WARNER',
  HasDraughtStop = 'HAS_DRAUGHT_STOP',
  HasDriverDrowsinessDetection = 'HAS_DRIVER_DROWSINESS_DETECTION',
  HasDrivingModeSwitch = 'HAS_DRIVING_MODE_SWITCH',
  HasDynamicPerformanceControl = 'HAS_DYNAMIC_PERFORMANCE_CONTROL',
  HasEcoDriveControl = 'HAS_ECO_DRIVE_CONTROL',
  HasElectricSeats = 'HAS_ELECTRIC_SEATS',
  HasElectricSideViewMirrors = 'HAS_ELECTRIC_SIDE_VIEW_MIRRORS',
  HasElectricSlidingDoors = 'HAS_ELECTRIC_SLIDING_DOORS',
  HasElectricTailGate = 'HAS_ELECTRIC_TAIL_GATE',
  HasEmergencyBreakAssist = 'HAS_EMERGENCY_BREAK_ASSIST',
  HasEmergencyCallSystem = 'HAS_EMERGENCY_CALL_SYSTEM',
  HasEntertainmentSystem = 'HAS_ENTERTAINMENT_SYSTEM',
  HasEnvironmentAssistSystem = 'HAS_ENVIRONMENT_ASSIST_SYSTEM',
  HasEsp = 'HAS_ESP',
  HasExteriorMirror = 'HAS_EXTERIOR_MIRROR',
  HasFogLights = 'HAS_FOG_LIGHTS',
  HasFoldFlatPassengerSeat = 'HAS_FOLD_FLAT_PASSENGER_SEAT',
  HasFrontCamera = 'HAS_FRONT_CAMERA',
  HasGlareFreeHighBeam = 'HAS_GLARE_FREE_HIGH_BEAM',
  /** @deprecated Deprecated */
  HasGps = 'HAS_GPS',
  HasHandicappedFeatures = 'HAS_HANDICAPPED_FEATURES',
  HasHandsFreeSystem = 'HAS_HANDS_FREE_SYSTEM',
  HasHardtop = 'HAS_HARDTOP',
  HasHeadlightCleaningSystem = 'HAS_HEADLIGHT_CLEANING_SYSTEM',
  HasHeadUpDisplay = 'HAS_HEAD_UP_DISPLAY',
  HasHeatedFrontWindow = 'HAS_HEATED_FRONT_WINDOW',
  HasHeatedSeats = 'HAS_HEATED_SEATS',
  HasHeatedSeatsFront = 'HAS_HEATED_SEATS_FRONT',
  HasHeatedSeatsFrontAndRear = 'HAS_HEATED_SEATS_FRONT_AND_REAR',
  HasHeatedSideViewMirrors = 'HAS_HEATED_SIDE_VIEW_MIRRORS',
  HasHeatedSteeringWheel = 'HAS_HEATED_STEERING_WHEEL',
  HasHeatedWindShield = 'HAS_HEATED_WIND_SHIELD',
  HasHighBeamAssist = 'HAS_HIGH_BEAM_ASSIST',
  HasHighPerformanceBrakes = 'HAS_HIGH_PERFORMANCE_BRAKES',
  HasHillDescentControl = 'HAS_HILL_DESCENT_CONTROL',
  HasHillStartAssist = 'HAS_HILL_START_ASSIST',
  HasIntegralSafetyConcept = 'HAS_INTEGRAL_SAFETY_CONCEPT',
  HasIntegratedMusicStreaming = 'HAS_INTEGRATED_MUSIC_STREAMING',
  HasIsofixMountingPoints = 'HAS_ISOFIX_MOUNTING_POINTS',
  HasKeylessEntry = 'HAS_KEYLESS_ENTRY',
  HasLaneAssist = 'HAS_LANE_ASSIST',
  HasLaneChangeAssist = 'HAS_LANE_CHANGE_ASSIST',
  HasLaserHeadlights = 'HAS_LASER_HEADLIGHTS',
  HasLeatherSteeringWheel = 'HAS_LEATHER_STEERING_WHEEL',
  HasLedHeadlights = 'HAS_LED_HEADLIGHTS',
  HasLevelControl = 'HAS_LEVEL_CONTROL',
  HasLimitedSlipDifferential = 'HAS_LIMITED_SLIP_DIFFERENTIAL',
  HasLpg = 'HAS_LPG',
  HasMaintenanceGuide = 'HAS_MAINTENANCE_GUIDE',
  HasMassageSeats = 'HAS_MASSAGE_SEATS',
  HasNavigationSystem = 'HAS_NAVIGATION_SYSTEM',
  HasNewHuAu = 'HAS_NEW_HU_AU',
  HasNightViewAssist = 'HAS_NIGHT_VIEW_ASSIST',
  HasOnBoardComputer = 'HAS_ON_BOARD_COMPUTER',
  HasPaddleShifters = 'HAS_PADDLE_SHIFTERS',
  HasPanoramicRoof = 'HAS_PANORAMIC_ROOF',
  /** @deprecated Deprecated */
  HasParkingAssist = 'HAS_PARKING_ASSIST',
  HasParkDistanceControl = 'HAS_PARK_DISTANCE_CONTROL',
  HasParkDistanceControlFrontAndRear = 'HAS_PARK_DISTANCE_CONTROL_FRONT_AND_REAR',
  HasParkDistanceControlRear = 'HAS_PARK_DISTANCE_CONTROL_REAR',
  HasParkDistanceControlWithCamera = 'HAS_PARK_DISTANCE_CONTROL_WITH_CAMERA',
  HasParkHeater = 'HAS_PARK_HEATER',
  HasPowerTakeOff = 'HAS_POWER_TAKE_OFF',
  HasPowerWindows = 'HAS_POWER_WINDOWS',
  HasPowerWindowsFront = 'HAS_POWER_WINDOWS_FRONT',
  HasPowerWindowsFrontAndRear = 'HAS_POWER_WINDOWS_FRONT_AND_REAR',
  HasRadioCassette = 'HAS_RADIO_CASSETTE',
  HasRadioCd = 'HAS_RADIO_CD',
  HasRadioDab = 'HAS_RADIO_DAB',
  HasRadioMp3 = 'HAS_RADIO_MP3',
  HasRadioUsb = 'HAS_RADIO_USB',
  HasRainSensorWipers = 'HAS_RAIN_SENSOR_WIPERS',
  HasRangeExtender = 'HAS_RANGE_EXTENDER',
  HasRearSeatPassThrough = 'HAS_REAR_SEAT_PASS_THROUGH',
  HasRearSeatSplit = 'HAS_REAR_SEAT_SPLIT',
  HasRearWindBlocker = 'HAS_REAR_WIND_BLOCKER',
  HasRemoteCentralLocking = 'HAS_REMOTE_CENTRAL_LOCKING',
  HasRoofRack = 'HAS_ROOF_RACK',
  HasSeatSettings = 'HAS_SEAT_SETTINGS',
  HasSeatVentilation = 'HAS_SEAT_VENTILATION',
  HasSecurityPackage = 'HAS_SECURITY_PACKAGE',
  HasSelfParkingSystem = 'HAS_SELF_PARKING_SYSTEM',
  HasSelfSteeringSystem = 'HAS_SELF_STEERING_SYSTEM',
  HasServoSteering = 'HAS_SERVO_STEERING',
  HasSkiBag = 'HAS_SKI_BAG',
  HasSmartphoneInductionCharger = 'HAS_SMARTPHONE_INDUCTION_CHARGER',
  HasSmokersPackage = 'HAS_SMOKERS_PACKAGE',
  HasSoundSystem = 'HAS_SOUND_SYSTEM',
  /** @deprecated Deprecated */
  HasSpeedControl = 'HAS_SPEED_CONTROL',
  HasSpeedLimiter = 'HAS_SPEED_LIMITER',
  HasSportPackage = 'HAS_SPORT_PACKAGE',
  HasSportSeats = 'HAS_SPORT_SEATS',
  HasSportSuspension = 'HAS_SPORT_SUSPENSION',
  HasSportUndercarriage = 'HAS_SPORT_UNDERCARRIAGE',
  HasStartStopAutomatic = 'HAS_START_STOP_AUTOMATIC',
  /** @deprecated Deprecated */
  HasStartStopSystemEngine = 'HAS_START_STOP_SYSTEM_ENGINE',
  HasSteeringStabilizerAssist = 'HAS_STEERING_STABILIZER_ASSIST',
  HasSteeringWheelWithMultifunction = 'HAS_STEERING_WHEEL_WITH_MULTIFUNCTION',
  HasSunroof = 'HAS_SUNROOF',
  HasSunBlindSystem = 'HAS_SUN_BLIND_SYSTEM',
  HasThermalGlazingInsulation = 'HAS_THERMAL_GLAZING_INSULATION',
  HasTirePressureMonitoringSystem = 'HAS_TIRE_PRESSURE_MONITORING_SYSTEM',
  HasTouchScreen = 'HAS_TOUCH_SCREEN',
  HasTowBar = 'HAS_TOW_BAR',
  HasTractionControl = 'HAS_TRACTION_CONTROL',
  HasTrafficSignRecognition = 'HAS_TRAFFIC_SIGN_RECOGNITION',
  HasTrailerCoupling = 'HAS_TRAILER_COUPLING',
  HasTuning = 'HAS_TUNING',
  HasTv = 'HAS_TV',
  HasUnclassifiedFeatureDetail = 'HAS_UNCLASSIFIED_FEATURE_DETAIL',
  HasUndercarriage = 'HAS_UNDERCARRIAGE',
  HasUsb = 'HAS_USB',
  HasVirtualCockpit = 'HAS_VIRTUAL_COCKPIT',
  HasVoiceControl = 'HAS_VOICE_CONTROL',
  HasWarranty = 'HAS_WARRANTY',
  HasWifiHotspot = 'HAS_WIFI_HOTSPOT',
  HasWinterPackage = 'HAS_WINTER_PACKAGE',
  HasWoodFlooringCargoArea = 'HAS_WOOD_FLOORING_CARGO_AREA',
  HasWoodPanelingCargoArea = 'HAS_WOOD_PANELING_CARGO_AREA',
  HasXenonLights = 'HAS_XENON_LIGHTS',
  IsBiodieselSuitable = 'IS_BIODIESEL_SUITABLE',
  IsConvertible = 'IS_CONVERTIBLE',
  IsE10Enabled = 'IS_E10_ENABLED',
  IsElectroHydraulicConvertible = 'IS_ELECTRO_HYDRAULIC_CONVERTIBLE',
  IsFinanced = 'IS_FINANCED'
}

export enum FeatureUsageDateSource {
  OrderContractSignedOn = 'ORDER_CONTRACT_SIGNED_ON',
  OrderCreatedOn = 'ORDER_CREATED_ON'
}

export type FeeItemProjection = {
  __typename?: 'FeeItemProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customDiscountMinorUnits?: Maybe<Scalars['Long']>;
  customDiscountType?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  fee?: Maybe<RetailFeeProjection>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  obsolete?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderItemRefund?: Maybe<Scalars['Long']>;
  parentId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type FilterProjectionInput = {
  property?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type FinanceAppProjection = {
  __typename?: 'FinanceAppProjection';
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
};

export type FinanceRejectReasonFiltersInput = {
  financingType?: InputMaybe<Scalars['String']>;
};

export type FinanceRiskEvaluationProjection = {
  __typename?: 'FinanceRiskEvaluationProjection';
  coBorrowerNeeded?: Maybe<Scalars['Boolean']>;
  contractType?: Maybe<Scalars['String']>;
  employedUnderLocalWorkContract?: Maybe<Scalars['Boolean']>;
  employmentPeriod?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  meetMonthlyIncome?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['UUID']>;
  sameEmployerOverTimePeriod?: Maybe<Scalars['Boolean']>;
};

export type FinancingApplicabilityProjection = {
  __typename?: 'FinancingApplicabilityProjection';
  applicability?: Maybe<Scalars['String']>;
  inapplicabilityReason?: Maybe<Scalars['String']>;
};

export type FinancingApplicationDto = {
  __typename?: 'FinancingApplicationDTO';
  applicationNumber?: Maybe<Scalars['String']>;
  applicationType?: Maybe<Scalars['String']>;
  approvedOn?: Maybe<Scalars['String']>;
  bankContractSignedOn?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelSubReason?: Maybe<Scalars['String']>;
  completedOn?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  financingAgentAssignedId?: Maybe<Scalars['UUID']>;
  financingFlowVersion?: Maybe<Scalars['String']>;
  financingType?: Maybe<Scalars['String']>;
  flowType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  monthlyPaymentDay?: Maybe<Scalars['Int']>;
  newFinancingApplication?: Maybe<NewFinancingApplicationDto>;
  orderId?: Maybe<Scalars['UUID']>;
  orderPaymentDetails?: Maybe<FinancingApplicationPaymentDetailsDto>;
  preApprovedOn?: Maybe<Scalars['String']>;
  rejectReason?: Maybe<Scalars['String']>;
  retailCountry?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  submittedToBankOn?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
  userConsent?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['UUID']>;
  validationOn?: Maybe<Scalars['String']>;
};

export type FinancingApplicationPaymentDetailsDto = {
  __typename?: 'FinancingApplicationPaymentDetailsDTO';
  balloonPaymentAmountMinorUnits?: Maybe<Scalars['Long']>;
  contractType?: Maybe<Scalars['String']>;
  creditAmountMinorUnits?: Maybe<Scalars['Long']>;
  creditPeriod?: Maybe<Scalars['Int']>;
  currencyCode?: Maybe<Scalars['String']>;
  financingContractType?: Maybe<Scalars['String']>;
  financingType?: Maybe<Scalars['String']>;
  interestRate?: Maybe<Scalars['Float']>;
  lastUpdateInitiator?: Maybe<Scalars['String']>;
  monthlyPaymentAmountMinorUnits?: Maybe<Scalars['Long']>;
  paymentType?: Maybe<Scalars['String']>;
  preCheckoutFinancingId?: Maybe<Scalars['UUID']>;
  updatedBy?: Maybe<Scalars['UUID']>;
};

export type FinancingApplicationPaymentDetailsProjection = {
  __typename?: 'FinancingApplicationPaymentDetailsProjection';
  balloonPaymentAmountMinorUnits?: Maybe<Scalars['Long']>;
  contractType?: Maybe<Scalars['String']>;
  creditAmountMinorUnits?: Maybe<Scalars['Long']>;
  creditPeriod?: Maybe<Scalars['Int']>;
  currencyCode?: Maybe<Scalars['String']>;
  financingContractType?: Maybe<Scalars['String']>;
  interestRate?: Maybe<Scalars['Float']>;
  lastUpdateInitiator?: Maybe<Scalars['String']>;
  maxAffordableMonthlyPaymentMinorUnits?: Maybe<Scalars['Long']>;
  monthlyPaymentAmountMinorUnits?: Maybe<Scalars['Long']>;
  paymentType?: Maybe<Scalars['String']>;
  preCheckoutFinancingId?: Maybe<Scalars['UUID']>;
};

export type FinancingApplicationPendingBankDecisionTaskProjection = {
  __typename?: 'FinancingApplicationPendingBankDecisionTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  cashAmount?: Maybe<PriceDto>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  creditAmount?: Maybe<PriceDto>;
  creditPeriod?: Maybe<Scalars['Int']>;
  dateOfApplicationSubmition?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderFinanceCancelReasons?: Maybe<Array<Maybe<OrderFinanceCancelReasonProjection>>>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type FinancingApplicationProjection = {
  __typename?: 'FinancingApplicationProjection';
  applicationNumber?: Maybe<Scalars['String']>;
  applicationType?: Maybe<RetailFinancingApplicationType>;
  approvedOn?: Maybe<Scalars['String']>;
  bankContractSignedOn?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelSubReason?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdByUser?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  financingAgentAssigned?: Maybe<UserProjection>;
  financingAgentAssignedId?: Maybe<Scalars['UUID']>;
  financingFlowVersion?: Maybe<RetailFinancingApplicationFlowVersion>;
  financingType?: Maybe<RetailFinancingApplicationFinancingType>;
  flowType?: Maybe<RetailFinancingApplicationFlowType>;
  id: Scalars['UUID'];
  monthlyPaymentDay?: Maybe<Scalars['Int']>;
  newFinancingApplication?: Maybe<NewFinancingApplicationProjection>;
  orderId: Scalars['UUID'];
  orderPaymentDetails?: Maybe<FinancingApplicationPaymentDetailsProjection>;
  preApprovedOn?: Maybe<Scalars['String']>;
  rejectReason?: Maybe<Scalars['String']>;
  retailCountry?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  submittedToBankOn?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedByUser?: Maybe<UserProjection>;
  updatedOn?: Maybe<Scalars['String']>;
  userConsent: Scalars['Boolean'];
  userId: Scalars['UUID'];
  validationOn?: Maybe<Scalars['String']>;
};

export type FinancingApplicationValidationTaskProjection = {
  __typename?: 'FinancingApplicationValidationTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  cashAmount?: Maybe<PriceDto>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  creditAmount?: Maybe<PriceDto>;
  dateOfApplicationValidation?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  externalFinancingOptionsValues?: Maybe<Array<Maybe<Scalars['ObjectScalar']>>>;
  financingBankSubmissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderFinanceCancelReasons?: Maybe<Array<Maybe<OrderFinanceCancelReasonProjection>>>;
  orderFinanceRejectReasons?: Maybe<Array<Maybe<OrderFinanceRejectReasonProjection>>>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type FinancingBillingAddressProjection = {
  __typename?: 'FinancingBillingAddressProjection';
  city: Scalars['String'];
  country: Scalars['String'];
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn: Scalars['String'];
  financingApplicationId: Scalars['UUID'];
  houseNumber: Scalars['String'];
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
  status: FinancingBillingAddressStatus;
  street: Scalars['String'];
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export enum FinancingBillingAddressStatus {
  Approved = 'APPROVED',
  PendingVerification = 'PENDING_VERIFICATION',
  Rejected = 'REJECTED'
}

export type FinancingBillingAddressVerificationTaskProjection = {
  __typename?: 'FinancingBillingAddressVerificationTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  documentsToVerify?: Maybe<Array<Maybe<RetailDocumentProjection>>>;
  financingApplication?: Maybe<FinancingApplicationProjection>;
  financingBillingAddress?: Maybe<FinancingBillingAddressProjection>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type FinancingCalculatorResponseProjection = {
  __typename?: 'FinancingCalculatorResponseProjection';
  calculatorTextFragmentFinancingPlanDetails?: Maybe<RetailFinancingPlanProjection>;
  financingConditions?: Maybe<FinancingConditionsProjection>;
  internalFinancingFeature?: Maybe<RetailFinancingPlanProjection>;
};

export type FinancingConditionsProjection = {
  __typename?: 'FinancingConditionsProjection';
  additionalFinancingFee?: Maybe<Scalars['Long']>;
  availableContractTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableFinancingTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  balloonAmount?: Maybe<Scalars['Long']>;
  balloonAmountNoRounding?: Maybe<Scalars['Long']>;
  contractType?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Long']>;
  discountAvailability?: Maybe<Scalars['Boolean']>;
  downpayment?: Maybe<Scalars['Long']>;
  effectiveInterestRate?: Maybe<Scalars['Float']>;
  financingAmount?: Maybe<Scalars['Long']>;
  financingPeriod?: Maybe<Scalars['Int']>;
  insuranceEffectiveRate?: Maybe<Scalars['Float']>;
  insuranceMonthlyInstalment?: Maybe<Scalars['Long']>;
  maxFinancingAmount?: Maybe<Scalars['Long']>;
  maxPeriod?: Maybe<Scalars['Int']>;
  minCashAmount?: Maybe<Scalars['Long']>;
  minFinancingAmount?: Maybe<Scalars['Long']>;
  minPeriod?: Maybe<Scalars['Int']>;
  monthlyInstalment?: Maybe<Scalars['Long']>;
  monthlyInstalmentNoRounding?: Maybe<Scalars['Long']>;
  monthlyInstalmentWithInsurance?: Maybe<Scalars['Long']>;
  monthlyInstalmentWithInsuranceNoRounding?: Maybe<Scalars['Long']>;
  noFinancingReason?: Maybe<Scalars['String']>;
  nominalInterestRate?: Maybe<Scalars['Float']>;
  periodStep?: Maybe<Scalars['Int']>;
  totalCostOfCredit?: Maybe<Scalars['Long']>;
  totalCostOfInsurance?: Maybe<Scalars['Long']>;
  totalFinancingAmount?: Maybe<Scalars['Long']>;
  totalOrderPriceGross?: Maybe<Scalars['Long']>;
  totalOwedAmount?: Maybe<Scalars['Long']>;
  totalOwedAmountWithInsurance?: Maybe<Scalars['Long']>;
};

export type FinancingInternalApprovalTaskProjection = {
  __typename?: 'FinancingInternalApprovalTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderContractSignedOn?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  pendingVerificationOn?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type FinancingMonthlyPaymentDayUpdateTaskProjection = {
  __typename?: 'FinancingMonthlyPaymentDayUpdateTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  monthlyPaymentDay?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type FinancingPaymentDetailsUpdateProjectionInput = {
  balloonPaymentAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  contractType?: InputMaybe<Scalars['String']>;
  creditAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  creditPeriod?: InputMaybe<Scalars['Int']>;
  financingType?: InputMaybe<Scalars['String']>;
  interestRate?: InputMaybe<Scalars['Float']>;
  monthlyPaymentAmountMinorUnits?: InputMaybe<Scalars['Long']>;
};

export type FinancingSearchProjection = {
  __typename?: 'FinancingSearchProjection';
  adId?: Maybe<Scalars['UUID']>;
  applicationNumber?: Maybe<Scalars['String']>;
  applicationType?: Maybe<Scalars['String']>;
  assignee?: Maybe<AssigneeSearchProjection>;
  balloonPayment?: Maybe<Scalars['Long']>;
  bankContractSignedOn?: Maybe<Scalars['String']>;
  cashAmountMinorUnits?: Maybe<Scalars['Long']>;
  cluster?: Maybe<Scalars['Int']>;
  contractType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  creditAmountMinorUnits?: Maybe<Scalars['Long']>;
  creditPeriod?: Maybe<Scalars['Int']>;
  creditType?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerSearchProjection>;
  externalFinancingRefNumber?: Maybe<Scalars['String']>;
  financingId?: Maybe<Scalars['UUID']>;
  financingProvider?: Maybe<Scalars['String']>;
  financingType?: Maybe<Scalars['String']>;
  flowType?: Maybe<Scalars['String']>;
  gap?: Maybe<Scalars['Boolean']>;
  interestRate?: Maybe<Scalars['Float']>;
  monthlyPaymentAmountMinorUnits?: Maybe<Scalars['Long']>;
  opportunity?: Maybe<OpportunitySearchProjection>;
  order?: Maybe<OrderSearchProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  paymentType?: Maybe<Scalars['String']>;
  ppi?: Maybe<Scalars['Boolean']>;
  selectedPlanId?: Maybe<Scalars['UUID']>;
  status?: Maybe<Scalars['String']>;
  submittedToBankOn?: Maybe<Scalars['String']>;
  totalPaymentAmount?: Maybe<Scalars['Long']>;
  verificationFormCheckListState?: Maybe<Scalars['String']>;
};

export type FinancingSepaMandateProjection = {
  __typename?: 'FinancingSepaMandateProjection';
  accountHolder?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  duplicates?: Maybe<Array<Maybe<SepaMandateDuplicateProjection>>>;
  financingApplicationId?: Maybe<Scalars['UUID']>;
  iban?: Maybe<Scalars['String']>;
  ibanCreationReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  notDuplicateReason?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  status?: Maybe<Scalars['String']>;
};

export type FinancingSepaMandateVerificationTaskProjection = {
  __typename?: 'FinancingSepaMandateVerificationTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  documentsToVerify?: Maybe<Array<Maybe<RetailDocumentProjection>>>;
  financingApplication?: Maybe<FinancingApplicationProjection>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sepaMandate?: Maybe<FinancingSepaMandateProjection>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type FrRegionalTaxBasedPriceModifierProjection = {
  __typename?: 'FrRegionalTaxBasedPriceModifierProjection';
  active: Scalars['Boolean'];
  carFuelType: Scalars['String'];
  createdBy: Scalars['UUID'];
  createdOn?: Maybe<Scalars['String']>;
  handlingFeeMinorUnits: Scalars['Int'];
  id: Scalars['UUID'];
  localTaxRateMinorUnits: Scalars['Int'];
  pnlCostPrice?: Maybe<PriceDetailsProjection>;
  postalCodeIndex: Scalars['String'];
  postalFeeMinorUnits: Scalars['Int'];
  price?: Maybe<PriceDetailsProjection>;
  priceModifierInstance: Scalars['String'];
  productId: Scalars['UUID'];
  updatedOn?: Maybe<Scalars['String']>;
};

export type FrRegionalTaxBasedPriceModifierUpdateProjectionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  grossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  handlingFeeMinorUnits?: InputMaybe<Scalars['Int']>;
  localTaxRateMinorUnits?: InputMaybe<Scalars['Int']>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  postalFeeMinorUnits?: InputMaybe<Scalars['Int']>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
};

export type FuelConsumptionDto = {
  __typename?: 'FuelConsumptionDTO';
  city?: Maybe<Scalars['BigDecimal']>;
  combined?: Maybe<Scalars['BigDecimal']>;
  highway?: Maybe<Scalars['BigDecimal']>;
};

export type FuelConsumptionDtoInput = {
  city?: InputMaybe<Scalars['BigDecimal']>;
  combined?: InputMaybe<Scalars['BigDecimal']>;
  highway?: InputMaybe<Scalars['BigDecimal']>;
};

export type GdprProjection = {
  __typename?: 'GDPRProjection';
  report?: Maybe<Scalars['Map_String_StringScalar']>;
};

export enum GpsType {
  BuiltIn = 'BUILT_IN',
  Mobile = 'MOBILE'
}

export type GqlRetailCarDocumentsService = {
  __typename?: 'GQLRetailCarDocumentsService';
  DOCUMENT_ITEM_UPDATE_NOT_PERMITTED?: Maybe<Scalars['String']>;
  DOCUMENT_PACKAGE_EMPTY_BRANCH_NAME_VALIDATION_CODE?: Maybe<Scalars['String']>;
  DOCUMENT_PACKAGE_EMPTY_FIRST_NAME_VALIDATION_CODE?: Maybe<Scalars['String']>;
  DOCUMENT_PACKAGE_EMPTY_LAST_NAME_VALIDATION_CODE?: Maybe<Scalars['String']>;
  DOCUMENT_PACKAGE_NO_HOUSE_NUM_ADDRESS_VALIDATION_CODE?: Maybe<Scalars['String']>;
  DOCUMENT_PACKAGE_STATUS_NOT_OPEN_VALIDATION_CODE?: Maybe<Scalars['String']>;
  EMPTY_DELIVERED_ON_DATE_VALIDATION_CODE?: Maybe<Scalars['String']>;
  EMPTY_PICKEDUP_ON_DATE_VALIDATION_CODE?: Maybe<Scalars['String']>;
  ITEMS_IN_MULTI_PACKAGES_VALIDATION_CODE?: Maybe<Scalars['String']>;
  ITEMS_LOCATION_CONFLICT_VALIDATION_CODE?: Maybe<Scalars['String']>;
  PACKAGE_STATUS_UPDATE_ALREADY_CANCELED_VALIDATION_CODE?: Maybe<Scalars['String']>;
  PACKAGE_STATUS_UPDATE_ALREADY_DELIVERED_VALIDATION_CODE?: Maybe<Scalars['String']>;
  PACKAGE_STATUS_UPDATE_SAME_STATUS_VALIDATION_CODE?: Maybe<Scalars['String']>;
  ZERO_ITEMS_VALIDATION_CODE?: Maybe<Scalars['String']>;
  ad?: Maybe<AdProjection>;
  documentInventoryItemLocations?: Maybe<ResultConnection_DocumentLocation>;
  documentInventoryItems?: Maybe<ResultConnection_DocumentInventoryItem>;
  documentInventoryPackage?: Maybe<DocumentInventoryPackageProjection>;
  documentInventoryPackages?: Maybe<ResultConnection_DocumentInventoryPackageProjection>;
  documentInventoryTypes?: Maybe<DocumentInventoryTypeConnection_DocumentInventoryType>;
  documentShippingProviders?: Maybe<ResultConnection_ShippingProviderProjection>;
};


export type GqlRetailCarDocumentsServiceAdArgs = {
  adId: Scalars['UUID'];
};


export type GqlRetailCarDocumentsServiceDocumentInventoryItemLocationsArgs = {
  filter: DocumentInventoryItemLocationFilterInput;
};


export type GqlRetailCarDocumentsServiceDocumentInventoryItemsArgs = {
  filter: DocumentInventoryItemsFilterInput;
};


export type GqlRetailCarDocumentsServiceDocumentInventoryPackageArgs = {
  id: Scalars['UUID'];
};


export type GqlRetailCarDocumentsServiceDocumentInventoryPackagesArgs = {
  filter?: InputMaybe<DocumentInventoryPackageFilterInput>;
};

export type GeneralDto = {
  __typename?: 'GeneralDTO';
  carStarts?: Maybe<Scalars['Boolean']>;
  cmrPhoto?: Maybe<Scalars['UUID']>;
  hasKey?: Maybe<Scalars['Boolean']>;
  keyBatteryWorks?: Maybe<Scalars['Boolean']>;
};

export type GeneralDtoInput = {
  carStarts?: InputMaybe<Scalars['Boolean']>;
  cmrPhoto?: InputMaybe<Scalars['UUID']>;
  hasKey?: InputMaybe<Scalars['Boolean']>;
  keyBatteryWorks?: InputMaybe<Scalars['Boolean']>;
};

export type GpsDtoInput = {
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type GroupCreateProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
};

export type GroupProjection = {
  __typename?: 'GroupProjection';
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<RoleProjection>>>;
  updatedOn?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<UserProjection>>>;
};

export type GroupUpdateProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type HandoverSubmissionResultProjection = {
  __typename?: 'HandoverSubmissionResultProjection';
  customerEmail?: Maybe<Scalars['String']>;
  pdfDownloadLink?: Maybe<Scalars['String']>;
};

export type HappinessCallTaskManagementProjection = {
  __typename?: 'HappinessCallTaskManagementProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  carHandoverOnDate?: Maybe<Scalars['String']>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderContractSignedOn?: Maybe<Scalars['String']>;
  orderHandoverOn?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  retailCountry?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type HighlightDto = {
  __typename?: 'HighlightDTO';
  id?: Maybe<Scalars['UUID']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  translationKey?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type HighlightProjectionInput = {
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  translationKey?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export enum HistoryEventType {
  AttendedOutboundCall = 'ATTENDED_OUTBOUND_CALL',
  Branch = 'BRANCH',
  CarDescriptionUpdate = 'CAR_DESCRIPTION_UPDATE',
  CarDownpricing = 'CAR_DOWNPRICING',
  Chat = 'CHAT',
  ChatRequest = 'CHAT_REQUEST',
  Checkout = 'CHECKOUT',
  FailedOutboundCall = 'FAILED_OUTBOUND_CALL',
  ImportedInboundCall = 'IMPORTED_INBOUND_CALL',
  ImportedMissedCall = 'IMPORTED_MISSED_CALL',
  InboundEmail = 'INBOUND_EMAIL',
  InboundPhone = 'INBOUND_PHONE',
  MissedCall = 'MISSED_CALL',
  MissedPhoneCall = 'MISSED_PHONE_CALL',
  Note = 'NOTE',
  OrderEmailOutgoing = 'ORDER_EMAIL_OUTGOING',
  OrderEmailReply = 'ORDER_EMAIL_REPLY',
  Other = 'OTHER',
  OutboundEmail = 'OUTBOUND_EMAIL',
  OutboundPhone = 'OUTBOUND_PHONE',
  Sms = 'SMS',
  SystemOutboundEmail = 'SYSTEM_OUTBOUND_EMAIL',
  Task = 'TASK',
  TestDriveRequest = 'TEST_DRIVE_REQUEST',
  UnattendedOutboundCall = 'UNATTENDED_OUTBOUND_CALL',
  Unknown = 'UNKNOWN'
}

export type HmcsDataProjection = {
  __typename?: 'HmcsDataProjection';
  amountOfInstalmentsOverdue?: Maybe<Scalars['Long']>;
  amountPaid: Scalars['Long'];
  dateOfLastUnpaidInstalment?: Maybe<Scalars['String']>;
  financingApplicationId: Scalars['UUID'];
  hmcsDataPulledOn: Scalars['String'];
  interestRate?: Maybe<Scalars['Float']>;
  numberOfDaysOverdue?: Maybe<Scalars['Int']>;
  numberOfInstallmentsPaidToDate?: Maybe<Scalars['Int']>;
  numberOfInstalmentsRemaining?: Maybe<Scalars['Int']>;
  specialPaymentsMade?: Maybe<Scalars['Long']>;
  totalAmountRemaining?: Maybe<Scalars['Long']>;
};

export type HmcsUpdateTestDataProjectionInput = {
  amountInterestDueAndPaid?: InputMaybe<Scalars['Long']>;
  amountOfInstallmentsDueAndPaid?: InputMaybe<Scalars['Long']>;
  amountOfInstallmentsDueInFuture?: InputMaybe<Scalars['Long']>;
  amountOfInstallmentsOverdue?: InputMaybe<Scalars['Long']>;
  amountPrincipalDueAndPaid?: InputMaybe<Scalars['Long']>;
  contractEndDate?: InputMaybe<Scalars['String']>;
  dateSinceWhenUnpaid?: InputMaybe<Scalars['String']>;
  financingApplicationId: Scalars['UUID'];
  interestRate?: InputMaybe<Scalars['Float']>;
  monthlyRate?: InputMaybe<Scalars['Long']>;
  numberOfDaysOverdue?: InputMaybe<Scalars['Int']>;
  numberOfInstallmentsDueAndPaid?: InputMaybe<Scalars['Int']>;
  numberOfInstallmentsDueInFuture?: InputMaybe<Scalars['Int']>;
  numberOfInstallmentsOverdue?: InputMaybe<Scalars['Int']>;
  orderId: Scalars['UUID'];
  principalAmountRemaining?: InputMaybe<Scalars['Long']>;
  riskRating?: InputMaybe<Scalars['String']>;
  specialPaymentsReceived?: InputMaybe<Scalars['Long']>;
  statusPayment?: InputMaybe<Scalars['String']>;
  totalNumberOfInstallments?: InputMaybe<Scalars['Int']>;
};

export type HomeDeliveryOptionRequestDtoInput = {
  destinationCountry?: InputMaybe<Scalars['String']>;
  destinationZipcode?: InputMaybe<Scalars['String']>;
};

export type ItPowerAndZoneBasedPriceModifierProjection = {
  __typename?: 'ITPowerAndZoneBasedPriceModifierProjection';
  active: Scalars['Boolean'];
  createdBy: Scalars['UUID'];
  createdOn?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  pnlCostPrice?: Maybe<PriceDetailsProjection>;
  postalZone: Scalars['String'];
  power: Scalars['Int'];
  price?: Maybe<PriceDetailsProjection>;
  priceModifierInstance: Scalars['String'];
  productId: Scalars['UUID'];
  updatedOn?: Maybe<Scalars['String']>;
};

export type ItPowerAndZoneBasedPriceModifierUpdateProjectionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  grossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
};

export type IbanBankAccountDetailsProjection = {
  __typename?: 'IbanBankAccountDetailsProjection';
  TYPE?: Maybe<Scalars['String']>;
  accountDataId?: Maybe<Scalars['UUID']>;
  bankName?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  blz?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Maybe<RetailDocumentProjection>>>;
  holder?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ImageDto = {
  __typename?: 'ImageDTO';
  cdnContainer?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  order?: Maybe<Scalars['Int']>;
  part?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type ImageIdDto = {
  __typename?: 'ImageIdDTO';
  id?: Maybe<Scalars['UUID']>;
  url?: Maybe<Scalars['String']>;
};

export type ImageProjection = {
  __typename?: 'ImageProjection';
  cdnContainer?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  order?: Maybe<Scalars['Int']>;
  part?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type ImageProjectionInput = {
  cdnContainer?: InputMaybe<Scalars['String']>;
  fullUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  order?: InputMaybe<Scalars['Int']>;
  part?: InputMaybe<Scalars['String']>;
  photoId?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ImagesDtoInput = {
  certifiedCheque?: InputMaybe<Scalars['UUID']>;
  comment?: InputMaybe<Scalars['UUID']>;
  comments?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  instantTransfer?: InputMaybe<Scalars['UUID']>;
  odometer?: InputMaybe<Scalars['UUID']>;
  overviewFront?: InputMaybe<Scalars['UUID']>;
  overviewRear?: InputMaybe<Scalars['UUID']>;
  secondaryWheels?: InputMaybe<Scalars['UUID']>;
  sideLeft?: InputMaybe<Scalars['UUID']>;
  sideRight?: InputMaybe<Scalars['UUID']>;
  signature?: InputMaybe<Scalars['UUID']>;
};

export type ImportCarleadProjectionInput = {
  carHash?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  skipPublish?: InputMaybe<Scalars['Boolean']>;
};

export type InboundOutboundPaymentRefDistributionProjection = {
  __typename?: 'InboundOutboundPaymentRefDistributionProjection';
  amount?: Maybe<RetailFinancePriceProjection>;
  inboundPayment?: Maybe<RetailPaymentProjection>;
  refundTempId?: Maybe<Scalars['Int']>;
};

export type InboundOutboundPaymentRefDistributionProjectionInput = {
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  inboundPayment?: InputMaybe<RetailPaymentProjectionInput>;
  refundTempId?: InputMaybe<Scalars['Int']>;
};

export type IncomeCheckCategoryDto = {
  __typename?: 'IncomeCheckCategoryDto';
  currentPayment?: Maybe<Scalars['Boolean']>;
  employer?: Maybe<Array<Maybe<IncomeCheckCategoryDtoEmployer>>>;
  monthlySums?: Maybe<Scalars['Map_String_BigDecimalScalar']>;
};

export type IncomeCheckCategoryDtoEmployer = {
  __typename?: 'IncomeCheckCategoryDtoEmployer';
  currentEmployer?: Maybe<Scalars['Boolean']>;
  monthsSumsMedian?: Maybe<Scalars['BigDecimal']>;
  monthsWithSalariesCount?: Maybe<Scalars['Int']>;
};

export type InsuranceItemProjection = {
  __typename?: 'InsuranceItemProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customDiscountMinorUnits?: Maybe<Scalars['Long']>;
  customDiscountType?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  insurance?: Maybe<RetailInsuranceProjection>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  obsolete?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderItemRefund?: Maybe<Scalars['Long']>;
  parentId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type InteriorDto = {
  __typename?: 'InteriorDTO';
  backSeatsPhoto?: Maybe<Scalars['UUID']>;
  frontSeatsPhoto?: Maybe<Scalars['UUID']>;
  fuelLevel?: Maybe<Scalars['String']>;
  fuelLevelPhoto?: Maybe<Scalars['UUID']>;
  gps?: Maybe<Scalars['Boolean']>;
  gpsManufacturer?: Maybe<Scalars['String']>;
  gpsPhoto?: Maybe<Scalars['UUID']>;
  gpsType?: Maybe<GpsType>;
  hasFirstAidKit?: Maybe<Scalars['Boolean']>;
  hasFloorMat?: Maybe<Scalars['Boolean']>;
  hasSecondaryWheels?: Maybe<Scalars['Boolean']>;
  hasSecurityTriangle?: Maybe<Scalars['Boolean']>;
  hasSpareTire?: Maybe<Scalars['Boolean']>;
  hasTireFit?: Maybe<Scalars['Boolean']>;
  hasTrunkCover?: Maybe<Scalars['Boolean']>;
  hasWarningVest?: Maybe<Scalars['Boolean']>;
  interiorPhotos?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  isInteriorClean?: Maybe<Scalars['Boolean']>;
  millage?: Maybe<Scalars['Int']>;
  odometerPhoto?: Maybe<Scalars['UUID']>;
  radio?: Maybe<Scalars['Boolean']>;
  radioManufacturer?: Maybe<Scalars['String']>;
  radioPhoto?: Maybe<Scalars['UUID']>;
  secondaryWheelsPhotos?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  secondaryWheelsRimType?: Maybe<RimType>;
  secondaryWheelsTireType?: Maybe<TireType>;
  warningLights?: Maybe<WarningLightsDto>;
  warningLightsOn?: Maybe<Scalars['Boolean']>;
  warningLightsPhoto?: Maybe<Scalars['UUID']>;
};

export type InteriorDtoInput = {
  backSeatsPhoto?: InputMaybe<Scalars['UUID']>;
  frontSeatsPhoto?: InputMaybe<Scalars['UUID']>;
  fuelLevel?: InputMaybe<Scalars['String']>;
  fuelLevelPhoto?: InputMaybe<Scalars['UUID']>;
  gps?: InputMaybe<Scalars['Boolean']>;
  gpsManufacturer?: InputMaybe<Scalars['String']>;
  gpsPhoto?: InputMaybe<Scalars['UUID']>;
  gpsType?: InputMaybe<GpsType>;
  hasFirstAidKit?: InputMaybe<Scalars['Boolean']>;
  hasFloorMat?: InputMaybe<Scalars['Boolean']>;
  hasSecondaryWheels?: InputMaybe<Scalars['Boolean']>;
  hasSecurityTriangle?: InputMaybe<Scalars['Boolean']>;
  hasSpareTire?: InputMaybe<Scalars['Boolean']>;
  hasTireFit?: InputMaybe<Scalars['Boolean']>;
  hasTrunkCover?: InputMaybe<Scalars['Boolean']>;
  hasWarningVest?: InputMaybe<Scalars['Boolean']>;
  interiorPhotos?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  isInteriorClean?: InputMaybe<Scalars['Boolean']>;
  millage?: InputMaybe<Scalars['Int']>;
  odometerPhoto?: InputMaybe<Scalars['UUID']>;
  radio?: InputMaybe<Scalars['Boolean']>;
  radioManufacturer?: InputMaybe<Scalars['String']>;
  radioPhoto?: InputMaybe<Scalars['UUID']>;
  secondaryWheelsPhotos?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  secondaryWheelsRimType?: InputMaybe<RimType>;
  secondaryWheelsTireType?: InputMaybe<TireType>;
  warningLights?: InputMaybe<WarningLightsDtoInput>;
  warningLightsOn?: InputMaybe<Scalars['Boolean']>;
  warningLightsPhoto?: InputMaybe<Scalars['UUID']>;
};

export type InternalPaymentOptionsProjection = {
  __typename?: 'InternalPaymentOptionsProjection';
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InventorySourcingImportCreateProjectionInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export type InvoiceCreateBatchItemProjectionInput = {
  orderId?: InputMaybe<Scalars['UUID']>;
  vatKey?: InputMaybe<Scalars['String']>;
};

export type InvoiceCreditNoteCreateProjectionInput = {
  creditNoteNumber?: InputMaybe<Scalars['String']>;
  invoicedOn?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  proformaNumber?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<InvoiceState>;
  totalPriceNetMinorUnits?: InputMaybe<Scalars['Long']>;
};

export enum InvoiceItemExternalType {
  Ad = 'AD',
  AdSecondaryWheels = 'AD_SECONDARY_WHEELS',
  Delivery = 'DELIVERY',
  DownPayment = 'DOWN_PAYMENT',
  Fee = 'FEE',
  Product = 'PRODUCT',
  Registration = 'REGISTRATION',
  VehiclePreparation = 'VEHICLE_PREPARATION',
  Warranty = 'WARRANTY'
}

export type InvoiceItemProjection = {
  __typename?: 'InvoiceItemProjection';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  discountGross?: Maybe<PriceDto>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<InvoiceItemExternalType>;
  id?: Maybe<Scalars['UUID']>;
  orderItemId?: Maybe<Scalars['UUID']>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  taxAdvantageAmountMinorUnits?: Maybe<Scalars['Long']>;
  updatedOn?: Maybe<Scalars['String']>;
  vatAmount?: Maybe<PriceDto>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type InvoiceProjection = {
  __typename?: 'InvoiceProjection';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  creditNoteNumber?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  invoicedOn?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<InvoiceItemProjection>>>;
  number?: Maybe<Scalars['String']>;
  oracleAccountingDate?: Maybe<Scalars['String']>;
  oracleFilePresignedUrl?: Maybe<Scalars['String']>;
  oracleGrossAmount?: Maybe<PriceDto>;
  oracleIssueDate?: Maybe<Scalars['String']>;
  oracleTransactionalNumber?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  paidOn?: Maybe<Scalars['String']>;
  proformaNumber?: Maybe<Scalars['String']>;
  state?: Maybe<InvoiceState>;
  totalPriceGross?: Maybe<PriceDto>;
  totalPriceGrossMinorUnits?: Maybe<Scalars['Long']>;
  totalPriceNet?: Maybe<PriceDto>;
  totalPriceNetMinorUnits?: Maybe<Scalars['Long']>;
  totalVatAmount?: Maybe<PriceDto>;
  totalVatAmountMinorUnits?: Maybe<Scalars['Long']>;
  type?: Maybe<InvoiceType>;
  updatedOn?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Scalars['String']>;
};

export enum InvoiceState {
  /** @deprecated Deprecated */
  Cancelled = 'CANCELLED',
  FullyCredited = 'FULLY_CREDITED',
  /** @deprecated Deprecated */
  Open = 'OPEN',
  Paid = 'PAID',
  PartiallyCredited = 'PARTIALLY_CREDITED',
  /** @deprecated Deprecated */
  PartiallyPaid = 'PARTIALLY_PAID',
  /** @deprecated Deprecated */
  Refund = 'REFUND'
}

export enum InvoiceType {
  /** @deprecated Deprecated */
  CreditNote = 'CREDIT_NOTE',
  DownPayment = 'DOWN_PAYMENT',
  Standard = 'STANDARD'
}

export type InvoiceUpdateProjectionInput = {
  creditNoteNumber?: InputMaybe<Scalars['String']>;
  invoicedOn?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  proformaNumber?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<InvoiceState>;
};

export type KbaRequestHistoryProjection = {
  __typename?: 'KBARequestHistoryProjection';
  applicationNumber?: Maybe<Scalars['String']>;
  createdOn: Scalars['String'];
  digitalCarRegistrationId: Scalars['UUID'];
  errorDescription?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
  status: Scalars['String'];
};

export type LatLngInput = {
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
};

export type LicensePlateProjection = {
  __typename?: 'LicensePlateProjection';
  digits?: Maybe<Scalars['String']>;
  letters?: Maybe<Scalars['String']>;
  licensePlateType: Scalars['String'];
  region?: Maybe<Scalars['String']>;
};

export type LicensePlateProjectionInput = {
  digits?: InputMaybe<Scalars['String']>;
  letters?: InputMaybe<Scalars['String']>;
  licensePlateType: Scalars['String'];
  region?: InputMaybe<Scalars['String']>;
};

export type LicensePlateTaskProjection = {
  __typename?: 'LicensePlateTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type LinkProjection = {
  __typename?: 'LinkProjection';
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type LinkedOrdersDto = {
  __typename?: 'LinkedOrdersDTO';
  id?: Maybe<Scalars['UUID']>;
  type?: Maybe<Scalars['String']>;
};

export type LinkedOrdersDtoInput = {
  id?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ListContainerDto_AuxUserDto = {
  __typename?: 'ListContainerDTO_AuxUserDTO';
  entities?: Maybe<Array<Maybe<AuxUserDto>>>;
};

export type ListContainerDto_GroupProjection = {
  __typename?: 'ListContainerDTO_GroupProjection';
  entities?: Maybe<Array<Maybe<GroupProjection>>>;
};

export type ListContainerDto_OrderItemsRefundsDto = {
  __typename?: 'ListContainerDTO_OrderItemsRefundsDTO';
  entities?: Maybe<Array<Maybe<OrderItemsRefundsDto>>>;
};

export type ListContainerDto_RetailDiscountItemDto = {
  __typename?: 'ListContainerDTO_RetailDiscountItemDTO';
  entities?: Maybe<Array<Maybe<RetailDiscountItemDto>>>;
};

export type ListContainerDto_String = {
  __typename?: 'ListContainerDTO_String';
  entities?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LiveChatAddTopicRequestProjectionInput = {
  liveChatId?: InputMaybe<Scalars['UUID']>;
  topicId?: InputMaybe<Scalars['UUID']>;
};

export type LiveChatAgentAnswerRequestProjectionInput = {
  liveChatId?: InputMaybe<Scalars['UUID']>;
  text?: InputMaybe<Scalars['String']>;
};

export type LiveChatAgentAnswerResponseProjection = {
  __typename?: 'LiveChatAgentAnswerResponseProjection';
  acknowledgedAt?: Maybe<Scalars['String']>;
};

export type LiveChatAgentMarkReadRequestProjectionInput = {
  liveChatId?: InputMaybe<Scalars['UUID']>;
};

export type LiveChatAgentProjection = {
  __typename?: 'LiveChatAgentProjection';
  boUserId?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type LiveChatAgentTeamProjection = {
  __typename?: 'LiveChatAgentTeamProjection';
  agents?: Maybe<Array<Maybe<LiveChatAgentProjection>>>;
  categories?: Maybe<Array<Maybe<LiveChatCategoryProjection>>>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type LiveChatAiSuggestionRequestProjectionInput = {
  conversationId?: InputMaybe<Scalars['UUID']>;
  locale?: InputMaybe<Scalars['String']>;
  targetMessageId?: InputMaybe<Scalars['UUID']>;
};

export type LiveChatAiSuggestionResponseProjection = {
  __typename?: 'LiveChatAiSuggestionResponseProjection';
  success?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
};

export type LiveChatCategoryProjection = {
  __typename?: 'LiveChatCategoryProjection';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type LiveChatEndRequestProjectionInput = {
  action?: InputMaybe<Scalars['String']>;
  liveChatId?: InputMaybe<Scalars['UUID']>;
};

export type LiveChatRemoveTopicRequestProjectionInput = {
  liveChatId?: InputMaybe<Scalars['UUID']>;
  topicId?: InputMaybe<Scalars['UUID']>;
};

export type LiveChatTrackAiSuggestionRequestProjectionInput = {
  conversationId?: InputMaybe<Scalars['UUID']>;
  eventType?: InputMaybe<Scalars['String']>;
  messageId?: InputMaybe<Scalars['UUID']>;
  rating?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type LiveChatUpdateDetailsRequestInput = {
  customerFirstName?: InputMaybe<Scalars['String']>;
  customerLastName?: InputMaybe<Scalars['String']>;
};

export type LiveChatUpdateTeamAgentsRequestProjectionInput = {
  addAgentIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  removeAgentIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  teamId?: InputMaybe<Scalars['UUID']>;
};

export type LiveChatUpdateTeamCategoriesRequestProjectionInput = {
  addCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  removeCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  teamId?: InputMaybe<Scalars['UUID']>;
};

export type Location = {
  __typename?: 'Location';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type LocationDetailDto = {
  __typename?: 'LocationDetailDTO';
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type LocationInfoDto = {
  __typename?: 'LocationInfoDTO';
  currentLocation?: Maybe<LocationDetailDto>;
  documentLocation?: Maybe<LocationDetailDto>;
  routeEstimatedArrivalDate?: Maybe<Scalars['String']>;
  routeId?: Maybe<Scalars['Long']>;
  routeStatus?: Maybe<RouteStatus>;
  sourceLocation?: Maybe<LocationDetailDto>;
};

export type LocationInput = {
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

export type LogProjectionInput = {
  details?: InputMaybe<Scalars['Map_String_StringScalar']>;
  eventSource?: InputMaybe<Scalars['String']>;
  eventType?: InputMaybe<Scalars['String']>;
};

export type MileageDto = {
  __typename?: 'MileageDTO';
  distance?: Maybe<Scalars['Int']>;
  unit?: Maybe<MileageUnit>;
};

export type MileageDtoInput = {
  distance?: InputMaybe<Scalars['Int']>;
  unit?: InputMaybe<MileageUnit>;
};

export type MileageProjection = {
  __typename?: 'MileageProjection';
  distance: Scalars['Int'];
  unit: MileageUnit;
};

export type MileageProjectionInput = {
  distance: Scalars['Int'];
  unit: MileageUnit;
};

export enum MileageUnit {
  Km = 'KM',
  Miles = 'MILES'
}

export type ModelDto = {
  __typename?: 'ModelDTO';
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  subModel?: Maybe<Scalars['String']>;
};

export type MonthlyNetIncomeProjection = {
  __typename?: 'MonthlyNetIncomeProjection';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  month?: Maybe<Scalars['String']>;
};

export type MonthlyNetIncomeProjectionInput = {
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  month?: InputMaybe<Scalars['String']>;
};

/** Mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  addAiSettings?: Maybe<AiSettingProjection>;
  addGroupUsers: Scalars['Boolean'];
  addLiveChatTopic: Scalars['Boolean'];
  addRetailAdSecondaryWheelsImages?: Maybe<RetailAdSecondaryWheelsProjection>;
  addUserGroups: Scalars['Boolean'];
  addUserProperties: Scalars['Boolean'];
  addpublishingblockers: Scalars['Boolean'];
  applyPriceUpdate: Scalars['Boolean'];
  approveOrderFinancing: Scalars['Boolean'];
  assignFinancingAgent: Scalars['Boolean'];
  assignGroupRoles: Scalars['Boolean'];
  assignGroupUsers: Scalars['Boolean'];
  assignRoleGroups: Scalars['Boolean'];
  assignTaskManagerTask: Scalars['Boolean'];
  assignTestDataAccessParam: Scalars['Boolean'];
  assignUnidentifiedPaymentToOrder?: Maybe<RetailPaymentProjection>;
  assignUserCountryAccessParams: Scalars['Boolean'];
  assignUserGroupAccessParams: Scalars['Boolean'];
  cancelCreditNoteClaim?: Maybe<CreditNoteProjection>;
  cancelOrderFinancing: Scalars['Boolean'];
  cancelOutboundDamageCompensationPayment?: Maybe<RetailOutboundPaymentProjection>;
  cancelRetailPayment: Scalars['Boolean'];
  carRegistrationActions: Scalars['Boolean'];
  changeAgentStatus: Scalars['Boolean'];
  changeCustomerType?: Maybe<RetailCustomerProjection>;
  changeDocumentPackageStatus?: Maybe<ChangeDocumentPackageStatusResult>;
  changeReferralStatus?: Maybe<ReferralPaymentProjection>;
  changeRegistrationStatus: Scalars['Boolean'];
  changeRetailPaymentOrder?: Maybe<RetailPaymentProjection>;
  claimPartialRefundRequestNewRetailBankDetails: Scalars['Boolean'];
  commentTaskManagerTask?: Maybe<TaskManagerCommentProjection>;
  completeExtFinBankDetailsTask: Scalars['Boolean'];
  completeOrderFinancing: Scalars['Boolean'];
  completeTaskManagerTask: Scalars['Boolean'];
  completeTaskManagerTaskV2: Scalars['Boolean'];
  createAbTestingExperiment?: Maybe<RetailAbTestingExperimentProjection>;
  createAdditionalFeesCollectionOrder?: Maybe<OrderProjection>;
  createAdditionalItemsPurchaseOrder?: Maybe<OrderProjection>;
  createAdditionalOrder?: Maybe<OrderProjection>;
  createAdditionalProofRequests?: Maybe<Array<Maybe<RetailClaimProofRequestProjection>>>;
  createAttachmentUploadPresignedUrl?: Maybe<AttachmentUploadPresignedUrlProjection>;
  createAvailabilityRule?: Maybe<AvailabilityRuleProjection>;
  createBranchEntryCheck?: Maybe<BranchEntryCheckSubmissionResultProjection>;
  createBranchEntryCheckImageUploadMetadata?: Maybe<AwsImageUploadResponseProjection>;
  createCarRegistrationProduct?: Maybe<RetailCarRegistrationProjection>;
  createChatbotQuestion?: Maybe<ChatbotQuestionDto>;
  createCompleteStep?: Maybe<RetailCheckoutStepCreateProjection>;
  createCreditNotes?: Maybe<Array<Maybe<CreditNoteProjection>>>;
  createCustomerAddress?: Maybe<CustomerAddressProjection>;
  createCustomerUser?: Maybe<CustomerProjection>;
  createCustomerUserPasswordless?: Maybe<CustomerProjection>;
  createDebtComment?: Maybe<RetailOrderDebtCommentProjection>;
  createDigitalHandoverProtocol?: Maybe<HandoverSubmissionResultProjection>;
  createDiscount?: Maybe<DiscountDto>;
  createDistributedCreditNotesRefunds?: Maybe<Scalars['Boolean']>;
  createDistributedRefunds?: Maybe<Scalars['Boolean']>;
  createDocument?: Maybe<RetailDocumentProjection>;
  createDocumentInventoryType?: Maybe<CreateDocumentInventoryTypePayload>;
  createDocumentPackage?: Maybe<CreateDocumentPackageResult>;
  createDocumentRequest?: Maybe<RetailDocumentRequestProjection>;
  createDownPaymentProduct?: Maybe<DownPaymentProjection>;
  createExternalFinancingEvaluation?: Maybe<ExternalFinanceRiskEvaluationProjection>;
  createExternalFinancingLeasingDetails?: Maybe<ExternalFinancingLeasingDetailsProjection>;
  createFeeProduct?: Maybe<RetailFeeProjection>;
  createGroup?: Maybe<GroupProjection>;
  createImageTag?: Maybe<RetailImageTagProjection>;
  createInsuranceProduct?: Maybe<RetailInsuranceProjection>;
  createInternalTransferPaymentComment?: Maybe<RetailPaymentCommentProjection>;
  createInvoiceCreditNote?: Maybe<InvoiceProjection>;
  createInvoiceDownPayment?: Maybe<InvoiceProjection>;
  createInvoiceStandard?: Maybe<InvoiceProjection>;
  createInvoiceStandardWithValidation?: Maybe<InvoiceProjection>;
  createLiveChatAgentAnswerTemplate?: Maybe<AgentAnswerTemplateProjection>;
  createLiveChatAgents?: Maybe<Array<Maybe<LiveChatAgentProjection>>>;
  createOrUpdateExternalFinancingCustomerDetails?: Maybe<ExternalFinancingCustomerDetailsProjection>;
  createOrUpdateExternalFinancingOptions?: Maybe<ExternalFinancingOptionsProjection>;
  createOracleDownPaymentInvoice: Scalars['Boolean'];
  createOracleStandardInvoice: Scalars['Boolean'];
  createOracleStandardInvoiceBatch: Scalars['Boolean'];
  createOrder?: Maybe<OrderProjection>;
  createOrderAddress?: Maybe<OrderAddressProjection>;
  createOrderBranchCarCheckupTask: Scalars['Boolean'];
  createOrderBranchCarCheckupTaskV2: Scalars['Boolean'];
  createOrderCancellationRequestedTask: Scalars['Boolean'];
  createOrderCancellationRequestedTaskV2: Scalars['Boolean'];
  createOrderCancellationTask: Scalars['Boolean'];
  createOrderCancellationTaskV2: Scalars['Boolean'];
  createOrderCarReadyForHomeDeliveryTask: Scalars['Boolean'];
  createOrderCarReadyForHomeDeliveryTaskV2: Scalars['Boolean'];
  createOrderCarReadyForPickupCallTask: Scalars['Boolean'];
  createOrderCarReadyForPickupCallTaskV2: Scalars['Boolean'];
  createOrderCarRegistrationDocumentsReturnedToAHTask: Scalars['Boolean'];
  createOrderCarRegistrationDocumentsReturnedToAHTaskV2: Scalars['Boolean'];
  createOrderCarReturnedToAHTask: Scalars['Boolean'];
  createOrderCarReturnedToAHTaskV2: Scalars['Boolean'];
  createOrderCareCallTask: Scalars['Boolean'];
  createOrderCareCallTaskV2: Scalars['Boolean'];
  createOrderClaimClosedTask: Scalars['Boolean'];
  createOrderClaimClosedTaskV2: Scalars['Boolean'];
  createOrderClaimReceivedTask: Scalars['Boolean'];
  createOrderClaimReceivedTaskV2: Scalars['Boolean'];
  createOrderComment?: Maybe<OrderCommentProjection>;
  createOrderCustomerAvailabilityForHomeDeliveryTask: Scalars['Boolean'];
  createOrderCustomerAvailabilityForHomeDeliveryTaskV2: Scalars['Boolean'];
  createOrderCustomerSignedBankReleaseTask: Scalars['Boolean'];
  createOrderCustomerSignedBankReleaseTaskV2: Scalars['Boolean'];
  createOrderDocumentsRegistrationReadyTask: Scalars['Boolean'];
  createOrderDocumentsRegistrationReadyTaskV2: Scalars['Boolean'];
  createOrderDocumentsRequestedTask: Scalars['Boolean'];
  createOrderDocumentsRequestedTaskV2: Scalars['Boolean'];
  createOrderFinalDocumentsReceivedTask: Scalars['Boolean'];
  createOrderFinalDocumentsReceivedTaskV2: Scalars['Boolean'];
  createOrderFinancing?: Maybe<FinancingApplicationDto>;
  createOrderHandoverAppointmentTask: Scalars['Boolean'];
  createOrderHandoverAppointmentTaskV2: Scalars['Boolean'];
  createOrderHappinessCallTask: Scalars['Boolean'];
  createOrderHappinessCallTaskV2: Scalars['Boolean'];
  createOrderHistoryEmailEvent?: Maybe<OrderHistoryEventProjection>;
  createOrderHistoryEvent?: Maybe<OrderHistoryEventProjection>;
  createOrderItem?: Maybe<RetailOrderItemDto>;
  createOrderRegistrationInitiatedTask: Scalars['Boolean'];
  createOrderRegistrationInitiatedTaskV2: Scalars['Boolean'];
  createOrderShippingAddressBody?: Maybe<RetailAddressProjection>;
  createOrderUploadedHandoverDocumentTask: Scalars['Boolean'];
  createOrderUploadedHandoverDocumentTaskV2: Scalars['Boolean'];
  createOrderWarrantyContractSentTask: Scalars['Boolean'];
  createOrderWarrantyContractSentTaskV2: Scalars['Boolean'];
  createOrderWarrantyContractSignedTask: Scalars['Boolean'];
  createOrderWarrantyContractSignedTaskV2: Scalars['Boolean'];
  createOtherOrder?: Maybe<OrderProjection>;
  createOutboundPayment?: Maybe<RetailOutboundPaymentProjection>;
  createPnlItem?: Maybe<PnlItemProjection>;
  createPnlItemType?: Maybe<PnlItemTypeProjection>;
  createPnlPartner?: Maybe<PnlPartnerProjection>;
  createProduct?: Maybe<RetailProductProjection>;
  createRefund?: Maybe<RetailRefundProjection>;
  createRefundsWithCreditNotesDistribution?: Maybe<Scalars['Boolean']>;
  createRefurbishment?: Maybe<RefurbishmentDto>;
  createRefurbishmentComment?: Maybe<RefurbishmentCommentProjection>;
  createRefurbishmentServices?: Maybe<Array<Maybe<RefurbishmentServiceDto>>>;
  createRefurbishmentServicesOrder?: Maybe<Array<Maybe<RefurbishmentServiceDto>>>;
  createRetailAccountData?: Maybe<AccountDataDto>;
  createRetailAdSecondaryWheels?: Maybe<RetailAdSecondaryWheelsProjection>;
  createRetailAddress?: Maybe<RetailAddressProjection>;
  createRetailCheckout?: Maybe<RetailCheckoutProjection>;
  createRetailClaimCost?: Maybe<RetailClaimCostProjection>;
  createRetailClaimCostsComment?: Maybe<RetailClaimCostCommentProjection>;
  createRetailClaimV2?: Maybe<RetailClaimProjection>;
  createRetailClaimWorkshopDetails?: Maybe<RetailClaimWorkshopDetailsProjection>;
  createRetailCustomer?: Maybe<RetailCustomerProjection>;
  createRetailDiscountCustomItem?: Maybe<RetailDiscountItemDto>;
  createRetailDiscountEcoBonusItem?: Maybe<RetailDiscountItemDto>;
  createRetailDiscountEmployeeItem?: Maybe<RetailDiscountItemDto>;
  createRetailDiscountFinancingItem?: Maybe<RetailDiscountItemDto>;
  createRetailFeature?: Maybe<RetailFeatureProjection>;
  createRetailLead?: Maybe<RetailLeadProjection>;
  createRetailOrderAccountDetails?: Maybe<RetailOrderAccountDetailsProjection>;
  createRetailOrderSubClaim?: Maybe<RetailSubClaimProjection>;
  createRetailPayment?: Maybe<RetailPaymentProjection>;
  createRetailPaymentComment?: Maybe<RetailPaymentCommentProjection>;
  createRetailSubClaimComment?: Maybe<RetailSubClaimCommentProjection>;
  createRetailSubClaimDocument?: Maybe<RetailSubClaimDocumentProjection>;
  createRole?: Maybe<RoleProjection>;
  createSEPAMandate?: Maybe<FinancingSepaMandateProjection>;
  createSellerAddress?: Maybe<SellerAddressProjection>;
  createStandardOrder?: Maybe<OrderProjection>;
  createSubProduct: Scalars['Boolean'];
  createTestHmcsData: Scalars['Boolean'];
  createTradeIn?: Maybe<RetailTradeInProjection>;
  createTypedRetailOrderAccountDetails?: Maybe<RetailOrderAccountDetailsProjection>;
  createUnidentifiedPaymentComment?: Maybe<RetailPaymentCommentProjection>;
  createUser?: Maybe<AuxUserDto>;
  createVehiclePreparationProduct?: Maybe<RetailVehiclePreparationProjection>;
  createVerificationCallSurvey?: Maybe<SurveyAggregationProjection>;
  createWarrantyProduct?: Maybe<RetailWarrantyProjection>;
  deleteAbTestingExperiment: Scalars['Boolean'];
  deleteAbTestingExperimentCounters: Scalars['Boolean'];
  deleteAbTestingParticipants?: Maybe<Scalars['Int']>;
  deleteAvailabilityRule: Scalars['Boolean'];
  deleteChatbotQuestion: Scalars['Boolean'];
  deleteDocumentInventoryItem?: Maybe<DeleteDocumentInventoryItemResult>;
  deleteExternalFinancingCustomerDetails: Scalars['Boolean'];
  deleteExternalRiskUserInfo: Scalars['Boolean'];
  deleteGroupUsers: Scalars['Boolean'];
  deleteImageTag: Scalars['Boolean'];
  deleteLiveChatAgent: Scalars['Boolean'];
  deleteLiveChatAgentAnswerTemplate: Scalars['Boolean'];
  deleteOrderAddress: Scalars['Boolean'];
  deleteOrderBranchCarCheckupTask: Scalars['Boolean'];
  deleteOrderBranchCarCheckupTaskV2: Scalars['Boolean'];
  deleteOrderCancellationRequestedTask: Scalars['Boolean'];
  deleteOrderCancellationRequestedTaskV2: Scalars['Boolean'];
  deleteOrderCarReadyForHomeDeliveryTask: Scalars['Boolean'];
  deleteOrderCarReadyForHomeDeliveryTaskV2: Scalars['Boolean'];
  deleteOrderCarReadyForPickupCallTask: Scalars['Boolean'];
  deleteOrderCarReadyForPickupCallTaskV2: Scalars['Boolean'];
  deleteOrderCarRegistrationDocumentsReturnedToAHTask: Scalars['Boolean'];
  deleteOrderCarRegistrationDocumentsReturnedToAHTaskV2: Scalars['Boolean'];
  deleteOrderCarReturnedToAHTask: Scalars['Boolean'];
  deleteOrderCarReturnedToAHTaskV2: Scalars['Boolean'];
  deleteOrderCareCallTask: Scalars['Boolean'];
  deleteOrderCareCallTaskV2: Scalars['Boolean'];
  deleteOrderClaimClosedTask: Scalars['Boolean'];
  deleteOrderClaimClosedTaskV2: Scalars['Boolean'];
  deleteOrderClaimReceivedTask: Scalars['Boolean'];
  deleteOrderClaimReceivedTaskV2: Scalars['Boolean'];
  deleteOrderCustomerAvailabilityForHomeDeliveryTask: Scalars['Boolean'];
  deleteOrderCustomerAvailabilityForHomeDeliveryTaskV2: Scalars['Boolean'];
  deleteOrderCustomerSignedBankReleaseTask: Scalars['Boolean'];
  deleteOrderCustomerSignedBankReleaseTaskV2: Scalars['Boolean'];
  deleteOrderDocumentsRegistrationReadyTask: Scalars['Boolean'];
  deleteOrderDocumentsRegistrationReadyTaskV2: Scalars['Boolean'];
  deleteOrderDocumentsRequestedTask: Scalars['Boolean'];
  deleteOrderDocumentsRequestedTaskV2: Scalars['Boolean'];
  deleteOrderFinalDocumentsReceivedTask: Scalars['Boolean'];
  deleteOrderFinalDocumentsReceivedTaskV2: Scalars['Boolean'];
  deleteOrderHandoverAppointmentTask: Scalars['Boolean'];
  deleteOrderHandoverAppointmentTaskV2: Scalars['Boolean'];
  deleteOrderHappinessCallTask: Scalars['Boolean'];
  deleteOrderHappinessCallTaskV2: Scalars['Boolean'];
  deleteOrderItem: Scalars['Boolean'];
  deleteOrderRegistrationInitiatedTask: Scalars['Boolean'];
  deleteOrderRegistrationInitiatedTaskV2: Scalars['Boolean'];
  deleteOrderUploadedHandoverDocumentTask: Scalars['Boolean'];
  deleteOrderUploadedHandoverDocumentTaskV2: Scalars['Boolean'];
  deleteOrderWarrantyContractSentTask: Scalars['Boolean'];
  deleteOrderWarrantyContractSentTaskV2: Scalars['Boolean'];
  deleteOrderWarrantyContractSignedTask: Scalars['Boolean'];
  deleteOrderWarrantyContractSignedTaskV2: Scalars['Boolean'];
  deletePnlItem: Scalars['Boolean'];
  deletePnlItemType: Scalars['Boolean'];
  deletePnlPartner: Scalars['Boolean'];
  deleteProduct: Scalars['Boolean'];
  deleteRefurbishmentComment: Scalars['Boolean'];
  deleteRefurbishmentServices: Scalars['Boolean'];
  deleteRetailAdSecondaryWheelsImages?: Maybe<RetailAdSecondaryWheelsProjection>;
  deleteRetailAddress: Scalars['Boolean'];
  deleteRetailClaimCost: Scalars['Boolean'];
  deleteRetailCustomer: Scalars['Boolean'];
  deleteRetailDiscountCustomItem: Scalars['Boolean'];
  deleteRetailDiscountEcoBonusItem: Scalars['Boolean'];
  deleteRetailDiscountEmployeeItem: Scalars['Boolean'];
  deleteRetailDiscountFinancingItem: Scalars['Boolean'];
  deleteRetailDiscountVoucherItem: Scalars['Boolean'];
  deleteRetailDiscountWarrantyItem: Scalars['Boolean'];
  deleteRetailFeature?: Maybe<RetailFeatureProjection>;
  deleteRetailSubClaim: Scalars['Boolean'];
  deleteRetailSubClaimDocument: Scalars['Boolean'];
  deleteSubProduct: Scalars['Boolean'];
  deleteUserGroups: Scalars['Boolean'];
  deleteUserProperties: Scalars['Boolean'];
  disableDocumentInventoryType?: Maybe<DisableDocumentInventoryTypePayload>;
  disableOrderAutoCancellation: Scalars['Boolean'];
  distributeRetailClaimCosts: Scalars['Boolean'];
  distributeUnidentifiedPayment: Scalars['Boolean'];
  enableDocumentInventoryType?: Maybe<EnableDocumentInventoryTypePayload>;
  endLiveChat: Scalars['Boolean'];
  externalFinancingEvaluationV2?: Maybe<ExternalFinancingEvaluationV2Projection>;
  externalFinancingPreEvaluationV2?: Maybe<ExternalFinancingEvaluationV2Projection>;
  forcePersistExternalRiskUserInfo?: Maybe<ExternalRiskUserInfoProjection>;
  generateAiSuggestion?: Maybe<LiveChatAiSuggestionResponseProjection>;
  generatePdfDocument?: Maybe<RetailDocumentGeneratePdfResponseProjection>;
  importCarRegistrationActionItems?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importCarlead?: Maybe<AdProjection>;
  importChatbotQuestions?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importInventorySourcingItems?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importPriceUpdateItems?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importProfitAndLossItems?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importRetailDocumentInventoryItems?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  importRetailPaymentItems?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  linkPreCheckoutFinancingToOrder: Scalars['Boolean'];
  markAllUserNotificationsReadOrUnread: Scalars['Boolean'];
  markLiveChatAsRead: Scalars['Boolean'];
  markNotificationReadOrUnread: Scalars['Boolean'];
  markRetailReady: Scalars['Boolean'];
  markUnidentifiedPaymentAsInternalTransfer?: Maybe<RetailInternalTransferPaymentProjection>;
  moveOrderToCanceled: Scalars['Boolean'];
  moveOrderToCompleted: Scalars['Boolean'];
  moveOrderToContractSent: Scalars['Boolean'];
  moveOrderToContractSigned: Scalars['Boolean'];
  moveOrderToDelivered: Scalars['Boolean'];
  moveOrderToVerified: Scalars['Boolean'];
  moveRetailAdToImportedToRetail: Scalars['Boolean'];
  moveRetailAdToReturnToAuto1: Scalars['Boolean'];
  notificationVisualized: Scalars['Boolean'];
  offlineLeadsNewsletterSubscriptionsImport?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  orderDebtsEdit: Scalars['Boolean'];
  patchAbTestingExperiment?: Maybe<RetailAbTestingExperimentProjection>;
  patchDocument?: Maybe<RetailDocumentProjection>;
  persistExternalRiskUserInfo?: Maybe<ExternalRiskUserInfoProjection>;
  persistOrderFinancingFlowType: Scalars['Boolean'];
  pinOrderComment: Scalars['Boolean'];
  pinOrderHistoryEvent: Scalars['Boolean'];
  preApproveOrderFinancing: Scalars['Boolean'];
  pushAxaPolicyNotification?: Maybe<RetailAxaPolicyNotificationProjection>;
  qaChangeRegistrationStatus: Scalars['Boolean'];
  qaChangeYouSignRequestStatus: Scalars['Boolean'];
  qaKbaSabrinaApproved: Scalars['Boolean'];
  qaKbaSabrinaRejected: Scalars['Boolean'];
  qaMarkKBARequestFailed: Scalars['Boolean'];
  qaMarkKBARequestSuccessful: Scalars['Boolean'];
  rejectOrderFinancing: Scalars['Boolean'];
  removeAdPublishingBlockers: Scalars['Boolean'];
  removeLiveChatTopic: Scalars['Boolean'];
  reprocessRetailPaymentDataImports?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  requestBankDetails: Scalars['Boolean'];
  requestBankDetailsForOutboundPayment: Scalars['Boolean'];
  requestExtFinBankDetails: Scalars['Boolean'];
  resetOrderHandoverDate: Scalars['Boolean'];
  resetUserPassword: Scalars['Boolean'];
  retailDocumentPackageUpdateImport?: Maybe<Array<Maybe<RetailDataImportDto>>>;
  retryRefund: Scalars['Boolean'];
  revertLastOrderTransition: Scalars['Boolean'];
  revokeAllDocumentRequests: Scalars['Boolean'];
  revokeDocumentRequest?: Maybe<RetailDocumentRequestProjection>;
  saveFinanceRiskEvaluation?: Maybe<FinanceRiskEvaluationProjection>;
  saveVerificationDocumentFields?: Maybe<VerificationDocumentFieldsProjection>;
  saveVisitingLog: Scalars['Boolean'];
  selectFinancingPlan: Scalars['Boolean'];
  selfAssignTaskManagerTask: Scalars['Boolean'];
  sendAgentAnswer?: Maybe<LiveChatAgentAnswerResponseProjection>;
  sendLiveChatTemplateTracking: Scalars['Boolean'];
  sendNewFinanceApplicationToCustomer: Scalars['Boolean'];
  setBankContractSignedOn: Scalars['Boolean'];
  setHighlights?: Maybe<Array<Maybe<HighlightDto>>>;
  setRetailClaimCostApprove1?: Maybe<RetailClaimCostProjection>;
  setRetailClaimCostApprove2?: Maybe<RetailClaimCostProjection>;
  setRetailClaimCostApprove3?: Maybe<RetailClaimCostProjection>;
  setRetailClaimCostCustomerDecision?: Maybe<RetailClaimCostProjection>;
  setSepaMandateNotDuplicateReason: Scalars['Boolean'];
  startFullManualEvaluationProtectedV2: Scalars['Boolean'];
  startFullManualEvaluationV2: Scalars['Boolean'];
  trackAiSuggestion: Scalars['Boolean'];
  transitionPaymentInternalTransferToUnidentified?: Maybe<Array<Maybe<RetailUnidentifiedPaymentProjection>>>;
  transitionPaymentUnidentifiedToInternalTransfer?: Maybe<RetailInternalTransferPaymentProjection>;
  transitionPaymentUnidentifiedToInternalTransferBatch?: Maybe<BatchProjection_RetailInternalTransferPaymentProjection>;
  unAssignTaskManagerTask: Scalars['Boolean'];
  updateAbTestingExperiment?: Maybe<RetailAbTestingExperimentProjection>;
  updateAd: Scalars['Boolean'];
  updateAdImages: Scalars['Boolean'];
  updateAdVehicle: Scalars['Boolean'];
  updateAvailabilityRule?: Maybe<AvailabilityRuleProjection>;
  updateBranchEntryCheckStatus?: Maybe<Scalars['Boolean']>;
  updateCarRegistrationProduct?: Maybe<RetailCarRegistrationProjection>;
  updateCarTypeBasedPriceModifier?: Maybe<CarTypeBasedPriceModifierProjection>;
  updateChatbotQuestion?: Maybe<ChatbotQuestionDto>;
  updateCheckoutPaymentDetails?: Maybe<CheckoutPaymentDetailsProjection>;
  updateClaimCostCompensation: Scalars['Boolean'];
  updateClaimStateComment?: Maybe<RetailClaimProjection>;
  updateClaimStateDueDate?: Maybe<RetailClaimProjection>;
  updateClaimStateDueDaysConfig?: Maybe<Array<Maybe<RetailClaimStateDueDaysConfigProjection>>>;
  updateCreditNote?: Maybe<CreditNoteProjection>;
  updateCustomerAddress?: Maybe<CustomerAddressProjection>;
  updateCustomerDisplayOnDamage?: Maybe<RetailAdDamageDto>;
  updateCustomerUser?: Maybe<CustomerProjection>;
  updateDigitalCarRegistration?: Maybe<DigitalCarRegistrationProjection>;
  updateDigitalCarRegistrationBankAccount: Scalars['Boolean'];
  updateDocumentClassification?: Maybe<RetailDocumentProjection>;
  updateDocumentPackage?: Maybe<UpdateDocumentPackageResult>;
  updateDocumentPackagePartial?: Maybe<UpdateDocumentPackagePartialResult>;
  updateDownPaymentProduct?: Maybe<DownPaymentProjection>;
  updateExternalFinancingEvaluation?: Maybe<ExternalFinanceRiskEvaluationProjection>;
  updateExternalFinancingLeasingDetails?: Maybe<ExternalFinancingLeasingDetailsProjection>;
  updateFeeProduct?: Maybe<RetailFeeProjection>;
  updateFinancingApplicationType: Scalars['Boolean'];
  updateFinancingPaymentDetails: Scalars['Boolean'];
  updateFrRegionalTaxBasedPriceModifier?: Maybe<FrRegionalTaxBasedPriceModifierProjection>;
  updateGroup?: Maybe<GroupProjection>;
  updateITPowerAndZoneBasedPriceModifier?: Maybe<ItPowerAndZoneBasedPriceModifierProjection>;
  updateImageTag?: Maybe<RetailImageTagProjection>;
  updateInsuranceProduct?: Maybe<RetailInsuranceProjection>;
  updateInvoice?: Maybe<InvoiceProjection>;
  updateLiveChatAgentAnswerTemplate?: Maybe<AgentAnswerTemplateProjection>;
  updateLiveChatAgentTeamAgents: Scalars['Boolean'];
  updateLiveChatAgentTeamCategories: Scalars['Boolean'];
  updateLiveChatDetails: Scalars['Boolean'];
  updateMonthlyPaymentDay: Scalars['Boolean'];
  updateOrCreateQADataDigitalCarRegistration: Scalars['Boolean'];
  updateOrder?: Maybe<OrderProjection>;
  updateOrderAddress?: Maybe<OrderAddressProjection>;
  updateOrderBillingAddress?: Maybe<OrderAddressProjection>;
  updateOrderBranch: Scalars['Boolean'];
  updateOrderCarRegistration?: Maybe<OrderProjection>;
  updateOrderCarRegistrationAddress?: Maybe<OrderAddressProjection>;
  updateOrderConversionType: Scalars['Boolean'];
  updateOrderDeliveryOption: Scalars['Boolean'];
  updateOrderDeliveryPlannedCarHandoverDate: Scalars['Boolean'];
  updateOrderDocumentHandoverOn?: Maybe<OrderProjection>;
  updateOrderDocumentShippingAddress?: Maybe<OrderAddressProjection>;
  updateOrderFinancing: Scalars['Boolean'];
  updateOrderIsBranchCheckupSuccessful?: Maybe<OrderProjection>;
  updateOrderIsTest?: Maybe<OrderProjection>;
  updateOrderItemDiscountGross: Scalars['Boolean'];
  updateOrderItemInvoiceName: Scalars['Boolean'];
  updateOrderItemPriceGross: Scalars['Boolean'];
  updateOrderPaymentDetails?: Maybe<OrderProjection>;
  updateOrderShippingAddress?: Maybe<OrderAddressProjection>;
  updateOrderShippingAddressForce?: Maybe<OrderAddressProjection>;
  updateOrderVehicleDeliveredOn?: Maybe<OrderProjection>;
  updateOutboundPayment?: Maybe<RetailOutboundPaymentProjection>;
  updatePaymentDetails?: Maybe<OrderProjection>;
  updatePnlItem?: Maybe<PnlItemProjection>;
  updateProduct?: Maybe<RetailProductProjection>;
  updateRefund?: Maybe<RetailRefundProjection>;
  updateRefundAmount: Scalars['Boolean'];
  updateRefurbishment?: Maybe<RefurbishmentDto>;
  updateRefurbishmentComment?: Maybe<RefurbishmentCommentProjection>;
  updateRefurbishmentServices?: Maybe<Array<Maybe<RefurbishmentServiceDto>>>;
  updateRefurbishmentState?: Maybe<RefurbishmentDto>;
  updateRetailAd?: Maybe<RetailAdProjection>;
  updateRetailAdAuto1BuyPrice?: Maybe<RetailAdProjection>;
  updateRetailAdAuto1ReturnOn?: Maybe<RetailAdProjection>;
  updateRetailAdAuto1ReturnReason?: Maybe<RetailAdProjection>;
  updateRetailAdAutoheroPurchaseOn?: Maybe<RetailAdProjection>;
  updateRetailAdDamageDisplayToCustomerFrontend?: Maybe<Scalars['Boolean']>;
  updateRetailAdFirstImportOn?: Maybe<RetailAdProjection>;
  updateRetailAdHidingReason?: Maybe<RetailAdProjection>;
  updateRetailAdIsTest?: Maybe<RetailAdProjection>;
  updateRetailAdRetailReadyDate?: Maybe<RetailAdProjection>;
  updateRetailAdSecondaryWheelsDetails?: Maybe<RetailAdSecondaryWheelsProjection>;
  updateRetailAdSecondaryWheelsImages?: Maybe<RetailAdSecondaryWheelsProjection>;
  updateRetailAdSecondaryWheelsRimsDetails?: Maybe<RetailAdSecondaryWheelsProjection>;
  updateRetailAdSecondaryWheelsTiresDetails?: Maybe<RetailAdSecondaryWheelsProjection>;
  updateRetailAddress?: Maybe<RetailAddressProjection>;
  updateRetailCheckout?: Maybe<RetailCheckoutProjection>;
  updateRetailCheckoutByOrderId?: Maybe<RetailCheckoutProjection>;
  updateRetailClaimCompensationType: Scalars['Boolean'];
  updateRetailClaimCost?: Maybe<RetailClaimCostProjection>;
  updateRetailClaimWorkshopDetails?: Maybe<RetailClaimWorkshopDetailsProjection>;
  updateRetailCustomer?: Maybe<RetailCustomerProjection>;
  updateRetailDiscountEcoBonusItem?: Maybe<RetailDiscountItemDto>;
  updateRetailDiscountEmployeeItem?: Maybe<RetailDiscountItemDto>;
  updateRetailDiscountFinancingItem: Scalars['Boolean'];
  updateRetailDiscountWarrantyItem: Scalars['Boolean'];
  updateRetailFeature?: Maybe<RetailFeatureProjection>;
  updateRetailLead?: Maybe<RetailLeadProjection>;
  updateRetailOrderClaimAssigneeUser: Scalars['Boolean'];
  updateRetailOrderClaimGroup: Scalars['Boolean'];
  updateRetailOrderClaimState?: Maybe<RetailClaimProjection>;
  updateRetailOrderSubClaim?: Maybe<RetailSubClaimProjection>;
  updateRetailPayment?: Maybe<RetailPaymentProjection>;
  updateRetailPaymentImportConfig?: Maybe<RetailDataImportConfigDto>;
  updateRetailShippingAddressForce?: Maybe<RetailAddressProjection>;
  updateRetailTaxNoVA: Scalars['Boolean'];
  updateRetailUnidentifiedPayment?: Maybe<RetailUnidentifiedPaymentProjection>;
  updateRole?: Maybe<RoleProjection>;
  updateSellerAddress?: Maybe<SellerAddressProjection>;
  updateSurveyActivePage: Scalars['Boolean'];
  updateSurveyStatus: Scalars['Boolean'];
  updateTaskManagerTaskAction: Scalars['Boolean'];
  updateTaxAdvantage?: Maybe<RetailAdTaxAdvantageProjection>;
  updateTaxBPM: Scalars['Boolean'];
  updateTestHmcsData: Scalars['Boolean'];
  updateTestOrderDownPayment?: Maybe<OrderProjection>;
  updateUser?: Maybe<UserProjection>;
  updateVehiclePreparationProduct?: Maybe<RetailVehiclePreparationProjection>;
  updateWarrantyCarPricePriceModifier?: Maybe<WarrantyCarPricePriceModifierProjection>;
  updateWarrantyCarYearsPriceModifier?: Maybe<WarrantyCarYearsPriceModifierProjection>;
  updateWarrantyProduct?: Maybe<RetailWarrantyProjection>;
  upsellSecondaryWheels?: Maybe<SecondaryWheelsItemProjection>;
  upsellTradeIn?: Maybe<RetailTradeInItemProjection>;
  upsellWarranty?: Maybe<WarrantyItemProjection>;
  userObfuscation?: Maybe<GdprProjection>;
  verificationCallSurveySubmitAnswers: Scalars['Boolean'];
};


/** Mutation root */
export type MutationAddAiSettingsArgs = {
  aiSettings: AiSettingsCreateProjectionInput;
};


/** Mutation root */
export type MutationAddGroupUsersArgs = {
  id: Scalars['UUID'];
  userIds: Array<InputMaybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationAddLiveChatTopicArgs = {
  request?: InputMaybe<LiveChatAddTopicRequestProjectionInput>;
};


/** Mutation root */
export type MutationAddRetailAdSecondaryWheelsImagesArgs = {
  id: Scalars['UUID'];
  imageIds: RetailBatchDto_UuidInput;
};


/** Mutation root */
export type MutationAddUserGroupsArgs = {
  groupIds: Array<InputMaybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAddUserPropertiesArgs = {
  id: Scalars['UUID'];
  properties: Array<InputMaybe<UserPropertyProjectionInput>>;
};


/** Mutation root */
export type MutationAddpublishingblockersArgs = {
  publishingBlockers: Array<InputMaybe<PublishingBlockerUpdateProjectionInput>>;
  retailAdId: Scalars['UUID'];
};


/** Mutation root */
export type MutationApplyPriceUpdateArgs = {
  request: ApplyPriceUpdateProjectionInput;
};


/** Mutation root */
export type MutationApproveOrderFinancingArgs = {
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssignFinancingAgentArgs = {
  financingAgentId?: InputMaybe<Scalars['UUID']>;
  financingApplicationId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssignGroupRolesArgs = {
  id: Scalars['UUID'];
  roleIds: Array<InputMaybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationAssignGroupUsersArgs = {
  id: Scalars['UUID'];
  userIds: Array<InputMaybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationAssignRoleGroupsArgs = {
  groupIds: Array<InputMaybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssignTaskManagerTaskArgs = {
  taskId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssignTestDataAccessParamArgs = {
  enabled: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssignUnidentifiedPaymentToOrderArgs = {
  orderId?: InputMaybe<Scalars['UUID']>;
  unidentifiedPaymentId?: InputMaybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationAssignUserCountryAccessParamsArgs = {
  countries: Array<InputMaybe<Scalars['String']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssignUserGroupAccessParamsArgs = {
  groupIds: Array<InputMaybe<Scalars['String']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCancelCreditNoteClaimArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationCancelOrderFinancingArgs = {
  orderId: Scalars['UUID'];
  reason: RetailOrderFinanceCancelReason;
};


/** Mutation root */
export type MutationCancelOutboundDamageCompensationPaymentArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationCancelRetailPaymentArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCarRegistrationActionsArgs = {
  action: Scalars['String'];
  orderUuid: Scalars['UUID'];
};


/** Mutation root */
export type MutationChangeAgentStatusArgs = {
  status?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationChangeCustomerTypeArgs = {
  customer: RetailCustomerCreateProjectionInput;
  customerId: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationChangeDocumentPackageStatusArgs = {
  input: ChangeDocumentPackageStatusInput;
};


/** Mutation root */
export type MutationChangeReferralStatusArgs = {
  accountingDate: Scalars['String'];
  mainOrderId: Scalars['UUID'];
  referralId: Scalars['UUID'];
  status: Scalars['String'];
};


/** Mutation root */
export type MutationChangeRegistrationStatusArgs = {
  registrationId: Scalars['UUID'];
  registrationStatus: Scalars['String'];
};


/** Mutation root */
export type MutationChangeRetailPaymentOrderArgs = {
  id: Scalars['UUID'];
  orderNumber: Scalars['String'];
};


/** Mutation root */
export type MutationClaimPartialRefundRequestNewRetailBankDetailsArgs = {
  claimPartialRefundId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCommentTaskManagerTaskArgs = {
  taskManagerCommentCreateProjection: TaskManagerCommentCreateProjectionInput;
};


/** Mutation root */
export type MutationCompleteExtFinBankDetailsTaskArgs = {
  description?: InputMaybe<Scalars['String']>;
  taskId: Scalars['UUID'];
  variables?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
};


/** Mutation root */
export type MutationCompleteOrderFinancingArgs = {
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCompleteTaskManagerTaskArgs = {
  description?: InputMaybe<Scalars['String']>;
  taskId: Scalars['UUID'];
  variables?: InputMaybe<Scalars['Map_String_StringScalar']>;
};


/** Mutation root */
export type MutationCompleteTaskManagerTaskV2Args = {
  description?: InputMaybe<Scalars['String']>;
  taskId: Scalars['UUID'];
  variables?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
};


/** Mutation root */
export type MutationCreateAbTestingExperimentArgs = {
  input: AbTestingExperimentInputInput;
};


/** Mutation root */
export type MutationCreateAdditionalFeesCollectionOrderArgs = {
  order: OrderCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateAdditionalItemsPurchaseOrderArgs = {
  order: OrderCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateAdditionalOrderArgs = {
  order: OrderCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateAdditionalProofRequestsArgs = {
  claimId: Scalars['UUID'];
  proofRequestsCreateProjection: ProofRequestsCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateAttachmentUploadPresignedUrlArgs = {
  attachmentFilename: Scalars['String'];
  contentType?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCreateAvailabilityRuleArgs = {
  availabilityRule: AvailabilityRuleCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateBranchEntryCheckArgs = {
  stockNumber: Scalars['String'];
  submission: BranchEntrySubmissionDtoInput;
};


/** Mutation root */
export type MutationCreateBranchEntryCheckImageUploadMetadataArgs = {
  uploadRequest: AwsImageUploadRequestProjectionInput;
};


/** Mutation root */
export type MutationCreateCarRegistrationProductArgs = {
  carRegistrationCreateProduct: RetailCarRegistrationCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateChatbotQuestionArgs = {
  question: ChatbotQuestionCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateCompleteStepArgs = {
  checkoutId: Scalars['UUID'];
  retailCheckoutStepCreateProjection: RetailCheckoutStepCreateRequestProjectionInput;
};


/** Mutation root */
export type MutationCreateCreditNotesArgs = {
  creditNote?: InputMaybe<CreditNoteCreateProjectionInput>;
};


/** Mutation root */
export type MutationCreateCustomerAddressArgs = {
  address: CustomerAddressCreateProjectionInput;
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateCustomerUserArgs = {
  user: CustomerUserCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateCustomerUserPasswordlessArgs = {
  user: UserPasswordlessProjectionInput;
};


/** Mutation root */
export type MutationCreateDebtCommentArgs = {
  debtId: Scalars['UUID'];
  text: Scalars['String'];
};


/** Mutation root */
export type MutationCreateDigitalHandoverProtocolArgs = {
  orderId: Scalars['UUID'];
  submission: ProtocolSubmissionDtoInput;
};


/** Mutation root */
export type MutationCreateDiscountArgs = {
  discount: DiscountCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateDistributedCreditNotesRefundsArgs = {
  refundsDistribution: RetailOrderRefundsDistributionProjectionInput;
};


/** Mutation root */
export type MutationCreateDistributedRefundsArgs = {
  refundsDistribution: RetailOrderRefundsDistributionProjectionInput;
};


/** Mutation root */
export type MutationCreateDocumentArgs = {
  retailCreateDocumentProjection: RetailCreateDocumentProjectionInput;
};


/** Mutation root */
export type MutationCreateDocumentInventoryTypeArgs = {
  input: CreateDocumentInventoryTypeInput;
};


/** Mutation root */
export type MutationCreateDocumentPackageArgs = {
  input: CreateDocumentPackageInput;
};


/** Mutation root */
export type MutationCreateDocumentRequestArgs = {
  createProjection: RetailDocumentRequestCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateDownPaymentProductArgs = {
  downPaymentCreateProduct: RetailDownPaymentCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateExternalFinancingEvaluationArgs = {
  financeRiskEvaluation: CreateExternalFinanceRiskEvaluationProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateExternalFinancingLeasingDetailsArgs = {
  request?: InputMaybe<ExternalFinancingLeasingDetailsCreateProjectionInput>;
};


/** Mutation root */
export type MutationCreateFeeProductArgs = {
  retailFeeCreateProduct: RetailFeeCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateGroupArgs = {
  group: GroupCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateImageTagArgs = {
  imageTag: RetailImageTagCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateInsuranceProductArgs = {
  retailInsuranceCreateProduct: RetailInsuranceCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateInternalTransferPaymentCommentArgs = {
  paymentComment: RetailPaymentCommentCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateInvoiceCreditNoteArgs = {
  creditNote: InvoiceCreditNoteCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateInvoiceDownPaymentArgs = {
  invoice: RetailInvoiceCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateInvoiceStandardArgs = {
  invoice: RetailInvoiceCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateInvoiceStandardWithValidationArgs = {
  invoice: RetailInvoiceCreateProjectionInput;
  validatePayments?: InputMaybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationCreateLiveChatAgentAnswerTemplateArgs = {
  createProjection: CreateAgentAnswerTemplateProjectionInput;
};


/** Mutation root */
export type MutationCreateLiveChatAgentsArgs = {
  boUserIds: Array<InputMaybe<Scalars['UUID']>>;
  locales: Array<InputMaybe<Scalars['String']>>;
};


/** Mutation root */
export type MutationCreateOrUpdateExternalFinancingCustomerDetailsArgs = {
  customerId: Scalars['UUID'];
  externalFinancingCustomerDetails: CreateOrUpdateExternalFinancingCustomerDetailsProjectionInput;
  orderFinancingId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrUpdateExternalFinancingOptionsArgs = {
  externalFinancingOptions: CreateOrUpdateExternalFinancingOptionsProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOracleDownPaymentInvoiceArgs = {
  orderId: Scalars['UUID'];
  vatKey: Scalars['String'];
};


/** Mutation root */
export type MutationCreateOracleStandardInvoiceArgs = {
  orderId: Scalars['UUID'];
  vatKey: Scalars['String'];
};


/** Mutation root */
export type MutationCreateOracleStandardInvoiceBatchArgs = {
  entities?: InputMaybe<Array<InputMaybe<InvoiceCreateBatchItemProjectionInput>>>;
};


/** Mutation root */
export type MutationCreateOrderArgs = {
  order: OrderCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateOrderAddressArgs = {
  address: OrderAddressCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderBranchCarCheckupTaskArgs = {
  branchCheckupOn: Scalars['String'];
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderBranchCarCheckupTaskV2Args = {
  branchCheckupOn: Scalars['String'];
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCancellationRequestedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCancellationRequestedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCancellationTaskArgs = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCancellationTaskV2Args = {
  comment: OrderCancellationTaskProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCarReadyForHomeDeliveryTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCarReadyForHomeDeliveryTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCarReadyForPickupCallTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCarReadyForPickupCallTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCarRegistrationDocumentsReturnedToAhTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCarRegistrationDocumentsReturnedToAhTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCarReturnedToAhTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCarReturnedToAhTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCareCallTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCareCallTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderClaimClosedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderClaimClosedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderClaimReceivedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderClaimReceivedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCommentArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCustomerAvailabilityForHomeDeliveryTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCustomerAvailabilityForHomeDeliveryTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCustomerSignedBankReleaseTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderCustomerSignedBankReleaseTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderDocumentsRegistrationReadyTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderDocumentsRegistrationReadyTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderDocumentsRequestedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderDocumentsRequestedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderFinalDocumentsReceivedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  finalDocumentHandoverOn: Scalars['String'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderFinalDocumentsReceivedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  finalDocumentHandoverOn: Scalars['String'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderFinancingArgs = {
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderHandoverAppointmentTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
  plannedCarHandoverOn?: InputMaybe<Scalars['String']>;
  plannedHandoverFrom?: InputMaybe<Scalars['String']>;
  plannedHandoverTo?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCreateOrderHandoverAppointmentTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
  plannedCarHandoverOn?: InputMaybe<Scalars['String']>;
  plannedHandoverFrom?: InputMaybe<Scalars['String']>;
  plannedHandoverTo?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCreateOrderHappinessCallTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderHappinessCallTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderHistoryEmailEventArgs = {
  emailEvent: CreateOrderHistoryEmailEventProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderHistoryEventArgs = {
  event: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderItemArgs = {
  item: OrderItemCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderRegistrationInitiatedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderRegistrationInitiatedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderShippingAddressBodyArgs = {
  address: RetailAddressCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderUploadedHandoverDocumentTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderUploadedHandoverDocumentTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderWarrantyContractSentTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderWarrantyContractSentTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderWarrantyContractSignedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOrderWarrantyContractSignedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateOtherOrderArgs = {
  order: OrderCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateOutboundPaymentArgs = {
  payment: CreateRetailOutboundPaymentProjectionInput;
};


/** Mutation root */
export type MutationCreatePnlItemArgs = {
  item: PnlItemCreateProjectionInput;
};


/** Mutation root */
export type MutationCreatePnlItemTypeArgs = {
  pnlItemType: PnlItemTypeCreateProjectionInput;
};


/** Mutation root */
export type MutationCreatePnlPartnerArgs = {
  partner: PnlPartnerCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateProductArgs = {
  product: ProductCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRefundArgs = {
  refund: RetailRefundCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRefundsWithCreditNotesDistributionArgs = {
  refundsDistribution: RetailOrderRefundsDistributionProjectionInput;
};


/** Mutation root */
export type MutationCreateRefurbishmentArgs = {
  refurbishment: RefurbishmentCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRefurbishmentCommentArgs = {
  refurbishmentComment: RefurbishmentCommentCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRefurbishmentServicesArgs = {
  refurbishmentId: Scalars['UUID'];
  services: Array<InputMaybe<RefurbishmentServiceCreateProjectionInput>>;
};


/** Mutation root */
export type MutationCreateRefurbishmentServicesOrderArgs = {
  adId: Scalars['UUID'];
  noRepairNeededDamages: Array<InputMaybe<Scalars['UUID']>>;
  refurbishmentId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateRetailAccountDataArgs = {
  accountDetails: AccountDetailsDtoInput;
};


/** Mutation root */
export type MutationCreateRetailAdSecondaryWheelsArgs = {
  id: Scalars['UUID'];
  retailAdSecondaryWheels: RetailAdSecondaryWheelsCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRetailAddressArgs = {
  address: RetailAddressCreateProjectionInput;
  validate?: InputMaybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationCreateRetailCheckoutArgs = {
  checkout: RetailCheckoutCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRetailClaimCostArgs = {
  claimCost: RetailClaimCostCreateProjectionInput;
  claimId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateRetailClaimCostsCommentArgs = {
  claimCostComment: RetailClaimCostCommentCreateProjectionInput;
  claimCostId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateRetailClaimV2Args = {
  claimCreate: RetailClaimCreateProjectionV2Input;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateRetailClaimWorkshopDetailsArgs = {
  claimId: Scalars['UUID'];
  retailClaimWorkshopDetails: RetailClaimWorkshopDetailsCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRetailCustomerArgs = {
  customer: RetailCustomerCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateRetailDiscountCustomItemArgs = {
  discountItem: RetailDiscountItemCreateDtoInput;
};


/** Mutation root */
export type MutationCreateRetailDiscountEcoBonusItemArgs = {
  discountItem: RetailDiscountItemCreateDtoInput;
};


/** Mutation root */
export type MutationCreateRetailDiscountEmployeeItemArgs = {
  discountItem: RetailDiscountItemCreateDtoInput;
};


/** Mutation root */
export type MutationCreateRetailDiscountFinancingItemArgs = {
  discountItem: RetailDiscountItemCreateDtoInput;
};


/** Mutation root */
export type MutationCreateRetailFeatureArgs = {
  feature: CreateRetailFeatureProjectionInput;
};


/** Mutation root */
export type MutationCreateRetailLeadArgs = {
  retailLead: RetailLeadCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRetailOrderAccountDetailsArgs = {
  accountDetails: RetailOrderAccountDetailsCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateRetailOrderSubClaimArgs = {
  claimId: Scalars['UUID'];
  retailClaim: RetailSubClaimCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRetailPaymentArgs = {
  payment: RetailPaymentCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRetailPaymentCommentArgs = {
  paymentComment: RetailPaymentCommentCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateRetailSubClaimCommentArgs = {
  retailSubClaimComment: RetailSubClaimCommentCreateProjectionInput;
  subClaimId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateRetailSubClaimDocumentArgs = {
  retailSubClaimDocument: RetailSubClaimCreateDocumentProjectionInput;
  subClaimId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateRoleArgs = {
  role: RoleCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateSepaMandateArgs = {
  accountHolder?: InputMaybe<Scalars['String']>;
  financingApplicationId: Scalars['UUID'];
  iban: Scalars['String'];
  ibanCreationReason?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCreateSellerAddressArgs = {
  sellerAddress: SellerAddressCreateProjectionInput;
  sellerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateStandardOrderArgs = {
  order: OrderCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateSubProductArgs = {
  productId: Scalars['UUID'];
  subProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateTestHmcsDataArgs = {
  qaData: HmcsUpdateTestDataProjectionInput;
};


/** Mutation root */
export type MutationCreateTradeInArgs = {
  create: RetailTradeInCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateTypedRetailOrderAccountDetailsArgs = {
  accountDetails: RetailOrderAccountDetailsCreateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCreateUnidentifiedPaymentCommentArgs = {
  paymentComment: RetailPaymentCommentCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateUserArgs = {
  user: UserCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateVehiclePreparationProductArgs = {
  retailVehiclePreparationCreateProduct: RetailVehiclePreparationCreateProjectionInput;
};


/** Mutation root */
export type MutationCreateVerificationCallSurveyArgs = {
  locale: Scalars['String'];
  orderId: Scalars['UUID'];
  templateType: Scalars['String'];
};


/** Mutation root */
export type MutationCreateWarrantyProductArgs = {
  retailWarrantyCreateProduct: RetailWarrantyCreateProjectionInput;
};


/** Mutation root */
export type MutationDeleteAbTestingExperimentArgs = {
  environment?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteAbTestingExperimentCountersArgs = {
  environment?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteAbTestingParticipantsArgs = {
  environment?: InputMaybe<Scalars['String']>;
  experimentName: Scalars['String'];
  orderId?: InputMaybe<Scalars['UUID']>;
  userId?: InputMaybe<Scalars['UUID']>;
  visitorId?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationDeleteAvailabilityRuleArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteChatbotQuestionArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteDocumentInventoryItemArgs = {
  input: DeleteDocumentInventoryItemInput;
};


/** Mutation root */
export type MutationDeleteExternalFinancingCustomerDetailsArgs = {
  customerId: Scalars['UUID'];
  orderFinancingId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteExternalRiskUserInfoArgs = {
  borrowerType: Scalars['String'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteGroupUsersArgs = {
  id: Scalars['UUID'];
  userIds: Array<InputMaybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationDeleteImageTagArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteLiveChatAgentArgs = {
  agentId: Scalars['UUID'];
  locale?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationDeleteLiveChatAgentAnswerTemplateArgs = {
  templateId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderAddressArgs = {
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderBranchCarCheckupTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderBranchCarCheckupTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCancellationRequestedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCancellationRequestedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCarReadyForHomeDeliveryTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCarReadyForHomeDeliveryTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCarReadyForPickupCallTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCarReadyForPickupCallTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCarRegistrationDocumentsReturnedToAhTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCarRegistrationDocumentsReturnedToAhTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCarReturnedToAhTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCarReturnedToAhTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCareCallTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCareCallTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderClaimClosedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderClaimClosedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderClaimReceivedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderClaimReceivedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCustomerAvailabilityForHomeDeliveryTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCustomerAvailabilityForHomeDeliveryTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCustomerSignedBankReleaseTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderCustomerSignedBankReleaseTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderDocumentsRegistrationReadyTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderDocumentsRegistrationReadyTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderDocumentsRequestedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderDocumentsRequestedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderFinalDocumentsReceivedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderFinalDocumentsReceivedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderHandoverAppointmentTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderHandoverAppointmentTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderHappinessCallTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderHappinessCallTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderItemArgs = {
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderRegistrationInitiatedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderRegistrationInitiatedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderUploadedHandoverDocumentTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderUploadedHandoverDocumentTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderWarrantyContractSentTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderWarrantyContractSentTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderWarrantyContractSignedTaskArgs = {
  comment: OrderCommentCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteOrderWarrantyContractSignedTaskV2Args = {
  comment: OrderHistoryEventCreateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeletePnlItemArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeletePnlItemTypeArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeletePnlPartnerArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteProductArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRefurbishmentCommentArgs = {
  commentId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRefurbishmentServicesArgs = {
  serviceIds: Array<InputMaybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationDeleteRetailAdSecondaryWheelsImagesArgs = {
  id: Scalars['UUID'];
  imageIds: RetailBatchDto_UuidInput;
};


/** Mutation root */
export type MutationDeleteRetailAddressArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRetailClaimCostArgs = {
  claimCostId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRetailCustomerArgs = {
  customerId: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRetailDiscountCustomItemArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRetailDiscountEcoBonusItemArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRetailDiscountEmployeeItemArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRetailDiscountFinancingItemArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRetailDiscountVoucherItemArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRetailDiscountWarrantyItemArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRetailFeatureArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRetailSubClaimArgs = {
  subClaimId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteRetailSubClaimDocumentArgs = {
  documentId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteSubProductArgs = {
  productId: Scalars['UUID'];
  subProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteUserGroupsArgs = {
  groupIds: Array<InputMaybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDeleteUserPropertiesArgs = {
  id: Scalars['UUID'];
  propertyNames: Array<InputMaybe<Scalars['String']>>;
};


/** Mutation root */
export type MutationDisableDocumentInventoryTypeArgs = {
  input: DisableDocumentInventoryTypeInput;
};


/** Mutation root */
export type MutationDisableOrderAutoCancellationArgs = {
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDistributeRetailClaimCostsArgs = {
  claimCostDistributions: BatchProjection_ClaimCostDistributionProjectionInput;
  claimId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDistributeUnidentifiedPaymentArgs = {
  distribution: RetailUnidentifiedPaymentDistributionProjectionInput;
};


/** Mutation root */
export type MutationEnableDocumentInventoryTypeArgs = {
  input: EnableDocumentInventoryTypeInput;
};


/** Mutation root */
export type MutationEndLiveChatArgs = {
  request?: InputMaybe<LiveChatEndRequestProjectionInput>;
};


/** Mutation root */
export type MutationExternalFinancingEvaluationV2Args = {
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationExternalFinancingPreEvaluationV2Args = {
  externalFinancingPreEvaluationV2Request: ExternalFinancingPreEvaluationV2ProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationForcePersistExternalRiskUserInfoArgs = {
  borrowerType: Scalars['String'];
  orderId: Scalars['UUID'];
  persistExternalRiskUserInfo: PersistExternalRiskUserInfoProjectionInput;
};


/** Mutation root */
export type MutationGenerateAiSuggestionArgs = {
  request?: InputMaybe<LiveChatAiSuggestionRequestProjectionInput>;
};


/** Mutation root */
export type MutationGeneratePdfDocumentArgs = {
  documentTypeKey: Scalars['String'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationImportCarRegistrationActionItemsArgs = {
  request: BatchProjection_CarRegistrationActionImportCreateProjectionInput;
};


/** Mutation root */
export type MutationImportCarleadArgs = {
  carlead: ImportCarleadProjectionInput;
};


/** Mutation root */
export type MutationImportChatbotQuestionsArgs = {
  request: BatchProjection_ChatbotQuestionImportCreateProjectionInput;
};


/** Mutation root */
export type MutationImportInventorySourcingItemsArgs = {
  request: BatchProjection_InventorySourcingImportCreateProjectionInput;
};


/** Mutation root */
export type MutationImportPriceUpdateItemsArgs = {
  request: BatchProjection_PriceUpdateItemImportCreateProjectionInput;
};


/** Mutation root */
export type MutationImportProfitAndLossItemsArgs = {
  request: BatchProjection_ProfitAndLossImportCreateProjectionInput;
};


/** Mutation root */
export type MutationImportRetailDocumentInventoryItemsArgs = {
  request: BatchProjection_RetailDocumentInventoryImportCreateProjectionInput;
};


/** Mutation root */
export type MutationImportRetailPaymentItemsArgs = {
  request: BatchProjection_RetailPaymentImportCreateProjectionInput;
};


/** Mutation root */
export type MutationLinkPreCheckoutFinancingToOrderArgs = {
  adId: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationMarkAllUserNotificationsReadOrUnreadArgs = {
  isRead: Scalars['Boolean'];
};


/** Mutation root */
export type MutationMarkLiveChatAsReadArgs = {
  request?: InputMaybe<LiveChatAgentMarkReadRequestProjectionInput>;
};


/** Mutation root */
export type MutationMarkNotificationReadOrUnreadArgs = {
  isRead: Scalars['Boolean'];
  notificationId: Scalars['UUID'];
};


/** Mutation root */
export type MutationMarkRetailReadyArgs = {
  refurbishmentIds: Array<InputMaybe<Scalars['UUID']>>;
  retailReady: Scalars['Boolean'];
};


/** Mutation root */
export type MutationMarkUnidentifiedPaymentAsInternalTransferArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationMoveOrderToCanceledArgs = {
  adId?: InputMaybe<Scalars['UUID']>;
  canceledDescription?: InputMaybe<Scalars['String']>;
  canceledPeriod?: InputMaybe<Scalars['String']>;
  canceledReason: Scalars['String'];
  canceledType?: InputMaybe<Scalars['String']>;
  financingRejectionReason?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  subCancelReason?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationMoveOrderToCompletedArgs = {
  adId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationMoveOrderToContractSentArgs = {
  adId?: InputMaybe<Scalars['UUID']>;
  contractSentOn: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationMoveOrderToContractSignedArgs = {
  adId?: InputMaybe<Scalars['UUID']>;
  contractSignedOn: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationMoveOrderToDeliveredArgs = {
  adId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  vehicleDeliveredOn: Scalars['String'];
};


/** Mutation root */
export type MutationMoveOrderToVerifiedArgs = {
  adId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationMoveRetailAdToImportedToRetailArgs = {
  adId: Scalars['UUID'];
  retailAdId: Scalars['UUID'];
};


/** Mutation root */
export type MutationMoveRetailAdToReturnToAuto1Args = {
  adId: Scalars['UUID'];
  reason: Scalars['String'];
  retailAdId: Scalars['UUID'];
  returnedOn: Scalars['String'];
};


/** Mutation root */
export type MutationNotificationVisualizedArgs = {
  notificationId: Scalars['UUID'];
};


/** Mutation root */
export type MutationOfflineLeadsNewsletterSubscriptionsImportArgs = {
  request: BatchProjection_OfflineLeadsNewsletterSubscriptionImportProjectionInput;
};


/** Mutation root */
export type MutationOrderDebtsEditArgs = {
  debt: RetailOrderDebtUpdateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPatchAbTestingExperimentArgs = {
  environment?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  updateInput: AbExperimentUpdateProjectionInput;
};


/** Mutation root */
export type MutationPatchDocumentArgs = {
  documentUpdate: RetailDocumentUpdateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPersistExternalRiskUserInfoArgs = {
  borrowerType: Scalars['String'];
  orderId: Scalars['UUID'];
  persistExternalRiskUserInfo: PersistExternalRiskUserInfoProjectionInput;
};


/** Mutation root */
export type MutationPersistOrderFinancingFlowTypeArgs = {
  flowTypeProjection: OrderFinancingSaveFlowTypeProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPinOrderCommentArgs = {
  id: Scalars['UUID'];
  pinInteraction: OrderCommentPinInteractionProjectionInput;
};


/** Mutation root */
export type MutationPinOrderHistoryEventArgs = {
  id: Scalars['UUID'];
  pinEvent: OrderHistoryEventPinProjectionInput;
};


/** Mutation root */
export type MutationPreApproveOrderFinancingArgs = {
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPushAxaPolicyNotificationArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationQaChangeRegistrationStatusArgs = {
  registrationId: Scalars['UUID'];
  registrationStatus: Scalars['String'];
};


/** Mutation root */
export type MutationQaChangeYouSignRequestStatusArgs = {
  registrationId: Scalars['UUID'];
  youSignRequestStatus: Scalars['String'];
};


/** Mutation root */
export type MutationQaKbaSabrinaApprovedArgs = {
  registrationId: Scalars['UUID'];
};


/** Mutation root */
export type MutationQaKbaSabrinaRejectedArgs = {
  registrationId: Scalars['UUID'];
};


/** Mutation root */
export type MutationQaMarkKbaRequestFailedArgs = {
  registrationId: Scalars['UUID'];
};


/** Mutation root */
export type MutationQaMarkKbaRequestSuccessfulArgs = {
  registrationId: Scalars['UUID'];
};


/** Mutation root */
export type MutationRejectOrderFinancingArgs = {
  orderId: Scalars['UUID'];
  reason: RetailOrderFinanceRejectReason;
};


/** Mutation root */
export type MutationRemoveAdPublishingBlockersArgs = {
  publishingBlockers: Array<InputMaybe<PublishingBlockerRemoveProjectionInput>>;
  retailAdId: Scalars['UUID'];
};


/** Mutation root */
export type MutationRemoveLiveChatTopicArgs = {
  request?: InputMaybe<LiveChatRemoveTopicRequestProjectionInput>;
};


/** Mutation root */
export type MutationReprocessRetailPaymentDataImportsArgs = {
  request: BatchProjection_UuidInput;
};


/** Mutation root */
export type MutationRequestBankDetailsArgs = {
  request: RequestBankDetailsProjectionInput;
};


/** Mutation root */
export type MutationRequestBankDetailsForOutboundPaymentArgs = {
  request: RequestBankDetailsForOutboundPaymentProjectionInput;
};


/** Mutation root */
export type MutationRequestExtFinBankDetailsArgs = {
  request: RequestExtFinBankDetailsProjectionInput;
};


/** Mutation root */
export type MutationResetOrderHandoverDateArgs = {
  adId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationResetUserPasswordArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationRetailDocumentPackageUpdateImportArgs = {
  request: BatchProjection_RetailDocumentPackageUpdateImportProjectionInput;
};


/** Mutation root */
export type MutationRetryRefundArgs = {
  id: Scalars['UUID'];
  refund: RetailRefundRetryProjectionInput;
};


/** Mutation root */
export type MutationRevertLastOrderTransitionArgs = {
  adId?: InputMaybe<Scalars['UUID']>;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationRevokeAllDocumentRequestsArgs = {
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationRevokeDocumentRequestArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSaveFinanceRiskEvaluationArgs = {
  financeRiskEvaluation: SaveFinanceRiskEvaluationProjectionInput;
};


/** Mutation root */
export type MutationSaveVerificationDocumentFieldsArgs = {
  input: VerificationDocumentFieldsCreateProjectionInput;
};


/** Mutation root */
export type MutationSaveVisitingLogArgs = {
  model: LogProjectionInput;
};


/** Mutation root */
export type MutationSelectFinancingPlanArgs = {
  orderId: Scalars['UUID'];
  planCalculationId: Scalars['UUID'];
  tierId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSelfAssignTaskManagerTaskArgs = {
  taskId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSendAgentAnswerArgs = {
  request?: InputMaybe<LiveChatAgentAnswerRequestProjectionInput>;
};


/** Mutation root */
export type MutationSendLiveChatTemplateTrackingArgs = {
  model: RetailAutoheroLiveChatTemplateTrackingProjectionInput;
};


/** Mutation root */
export type MutationSendNewFinanceApplicationToCustomerArgs = {
  orderFinancingId: Scalars['UUID'];
  request?: InputMaybe<SendNewFinanceApplicationToCustomerProjectionInput>;
};


/** Mutation root */
export type MutationSetBankContractSignedOnArgs = {
  dateTime: Scalars['String'];
  financingApplicationId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSetHighlightsArgs = {
  adId: Scalars['UUID'];
  highlights: BatchProjection_HighlightProjectionInput;
  locale?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSetRetailClaimCostApprove1Args = {
  claimCostApprove: Scalars['String'];
  costId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSetRetailClaimCostApprove2Args = {
  claimCostApprove: Scalars['String'];
  costId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSetRetailClaimCostApprove3Args = {
  claimCostApprove: Scalars['String'];
  costId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSetRetailClaimCostCustomerDecisionArgs = {
  costId: Scalars['UUID'];
  customerDecision: Scalars['String'];
};


/** Mutation root */
export type MutationSetSepaMandateNotDuplicateReasonArgs = {
  notDuplicateReason: Scalars['String'];
  sepaMandateId: Scalars['UUID'];
};


/** Mutation root */
export type MutationStartFullManualEvaluationProtectedV2Args = {
  orderId: Scalars['UUID'];
  retailManualFullEvaluation: RetailManualFullEvaluationProjectionV2Input;
};


/** Mutation root */
export type MutationStartFullManualEvaluationV2Args = {
  orderId: Scalars['UUID'];
  retailManualFullEvaluation: RetailManualFullEvaluationProjectionV2Input;
};


/** Mutation root */
export type MutationTrackAiSuggestionArgs = {
  request?: InputMaybe<LiveChatTrackAiSuggestionRequestProjectionInput>;
};


/** Mutation root */
export type MutationTransitionPaymentInternalTransferToUnidentifiedArgs = {
  ids?: InputMaybe<BatchProjection_UuidInput>;
};


/** Mutation root */
export type MutationTransitionPaymentUnidentifiedToInternalTransferArgs = {
  category: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationTransitionPaymentUnidentifiedToInternalTransferBatchArgs = {
  request: BatchProjection_RetailTransitionInternalTransferDtoInput;
};


/** Mutation root */
export type MutationUnAssignTaskManagerTaskArgs = {
  taskId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateAbTestingExperimentArgs = {
  id: Scalars['UUID'];
  input: AbTestingExperimentInputInput;
};


/** Mutation root */
export type MutationUpdateAdArgs = {
  ad: AdUpdateProjectionInput;
  adId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateAdImagesArgs = {
  adId: Scalars['UUID'];
  adImages: AdImageProjectionInput;
};


/** Mutation root */
export type MutationUpdateAdVehicleArgs = {
  adId: Scalars['UUID'];
  vehicle: AdVehicleProjectionInput;
};


/** Mutation root */
export type MutationUpdateAvailabilityRuleArgs = {
  availabilityRule: AvailabilityRuleUpdateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateBranchEntryCheckStatusArgs = {
  id: Scalars['UUID'];
  status: BranchEntryCheckStatus;
};


/** Mutation root */
export type MutationUpdateCarRegistrationProductArgs = {
  carRegistrationProduct: RetailCarRegistrationUpdateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateCarTypeBasedPriceModifierArgs = {
  carTypeBasedUpdateProjection: CarTypeBasedPriceModifierUpdateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateChatbotQuestionArgs = {
  id: Scalars['UUID'];
  question: ChatbotQuestionUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateCheckoutPaymentDetailsArgs = {
  checkoutPaymentDetails: CheckoutPaymentDetailsUpdateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateClaimCostCompensationArgs = {
  claimCostCompensation: ClaimCostCompensationUpdateProjectionInput;
  compensationId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateClaimStateCommentArgs = {
  claimId: Scalars['UUID'];
  comment?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationUpdateClaimStateDueDateArgs = {
  claimId: Scalars['UUID'];
  dueDate: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateClaimStateDueDaysConfigArgs = {
  updates: RetailClaimStateDueDaysConfigBatchUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateCreditNoteArgs = {
  creditNote?: InputMaybe<CreditNoteUpdateProjectionInput>;
  id?: InputMaybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationUpdateCustomerAddressArgs = {
  address: CustomerAddressUpdateProjectionInput;
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateCustomerDisplayOnDamageArgs = {
  customerDisplay: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateCustomerUserArgs = {
  id: Scalars['UUID'];
  user: CustomerUserUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateDigitalCarRegistrationArgs = {
  id: Scalars['UUID'];
  registration: DigitalCarRegistrationUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateDigitalCarRegistrationBankAccountArgs = {
  bankAccount: RetailAccountDataCreateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateDocumentClassificationArgs = {
  documentSubTypeKey?: InputMaybe<Scalars['String']>;
  documentTypeKey: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateDocumentPackageArgs = {
  input: UpdateDocumentPackageInput;
};


/** Mutation root */
export type MutationUpdateDocumentPackagePartialArgs = {
  input: UpdateDocumentPackagePartialInput;
};


/** Mutation root */
export type MutationUpdateDownPaymentProductArgs = {
  downPaymentUpdateProduct: DownPaymentUpdateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateExternalFinancingEvaluationArgs = {
  financeRiskEvaluation: UpdateExternalFinanceRiskEvaluationProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateExternalFinancingLeasingDetailsArgs = {
  orderFinancingId: Scalars['UUID'];
  update?: InputMaybe<ExternalFinancingLeasingDetailsUpdateProjectionInput>;
};


/** Mutation root */
export type MutationUpdateFeeProductArgs = {
  id: Scalars['UUID'];
  retailFeeUpdateProduct: RetailFeeUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateFinancingApplicationTypeArgs = {
  applicationType: Scalars['String'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateFinancingPaymentDetailsArgs = {
  orderId: Scalars['UUID'];
  update: FinancingPaymentDetailsUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateFrRegionalTaxBasedPriceModifierArgs = {
  frRegionalTaxBasedUpdateProjection: FrRegionalTaxBasedPriceModifierUpdateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateGroupArgs = {
  group: GroupUpdateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateItPowerAndZoneBasedPriceModifierArgs = {
  id: Scalars['UUID'];
  itPowerAndZoneBasedUpdateProjection: ItPowerAndZoneBasedPriceModifierUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateImageTagArgs = {
  id: Scalars['UUID'];
  imageTag: RetailImageTagUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateInsuranceProductArgs = {
  id: Scalars['UUID'];
  retailInsuranceUpdateProduct: RetailInsuranceUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateInvoiceArgs = {
  id: Scalars['UUID'];
  invoice: InvoiceUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateLiveChatAgentAnswerTemplateArgs = {
  templateId: Scalars['UUID'];
  updateProjection: UpdateAgentAnswerTemplateProjectionInput;
};


/** Mutation root */
export type MutationUpdateLiveChatAgentTeamAgentsArgs = {
  request: LiveChatUpdateTeamAgentsRequestProjectionInput;
};


/** Mutation root */
export type MutationUpdateLiveChatAgentTeamCategoriesArgs = {
  request: LiveChatUpdateTeamCategoriesRequestProjectionInput;
};


/** Mutation root */
export type MutationUpdateLiveChatDetailsArgs = {
  id: Scalars['UUID'];
  request?: InputMaybe<LiveChatUpdateDetailsRequestInput>;
};


/** Mutation root */
export type MutationUpdateMonthlyPaymentDayArgs = {
  orderId: Scalars['UUID'];
  paymentDay: Scalars['Int'];
};


/** Mutation root */
export type MutationUpdateOrCreateQaDataDigitalCarRegistrationArgs = {
  qaData: DigitalCarRegistrationQaDataUpdateProjectionInput;
  registrationId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderArgs = {
  id: Scalars['UUID'];
  order: OrderUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateOrderAddressArgs = {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderBillingAddressArgs = {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderBranchArgs = {
  branchId?: InputMaybe<Scalars['Int']>;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderCarRegistrationArgs = {
  carRegistration: OrderCarRegistrationUpdateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderCarRegistrationAddressArgs = {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderConversionTypeArgs = {
  conversionType?: InputMaybe<UpdateConversionTypeProjectionInput>;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderDeliveryOptionArgs = {
  deliveryOption: Scalars['String'];
  estimationId?: InputMaybe<Scalars['UUID']>;
  hubId?: InputMaybe<Scalars['Int']>;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderDeliveryPlannedCarHandoverDateArgs = {
  branchPlannedCarHandoverOn?: InputMaybe<Scalars['String']>;
  comment: ReschedulingCommentProjectionInput;
  homePlannedCarHandoverFrom?: InputMaybe<Scalars['String']>;
  homePlannedCarHandoverTo?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderDocumentHandoverOnArgs = {
  id: Scalars['UUID'];
  value: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateOrderDocumentShippingAddressArgs = {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderFinancingArgs = {
  orderFinancing: OrderFinancingUpdateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderIsBranchCheckupSuccessfulArgs = {
  id: Scalars['UUID'];
  value: Scalars['Boolean'];
};


/** Mutation root */
export type MutationUpdateOrderIsTestArgs = {
  id: Scalars['UUID'];
  value: Scalars['Boolean'];
};


/** Mutation root */
export type MutationUpdateOrderItemDiscountGrossArgs = {
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
  value: Scalars['Long'];
};


/** Mutation root */
export type MutationUpdateOrderItemInvoiceNameArgs = {
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
  value: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateOrderItemPriceGrossArgs = {
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
  value: Scalars['Long'];
};


/** Mutation root */
export type MutationUpdateOrderPaymentDetailsArgs = {
  orderId: Scalars['UUID'];
  paymentDetails: OrderPaymentDetailsUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateOrderShippingAddressArgs = {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderShippingAddressForceArgs = {
  address: OrderAddressUpdateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateOrderVehicleDeliveredOnArgs = {
  id: Scalars['UUID'];
  value: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateOutboundPaymentArgs = {
  id: Scalars['UUID'];
  outboundPaymentUpdate: RetailOutboundPaymentUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdatePaymentDetailsArgs = {
  orderId: Scalars['UUID'];
  paymentDetails: PaymentDetailsUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdatePnlItemArgs = {
  id: Scalars['UUID'];
  item: PnlItemUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateProductArgs = {
  id: Scalars['UUID'];
  product: ProductUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRefundArgs = {
  id: Scalars['UUID'];
  refund: RetailRefundUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRefundAmountArgs = {
  id: Scalars['UUID'];
  value: Scalars['Long'];
};


/** Mutation root */
export type MutationUpdateRefurbishmentArgs = {
  id: Scalars['UUID'];
  refurbishment: RefurbishmentUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRefurbishmentCommentArgs = {
  comment: Scalars['String'];
  commentId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRefurbishmentServicesArgs = {
  services: Array<InputMaybe<RefurbishmentUpdateServicesProjectionInput>>;
};


/** Mutation root */
export type MutationUpdateRefurbishmentStateArgs = {
  adId: Scalars['UUID'];
  refurbishmentId: Scalars['UUID'];
  stateChange: RetailRefurbishmentStateChangeDtoInput;
};


/** Mutation root */
export type MutationUpdateRetailAdArgs = {
  id: Scalars['UUID'];
  retailAd: RetailAdUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailAdAuto1BuyPriceArgs = {
  id: Scalars['UUID'];
  value: Scalars['Int'];
};


/** Mutation root */
export type MutationUpdateRetailAdAuto1ReturnOnArgs = {
  id: Scalars['UUID'];
  value?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationUpdateRetailAdAuto1ReturnReasonArgs = {
  id: Scalars['UUID'];
  value?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationUpdateRetailAdAutoheroPurchaseOnArgs = {
  id: Scalars['UUID'];
  value: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateRetailAdDamageDisplayToCustomerFrontendArgs = {
  changes: Array<InputMaybe<ChangeInput>>;
  retailAdId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailAdFirstImportOnArgs = {
  id: Scalars['UUID'];
  value: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateRetailAdHidingReasonArgs = {
  id: Scalars['UUID'];
  value: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateRetailAdIsTestArgs = {
  id: Scalars['UUID'];
  value: Scalars['Boolean'];
};


/** Mutation root */
export type MutationUpdateRetailAdRetailReadyDateArgs = {
  id: Scalars['UUID'];
  value: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateRetailAdSecondaryWheelsDetailsArgs = {
  details: RetailAdSecondaryWheelsDetailsUpdateProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailAdSecondaryWheelsImagesArgs = {
  id: Scalars['UUID'];
  imageIds: RetailBatchDto_UuidInput;
};


/** Mutation root */
export type MutationUpdateRetailAdSecondaryWheelsRimsDetailsArgs = {
  id: Scalars['UUID'];
  rimsDetails: NotEmptyMapUpdateProjection_WheelPlacementType_RimDetailsInput;
};


/** Mutation root */
export type MutationUpdateRetailAdSecondaryWheelsTiresDetailsArgs = {
  id: Scalars['UUID'];
  tiresDetails: NotEmptyMapUpdateProjection_WheelPlacementType_TireDetailsInput;
};


/** Mutation root */
export type MutationUpdateRetailAddressArgs = {
  address: RetailAddressUpdateProjectionInput;
  id: Scalars['UUID'];
  validate?: InputMaybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationUpdateRetailCheckoutArgs = {
  checkout: RetailCheckoutUpdateProjectionInput;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailCheckoutByOrderIdArgs = {
  checkout: RetailCheckoutUpdateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailClaimCompensationTypeArgs = {
  claimId: Scalars['UUID'];
  compensation: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateRetailClaimCostArgs = {
  claimCostUpdate: RetailClaimCostUpdateProjectionInput;
  costId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailClaimWorkshopDetailsArgs = {
  claimId: Scalars['UUID'];
  retailClaimWorkshopDetails: RetailClaimWorkshopDetailsUpdateProjectionInput;
  workshopId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailCustomerArgs = {
  customer: RetailCustomerUpdateProjectionInput;
  customerId: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailDiscountEcoBonusItemArgs = {
  id: Scalars['UUID'];
  retailDiscountItemUpdateProjection: RetailDiscountItemUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailDiscountEmployeeItemArgs = {
  id: Scalars['UUID'];
  retailDiscountItemUpdateProjection: RetailDiscountItemUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailDiscountFinancingItemArgs = {
  id: Scalars['UUID'];
  retailDiscountItemUpdate: RetailDiscountItemUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailDiscountWarrantyItemArgs = {
  id: Scalars['UUID'];
  retailDiscountItemUpdate: RetailDiscountItemUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailFeatureArgs = {
  feature: UpdateRetailFeatureProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailLeadArgs = {
  id: Scalars['UUID'];
  retailLead: RetailLeadProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailOrderClaimAssigneeUserArgs = {
  claimId: Scalars['UUID'];
  updateUserAssign: RetailClaimUpdateUserAssignProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailOrderClaimGroupArgs = {
  claimId: Scalars['UUID'];
  updateGroup: RetailClaimUpdateGroupProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailOrderClaimStateArgs = {
  claimId: Scalars['UUID'];
  updateState: RetailClaimUpdateStateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailOrderSubClaimArgs = {
  retailClaim: RetailSubClaimUpdateProjectionInput;
  subClaimId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailPaymentArgs = {
  id: Scalars['UUID'];
  payment: RetailPaymentUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailPaymentImportConfigArgs = {
  config: RetailDataImportConfigUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailShippingAddressForceArgs = {
  address: RetailAddressUpdateProjectionInput;
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateRetailTaxNoVaArgs = {
  id: Scalars['UUID'];
  updateProjection: RetailTaxNoVaUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRetailUnidentifiedPaymentArgs = {
  id: Scalars['UUID'];
  unidentifiedPayment: RetailUnidentifiedPaymentUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateRoleArgs = {
  id: Scalars['UUID'];
  role: RoleUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateSellerAddressArgs = {
  addressId: Scalars['UUID'];
  sellerAddress: SellerAddressProjectionInput;
  sellerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateSurveyActivePageArgs = {
  activePage: Scalars['Int'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateSurveyStatusArgs = {
  id: Scalars['UUID'];
  status: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateTaskManagerTaskActionArgs = {
  description?: InputMaybe<Scalars['String']>;
  taskId: Scalars['UUID'];
  variables?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
};


/** Mutation root */
export type MutationUpdateTaxAdvantageArgs = {
  id: Scalars['UUID'];
  taxAdvantage: RetailAdTaxAdvantageUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateTaxBpmArgs = {
  id: Scalars['UUID'];
  updateProjection: RetailTaxBpmUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateTestHmcsDataArgs = {
  qaData: HmcsUpdateTestDataProjectionInput;
};


/** Mutation root */
export type MutationUpdateTestOrderDownPaymentArgs = {
  downPayment: UpdateTestOrderDownPaymentProjectionInput;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpdateUserArgs = {
  id: Scalars['UUID'];
  user: UserUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateVehiclePreparationProductArgs = {
  id: Scalars['UUID'];
  retailVehiclePreparationUpdateProduct: RetailVehiclePreparationUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateWarrantyCarPricePriceModifierArgs = {
  id: Scalars['UUID'];
  warrantyCarPriceUpdateProjection: WarrantyCarPricePriceModifierUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateWarrantyCarYearsPriceModifierArgs = {
  id: Scalars['UUID'];
  warrantyCarYearsUpdateProjection: WarrantyCarYearsPriceModifierUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpdateWarrantyProductArgs = {
  id: Scalars['UUID'];
  retailWarrantyUpdateProduct: RetailWarrantyUpdateProjectionInput;
};


/** Mutation root */
export type MutationUpsellSecondaryWheelsArgs = {
  orderId: Scalars['UUID'];
  secondaryWheelsId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpsellTradeInArgs = {
  orderId: Scalars['UUID'];
  tradeInId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUpsellWarrantyArgs = {
  orderId: Scalars['UUID'];
  warrantyId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserObfuscationArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationVerificationCallSurveySubmitAnswersArgs = {
  surveyId: Scalars['UUID'];
  updateSurveyDTOInput: UpdateSurveyDtoInputInput;
};

export type NewFinancingApplicationDto = {
  __typename?: 'NewFinancingApplicationDTO';
  expiresOn?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  sendToCustomerOn?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type NewFinancingApplicationProjection = {
  __typename?: 'NewFinancingApplicationProjection';
  expiresOn?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  sendToCustomerOn?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type NotEmptyMapUpdateProjection_WheelPlacementType_RimDetailsInput = {
  map?: InputMaybe<Scalars['Map_WheelPlacementType_RimDetailsScalar']>;
};

export type NotEmptyMapUpdateProjection_WheelPlacementType_TireDetailsInput = {
  map?: InputMaybe<Scalars['Map_WheelPlacementType_TireDetailsScalar']>;
};

export type OfflineLeadsNewsletterSubscriptionImportProjectionInput = {
  fileName: Scalars['String'];
};

export type OffsetBasedResponseProjection = {
  __typename?: 'OffsetBasedResponseProjection';
  entities?: Maybe<Array<Maybe<BranchDto>>>;
  first: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type OpenAiModelProjection = {
  __typename?: 'OpenAiModelProjection';
  id?: Maybe<Scalars['String']>;
};

export type OpportunitySearchProjection = {
  __typename?: 'OpportunitySearchProjection';
  customerLeadId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
};

export enum OrderAccountDetailsRequestSource {
  CancellationRefund = 'CANCELLATION_REFUND',
  DamageCompensation = 'DAMAGE_COMPENSATION',
  Overpayment = 'OVERPAYMENT',
  PartialRefund = 'PARTIAL_REFUND'
}

export type OrderAddressCreateProjectionInput = {
  addressId?: InputMaybe<Scalars['UUID']>;
  addressType?: InputMaybe<Scalars['String']>;
  branchId?: InputMaybe<Scalars['Long']>;
  customerId?: InputMaybe<Scalars['UUID']>;
  customerType?: InputMaybe<Scalars['String']>;
};

export type OrderAddressProjection = {
  __typename?: 'OrderAddressProjection';
  address?: Maybe<RetailAddressProjection>;
  addressId?: Maybe<Scalars['UUID']>;
  addressType?: Maybe<Scalars['String']>;
  branch?: Maybe<BranchProjection>;
  branchId?: Maybe<Scalars['Long']>;
  created?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  updated?: Maybe<Scalars['String']>;
};

export type OrderAddressUpdateProjectionInput = {
  addressId?: InputMaybe<Scalars['UUID']>;
  branchId?: InputMaybe<Scalars['Long']>;
};

export type OrderAndTaskCommentsProjection = {
  __typename?: 'OrderAndTaskCommentsProjection';
  comments?: Maybe<Array<Maybe<CommentProjection>>>;
  countOfComments: Scalars['Int'];
};

export type OrderAutoCancellationProjection = {
  __typename?: 'OrderAutoCancellationProjection';
  applicability?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  plannedOn?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type OrderAutomaticVerificationProjection = {
  __typename?: 'OrderAutomaticVerificationProjection';
  orderId?: Maybe<Scalars['UUID']>;
  reason?: Maybe<AutomaticVerificationReason>;
  source?: Maybe<Scalars['UUID']>;
  sourceOrderNumber?: Maybe<Scalars['String']>;
};

export type OrderCancelMainReasonProjection = {
  __typename?: 'OrderCancelMainReasonProjection';
  name?: Maybe<Scalars['String']>;
  subReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderCancelPeriodProjection = {
  __typename?: 'OrderCancelPeriodProjection';
  name?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Maybe<OrderCancelMainReasonProjection>>>;
};

export type OrderCancelTypeProjection = {
  __typename?: 'OrderCancelTypeProjection';
  name?: Maybe<Scalars['String']>;
  periods?: Maybe<Array<Maybe<OrderCancelPeriodProjection>>>;
};

export type OrderCancellationOptionsProjection = {
  __typename?: 'OrderCancellationOptionsProjection';
  options?: Maybe<Array<Maybe<OrderCancelTypeProjection>>>;
};

export type OrderCancellationRevertibleProjection = {
  __typename?: 'OrderCancellationRevertibleProjection';
  reasonForNotSupporting?: Maybe<Scalars['String']>;
  stateBeforeCancelling?: Maybe<Scalars['String']>;
  supported?: Maybe<Scalars['Boolean']>;
};

export type OrderCancellationTaskProjectionInput = {
  cancelRequestSubReason?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<Scalars['String']>;
};

export type OrderCarRegistrationUpdateProjectionInput = {
  status?: InputMaybe<Scalars['String']>;
};

export type OrderCommentCreateProjectionInput = {
  cancelRequestSubReason?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<Scalars['String']>;
};

export type OrderCommentPinInteractionProjectionInput = {
  interactionId?: InputMaybe<Scalars['UUID']>;
  pin?: InputMaybe<Scalars['String']>;
};

export type OrderCommentProjection = {
  __typename?: 'OrderCommentProjection';
  channel?: Maybe<Scalars['String']>;
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  notes?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  tags?: Maybe<Array<Maybe<CommentTagProjection>>>;
};

export type OrderComplianceCheckCaseProjection = {
  __typename?: 'OrderComplianceCheckCaseProjection';
  customerCheckResult?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  verificationDecision?: Maybe<Scalars['String']>;
};

export type OrderCreateProjectionInput = {
  auxUserId?: InputMaybe<Scalars['UUID']>;
  branchId?: InputMaybe<Scalars['Int']>;
  checkoutBranchId?: InputMaybe<Scalars['Int']>;
  conversionType?: InputMaybe<Scalars['String']>;
  createReason?: InputMaybe<Scalars['String']>;
  createSubreason?: InputMaybe<Scalars['String']>;
  delivery?: InputMaybe<RetailOrderDeliveryDtoInput>;
  flowVersion?: InputMaybe<Scalars['String']>;
  isAhCheckoutByAgent?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Array<InputMaybe<OrderItemCreateProjectionInput>>>;
  linkedOrder?: InputMaybe<LinkedOrdersDtoInput>;
  orderAddresses?: InputMaybe<Array<InputMaybe<OrderAddressCreateProjectionInput>>>;
  retailCountry?: InputMaybe<Scalars['String']>;
  retailCustomers?: InputMaybe<Array<InputMaybe<RetailCustomerCreateProjectionInput>>>;
  retailOrderLink?: InputMaybe<RetailOrderLinkCreateDtoInput>;
  source?: InputMaybe<Scalars['String']>;
};

export type OrderCustomerLeadProjection = {
  __typename?: 'OrderCustomerLeadProjection';
  lastUpdated?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type OrderDeliveryEtaEntryDto = {
  __typename?: 'OrderDeliveryEtaEntryDTO';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  daysForDelivery?: Maybe<Scalars['Int']>;
  deliveryEtaDisplayMode?: Maybe<Scalars['String']>;
  earliestEta?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  latestEta?: Maybe<Scalars['String']>;
  onEvent?: Maybe<Scalars['String']>;
  orderDeliveryId?: Maybe<Scalars['UUID']>;
  type?: Maybe<Scalars['String']>;
};

export type OrderDeliveryEtaEntryDtoInput = {
  createdBy?: InputMaybe<Scalars['UUID']>;
  createdOn?: InputMaybe<Scalars['String']>;
  daysForDelivery?: InputMaybe<Scalars['Int']>;
  deliveryEtaDisplayMode?: InputMaybe<Scalars['String']>;
  earliestEta?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  latestEta?: InputMaybe<Scalars['String']>;
  onEvent?: InputMaybe<Scalars['String']>;
  orderDeliveryId?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<Scalars['String']>;
};

export type OrderEmailEventAttachmentCreateProjectionInput = {
  fileName?: InputMaybe<Scalars['String']>;
  originalFileName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type OrderEmailEventAttachmentProjection = {
  __typename?: 'OrderEmailEventAttachmentProjection';
  id?: Maybe<Scalars['String']>;
  originalFileName?: Maybe<Scalars['String']>;
  stored?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type OrderFinanceCancelReasonProjection = {
  __typename?: 'OrderFinanceCancelReasonProjection';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  subReasons?: Maybe<Array<Maybe<OrderFinanceCancelSubReasonProjection>>>;
};

export type OrderFinanceCancelSubReasonProjection = {
  __typename?: 'OrderFinanceCancelSubReasonProjection';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export type OrderFinanceRejectReasonProjection = {
  __typename?: 'OrderFinanceRejectReasonProjection';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export type OrderFinancingDto = {
  __typename?: 'OrderFinancingDto';
  applicationNumber?: Maybe<Scalars['String']>;
  applicationType?: Maybe<Scalars['String']>;
  approvedOn?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelSubReason?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  financingFlowVersion?: Maybe<RetailOrderFinancingFlowVersion>;
  financingType?: Maybe<OrderFinancingType>;
  flowType?: Maybe<RetailOrderFinancingFlowType>;
  id?: Maybe<Scalars['UUID']>;
  monthlyPaymentDay?: Maybe<Scalars['Int']>;
  newFinancingApplication?: Maybe<NewFinancingApplicationDto>;
  orderId?: Maybe<Scalars['UUID']>;
  orderPaymentDetails?: Maybe<OrderFinancingPaymentDetailsDto>;
  preApprovedOn?: Maybe<Scalars['String']>;
  rejectReason?: Maybe<Scalars['String']>;
  retailCountry?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  submittedToBankOn?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
  userConsent: Scalars['Boolean'];
  userId?: Maybe<Scalars['UUID']>;
  validationOn?: Maybe<Scalars['String']>;
};

export type OrderFinancingHistoryProjection = {
  __typename?: 'OrderFinancingHistoryProjection';
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderFinancingId?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type OrderFinancingPaymentDetailsDto = {
  __typename?: 'OrderFinancingPaymentDetailsDTO';
  contractType?: Maybe<Scalars['String']>;
  creditAmountMinorUnits?: Maybe<Scalars['Long']>;
  creditPeriod?: Maybe<Scalars['Int']>;
  currencyCode?: Maybe<Scalars['String']>;
  interestRate?: Maybe<Scalars['Float']>;
  lastUpdateInitiator?: Maybe<Scalars['String']>;
  maxAffordableMonthlyPaymentMinorUnits?: Maybe<Scalars['Long']>;
  monthlyPaymentAmountMinorUnits?: Maybe<Scalars['Long']>;
  paymentType?: Maybe<Scalars['String']>;
  preCheckoutFinancingId?: Maybe<Scalars['UUID']>;
};

export type OrderFinancingSaveFlowTypeProjectionInput = {
  flowType?: InputMaybe<RetailOrderFinancingFlowType>;
};

export enum OrderFinancingType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL'
}

export type OrderFinancingUpdateProjectionInput = {
  cancelReason?: InputMaybe<RetailOrderFinanceCancelReason>;
  cancelSubReason?: InputMaybe<RetailOrderFinanceCancelSubReason>;
  rejectReason?: InputMaybe<RetailOrderFinanceRejectReason>;
  status?: InputMaybe<Scalars['String']>;
};

export type OrderHistoryEmailPlaceholderInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type OrderHistoryEventCreateProjectionInput = {
  cancelRequestSubReason?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type OrderHistoryEventPinProjectionInput = {
  eventId?: InputMaybe<Scalars['UUID']>;
  pin?: InputMaybe<Scalars['String']>;
};

export type OrderHistoryEventProjection = {
  __typename?: 'OrderHistoryEventProjection';
  attachments?: Maybe<Array<Maybe<OrderEmailEventAttachmentProjection>>>;
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  eventType?: Maybe<HistoryEventType>;
  id?: Maybe<Scalars['UUID']>;
  message?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  pinned?: Maybe<Scalars['Boolean']>;
  properties?: Maybe<Scalars['Map_String_StringScalar']>;
  recipient?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type OrderItemCreateProjectionInput = {
  customDiscountMinorUnits?: InputMaybe<Scalars['Long']>;
  customDiscountType?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<RetailDiscountItemProjectionInput>;
  discountGrossMinorUnits?: InputMaybe<Scalars['Long']>;
  discountMinorUnits?: InputMaybe<Scalars['Long']>;
  externalId?: InputMaybe<Scalars['UUID']>;
  externalType?: InputMaybe<Scalars['Int']>;
  priceGrossMinorUnits?: InputMaybe<Scalars['Long']>;
  type?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type OrderItemProjection = {
  __typename?: 'OrderItemProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customDiscountMinorUnits?: Maybe<Scalars['Long']>;
  customDiscountType?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  obsolete?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderItemRefund?: Maybe<Scalars['Long']>;
  parentId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type OrderItemsOptionsProjection = {
  __typename?: 'OrderItemsOptionsProjection';
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderItemsRefundsDto = {
  __typename?: 'OrderItemsRefundsDTO';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  externalOrderItemId?: Maybe<Scalars['UUID']>;
  orderItemId?: Maybe<Scalars['UUID']>;
};

export type OrderPaymentDetailsProjection = {
  __typename?: 'OrderPaymentDetailsProjection';
  amountInWords?: Maybe<Scalars['String']>;
  balanceAmount?: Maybe<PriceDto>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  creditAmount?: Maybe<PriceDto>;
  creditNoteAmount?: Maybe<PriceDto>;
  creditPeriod?: Maybe<Scalars['Int']>;
  depositAmount?: Maybe<PriceDto>;
  depositDueDate?: Maybe<Scalars['String']>;
  depositPaidOn?: Maybe<Scalars['String']>;
  financingApplicability?: Maybe<FinancingApplicabilityProjection>;
  fullPaymentDueDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  monthlyPaymentAmountMinorUnits?: Maybe<Scalars['Long']>;
  orderId?: Maybe<Scalars['UUID']>;
  paidAmount?: Maybe<PriceDto>;
  paidCashAmount?: Maybe<PriceDto>;
  paidFinancingAmount?: Maybe<PriceDto>;
  paymentState?: Maybe<OrderPaymentState>;
  paymentType?: Maybe<Scalars['String']>;
  refundAmount?: Maybe<PriceDto>;
  remainingAmount?: Maybe<PriceDto>;
  remainingCashAmount?: Maybe<PriceDto>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type OrderPaymentDetailsUpdateProjectionInput = {
  amountInWords?: InputMaybe<Scalars['String']>;
  creditAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  creditPeriod?: InputMaybe<Scalars['Int']>;
  depositAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  depositDueDate?: InputMaybe<Scalars['String']>;
  discountRequested?: InputMaybe<Scalars['Boolean']>;
  fullPaymentDueDate?: InputMaybe<Scalars['String']>;
  monthlyPaymentAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  paymentType?: InputMaybe<Scalars['String']>;
};

export enum OrderPaymentState {
  FullyPaid = 'FULLY_PAID',
  FullyRefunded = 'FULLY_REFUNDED',
  Open = 'OPEN',
  Overpaid = 'OVERPAID',
  PartlyPaid = 'PARTLY_PAID',
  Refunded = 'REFUNDED'
}

export type OrderProjection = {
  __typename?: 'OrderProjection';
  adItems?: Maybe<Array<Maybe<AdItemProjection>>>;
  adSecondaryWheelsItems?: Maybe<Array<Maybe<SecondaryWheelsItemProjection>>>;
  amountInWords?: Maybe<Scalars['String']>;
  branchCheckupOn?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['Int']>;
  cancelDescription?: Maybe<Scalars['String']>;
  cancelPeriod?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelType?: Maybe<Scalars['String']>;
  canceledOn?: Maybe<Scalars['String']>;
  carInBranch?: Maybe<Scalars['Boolean']>;
  carRegistration?: Maybe<RetailOrderCarRegistrationDto>;
  carRegistrationItems?: Maybe<Array<Maybe<CarRegistrationItemProjection>>>;
  carRegistrationStatus?: Maybe<CarRegistrationStatusDictionaryDto>;
  carReservedOn?: Maybe<Scalars['String']>;
  checkoutBranchId?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  contractSentOn?: Maybe<Scalars['String']>;
  contractSignedOn?: Maybe<Scalars['String']>;
  conversionType?: Maybe<Scalars['String']>;
  createReason?: Maybe<Scalars['String']>;
  createSubreason?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  creditAmount?: Maybe<PriceDto>;
  creditAmountMinorUnits?: Maybe<Scalars['Long']>;
  customer?: Maybe<RetailCustomerProjection>;
  customerCountry?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  deliveryDetails?: Maybe<RetailOrderDeliveryDetailsProjection>;
  deliveryETA?: Maybe<RangeDto_Integer>;
  deliveryItems?: Maybe<Array<Maybe<OrderItemProjection>>>;
  depositAmount?: Maybe<PriceDto>;
  depositAmountMinorUnits?: Maybe<Scalars['Long']>;
  depositDueDate?: Maybe<Scalars['String']>;
  documentHandoverOn?: Maybe<Scalars['String']>;
  downPaymentItems?: Maybe<Array<Maybe<DownPaymentItemProjection>>>;
  editable?: Maybe<Scalars['Boolean']>;
  feeItems?: Maybe<Array<Maybe<FeeItemProjection>>>;
  finalDocumentHandoverOn?: Maybe<Scalars['String']>;
  finalPaymentDueDate?: Maybe<Scalars['String']>;
  financeApplications?: Maybe<Array<Maybe<FinanceAppProjection>>>;
  flowVersion?: Maybe<Scalars['String']>;
  fullPaymentDueDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  insuranceItems?: Maybe<Array<Maybe<InsuranceItemProjection>>>;
  isAhCheckoutByAgent?: Maybe<Scalars['Boolean']>;
  isBranchCheckupSuccessful?: Maybe<Scalars['Boolean']>;
  isOracle?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isTradeIn?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<OrderItemProjection>>>;
  linkedOrders?: Maybe<Array<Maybe<LinkedOrdersDto>>>;
  locale?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  overallDelayCalendarDays?: Maybe<Scalars['Int']>;
  paymentDetails?: Maybe<OrderPaymentDetailsProjection>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  productItems?: Maybe<Array<Maybe<ProductItemProjection>>>;
  reservationEndOn?: Maybe<Scalars['String']>;
  retailCountry?: Maybe<Scalars['String']>;
  retailOrderLinks?: Maybe<Array<Maybe<RetailOrderLinkDto>>>;
  salesAgentAssignedTo?: Maybe<UserProjection>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  subCancelReason?: Maybe<Scalars['String']>;
  tasksCount?: Maybe<TaskCountProjection>;
  taxBPM?: Maybe<RetailTaxBpmProjection>;
  taxNoVA?: Maybe<RetailTaxNoVaProjection>;
  totalPriceGross?: Maybe<PriceDto>;
  totalPriceNet?: Maybe<PriceDto>;
  totalTaxAdvantage?: Maybe<PriceDto>;
  totalVat?: Maybe<PriceDto>;
  tradeInItems?: Maybe<Array<Maybe<RetailTradeInItemProjection>>>;
  transportOrdered?: Maybe<Scalars['String']>;
  trialExpiredOn?: Maybe<Scalars['String']>;
  trialReturnOn?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  user?: Maybe<CustomerProjection>;
  userId?: Maybe<Scalars['UUID']>;
  vehicleDeliveredOn?: Maybe<Scalars['String']>;
  vehiclePreparationItems?: Maybe<Array<Maybe<VehiclePreparationItemProjection>>>;
  verifiedOn?: Maybe<Scalars['String']>;
  warrantyDetails?: Maybe<Array<Maybe<WarrantyDetailsProjection>>>;
  warrantyItems?: Maybe<Array<Maybe<WarrantyItemProjection>>>;
};

export type OrderReadyForVerificationTaskProjection = {
  __typename?: 'OrderReadyForVerificationTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderContractSignedOn?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  pendingVerificationOn?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type OrderSearchProjection = {
  __typename?: 'OrderSearchProjection';
  auxUserId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['String']>;
  customerSignedBankRelease?: Maybe<Scalars['Boolean']>;
  financingFlowVersion?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  handoverDate?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  priorityRank?: Maybe<Scalars['Int']>;
  retailCountry?: Maybe<Scalars['String']>;
  salesAgentAssignedTo?: Maybe<UserProjection>;
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
};

export type OrderShippingDetailsUpdateTaskProjection = {
  __typename?: 'OrderShippingDetailsUpdateTaskProjection';
  action?: Maybe<Scalars['String']>;
  addressUpdateDetails?: Maybe<ShippingAddressDetails>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  branchId?: Maybe<Scalars['Int']>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type OrderTaskProjection = {
  __typename?: 'OrderTaskProjection';
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  type?: Maybe<Scalars['String']>;
};

export enum OrderType {
  Additional = 'ADDITIONAL',
  Other = 'OTHER',
  Standard = 'STANDARD'
}

export type OrderUpdateProjectionInput = {
  amountInWords?: InputMaybe<Scalars['String']>;
  branchId?: InputMaybe<Scalars['Int']>;
  cancelDescription?: InputMaybe<Scalars['String']>;
  cancelReason?: InputMaybe<Scalars['String']>;
  canceledOn?: InputMaybe<Scalars['String']>;
  checkoutBranchId?: InputMaybe<Scalars['Int']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  contractSentOn?: InputMaybe<Scalars['String']>;
  contractSigned?: InputMaybe<Scalars['Boolean']>;
  contractSignedOn?: InputMaybe<Scalars['String']>;
  conversionType?: InputMaybe<Scalars['String']>;
  depositAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  depositDueDate?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<Scalars['String']>;
  fullPaymentDueDate?: InputMaybe<Scalars['String']>;
  isBranchCheckupSuccessful?: InputMaybe<Scalars['Boolean']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  isTradeIn?: InputMaybe<Scalars['Boolean']>;
  payment?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<Scalars['String']>;
  reservationEndOn?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subCancelReason?: InputMaybe<Scalars['String']>;
  trialExpiredOn?: InputMaybe<Scalars['String']>;
  trialReturnOn?: InputMaybe<Scalars['String']>;
};

export type OutboundPaymentOracleDetailsProjection = {
  __typename?: 'OutboundPaymentOracleDetailsProjection';
  accountingDate?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  oracleFileName?: Maybe<Scalars['String']>;
  oracleFilePresignedUrl?: Maybe<Scalars['String']>;
  paymentNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type OverpaymentRefundApprovalTaskProjection = {
  __typename?: 'OverpaymentRefundApprovalTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  overpaymentAmount?: Maybe<Scalars['Long']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refundApprovedAmount?: Maybe<Scalars['String']>;
  refundTo?: Maybe<SelectProjection>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type OverpaymentRefundPendingTaskProjection = {
  __typename?: 'OverpaymentRefundPendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  overpaymentAmount?: Maybe<Scalars['Long']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refundApprovedAmount?: Maybe<Scalars['String']>;
  refundTo?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type PageDto_AgentAnswerTemplateProjection = {
  __typename?: 'PageDTO_AgentAnswerTemplateProjection';
  entities?: Maybe<Array<Maybe<AgentAnswerTemplateProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_AiSettingProjection = {
  __typename?: 'PageDTO_AiSettingProjection';
  entities?: Maybe<Array<Maybe<AiSettingProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_AutoheroChatBotLiveAgentProjection = {
  __typename?: 'PageDTO_AutoheroChatBotLiveAgentProjection';
  entities?: Maybe<Array<Maybe<AutoheroChatBotLiveAgentProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_AvailabilityRuleProjection = {
  __typename?: 'PageDTO_AvailabilityRuleProjection';
  entities?: Maybe<Array<Maybe<AvailabilityRuleProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_BranchEntryCheckSearchItemDto = {
  __typename?: 'PageDTO_BranchEntryCheckSearchItemDTO';
  entities?: Maybe<Array<Maybe<BranchEntryCheckSearchItemDto>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_BranchProjection = {
  __typename?: 'PageDTO_BranchProjection';
  entities?: Maybe<Array<Maybe<BranchProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_CarTypeBasedPriceModifierProjection = {
  __typename?: 'PageDTO_CarTypeBasedPriceModifierProjection';
  entities?: Maybe<Array<Maybe<CarTypeBasedPriceModifierProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_ChangelogProjection = {
  __typename?: 'PageDTO_ChangelogProjection';
  entities?: Maybe<Array<Maybe<ChangelogProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_ChatbotQuestionDto = {
  __typename?: 'PageDTO_ChatbotQuestionDTO';
  entities?: Maybe<Array<Maybe<ChatbotQuestionDto>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_ClaimChangelogResponseProjection = {
  __typename?: 'PageDTO_ClaimChangelogResponseProjection';
  entities?: Maybe<Array<Maybe<ClaimChangelogResponseProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_CreditNoteProjection = {
  __typename?: 'PageDTO_CreditNoteProjection';
  entities?: Maybe<Array<Maybe<CreditNoteProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_CustomerProjection = {
  __typename?: 'PageDTO_CustomerProjection';
  entities?: Maybe<Array<Maybe<CustomerProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_DataValidationDto = {
  __typename?: 'PageDTO_DataValidationDTO';
  entities?: Maybe<Array<Maybe<DataValidationDto>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_DigitalCarRegistrationProjection = {
  __typename?: 'PageDTO_DigitalCarRegistrationProjection';
  entities?: Maybe<Array<Maybe<DigitalCarRegistrationProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_DigitalCarRegistrationQaDataProjection = {
  __typename?: 'PageDTO_DigitalCarRegistrationQADataProjection';
  entities?: Maybe<Array<Maybe<DigitalCarRegistrationQaDataProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_DiscountDto = {
  __typename?: 'PageDTO_DiscountDTO';
  entities?: Maybe<Array<Maybe<DiscountDto>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_DownPaymentProjection = {
  __typename?: 'PageDTO_DownPaymentProjection';
  entities?: Maybe<Array<Maybe<DownPaymentProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_EsOrderProjection = {
  __typename?: 'PageDTO_EsOrderProjection';
  entities?: Maybe<Array<Maybe<EsOrderProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_ExitCheckProjection = {
  __typename?: 'PageDTO_ExitCheckProjection';
  entities?: Maybe<Array<Maybe<ExitCheckProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_ExternalFinancingLeasingCompanyProjection = {
  __typename?: 'PageDTO_ExternalFinancingLeasingCompanyProjection';
  entities?: Maybe<Array<Maybe<ExternalFinancingLeasingCompanyProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_FinancingSearchProjection = {
  __typename?: 'PageDTO_FinancingSearchProjection';
  entities?: Maybe<Array<Maybe<FinancingSearchProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_FrRegionalTaxBasedPriceModifierProjection = {
  __typename?: 'PageDTO_FrRegionalTaxBasedPriceModifierProjection';
  entities?: Maybe<Array<Maybe<FrRegionalTaxBasedPriceModifierProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_GroupProjection = {
  __typename?: 'PageDTO_GroupProjection';
  entities?: Maybe<Array<Maybe<GroupProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_ItPowerAndZoneBasedPriceModifierProjection = {
  __typename?: 'PageDTO_ITPowerAndZoneBasedPriceModifierProjection';
  entities?: Maybe<Array<Maybe<ItPowerAndZoneBasedPriceModifierProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_InvoiceProjection = {
  __typename?: 'PageDTO_InvoiceProjection';
  entities?: Maybe<Array<Maybe<InvoiceProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_KbaRequestHistoryProjection = {
  __typename?: 'PageDTO_KBARequestHistoryProjection';
  entities?: Maybe<Array<Maybe<KbaRequestHistoryProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_OrderCommentProjection = {
  __typename?: 'PageDTO_OrderCommentProjection';
  entities?: Maybe<Array<Maybe<OrderCommentProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_OrderFinancingHistoryProjection = {
  __typename?: 'PageDTO_OrderFinancingHistoryProjection';
  entities?: Maybe<Array<Maybe<OrderFinancingHistoryProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_OrderHistoryEventProjection = {
  __typename?: 'PageDTO_OrderHistoryEventProjection';
  entities?: Maybe<Array<Maybe<OrderHistoryEventProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_OrderProjection = {
  __typename?: 'PageDTO_OrderProjection';
  entities?: Maybe<Array<Maybe<OrderProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_OrderTaskProjection = {
  __typename?: 'PageDTO_OrderTaskProjection';
  entities?: Maybe<Array<Maybe<OrderTaskProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_PnlItemProjection = {
  __typename?: 'PageDTO_PnlItemProjection';
  entities?: Maybe<Array<Maybe<PnlItemProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_PnlItemTypeProjection = {
  __typename?: 'PageDTO_PnlItemTypeProjection';
  entities?: Maybe<Array<Maybe<PnlItemTypeProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_PnlPartnerProjection = {
  __typename?: 'PageDTO_PnlPartnerProjection';
  entities?: Maybe<Array<Maybe<PnlPartnerProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RefurbishmentAggregateProjection = {
  __typename?: 'PageDTO_RefurbishmentAggregateProjection';
  entities?: Maybe<Array<Maybe<RefurbishmentAggregateProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RefurbishmentCommentProjection = {
  __typename?: 'PageDTO_RefurbishmentCommentProjection';
  entities?: Maybe<Array<Maybe<RefurbishmentCommentProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RefurbishmentDto = {
  __typename?: 'PageDTO_RefurbishmentDTO';
  entities?: Maybe<Array<Maybe<RefurbishmentDto>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RefurbishmentPartnerDto = {
  __typename?: 'PageDTO_RefurbishmentPartnerDTO';
  entities?: Maybe<Array<Maybe<RefurbishmentPartnerDto>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RefurbishmentProjection = {
  __typename?: 'PageDTO_RefurbishmentProjection';
  entities?: Maybe<Array<Maybe<RefurbishmentProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailAbTestingExperimentProjection = {
  __typename?: 'PageDTO_RetailAbTestingExperimentProjection';
  entities?: Maybe<Array<Maybe<RetailAbTestingExperimentProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailAbTestingParticipantProjection = {
  __typename?: 'PageDTO_RetailAbTestingParticipantProjection';
  entities?: Maybe<Array<Maybe<RetailAbTestingParticipantProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailAdPnlDataProjection = {
  __typename?: 'PageDTO_RetailAdPnlDataProjection';
  entities?: Maybe<Array<Maybe<RetailAdPnlDataProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailAdPriceChangeDto = {
  __typename?: 'PageDTO_RetailAdPriceChangeDTO';
  entities?: Maybe<Array<Maybe<RetailAdPriceChangeDto>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailAdProjection = {
  __typename?: 'PageDTO_RetailAdProjection';
  entities?: Maybe<Array<Maybe<RetailAdProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailAxaPolicyNotificationProjection = {
  __typename?: 'PageDTO_RetailAxaPolicyNotificationProjection';
  entities?: Maybe<Array<Maybe<RetailAxaPolicyNotificationProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailBackofficeNotificationProjection = {
  __typename?: 'PageDTO_RetailBackofficeNotificationProjection';
  entities?: Maybe<Array<Maybe<RetailBackofficeNotificationProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailBaseProductProjection = {
  __typename?: 'PageDTO_RetailBaseProductProjection';
  entities?: Maybe<Array<Maybe<RetailBaseProductProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailCarRegistrationProjection = {
  __typename?: 'PageDTO_RetailCarRegistrationProjection';
  entities?: Maybe<Array<Maybe<RetailCarRegistrationProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailClaimAssigneeDto = {
  __typename?: 'PageDTO_RetailClaimAssigneeDTO';
  entities?: Maybe<Array<Maybe<RetailClaimAssigneeDto>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailClaimProjection = {
  __typename?: 'PageDTO_RetailClaimProjection';
  entities?: Maybe<Array<Maybe<RetailClaimProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailClaimStateDueDaysConfigProjection = {
  __typename?: 'PageDTO_RetailClaimStateDueDaysConfigProjection';
  entities?: Maybe<Array<Maybe<RetailClaimStateDueDaysConfigProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailDataImportProjection = {
  __typename?: 'PageDTO_RetailDataImportProjection';
  entities?: Maybe<Array<Maybe<RetailDataImportProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailDigitalCarRegistrationAssigneeDto = {
  __typename?: 'PageDTO_RetailDigitalCarRegistrationAssigneeDTO';
  entities?: Maybe<Array<Maybe<RetailDigitalCarRegistrationAssigneeDto>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailDocumentRequestProjection = {
  __typename?: 'PageDTO_RetailDocumentRequestProjection';
  entities?: Maybe<Array<Maybe<RetailDocumentRequestProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailFeatureProjection = {
  __typename?: 'PageDTO_RetailFeatureProjection';
  entities?: Maybe<Array<Maybe<RetailFeatureProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailFeeProjection = {
  __typename?: 'PageDTO_RetailFeeProjection';
  entities?: Maybe<Array<Maybe<RetailFeeProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailImageTagProjection = {
  __typename?: 'PageDTO_RetailImageTagProjection';
  entities?: Maybe<Array<Maybe<RetailImageTagProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailInsuranceProjection = {
  __typename?: 'PageDTO_RetailInsuranceProjection';
  entities?: Maybe<Array<Maybe<RetailInsuranceProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailInternalTransferPaymentProjection = {
  __typename?: 'PageDTO_RetailInternalTransferPaymentProjection';
  entities?: Maybe<Array<Maybe<RetailInternalTransferPaymentProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailLeadProjection = {
  __typename?: 'PageDTO_RetailLeadProjection';
  entities?: Maybe<Array<Maybe<RetailLeadProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailOrderDebtCsvDataProjection = {
  __typename?: 'PageDTO_RetailOrderDebtCSVDataProjection';
  entities?: Maybe<Array<Maybe<RetailOrderDebtCsvDataProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailOrderDebtProjection = {
  __typename?: 'PageDTO_RetailOrderDebtProjection';
  entities?: Maybe<Array<Maybe<RetailOrderDebtProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailOutboundPaymentProjection = {
  __typename?: 'PageDTO_RetailOutboundPaymentProjection';
  entities?: Maybe<Array<Maybe<RetailOutboundPaymentProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailPaymentProjection = {
  __typename?: 'PageDTO_RetailPaymentProjection';
  entities?: Maybe<Array<Maybe<RetailPaymentProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailProductDto = {
  __typename?: 'PageDTO_RetailProductDTO';
  entities?: Maybe<Array<Maybe<RetailProductDto>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailRefundProjection = {
  __typename?: 'PageDTO_RetailRefundProjection';
  entities?: Maybe<Array<Maybe<RetailRefundProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailUnidentifiedPaymentProjection = {
  __typename?: 'PageDTO_RetailUnidentifiedPaymentProjection';
  entities?: Maybe<Array<Maybe<RetailUnidentifiedPaymentProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailUserDto = {
  __typename?: 'PageDTO_RetailUserDTO';
  entities?: Maybe<Array<Maybe<RetailUserDto>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailVehiclePreparationProjection = {
  __typename?: 'PageDTO_RetailVehiclePreparationProjection';
  entities?: Maybe<Array<Maybe<RetailVehiclePreparationProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailWarrantyProjection = {
  __typename?: 'PageDTO_RetailWarrantyProjection';
  entities?: Maybe<Array<Maybe<RetailWarrantyProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RetailYouSignSignatureRequestProjection = {
  __typename?: 'PageDTO_RetailYouSignSignatureRequestProjection';
  entities?: Maybe<Array<Maybe<RetailYouSignSignatureRequestProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_RoleProjection = {
  __typename?: 'PageDTO_RoleProjection';
  entities?: Maybe<Array<Maybe<RoleProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_SearchRetailDocumentProjection = {
  __typename?: 'PageDTO_SearchRetailDocumentProjection';
  entities?: Maybe<Array<Maybe<SearchRetailDocumentProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_TaskManagerCommentProjection = {
  __typename?: 'PageDTO_TaskManagerCommentProjection';
  entities?: Maybe<Array<Maybe<TaskManagerCommentProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_WarrantyCarPricePriceModifierProjection = {
  __typename?: 'PageDTO_WarrantyCarPricePriceModifierProjection';
  entities?: Maybe<Array<Maybe<WarrantyCarPricePriceModifierProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_WarrantyCarYearsPriceModifierProjection = {
  __typename?: 'PageDTO_WarrantyCarYearsPriceModifierProjection';
  entities?: Maybe<Array<Maybe<WarrantyCarYearsPriceModifierProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageDto_TaskManagementProjection = {
  __typename?: 'PageDTO_taskManagementProjection';
  entities?: Maybe<Array<Maybe<TaskManagementProjection>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalEntityCount?: Maybe<Scalars['Long']>;
  totalPageCount?: Maybe<Scalars['Int']>;
};

export type PageFilterProjectionInput = {
  filters?: InputMaybe<Array<InputMaybe<FilterProjectionInput>>>;
};

export type PageRequestProjectionInput = {
  filter?: InputMaybe<PageFilterProjectionInput>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sort?: InputMaybe<PageSortProjectionInput>;
};

export type PageSortProjectionInput = {
  order?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
};

export type PageableRequestProjection_RefurbishmentAggregateSearchDtoInput = {
  criteria?: InputMaybe<RefurbishmentAggregateSearchDtoInput>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type PartnerDto = {
  __typename?: 'PartnerDTO';
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Long']>;
  logoPath?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export enum PartnerType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL'
}

export type PartnerWorkshopConnectionDto = {
  __typename?: 'PartnerWorkshopConnectionDTO';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isImportedFromBackoffice?: Maybe<Scalars['Boolean']>;
  mainWorkshop?: Maybe<WorkshopDto>;
  repairPartner?: Maybe<RepairPartnerDto>;
  repairWorkshop?: Maybe<WorkshopDto>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type PaymentDetailsProjectionInput = {
  recipientBankAccount?: InputMaybe<Scalars['String']>;
};

export type PaymentDetailsUpdateProjectionInput = {
  amountInWords?: InputMaybe<Scalars['String']>;
  balloonPaymentAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  contractType?: InputMaybe<Scalars['String']>;
  creditAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  creditPeriod?: InputMaybe<Scalars['Int']>;
  depositAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  depositDueDate?: InputMaybe<Scalars['String']>;
  discountRequested?: InputMaybe<Scalars['Boolean']>;
  financingType?: InputMaybe<Scalars['String']>;
  fullPaymentDueDate?: InputMaybe<Scalars['String']>;
  interestRate?: InputMaybe<Scalars['Float']>;
  monthlyPaymentAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  paymentType?: InputMaybe<Scalars['String']>;
};

export type PdfDto = {
  __typename?: 'PdfDTO';
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  type?: Maybe<Scalars['String']>;
};

export type PdfDtoInput = {
  fullUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<Scalars['String']>;
};

export type PermissionDto = {
  __typename?: 'PermissionDTO';
  id?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
};

export type PermissionProjection = {
  __typename?: 'PermissionProjection';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type PersistExternalRiskUserInfoProjectionInput = {
  accountHolder?: InputMaybe<Scalars['String']>;
  additionalIncomeMinorUnits?: InputMaybe<Scalars['Long']>;
  bankAccountOpenDate?: InputMaybe<Scalars['String']>;
  contractValidFor?: InputMaybe<Scalars['Boolean']>;
  employedBy?: InputMaybe<Scalars['String']>;
  employmentEndDate?: InputMaybe<Scalars['String']>;
  employmentStartDate?: InputMaybe<Scalars['String']>;
  employmentStatus?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<Scalars['String']>;
  housingConstruction?: InputMaybe<Scalars['String']>;
  housingType?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  incomeFromSubsidiesMinorUnits?: InputMaybe<Scalars['Long']>;
  incomeSpread?: InputMaybe<Scalars['Int']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  maritalStatus?: InputMaybe<Scalars['String']>;
  mortgageOrRentPaymentMinorUnits?: InputMaybe<Scalars['Long']>;
  movingDate?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  numberOfDependents?: InputMaybe<Scalars['Int']>;
  numberOfOtherDependents?: InputMaybe<Scalars['Int']>;
  numberOfOtherLoans?: InputMaybe<Scalars['Int']>;
  otherLoanPaymentsMinorUnits?: InputMaybe<Scalars['Long']>;
  pep?: InputMaybe<Scalars['Boolean']>;
  placeOfBirth?: InputMaybe<Scalars['String']>;
  previousAddressMovingDate?: InputMaybe<Scalars['String']>;
  primaryIncomeMinorUnits?: InputMaybe<Scalars['Long']>;
  residentSinceDate?: InputMaybe<Scalars['String']>;
  sameEmployerOverTimePeriod?: InputMaybe<Scalars['Boolean']>;
  sector?: InputMaybe<Scalars['String']>;
  seniority?: InputMaybe<Scalars['String']>;
  separationAllowanceMinorUnits?: InputMaybe<Scalars['Long']>;
  separationOfGoodsInMarriage?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
  zipcodeOfBirth?: InputMaybe<Scalars['String']>;
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  internationalCallingCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type PhoneNumberInput = {
  internationalCallingCode?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export enum PnlInvoiceType {
  CreditNote = 'CREDIT_NOTE',
  DamageCompensation = 'DAMAGE_COMPENSATION',
  Estimate = 'ESTIMATE',
  Invoice = 'INVOICE',
  InvoiceCorrection = 'INVOICE_CORRECTION',
  SalesCreditNote = 'SALES_CREDIT_NOTE'
}

export type PnlItemCreateProjectionInput = {
  currencyCode?: InputMaybe<Scalars['String']>;
  entityChargedName?: InputMaybe<Scalars['String']>;
  grossAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  invoiceDate?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  netAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  partnerId?: InputMaybe<Scalars['UUID']>;
  pnlInvoiceType?: InputMaybe<PnlInvoiceType>;
  pnlItemTypeId?: InputMaybe<Scalars['UUID']>;
  restoreIfDeleted?: InputMaybe<Scalars['Boolean']>;
  retailAdId?: InputMaybe<Scalars['UUID']>;
  retailCountry?: InputMaybe<Scalars['String']>;
  serviceEndDate?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['String']>;
  stockNumber?: InputMaybe<Scalars['String']>;
  vatAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  vatRate?: InputMaybe<Scalars['Float']>;
  vin?: InputMaybe<Scalars['String']>;
};

export type PnlItemProjection = {
  __typename?: 'PnlItemProjection';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  entityChargedName?: Maybe<Scalars['String']>;
  grossAmount?: Maybe<PriceDto>;
  grossAmountMinorUnits?: Maybe<Scalars['Long']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDate?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  netAmount?: Maybe<PriceDto>;
  netAmountMinorUnits?: Maybe<Scalars['Long']>;
  partner?: Maybe<PnlPartnerProjection>;
  partnerId?: Maybe<Scalars['UUID']>;
  pnlInvoiceType?: Maybe<PnlInvoiceType>;
  pnlItemTypeId?: Maybe<Scalars['UUID']>;
  retailAdId?: Maybe<Scalars['UUID']>;
  retailCountry?: Maybe<Scalars['String']>;
  serviceEndDate?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  type?: Maybe<PnlItemTypeProjection>;
  updatedOn?: Maybe<Scalars['String']>;
  vatAmount?: Maybe<PriceDto>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatRate?: Maybe<Scalars['Float']>;
  vin?: Maybe<Scalars['String']>;
};

export type PnlItemTypeCreateProjectionInput = {
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type PnlItemTypeProjection = {
  __typename?: 'PnlItemTypeProjection';
  category?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type PnlItemUpdateProjectionInput = {
  currencyCode?: InputMaybe<Scalars['String']>;
  entityChargedName?: InputMaybe<Scalars['String']>;
  grossAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  invoiceDate?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  netAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  partnerId?: InputMaybe<Scalars['UUID']>;
  pnlInvoiceType?: InputMaybe<PnlInvoiceType>;
  pnlItemTypeId?: InputMaybe<Scalars['UUID']>;
  serviceEndDate?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['String']>;
  vatAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  vatRate?: InputMaybe<Scalars['Float']>;
};

export type PnlPartnerCreateProjectionInput = {
  adminCarrierId?: InputMaybe<Scalars['Long']>;
  auto1Entity?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  invoiceCurrency?: InputMaybe<Scalars['String']>;
  partnerAddress?: InputMaybe<Scalars['String']>;
  partnerCity?: InputMaybe<Scalars['String']>;
  partnerCountry?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['String']>;
  partnerName?: InputMaybe<Scalars['String']>;
  partnerVatId?: InputMaybe<Scalars['String']>;
  partnerZip?: InputMaybe<Scalars['String']>;
};

export type PnlPartnerProjection = {
  __typename?: 'PnlPartnerProjection';
  adminCarrierId?: Maybe<Scalars['Long']>;
  auto1Entity?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  invoiceCurrency?: Maybe<Scalars['String']>;
  partnerAddress?: Maybe<Scalars['String']>;
  partnerCity?: Maybe<Scalars['String']>;
  partnerCountry?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['String']>;
  partnerName?: Maybe<Scalars['String']>;
  partnerVatId?: Maybe<Scalars['String']>;
  partnerZip?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type PollValidationStatusProjectionInput = {
  dataImportId?: InputMaybe<Scalars['UUID']>;
};

export type PositionDto = {
  __typename?: 'PositionDTO';
  leftPercentage?: Maybe<Scalars['Float']>;
  topPercentage?: Maybe<Scalars['Float']>;
};

export type PositionDtoInput = {
  leftPercentage?: InputMaybe<Scalars['Float']>;
  topPercentage?: InputMaybe<Scalars['Float']>;
};

export type PreCheckoutFinancingProjection = {
  __typename?: 'PreCheckoutFinancingProjection';
  auxUserId?: Maybe<Scalars['UUID']>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  creditCheckFlowType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  reason?: Maybe<Scalars['String']>;
  riskEvaluation?: Maybe<FinanceRiskEvaluationProjection>;
  status?: Maybe<Scalars['String']>;
};

export type Price = {
  __typename?: 'Price';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  conversionMajor?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
};

export type PriceDto = {
  __typename?: 'PriceDTO';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export type PriceDtoInput = {
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  currencyCode?: InputMaybe<Scalars['String']>;
};

export enum PriceDetailType {
  Invoice = 'INVOICE',
  Pnl = 'PNL'
}

export type PriceDetailsDto = {
  __typename?: 'PriceDetailsDTO';
  grossPriceMinorUnits?: Maybe<Scalars['Int']>;
  netPriceMinorUnits?: Maybe<Scalars['Int']>;
  priceDetailType?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Int']>;
  vatKey?: Maybe<Scalars['String']>;
  vatPercentage?: Maybe<Scalars['Float']>;
};

export type PriceDetailsProjection = {
  __typename?: 'PriceDetailsProjection';
  grossPriceMinorUnits?: Maybe<Scalars['Int']>;
  netPriceMinorUnits?: Maybe<Scalars['Int']>;
  priceDetailType?: Maybe<PriceDetailType>;
  vatAmountMinorUnits?: Maybe<Scalars['Int']>;
  vatKey?: Maybe<VatKeyType>;
  vatPercentage?: Maybe<Scalars['Float']>;
};

export type PriceInput = {
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  conversionMajor?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
};

export type PriceProjection = {
  __typename?: 'PriceProjection';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export enum PriceType {
  Fixed = 'FIXED',
  Negotiable = 'NEGOTIABLE'
}

export type PriceUpdateItemImportCreateProjectionInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export enum PriceUpdateType {
  ProceedExcludingPriceError = 'PROCEED_EXCLUDING_PRICE_ERROR',
  ProceedWithPriceError = 'PROCEED_WITH_PRICE_ERROR'
}

export enum PrimaryUseOfVehicle {
  Commercial = 'COMMERCIAL',
  Personal = 'PERSONAL'
}

export type ProductCreateProjectionInput = {
  active: Scalars['Boolean'];
  category?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  depositApplicable: Scalars['Boolean'];
  depositPercentage?: InputMaybe<Scalars['Float']>;
  excludedFromInvoice?: InputMaybe<Scalars['Boolean']>;
  invoiceDescription?: InputMaybe<Scalars['String']>;
  invoiceItemNetMinorUnits?: InputMaybe<Scalars['Long']>;
  paidService: Scalars['Boolean'];
  partner?: InputMaybe<Scalars['String']>;
  pnlCostNetMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlVatApplicable: Scalars['Boolean'];
  pnlVatRate?: InputMaybe<Scalars['Float']>;
  properties?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  shippingRequired: Scalars['Boolean'];
  sku?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vatKey?: InputMaybe<Scalars['String']>;
  vatRate?: InputMaybe<Scalars['Float']>;
};

export type ProductItemProjection = {
  __typename?: 'ProductItemProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customDiscountMinorUnits?: Maybe<Scalars['Long']>;
  customDiscountType?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  obsolete?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderItemRefund?: Maybe<Scalars['Long']>;
  parentId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  product?: Maybe<ProductProjection>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type ProductPartnersProjection = {
  __typename?: 'ProductPartnersProjection';
  entities?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProductPriceDto = {
  __typename?: 'ProductPriceDTO';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export type ProductProjection = {
  __typename?: 'ProductProjection';
  active: Scalars['Boolean'];
  category?: Maybe<RetailProductCategory>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  depositApplicable: Scalars['Boolean'];
  depositPercentage?: Maybe<Scalars['Float']>;
  excludedFromInvoice: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceItemGross?: Maybe<PriceDto>;
  invoiceItemGrossMinorUnits?: Maybe<Scalars['Long']>;
  invoiceItemNet?: Maybe<PriceDto>;
  invoiceItemNetMinorUnits?: Maybe<Scalars['Long']>;
  invoiceItemVatAmount?: Maybe<PriceDto>;
  invoiceItemVatAmountMinorUnits?: Maybe<Scalars['Long']>;
  paidService: Scalars['Boolean'];
  partner?: Maybe<Scalars['String']>;
  pnlCostGross?: Maybe<PriceDto>;
  pnlCostGrossMinorUnits?: Maybe<Scalars['Long']>;
  pnlCostNet?: Maybe<PriceDto>;
  pnlCostNetMinorUnits?: Maybe<Scalars['Long']>;
  pnlCostVatAmount?: Maybe<PriceDto>;
  pnlCostVatAmountMinorUnits?: Maybe<Scalars['Long']>;
  pnlVatApplicable: Scalars['Boolean'];
  pnlVatRate?: Maybe<Scalars['Float']>;
  properties?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  shippingRequired: Scalars['Boolean'];
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  vatKey?: Maybe<VatKeyType>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type ProductTypesProjection = {
  __typename?: 'ProductTypesProjection';
  entities?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProductUpdateProjectionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  defaulted?: InputMaybe<Scalars['Boolean']>;
  depositApplicable?: InputMaybe<Scalars['Boolean']>;
  depositPercentage?: InputMaybe<Scalars['Float']>;
  excludedFromInvoice?: InputMaybe<Scalars['Boolean']>;
  invoiceDescription?: InputMaybe<Scalars['String']>;
  invoiceItemNetMinorUnits?: InputMaybe<Scalars['Long']>;
  paidService?: InputMaybe<Scalars['Boolean']>;
  partner?: InputMaybe<Scalars['String']>;
  pnlCostNetMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlVatApplicable?: InputMaybe<Scalars['Boolean']>;
  pnlVatRate?: InputMaybe<Scalars['Float']>;
  properties?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  shippingRequired?: InputMaybe<Scalars['Boolean']>;
  sku?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vatKey?: InputMaybe<Scalars['String']>;
  vatRate?: InputMaybe<Scalars['Float']>;
};

export type Profile = {
  __typename?: 'Profile';
  depth?: Maybe<Scalars['Int']>;
  unit?: Maybe<ProfileUnit>;
};

export enum ProfileUnit {
  Mm = 'MM'
}

export type ProfitAndLossImportCreateProjectionInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export type ProofRequestItemCreateProjectionInput = {
  comment?: InputMaybe<Scalars['String']>;
  complaintId?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ProofRequestsCreateProjectionInput = {
  requests?: InputMaybe<Array<InputMaybe<ProofRequestItemCreateProjectionInput>>>;
};

export type ProtocolComplementaryProjection = {
  __typename?: 'ProtocolComplementaryProjection';
  chequeVerificationRequired?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  customerAddress?: Maybe<Scalars['String']>;
  customerDateOfBirth?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  handoverDate?: Maybe<Scalars['String']>;
  handoverLocation?: Maybe<Scalars['String']>;
  isHomeDelivery?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  modelDescription?: Maybe<Scalars['String']>;
  onDeliveryPaymentType?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  paymentShouldBeDoneOnDelivery?: Maybe<Scalars['Boolean']>;
  paymentStatus?: Maybe<Scalars['String']>;
  remainingBalanceMinorUnits?: Maybe<Scalars['Long']>;
  shippingAddress?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type ProtocolSubmissionDtoInput = {
  car?: InputMaybe<CarDtoInput>;
  customer?: InputMaybe<CustomerDtoInput>;
  customerComment?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<DocumentsDtoInput>;
  images?: InputMaybe<ImagesDtoInput>;
  vin?: InputMaybe<Scalars['String']>;
};

export enum PublishedScopeType {
  Global = 'GLOBAL',
  Online = 'ONLINE',
  Showroom = 'SHOWROOM'
}

export type PublishingBlockerProjection = {
  __typename?: 'PublishingBlockerProjection';
  comment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  publishingBlockerReasonId?: Maybe<Scalars['UUID']>;
  publishingBlockerSubReasonId?: Maybe<Scalars['UUID']>;
};

export type PublishingBlockerReasonDto = {
  __typename?: 'PublishingBlockerReasonDTO';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  subReasons?: Maybe<Array<Maybe<PublishingBlockerSubReasonDto>>>;
};

export type PublishingBlockerRemoveProjectionInput = {
  publishingBlockerSubReasonId?: InputMaybe<Scalars['UUID']>;
};

export type PublishingBlockerSubReasonDto = {
  __typename?: 'PublishingBlockerSubReasonDTO';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
};

export type PublishingBlockerUpdateProjectionInput = {
  comment?: InputMaybe<Scalars['String']>;
  publishingBlockerSubReasonId?: InputMaybe<Scalars['UUID']>;
};

export type PublishingError = {
  __typename?: 'PublishingError';
  createdOn?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** Query root */
export type Query = {
  __typename?: 'Query';
  abTestingAvailableConditionVariables?: Maybe<Array<Maybe<RetailAbTestingConditionVariableProjection>>>;
  abTestingExperiment?: Maybe<RetailAbTestingExperimentProjection>;
  abTestingExperimentCounters?: Maybe<RetailAbTestingExperimentCountersProjection>;
  abTestingExperiments?: Maybe<Array<Maybe<RetailAbTestingExperimentProjection>>>;
  activePreCheckoutFinancing?: Maybe<PreCheckoutFinancingProjection>;
  actualFinancingBillingAddress?: Maybe<FinancingBillingAddressProjection>;
  actualSepaMandate?: Maybe<FinancingSepaMandateProjection>;
  ad?: Maybe<AdProjection>;
  adByStockNumber?: Maybe<AdProjection>;
  adDetailByRetailAdId?: Maybe<AdDetailsProjection>;
  adEquipment?: Maybe<Array<Maybe<CategorizedEquipmentsDto>>>;
  adVideoPresignedUrls?: Maybe<Array<Maybe<AwsS3AdVideoPresignedUrlDto>>>;
  aiSettingsQualityCheck?: Maybe<Scalars['Map_String_ObjectScalar']>;
  allowedClaimStates?: Maybe<RetailClaimAllowedStatesProjection>;
  attachableTradeIns?: Maybe<Array<Maybe<RetailTradeInProjection>>>;
  availabilityRule?: Maybe<AvailabilityRuleProjection>;
  baseProduct?: Maybe<RetailBaseProductProjection>;
  batchPriceUpdateValidationSummary?: Maybe<RetailBatchPriceUpdateValidationSummaryDto>;
  buildImageUploadCredentials?: Maybe<AwsUploadCredentialsProjection>;
  carRegistrationActions?: Maybe<Array<Maybe<CarRegistrationActionProjection>>>;
  carRegistrationProduct?: Maybe<RetailCarRegistrationProjection>;
  checkoutPaymentDetails?: Maybe<CheckoutPaymentDetailsProjection>;
  claimOrderDetails?: Maybe<OrderProjection>;
  claimStateDueDaysConfig?: Maybe<PageDto_RetailClaimStateDueDaysConfigProjection>;
  compiledEmailTemplate?: Maybe<CompiledOrderEmailTemplateProjection>;
  completeAiQuestion?: Maybe<CompleteQuestionResponseProjection>;
  creditScoreDataByOrderFinancing?: Maybe<RetailCreditScoreDataProjection>;
  creditScoreDataByOrderFinancingV2?: Maybe<RetailCreditScoreDataProjectionV2>;
  customerAddress?: Maybe<CustomerAddressProjection>;
  deliveryRoute?: Maybe<DeliveryRouteProjection>;
  digitalCarRegistration?: Maybe<DigitalCarRegistrationProjection>;
  digitalCarRegistrationByOrderId?: Maybe<DigitalCarRegistrationProjection>;
  digitalCarRegistrationTestDataSource?: Maybe<PageDto_DigitalCarRegistrationQaDataProjection>;
  digitalHandoverComplementary?: Maybe<ProtocolComplementaryProjection>;
  distributeCreditNotesRefunds?: Maybe<RetailOrderRefundsDistributionProjection>;
  distributeRefunds?: Maybe<RetailOrderRefundsDistributionProjection>;
  distributeRefundsByOrderItems?: Maybe<ListContainerDto_OrderItemsRefundsDto>;
  /** Returns all the categories with their respective document types */
  documentCategories?: Maybe<RetailDocumentTopCategoryProjection>;
  documentInventoryItemLocations?: Maybe<ResultConnection_DocumentLocation>;
  documentInventoryItems?: Maybe<ResultConnection_DocumentInventoryItem>;
  documentInventoryPackage?: Maybe<DocumentInventoryPackageProjection>;
  documentInventoryPackages?: Maybe<ResultConnection_DocumentInventoryPackageProjection>;
  documentInventoryTypes?: Maybe<DocumentInventoryTypeConnection_DocumentInventoryType>;
  documentShippingDeliveryAddresses?: Maybe<BranchSearchingResult_DocumentBranchProjection>;
  documentShippingProviders?: Maybe<ResultConnection_ShippingProviderProjection>;
  downPayment?: Maybe<DownPaymentProjection>;
  esSearchOrders?: Maybe<PageDto_EsOrderProjection>;
  exitCheckComments?: Maybe<ExitCheckCommentsProjection>;
  experianDataByOrderFinancing?: Maybe<RetailExperianDataProjection>;
  exportSection?: Maybe<ExportSectionProjection>;
  extendedTradeInsForUser?: Maybe<Array<Maybe<RetailTradeInExtendedProjection>>>;
  externalFinancingCustomerDetails?: Maybe<ExternalFinancingCustomerDetailsProjection>;
  externalFinancingEvaluation?: Maybe<ExternalFinanceRiskEvaluationProjection>;
  externalFinancingLeasingDetails?: Maybe<ExternalFinancingLeasingDetailsProjection>;
  externalFinancingOptions?: Maybe<ExternalFinancingOptionsProjection>;
  externalFinancingOptionsValues?: Maybe<ExternalFinancingOptionsValuesProjection>;
  externalFinancingScores?: Maybe<Array<Maybe<ExternalFinancingApplicationScoringResultProjection>>>;
  externalRiskUserInfo?: Maybe<ExternalRiskUserInfoProjection>;
  financeRiskEvaluation?: Maybe<FinanceRiskEvaluationProjection>;
  financeRiskEvaluationByOrder?: Maybe<FinanceRiskEvaluationProjection>;
  financeRiskEvaluationByOrderFinancing?: Maybe<FinanceRiskEvaluationProjection>;
  financingAgentAssignees?: Maybe<Array<Maybe<UserProjection>>>;
  financingApplication?: Maybe<FinancingApplicationProjection>;
  financingCalculator?: Maybe<FinancingCalculatorResponseProjection>;
  financingPlanPdfData?: Maybe<Array<Maybe<RetailFinancingPlanBreakdownProjection>>>;
  fullFtsDataByOrderFinancing?: Maybe<RetailFullFtsDataProjection>;
  getAllValidations?: Maybe<PageDto_DataValidationDto>;
  getAutomaticVerification?: Maybe<OrderAutomaticVerificationProjection>;
  getBranchEntryCheckCarSupported?: Maybe<BranchEntryCheckCarSupportedProjection>;
  getBranchEntryCheckComplementary?: Maybe<BranchEntryCheckComplementaryProjection>;
  getBranchEntryCheckDetails?: Maybe<BranchEntryCheckDetailsProjection>;
  getClaimsChangelog?: Maybe<PageDto_ClaimChangelogResponseProjection>;
  getCustomerCreditScore?: Maybe<CreditScoreResponseDataProjection>;
  getHandover?: Maybe<RetailOrderHandoverProjection>;
  getHmcsData?: Maybe<HmcsDataProjection>;
  getLiveChatAgentData?: Maybe<ChatbotLiveChatAgentDataProjection>;
  getLiveChatAgentTeams?: Maybe<Array<Maybe<LiveChatAgentTeamProjection>>>;
  getLiveChatAgentUnreadChatsCounter?: Maybe<AgentUnreadChatsCountProjection>;
  getLiveChatAgents?: Maybe<Array<Maybe<LiveChatAgentProjection>>>;
  getLiveChatAnswerTemplatesForAgent?: Maybe<Array<Maybe<AgentAnswerTemplateProjection>>>;
  getLiveChatCategories?: Maybe<Array<Maybe<LiveChatCategoryProjection>>>;
  getLiveChatHistory?: Maybe<Array<Maybe<DialogProjection>>>;
  getOpenAiModels?: Maybe<Array<Maybe<OpenAiModelProjection>>>;
  getOrderAutoCancellation?: Maybe<OrderAutoCancellationProjection>;
  getOutboundPaymentOptions?: Maybe<Scalars['Map_String_RetailOutboundPaymentOptionProjectionScalar']>;
  getReferralStatuses?: Maybe<Array<Maybe<ReferralProjection>>>;
  getRefurbishmentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  getReschedulingReasons?: Maybe<Array<Maybe<RetailOrderDeliveryReschedulingReasonDto>>>;
  getRetailClaimById?: Maybe<RetailClaimProjection>;
  getSalesAgentsList?: Maybe<Array<Maybe<SalesAgentProjection>>>;
  getSubProducts?: Maybe<Array<Maybe<RetailBaseProductProjection>>>;
  getVatRateByCountry?: Maybe<VatRateProjection>;
  getVerificationSurveyAnsweredQuestions?: Maybe<Array<Maybe<SurveyAnsweredQuestionProjection>>>;
  getVoucherData?: Maybe<VoucherProjection>;
  group?: Maybe<GroupProjection>;
  highlights?: Maybe<Array<Maybe<HighlightDto>>>;
  highlightsSuggestions?: Maybe<Array<Maybe<HighlightDto>>>;
  importedAd?: Maybe<Scalars['Map_String_ObjectScalar']>;
  internalPaymentOptions?: Maybe<InternalPaymentOptionsProjection>;
  internalTransferPaymentById?: Maybe<RetailInternalTransferPaymentProjection>;
  isAppointmentBookingApplicable: Scalars['Boolean'];
  isProductDeRegTempRequired: Scalars['Boolean'];
  isRevertOrderCancellationSupported?: Maybe<OrderCancellationRevertibleProjection>;
  kbaRequestDumpURL?: Maybe<Scalars['String']>;
  latestFinancingBillingAddress?: Maybe<FinancingBillingAddressProjection>;
  latestSepaMandate?: Maybe<FinancingSepaMandateProjection>;
  licensePlateByRetailAdId?: Maybe<RetailOrderContractRetailVehicleLicensePlateProjection>;
  me?: Maybe<RetailUserDto>;
  order?: Maybe<OrderProjection>;
  orderAddresses?: Maybe<Array<Maybe<OrderAddressProjection>>>;
  orderCancellationOptions?: Maybe<OrderCancellationOptionsProjection>;
  orderComplianceCheckCase?: Maybe<OrderComplianceCheckCaseProjection>;
  orderContract?: Maybe<RetailOrderContractProjection>;
  orderCountsByExternalIds?: Maybe<Scalars['Map_UUID_LongScalar']>;
  orderCustomerNotes?: Maybe<OrderCustomerLeadProjection>;
  orderDebtsCsvData?: Maybe<PageDto_RetailOrderDebtCsvDataProjection>;
  orderDelivery?: Maybe<RetailOrderDeliveryProjection>;
  orderEmailTemplates?: Maybe<Array<Maybe<TemplateProjection>>>;
  orderFinanceCancelReasons?: Maybe<Array<Maybe<OrderFinanceCancelReasonProjection>>>;
  orderFinanceRejectReasons?: Maybe<Array<Maybe<OrderFinanceRejectReasonProjection>>>;
  orderFinancing?: Maybe<OrderFinancingDto>;
  orderFinancingPlansHistory?: Maybe<Array<Maybe<RetailFinancingPlanCalculationProjection>>>;
  orderIncludedInABTestingExperiment: Scalars['Boolean'];
  orderItemsOptions?: Maybe<OrderItemsOptionsProjection>;
  orderSalesAgentAssignees?: Maybe<Array<Maybe<UserProjection>>>;
  orderStatistics?: Maybe<RetailOrderStatisticsDto>;
  orderVerificationPriorityScoringResults?: Maybe<Array<Maybe<RetailOrderVerificationPriorityScoringResultProjection>>>;
  ordersByAdId?: Maybe<Array<Maybe<OrderProjection>>>;
  plainSearchBaseProducts?: Maybe<PageDto_RetailBaseProductProjection>;
  plainSearchCarRegistrationProducts?: Maybe<PageDto_RetailCarRegistrationProjection>;
  plainSearchDownPaymentProducts?: Maybe<PageDto_DownPaymentProjection>;
  plainSearchFeeProducts?: Maybe<PageDto_RetailFeeProjection>;
  plainSearchInsuranceProducts?: Maybe<PageDto_RetailInsuranceProjection>;
  plainSearchVehiclePreparationProducts?: Maybe<PageDto_RetailVehiclePreparationProjection>;
  plainSearchWarrantyProducts?: Maybe<PageDto_RetailWarrantyProjection>;
  pnlItem?: Maybe<PnlItemProjection>;
  pnlItemChangelog?: Maybe<PageDto_ChangelogProjection>;
  pnlItemType?: Maybe<PnlItemTypeProjection>;
  pnlItemTypeCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  pnlPartner?: Maybe<PnlPartnerProjection>;
  pollValidationStatus?: Maybe<RetailDataImportDto>;
  presignedUrls?: Maybe<Array<Maybe<AwsS3PresignedUrlDto>>>;
  product?: Maybe<RetailProductProjection>;
  productPartners?: Maybe<ProductPartnersProjection>;
  productTypes?: Maybe<ProductTypesProjection>;
  recentOrderFinancing?: Maybe<OrderFinancingDto>;
  refundsWithCreditNotesDistribute?: Maybe<RetailOrderRefundsDistributionProjection>;
  refurbishment?: Maybe<RefurbishmentDto>;
  refurbishmentByRefId?: Maybe<RefurbishmentDto>;
  refurbishmentCommentsByRefurbishmentId?: Maybe<PageDto_RefurbishmentCommentProjection>;
  refurbishmentPartners?: Maybe<PageDto_RefurbishmentPartnerDto>;
  refurbishmentsByRetailAdId?: Maybe<PageDto_RefurbishmentProjection>;
  refurbishmentsOverview?: Maybe<PageDto_RefurbishmentAggregateProjection>;
  retailAccountDetailsForPayment?: Maybe<RetailAccountDetailsForPaymentProjection>;
  retailAd?: Maybe<RetailAdProjection>;
  retailAdByAdId?: Maybe<RetailAdProjection>;
  retailAdDamage?: Maybe<Array<Maybe<RetailAdDamageImageDto>>>;
  retailAdDeliveryOptions?: Maybe<BatchProjection_RetailAdDeliveryOptionProjection>;
  retailAdPnlData?: Maybe<RetailAdPnlDataProjection>;
  retailAdPriceHistory?: Maybe<PageDto_RetailAdPriceChangeDto>;
  retailAdPublishingBlockers?: Maybe<RetailAdPublishingBlockerProjection>;
  retailAdSecondaryWheels?: Maybe<RetailAdSecondaryWheelsProjection>;
  retailAdSecondaryWheelsOptions?: Maybe<SecondaryWheelsOptionsProjection>;
  retailAddress?: Maybe<RetailAddressProjection>;
  retailCarDetailsPlainByStockNumber?: Maybe<CarDetailsStoreAdPlainProjection>;
  retailCheckout?: Maybe<RetailCheckoutProjection>;
  retailCheckoutStepTypes?: Maybe<RetailCheckoutAllStepTypesProjection>;
  retailClaimCostCompensationsByOrderId?: Maybe<Array<Maybe<ClaimCostCompensationProjection>>>;
  retailClaimGroupsOptions?: Maybe<Array<Maybe<RetailClaimGroupProjection>>>;
  retailCustomer?: Maybe<Array<Maybe<RetailCustomerProjection>>>;
  retailCustomersByOrderIds?: Maybe<Array<Maybe<RetailCustomersByOrderProjection>>>;
  retailDiscountItemsByOrderId?: Maybe<ListContainerDto_RetailDiscountItemDto>;
  retailDiscountOptions?: Maybe<RetailDiscountItemOptionsProjection>;
  retailFeature?: Maybe<RetailFeatureProjection>;
  /** evaluate status one feature by name */
  retailFeatureStatusV2?: Maybe<RetailFeatureStatusProjectionV2>;
  /**
   * returns status of all features
   * @deprecated request is heavy and returns unnecessary data
   */
  retailFeatures?: Maybe<Array<Maybe<RetailFeatureStatusDto>>>;
  /** evaluate status of requested features by names */
  retailFeaturesStatusV2?: Maybe<Array<Maybe<RetailFeatureStatusProjectionV2>>>;
  retailFee?: Maybe<RetailFeeProjection>;
  retailFeeOptions?: Maybe<RetailFeeOptionsProjection>;
  retailFtsRiskDataByOrderFinancing?: Maybe<RetailFtsRiskDataProjection>;
  retailInsurance?: Maybe<RetailInsuranceProjection>;
  retailLead?: Maybe<RetailLeadProjection>;
  retailOrderReturnAppointment?: Maybe<RetailOrderReturnAppointmentProjection>;
  retailPayment?: Maybe<RetailPaymentProjection>;
  retailPaymentImportConfig?: Maybe<Array<Maybe<RetailDataImportConfigDto>>>;
  retailPublishingBlockerReasons?: Maybe<Array<Maybe<PublishingBlockerReasonDto>>>;
  retailTypedAccountDetailsForPayment?: Maybe<RetailTypedAccountDetailsForPaymentProjection>;
  retailVehiclePreparation?: Maybe<RetailVehiclePreparationProjection>;
  role?: Maybe<RoleProjection>;
  roleGroups?: Maybe<ListContainerDto_GroupProjection>;
  roleUsers?: Maybe<ListContainerDto_AuxUserDto>;
  salesScoreboardConfig?: Maybe<RetailSalesScoreboardConfigProjection>;
  searchAbTestingExperiments?: Maybe<PageDto_RetailAbTestingExperimentProjection>;
  searchAbTestingParticipants?: Maybe<PageDto_RetailAbTestingParticipantProjection>;
  searchAdsByChannelAndSourceIds?: Maybe<Array<Maybe<AdProjection>>>;
  searchAdsByIds?: Maybe<Array<Maybe<AdProjection>>>;
  searchAiSettings?: Maybe<PageDto_AiSettingProjection>;
  searchAvailabilityRules?: Maybe<PageDto_AvailabilityRuleProjection>;
  searchAxaPolicyNotifications?: Maybe<PageDto_RetailAxaPolicyNotificationProjection>;
  searchBranchEntryChecks?: Maybe<PageDto_BranchEntryCheckSearchItemDto>;
  searchBranches?: Maybe<PageDto_BranchProjection>;
  searchBranchesForSelection?: Maybe<OffsetBasedResponseProjection>;
  searchCarRegistration?: Maybe<PageDto_RetailCarRegistrationProjection>;
  /** @deprecated Use searchCarRegistration */
  searchCarRegistrationProduct?: Maybe<PageDto_RetailCarRegistrationProjection>;
  searchCarTypeBasedPriceModifier?: Maybe<PageDto_CarTypeBasedPriceModifierProjection>;
  searchChatbotQuestions?: Maybe<PageDto_ChatbotQuestionDto>;
  searchClaimAssigneeUsers?: Maybe<PageDto_RetailClaimAssigneeDto>;
  searchCreditNotes?: Maybe<PageDto_CreditNoteProjection>;
  searchCustomerUsers?: Maybe<PageDto_CustomerProjection>;
  searchDigitalCarRegistrationAssigneeUsers?: Maybe<PageDto_RetailDigitalCarRegistrationAssigneeDto>;
  searchDigitalCarRegistrationQAData?: Maybe<PageDto_DigitalCarRegistrationQaDataProjection>;
  searchDigitalCarRegistrations?: Maybe<PageDto_DigitalCarRegistrationProjection>;
  searchDiscounts?: Maybe<PageDto_DiscountDto>;
  searchDocumentRequests?: Maybe<PageDto_RetailDocumentRequestProjection>;
  searchDocuments?: Maybe<PageDto_SearchRetailDocumentProjection>;
  searchExternalFinancingLeasingCompanies?: Maybe<PageDto_ExternalFinancingLeasingCompanyProjection>;
  searchFeeProducts?: Maybe<PageDto_RetailFeeProjection>;
  searchFinancings?: Maybe<PageDto_FinancingSearchProjection>;
  searchFrRegionalTaxBasedPriceModifier?: Maybe<PageDto_FrRegionalTaxBasedPriceModifierProjection>;
  searchGroups?: Maybe<PageDto_GroupProjection>;
  searchITPowerAndZoneBasedPriceModifier?: Maybe<PageDto_ItPowerAndZoneBasedPriceModifierProjection>;
  searchImageTags?: Maybe<PageDto_RetailImageTagProjection>;
  searchInsurance?: Maybe<PageDto_RetailInsuranceProjection>;
  searchInternalTransferPayments?: Maybe<PageDto_RetailInternalTransferPaymentProjection>;
  searchInvoices?: Maybe<PageDto_InvoiceProjection>;
  searchKBARequestHistory?: Maybe<PageDto_KbaRequestHistoryProjection>;
  searchLiveChatAgentAnswerTemplate?: Maybe<PageDto_AgentAnswerTemplateProjection>;
  searchLiveChatAgents?: Maybe<PageDto_AutoheroChatBotLiveAgentProjection>;
  searchOrderAndTaskComments?: Maybe<OrderAndTaskCommentsProjection>;
  searchOrderChangelogs?: Maybe<PageDto_ChangelogProjection>;
  searchOrderComments?: Maybe<PageDto_OrderCommentProjection>;
  searchOrderDebts?: Maybe<PageDto_RetailOrderDebtProjection>;
  searchOrderFinancingByOrder?: Maybe<Array<Maybe<FinancingApplicationProjection>>>;
  searchOrderFinancingHistory?: Maybe<PageDto_OrderFinancingHistoryProjection>;
  searchOrderHistoryEvents?: Maybe<PageDto_OrderHistoryEventProjection>;
  searchOrderTasks?: Maybe<PageDto_OrderTaskProjection>;
  searchOrders?: Maybe<PageDto_OrderProjection>;
  searchOutboundPayment?: Maybe<PageDto_RetailOutboundPaymentProjection>;
  searchPnlItemTypes?: Maybe<PageDto_PnlItemTypeProjection>;
  searchPnlItems?: Maybe<PageDto_PnlItemProjection>;
  searchPnlPartners?: Maybe<PageDto_PnlPartnerProjection>;
  searchProductChangelogs?: Maybe<PageDto_ChangelogProjection>;
  searchProductPriceModifierInstances?: Maybe<ListContainerDto_String>;
  searchProducts?: Maybe<PageDto_RetailProductDto>;
  searchRefunds?: Maybe<PageDto_RetailRefundProjection>;
  searchRefurbishmentETAsByRetailAdId?: Maybe<RefurbishmentEtaListProjection>;
  searchRefurbishmentExitChecks?: Maybe<PageDto_ExitCheckProjection>;
  searchRefurbishments?: Maybe<PageDto_RefurbishmentDto>;
  searchRetailAdPnlData?: Maybe<PageDto_RetailAdPnlDataProjection>;
  searchRetailAds?: Maybe<PageDto_RetailAdProjection>;
  searchRetailClaims?: Maybe<PageDto_RetailClaimProjection>;
  searchRetailDataImport?: Maybe<PageDto_RetailDataImportProjection>;
  searchRetailDataImports?: Maybe<PageDto_RetailDataImportProjection>;
  searchRetailFeatureChangelogs?: Maybe<PageDto_ChangelogProjection>;
  searchRetailFeatures?: Maybe<PageDto_RetailFeatureProjection>;
  searchRetailLead?: Maybe<PageDto_RetailLeadProjection>;
  searchRetailPayments?: Maybe<PageDto_RetailPaymentProjection>;
  searchRetailUnidentifiedPayments?: Maybe<PageDto_RetailUnidentifiedPaymentProjection>;
  searchRoles?: Maybe<PageDto_RoleProjection>;
  searchSalesScoreboardResults?: Maybe<Array<Maybe<RetailSalesScoreboardResultProjection>>>;
  searchTaskManagerTaskComments?: Maybe<PageDto_TaskManagerCommentProjection>;
  searchTaskManagerTasks?: Maybe<PageDto_TaskManagementProjection>;
  searchTradeInByStockNumber?: Maybe<RetailTradeInCreateProjection>;
  searchUsers?: Maybe<PageDto_RetailUserDto>;
  searchVehiclePreparation?: Maybe<PageDto_RetailVehiclePreparationProjection>;
  /** @deprecated Use searchVehiclePreparation */
  searchVehiclePreparationProduct?: Maybe<PageDto_RetailVehiclePreparationProjection>;
  searchVerificationCallTask?: Maybe<VerificationCallTaskProjection>;
  searchWarranty?: Maybe<PageDto_RetailWarrantyProjection>;
  searchWarrantyCarPricePriceModifier?: Maybe<PageDto_WarrantyCarPricePriceModifierProjection>;
  searchWarrantyCarYearsPriceModifier?: Maybe<PageDto_WarrantyCarYearsPriceModifierProjection>;
  /** @deprecated Use new query searchWarranty */
  searchWarrantyProducts?: Maybe<PageDto_RetailWarrantyProjection>;
  searchYouSignSignatureRequests?: Maybe<PageDto_RetailYouSignSignatureRequestProjection>;
  secondaryWheelsUpsellOptions?: Maybe<Array<Maybe<RetailAdSecondaryWheelsProjection>>>;
  sellerAddress?: Maybe<SellerAddressProjection>;
  servicesPerType?: Maybe<Scalars['Map_RefurbishmentServiceType_List_RefurbishmentServiceNameScalar']>;
  surveyScore?: Maybe<RetailSurveyScoreProjection>;
  surveyScores?: Maybe<Array<Maybe<RetailSurveyScoreProjection>>>;
  tasksManagerSearchOptions?: Maybe<TasksManagerSearchOptionsProjection>;
  tasksSearchOptions?: Maybe<TasksSearchOptionsProjection>;
  taxAdvantage?: Maybe<RetailAdTaxAdvantageProjection>;
  tradeInUpsellOptions?: Maybe<RetailTradeInUpSellOptionsProjection>;
  trustpilotInvitation?: Maybe<RetailTrustpilotInvitationProjection>;
  trustpilotReview?: Maybe<RetailTrustpilotCustomerReviewProjection>;
  unidentifiedPaymentById?: Maybe<RetailUnidentifiedPaymentProjection>;
  user?: Maybe<UserProjection>;
  userNotifications?: Maybe<PageDto_RetailBackofficeNotificationProjection>;
  userOrdersInfoForVerification?: Maybe<UserOrdersInfoForVerificationProjection>;
  vehicleByVin?: Maybe<VehicleInfoProjection>;
  verificationCallSurvey?: Maybe<SurveyAggregationProjection>;
  verificationCallSurveyAnswers?: Maybe<SurveyAnsweredQuestionsProjection>;
  verificationDocumentFields?: Maybe<VerificationDocumentFieldsProjection>;
  warranty?: Maybe<RetailWarrantyProjection>;
  warrantyUpsellOptions?: Maybe<Array<Maybe<RetailWarrantyProjection>>>;
};


/** Query root */
export type QueryAbTestingExperimentArgs = {
  environment?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Query root */
export type QueryAbTestingExperimentCountersArgs = {
  environment?: InputMaybe<Scalars['String']>;
  experimentId: Scalars['UUID'];
};


/** Query root */
export type QueryAbTestingExperimentsArgs = {
  environment?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryActivePreCheckoutFinancingArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryActualFinancingBillingAddressArgs = {
  financingApplicationId: Scalars['UUID'];
};


/** Query root */
export type QueryActualSepaMandateArgs = {
  financingApplicationId: Scalars['UUID'];
};


/** Query root */
export type QueryAdArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryAdByStockNumberArgs = {
  stockNumber: Scalars['String'];
};


/** Query root */
export type QueryAdDetailByRetailAdIdArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryAdEquipmentArgs = {
  adId: Scalars['UUID'];
  locale: Scalars['String'];
};


/** Query root */
export type QueryAdVideoPresignedUrlsArgs = {
  request: BatchProjection_AwsS3AdVideoPresignedUrlRequestDtoInput;
};


/** Query root */
export type QueryAiSettingsQualityCheckArgs = {
  contextQuestionsLimit: Scalars['Int'];
  country: Scalars['String'];
  similarityScoreThreshold: Scalars['Int'];
};


/** Query root */
export type QueryAllowedClaimStatesArgs = {
  claimId: Scalars['UUID'];
};


/** Query root */
export type QueryAttachableTradeInsArgs = {
  auxUserId: Scalars['UUID'];
};


/** Query root */
export type QueryAvailabilityRuleArgs = {
  productId?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryBaseProductArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryBatchPriceUpdateValidationSummaryArgs = {
  dataImportId?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryBuildImageUploadCredentialsArgs = {
  awsUploadCredentialsRequestProjection: AwsUploadCredentialsRequestProjectionInput;
};


/** Query root */
export type QueryCarRegistrationActionsArgs = {
  orderUuid: Scalars['UUID'];
};


/** Query root */
export type QueryCarRegistrationProductArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryCheckoutPaymentDetailsArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryClaimOrderDetailsArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryCompiledEmailTemplateArgs = {
  compileOrderEmailTemplateProjection?: InputMaybe<CompileOrderEmailTemplateProjectionInput>;
};


/** Query root */
export type QueryCompleteAiQuestionArgs = {
  question: CompleteQuestionRequestProjectionInput;
};


/** Query root */
export type QueryCreditScoreDataByOrderFinancingArgs = {
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryCreditScoreDataByOrderFinancingV2Args = {
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryCustomerAddressArgs = {
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Query root */
export type QueryDeliveryRouteArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryDigitalCarRegistrationArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryDigitalCarRegistrationByOrderIdArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryDigitalHandoverComplementaryArgs = {
  orderId: Scalars['UUID'];
  vin: Scalars['String'];
};


/** Query root */
export type QueryDistributeCreditNotesRefundsArgs = {
  refundsDistribute: RetailOrderRefundsDistributeProjectionInput;
};


/** Query root */
export type QueryDistributeRefundsArgs = {
  refundsDistribute: RetailOrderRefundsDistributeProjectionInput;
};


/** Query root */
export type QueryDistributeRefundsByOrderItemsArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryDocumentCategoriesArgs = {
  country: Scalars['String'];
  topCategory: Scalars['String'];
};


/** Query root */
export type QueryDocumentInventoryItemLocationsArgs = {
  filter: DocumentInventoryItemLocationFilterInput;
};


/** Query root */
export type QueryDocumentInventoryItemsArgs = {
  filter: DocumentInventoryItemsFilterInput;
};


/** Query root */
export type QueryDocumentInventoryPackageArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryDocumentInventoryPackagesArgs = {
  filter?: InputMaybe<DocumentInventoryPackageFilterInput>;
};


/** Query root */
export type QueryDocumentShippingDeliveryAddressesArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryDownPaymentArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryEsSearchOrdersArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QueryExitCheckCommentsArgs = {
  orderNumber: Scalars['String'];
};


/** Query root */
export type QueryExperianDataByOrderFinancingArgs = {
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryExportSectionArgs = {
  adId: Scalars['UUID'];
};


/** Query root */
export type QueryExtendedTradeInsForUserArgs = {
  auxUserId: Scalars['UUID'];
  country?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryExternalFinancingCustomerDetailsArgs = {
  customerId: Scalars['UUID'];
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryExternalFinancingEvaluationArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryExternalFinancingLeasingDetailsArgs = {
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryExternalFinancingOptionsArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryExternalFinancingScoresArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryExternalRiskUserInfoArgs = {
  borrowerType: Scalars['String'];
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryFinanceRiskEvaluationArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryFinanceRiskEvaluationByOrderArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryFinanceRiskEvaluationByOrderFinancingArgs = {
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryFinancingApplicationArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryFinancingCalculatorArgs = {
  adId: Scalars['UUID'];
  contractType?: InputMaybe<Scalars['String']>;
  financingAmount?: InputMaybe<Scalars['Long']>;
  financingPeriod?: InputMaybe<Scalars['Int']>;
  financingType?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  withDiscount?: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryFinancingPlanPdfDataArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryFullFtsDataByOrderFinancingArgs = {
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryGetAllValidationsArgs = {
  request: SearchProjection2Input;
};


/** Query root */
export type QueryGetAutomaticVerificationArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryGetBranchEntryCheckCarSupportedArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryGetBranchEntryCheckComplementaryArgs = {
  stockNumber: Scalars['String'];
  vin: Scalars['String'];
};


/** Query root */
export type QueryGetBranchEntryCheckDetailsArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryGetClaimsChangelogArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QueryGetCustomerCreditScoreArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryGetHandoverArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryGetHmcsDataArgs = {
  financingApplicationId: Scalars['UUID'];
};


/** Query root */
export type QueryGetLiveChatAgentDataArgs = {
  request?: InputMaybe<ChatbotLiveChatAgentDataRequestProjectionInput>;
};


/** Query root */
export type QueryGetLiveChatAgentTeamsArgs = {
  locale: Scalars['String'];
};


/** Query root */
export type QueryGetLiveChatAgentUnreadChatsCounterArgs = {
  boUserId?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryGetLiveChatAgentsArgs = {
  locale: Scalars['String'];
};


/** Query root */
export type QueryGetLiveChatAnswerTemplatesForAgentArgs = {
  agentId: Scalars['UUID'];
  locale: Scalars['String'];
};


/** Query root */
export type QueryGetLiveChatHistoryArgs = {
  request?: InputMaybe<DialogHistoryRequestProjectionInput>;
};


/** Query root */
export type QueryGetOrderAutoCancellationArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryGetOutboundPaymentOptionsArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryGetReferralStatusesArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryGetRefurbishmentTypesArgs = {
  retailAdId: Scalars['UUID'];
};


/** Query root */
export type QueryGetRetailClaimByIdArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryGetSubProductsArgs = {
  productId: Scalars['UUID'];
};


/** Query root */
export type QueryGetVatRateByCountryArgs = {
  countryCode: Scalars['String'];
};


/** Query root */
export type QueryGetVerificationSurveyAnsweredQuestionsArgs = {
  locale: Scalars['String'];
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryGetVoucherDataArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryGroupArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryHighlightsArgs = {
  adId: Scalars['UUID'];
  locale?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryHighlightsSuggestionsArgs = {
  amount?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryImportedAdArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
  stockNumber: Scalars['String'];
};


/** Query root */
export type QueryInternalTransferPaymentByIdArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryIsAppointmentBookingApplicableArgs = {
  appointmentType: Scalars['String'];
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryIsProductDeRegTempRequiredArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryIsRevertOrderCancellationSupportedArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryKbaRequestDumpUrlArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryLatestFinancingBillingAddressArgs = {
  financingApplicationId: Scalars['UUID'];
};


/** Query root */
export type QueryLatestSepaMandateArgs = {
  financingApplicationId: Scalars['UUID'];
};


/** Query root */
export type QueryLicensePlateByRetailAdIdArgs = {
  adId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryOrderAddressesArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderCancellationOptionsArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderComplianceCheckCaseArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderContractArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderCountsByExternalIdsArgs = {
  id: Array<InputMaybe<Scalars['UUID']>>;
};


/** Query root */
export type QueryOrderCustomerNotesArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderDebtsCsvDataArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QueryOrderDeliveryArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderEmailTemplatesArgs = {
  market: Scalars['String'];
  project: Scalars['String'];
};


/** Query root */
export type QueryOrderFinanceRejectReasonsArgs = {
  filters?: InputMaybe<FinanceRejectReasonFiltersInput>;
};


/** Query root */
export type QueryOrderFinancingArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderFinancingPlansHistoryArgs = {
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderIncludedInAbTestingExperimentArgs = {
  environment?: InputMaybe<Scalars['String']>;
  experimentName: Scalars['String'];
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderItemsOptionsArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryOrderVerificationPriorityScoringResultsArgs = {
  adId: Scalars['UUID'];
  orderId?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryOrdersByAdIdArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryPlainSearchBaseProductsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QueryPlainSearchCarRegistrationProductsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QueryPlainSearchDownPaymentProductsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QueryPlainSearchFeeProductsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QueryPlainSearchInsuranceProductsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QueryPlainSearchVehiclePreparationProductsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QueryPlainSearchWarrantyProductsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QueryPnlItemArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryPnlItemChangelogArgs = {
  retailAdId: Scalars['UUID'];
  search: SearchProjection2Input;
};


/** Query root */
export type QueryPnlItemTypeArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryPnlPartnerArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryPollValidationStatusArgs = {
  request: PollValidationStatusProjectionInput;
};


/** Query root */
export type QueryPresignedUrlsArgs = {
  request: BatchProjection_AwsS3PresignedUrlRequestDtoInput;
};


/** Query root */
export type QueryProductArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryProductPartnersArgs = {
  category: Scalars['String'];
  country: Scalars['String'];
  type: Scalars['String'];
};


/** Query root */
export type QueryProductTypesArgs = {
  category: Scalars['String'];
  country: Scalars['String'];
};


/** Query root */
export type QueryRecentOrderFinancingArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryRefundsWithCreditNotesDistributeArgs = {
  refundsDistribute: RetailOrderRefundsDistributeProjectionInput;
};


/** Query root */
export type QueryRefurbishmentArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRefurbishmentByRefIdArgs = {
  refId: Scalars['String'];
};


/** Query root */
export type QueryRefurbishmentCommentsByRefurbishmentIdArgs = {
  refurbishmentId: Scalars['UUID'];
};


/** Query root */
export type QueryRefurbishmentPartnersArgs = {
  search: PageRequestProjectionInput;
};


/** Query root */
export type QueryRefurbishmentsByRetailAdIdArgs = {
  retailAdId: Scalars['UUID'];
};


/** Query root */
export type QueryRefurbishmentsOverviewArgs = {
  search: PageableRequestProjection_RefurbishmentAggregateSearchDtoInput;
};


/** Query root */
export type QueryRetailAccountDetailsForPaymentArgs = {
  country: Scalars['String'];
  orderId: Scalars['UUID'];
  paymentId?: InputMaybe<Scalars['UUID']>;
  paymentType: Scalars['String'];
};


/** Query root */
export type QueryRetailAdArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRetailAdByAdIdArgs = {
  adId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailAdDamageArgs = {
  adId?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryRetailAdDeliveryOptionsArgs = {
  auxUserId?: InputMaybe<Scalars['UUID']>;
  branchDeliveryOptionRequest?: InputMaybe<BranchDeliveryOptionRequestDtoInput>;
  homeDeliveryOptionRequest?: InputMaybe<HomeDeliveryOptionRequestDtoInput>;
  orderId?: InputMaybe<Scalars['UUID']>;
  retailAdId: Scalars['UUID'];
  retailCountry?: InputMaybe<Scalars['String']>;
  tradeInCompatibleOnly?: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryRetailAdPnlDataArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRetailAdPriceHistoryArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  retailAdId?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryRetailAdPublishingBlockersArgs = {
  retailAdId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailAdSecondaryWheelsArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRetailAddressArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRetailCarDetailsPlainByStockNumberArgs = {
  stockNumber: Scalars['String'];
};


/** Query root */
export type QueryRetailCheckoutArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailClaimCostCompensationsByOrderIdArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailCustomerArgs = {
  orderId: Scalars['UUID'];
  type?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryRetailCustomersByOrderIdsArgs = {
  orderIds: Array<InputMaybe<Scalars['UUID']>>;
};


/** Query root */
export type QueryRetailDiscountItemsByOrderIdArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailDiscountOptionsArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailFeatureArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRetailFeatureStatusV2Args = {
  context: RetailFeatureStatusContextDtoInput;
  name: Scalars['String'];
};


/** Query root */
export type QueryRetailFeaturesArgs = {
  context: RetailFeatureStatusContextDtoInput;
};


/** Query root */
export type QueryRetailFeaturesStatusV2Args = {
  context: RetailFeatureStatusContextDtoInput;
  names: Array<InputMaybe<Scalars['String']>>;
};


/** Query root */
export type QueryRetailFeeArgs = {
  feeId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailFeeOptionsArgs = {
  request: RetailFeeOptionsRequestProjectionInput;
};


/** Query root */
export type QueryRetailFtsRiskDataByOrderFinancingArgs = {
  orderFinancingId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailInsuranceArgs = {
  insuranceId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailLeadArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRetailOrderReturnAppointmentArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryRetailPaymentArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRetailTypedAccountDetailsForPaymentArgs = {
  country: Scalars['String'];
  orderId: Scalars['UUID'];
  paymentId?: InputMaybe<Scalars['UUID']>;
  paymentType: Scalars['String'];
};


/** Query root */
export type QueryRetailVehiclePreparationArgs = {
  vehiclePreparationId: Scalars['UUID'];
};


/** Query root */
export type QueryRoleArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRoleGroupsArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRoleUsersArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QuerySalesScoreboardConfigArgs = {
  request: RetailSalesScoreboardConfigRequestProjectionInput;
};


/** Query root */
export type QuerySearchAbTestingExperimentsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchAbTestingParticipantsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchAdsByChannelAndSourceIdsArgs = {
  channel: Scalars['String'];
  sourceIds: Array<InputMaybe<Scalars['String']>>;
};


/** Query root */
export type QuerySearchAdsByIdsArgs = {
  ids: Array<InputMaybe<Scalars['UUID']>>;
};


/** Query root */
export type QuerySearchAiSettingsArgs = {
  search: AiSettingsSearchProjectionInput;
};


/** Query root */
export type QuerySearchAvailabilityRulesArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchAxaPolicyNotificationsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchBranchEntryChecksArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchBranchesArgs = {
  search: PageRequestProjectionInput;
};


/** Query root */
export type QuerySearchBranchesForSelectionArgs = {
  search: BranchForSelectionSearchProjectionInput;
};


/** Query root */
export type QuerySearchCarRegistrationArgs = {
  search: RetailCarRegistrationSearchProjectionInput;
};


/** Query root */
export type QuerySearchCarRegistrationProductArgs = {
  priceModifiers?: InputMaybe<Scalars['Map_String_PriceModifierPayloadScalar']>;
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchCarTypeBasedPriceModifierArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchChatbotQuestionsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchClaimAssigneeUsersArgs = {
  countries: Array<InputMaybe<Scalars['String']>>;
};


/** Query root */
export type QuerySearchCreditNotesArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchCustomerUsersArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchDigitalCarRegistrationQaDataArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchDigitalCarRegistrationsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchDiscountsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchDocumentRequestsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchDocumentsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchExternalFinancingLeasingCompaniesArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchFeeProductsArgs = {
  search: RetailFeeSearchProjectionInput;
};


/** Query root */
export type QuerySearchFinancingsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchFrRegionalTaxBasedPriceModifierArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchGroupsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchItPowerAndZoneBasedPriceModifierArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchImageTagsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchInsuranceArgs = {
  search: RetailInsuranceSearchProjectionInput;
};


/** Query root */
export type QuerySearchInternalTransferPaymentsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchInvoicesArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchKbaRequestHistoryArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchLiveChatAgentAnswerTemplateArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchLiveChatAgentsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchOrderAndTaskCommentsArgs = {
  financingApplicationId: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


/** Query root */
export type QuerySearchOrderChangelogsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchOrderCommentsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchOrderDebtsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchOrderFinancingByOrderArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QuerySearchOrderFinancingHistoryArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchOrderHistoryEventsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchOrderTasksArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchOrdersArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchOutboundPaymentArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchPnlItemTypesArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchPnlItemsArgs = {
  currencyCode?: InputMaybe<Scalars['String']>;
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchPnlPartnersArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchProductChangelogsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchProductPriceModifierInstancesArgs = {
  productId: Scalars['UUID'];
};


/** Query root */
export type QuerySearchProductsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRefundsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRefurbishmentEtAsByRetailAdIdArgs = {
  retailAdId: Scalars['UUID'];
};


/** Query root */
export type QuerySearchRefurbishmentExitChecksArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRefurbishmentsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailAdPnlDataArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailAdsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailClaimsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailDataImportArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailDataImportsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailFeatureChangelogsArgs = {
  id: Scalars['UUID'];
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailFeaturesArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailLeadArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailPaymentsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRetailUnidentifiedPaymentsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchRolesArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchSalesScoreboardResultsArgs = {
  search: RetailSalesScoreboardSearchProjectionInput;
};


/** Query root */
export type QuerySearchTaskManagerTaskCommentsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchTaskManagerTasksArgs = {
  search: CamundaSearchProjection2Input;
};


/** Query root */
export type QuerySearchTradeInByStockNumberArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
  stockNumber: Scalars['String'];
};


/** Query root */
export type QuerySearchUsersArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchVehiclePreparationArgs = {
  search: RetailVehiclePreparationSearchProjectionInput;
};


/** Query root */
export type QuerySearchVehiclePreparationProductArgs = {
  priceModifiers?: InputMaybe<Scalars['Map_String_PriceModifierPayloadScalar']>;
  search?: InputMaybe<SearchProjection2Input>;
};


/** Query root */
export type QuerySearchVerificationCallTaskArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QuerySearchWarrantyArgs = {
  search: RetailWarrantySearchProjectionInput;
};


/** Query root */
export type QuerySearchWarrantyCarPricePriceModifierArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchWarrantyCarYearsPriceModifierArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchWarrantyProductsArgs = {
  ad_id?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['String']>;
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySearchYouSignSignatureRequestsArgs = {
  search: SearchProjection2Input;
};


/** Query root */
export type QuerySecondaryWheelsUpsellOptionsArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QuerySellerAddressArgs = {
  addressId: Scalars['UUID'];
  sellerId: Scalars['UUID'];
};


/** Query root */
export type QuerySurveyScoreArgs = {
  extId: Scalars['UUID'];
  extType: Scalars['String'];
  surveyType: Scalars['String'];
};


/** Query root */
export type QuerySurveyScoresArgs = {
  extId: Scalars['UUID'];
  extType: Scalars['String'];
  surveyTypes: Array<InputMaybe<Scalars['String']>>;
};


/** Query root */
export type QueryTaxAdvantageArgs = {
  retailAdId: Scalars['UUID'];
};


/** Query root */
export type QueryTradeInUpsellOptionsArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryTrustpilotInvitationArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryTrustpilotReviewArgs = {
  orderId: Scalars['UUID'];
};


/** Query root */
export type QueryUnidentifiedPaymentByIdArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryUserArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryUserNotificationsArgs = {
  locale: Scalars['String'];
  search: SearchProjection2Input;
};


/** Query root */
export type QueryUserOrdersInfoForVerificationArgs = {
  auxUserId: Scalars['UUID'];
};


/** Query root */
export type QueryVehicleByVinArgs = {
  vin: Scalars['String'];
};


/** Query root */
export type QueryVerificationCallSurveyArgs = {
  locale: Scalars['String'];
  orderId: Scalars['UUID'];
  templateType: Scalars['String'];
};


/** Query root */
export type QueryVerificationCallSurveyAnswersArgs = {
  locale: Scalars['String'];
  orderId: Scalars['UUID'];
  templateType: Scalars['String'];
};


/** Query root */
export type QueryVerificationDocumentFieldsArgs = {
  customerId: Scalars['UUID'];
};


/** Query root */
export type QueryWarrantyArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryWarrantyUpsellOptionsArgs = {
  orderId: Scalars['UUID'];
};

export type RadioDtoInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type RangeDto_Integer = {
  __typename?: 'RangeDTO_Integer';
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

export type ReferralPaymentProjection = {
  __typename?: 'ReferralPaymentProjection';
  orderId?: Maybe<Scalars['UUID']>;
  referralType?: Maybe<Scalars['String']>;
};

export type ReferralProjection = {
  __typename?: 'ReferralProjection';
  accountingDate?: Maybe<Scalars['String']>;
  auxUserId?: Maybe<Scalars['UUID']>;
  canceled?: Maybe<Scalars['Boolean']>;
  connectedOrderId?: Maybe<Scalars['UUID']>;
  connectedOrderNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  referralStatus?: Maybe<Scalars['String']>;
  reward?: Maybe<Scalars['Int']>;
};

export type RefundDistributionProjection = {
  __typename?: 'RefundDistributionProjection';
  accountDetails?: Maybe<RetailAccountDetailsCreateProjection>;
  amount?: Maybe<RetailFinancePriceProjection>;
  tempId?: Maybe<Scalars['Int']>;
  typedAccountDetails?: Maybe<Scalars['Map_String_ObjectScalar']>;
};

export type RefundDistributionProjectionInput = {
  accountDetails?: InputMaybe<RetailAccountDetailsCreateProjectionInput>;
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  tempId?: InputMaybe<Scalars['Int']>;
  typedAccountDetails?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
};

export type RefundPendingTaskProjection = {
  __typename?: 'RefundPendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  cancellationReasonComment?: Maybe<Scalars['String']>;
  cashOutReason?: Maybe<Scalars['String']>;
  cashOutRequestDate?: Maybe<Scalars['String']>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refundAmount?: Maybe<PriceDto>;
  refundAmountOnTaskCreation?: Maybe<PriceDto>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type RefurbishmentAggregateProjection = {
  __typename?: 'RefurbishmentAggregateProjection';
  adId?: Maybe<Scalars['UUID']>;
  beautyShot?: Maybe<Scalars['String']>;
  beautyShotsTakenOn?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['Int']>;
  branchName?: Maybe<Scalars['String']>;
  cancelDescription?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  completedReason?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  daysInCurrentStatus?: Maybe<Scalars['Int']>;
  daysSinceCarArrivedInWorkshop?: Maybe<Scalars['String']>;
  daysSincePreparationStarted?: Maybe<Scalars['String']>;
  estimatedCompleteDate?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<RefurbishmentFlowVersion>;
  id?: Maybe<Scalars['UUID']>;
  lastStatusUpdatedOn?: Maybe<Scalars['String']>;
  maximumBudgetMinorUnits?: Maybe<Scalars['Long']>;
  modelInfo?: Maybe<ModelDto>;
  notificationCount?: Maybe<Scalars['Map_RetailNotificationEntityType_IntegerScalar']>;
  partnerId?: Maybe<Scalars['Int']>;
  partnerName?: Maybe<Scalars['String']>;
  picturesReceivedOn?: Maybe<Scalars['String']>;
  picturesUploadedOn?: Maybe<Scalars['String']>;
  providerEstimatedCompleteDate?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['String']>;
  refurbishmentLocation?: Maybe<Scalars['String']>;
  refurbishmentPartner?: Maybe<Scalars['String']>;
  refurbishmentType?: Maybe<Scalars['String']>;
  retailAdId?: Maybe<Scalars['UUID']>;
  retailReady?: Maybe<Scalars['Boolean']>;
  retailStockCountry?: Maybe<Scalars['String']>;
  sourcingCountry?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  totalCostBudgetMinorUnits?: Maybe<Scalars['Long']>;
  updatedOn?: Maybe<Scalars['String']>;
  videoUploadedOn?: Maybe<Scalars['String']>;
  vinNumber?: Maybe<Scalars['String']>;
  wheelSetDeleted?: Maybe<Scalars['Boolean']>;
  wheelSetDeletedReason?: Maybe<WheelSetDeleteReason>;
};

export type RefurbishmentAggregateSearchDtoInput = {
  branchIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  countries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  refId?: InputMaybe<Scalars['String']>;
  refurbishmentCancelReasons?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  refurbishmentCompletedReasons?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  refurbishmentStates?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  refurbishmentTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  retailReady?: InputMaybe<Scalars['Boolean']>;
  stockNumber?: InputMaybe<Scalars['String']>;
  vinNumber?: InputMaybe<Scalars['String']>;
};

export type RefurbishmentCommentCreateProjectionInput = {
  comment?: InputMaybe<Scalars['String']>;
  refurbishmentId?: InputMaybe<Scalars['UUID']>;
};

export type RefurbishmentCommentProjection = {
  __typename?: 'RefurbishmentCommentProjection';
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  refurbishmentId?: Maybe<Scalars['UUID']>;
  refurbishmentTransitionId?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RefurbishmentCreateProjectionInput = {
  beautyShotsTakenOn?: InputMaybe<Scalars['String']>;
  branchId?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['Int']>;
  picturesReceivedOn?: InputMaybe<Scalars['String']>;
  picturesUploadedOn?: InputMaybe<Scalars['String']>;
  refurbishmentType?: InputMaybe<Scalars['String']>;
  retailAdId?: InputMaybe<Scalars['UUID']>;
};

export type RefurbishmentDto = {
  __typename?: 'RefurbishmentDTO';
  adDetails?: Maybe<AdDetailsProjection>;
  beautyShot?: Maybe<Scalars['String']>;
  beautyShotsTakenOn?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['Int']>;
  branchName?: Maybe<Scalars['String']>;
  branches?: Maybe<Array<Maybe<BranchProjection>>>;
  cancelDescription?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  carPosition?: Maybe<Scalars['String']>;
  completedReason?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  currentPartnerWorkshopConnectionId?: Maybe<Scalars['UUID']>;
  daysInCurrentStatus?: Maybe<Scalars['Int']>;
  daysSinceCarArrivedInWorkshop?: Maybe<Scalars['String']>;
  daysSincePreparationStarted?: Maybe<Scalars['String']>;
  defaultPartnerWorkshopConnectionId?: Maybe<Scalars['UUID']>;
  documents?: Maybe<Array<Maybe<RetailDocumentProjection>>>;
  estimatedCompleteDate?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<RefurbishmentFlowVersion>;
  id?: Maybe<Scalars['UUID']>;
  isAutoOrdered?: Maybe<Scalars['Boolean']>;
  keysCount?: Maybe<Scalars['Int']>;
  lastStatusUpdatedOn?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  maximumBudgetMinorUnits?: Maybe<Scalars['Long']>;
  minimalGrossProfitMinorUnits?: Maybe<Scalars['Long']>;
  partnerId?: Maybe<Scalars['Int']>;
  partnerName?: Maybe<Scalars['String']>;
  partnerWorkshopConnections?: Maybe<Array<Maybe<PartnerWorkshopConnectionDto>>>;
  partners?: Maybe<Array<Maybe<RefurbishmentPartnerDto>>>;
  picturesReceivedOn?: Maybe<Scalars['String']>;
  picturesUploadedOn?: Maybe<Scalars['String']>;
  pricingBudgetCostMinorUnits?: Maybe<Scalars['Long']>;
  providerEstimatedCompleteDate?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['String']>;
  refurbishmentDamages?: Maybe<RefurbishmentDamagesProjection>;
  refurbishmentServices?: Maybe<Array<Maybe<RefurbishmentServiceDto>>>;
  refurbishmentSparePartStatus?: Maybe<Scalars['String']>;
  refurbishmentType?: Maybe<Scalars['String']>;
  retailAdId?: Maybe<Scalars['UUID']>;
  retailReady?: Maybe<Scalars['Boolean']>;
  serviceBookPresent?: Maybe<Scalars['Boolean']>;
  serviceBookType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetGrossProfitMinorUnits?: Maybe<Scalars['Long']>;
  totalCostBudgetIncludingInternalLaborMinorUnits?: Maybe<Scalars['Long']>;
  totalCostBudgetMinorUnits?: Maybe<Scalars['Long']>;
  transitions?: Maybe<Array<Maybe<RefurbishmentTransitionDto>>>;
  updatedOn?: Maybe<Scalars['String']>;
  videoUploadedOn?: Maybe<Scalars['String']>;
  vinNumber?: Maybe<Scalars['String']>;
  wheelSetDeleted?: Maybe<Scalars['Boolean']>;
  wheelSetDeletedReason?: Maybe<WheelSetDeleteReason>;
};

export type RefurbishmentDamageImageProjection = {
  __typename?: 'RefurbishmentDamageImageProjection';
  fullUrl?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['Int']>;
};

export type RefurbishmentDamageProjection = {
  __typename?: 'RefurbishmentDamageProjection';
  area?: Maybe<Scalars['String']>;
  areaTranslation?: Maybe<Scalars['String']>;
  customerDisplay?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  image?: Maybe<RefurbishmentDamageImageProjection>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  part?: Maybe<Scalars['String']>;
  partTranslation?: Maybe<Scalars['String']>;
  refurbishmentDamageService?: Maybe<RefurbishmentServiceDto>;
  refurbishmentId?: Maybe<Scalars['UUID']>;
  service?: Maybe<RefurbishmentServiceDto>;
  severities?: Maybe<Array<Maybe<Scalars['String']>>>;
  sourceType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subArea?: Maybe<Scalars['String']>;
  subAreaTranslation?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeTranslation?: Maybe<Scalars['String']>;
};

export type RefurbishmentDamagesProjection = {
  __typename?: 'RefurbishmentDamagesProjection';
  damages?: Maybe<Array<Maybe<RefurbishmentDamageProjection>>>;
};

export type RefurbishmentEtaListProjection = {
  __typename?: 'RefurbishmentEtaListProjection';
  etaList?: Maybe<Array<Maybe<RefurbishmentEtaProjection>>>;
};

export type RefurbishmentEtaProjection = {
  __typename?: 'RefurbishmentEtaProjection';
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  eta?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  refurbishmentId?: Maybe<Scalars['UUID']>;
};

export enum RefurbishmentFlowVersion {
  V1 = 'V1',
  V2 = 'V2'
}

export type RefurbishmentPartnerDto = {
  __typename?: 'RefurbishmentPartnerDTO';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdOn?: Maybe<Scalars['String']>;
  email1?: Maybe<Scalars['String']>;
  email2?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  partnerEmail?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  plz?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type RefurbishmentProjection = {
  __typename?: 'RefurbishmentProjection';
  beautyShotsTakenOn?: Maybe<Scalars['String']>;
  branchData?: Maybe<BranchProjection>;
  cancelDescription?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  completedReason?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  daysInCurrentStatus?: Maybe<Scalars['Int']>;
  daysSinceCarArrivedInWorkshop?: Maybe<Scalars['String']>;
  daysSincePreparationStarted?: Maybe<Scalars['String']>;
  estimatedCompleteDate?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<RefurbishmentFlowVersion>;
  id?: Maybe<Scalars['UUID']>;
  lastStatusUpdatedOn?: Maybe<Scalars['String']>;
  maximumBudget?: Maybe<PriceDto>;
  maximumBudgetMinorUnits?: Maybe<Scalars['Long']>;
  partnerData?: Maybe<RefurbishmentPartnerDto>;
  picturesReceivedOn?: Maybe<Scalars['String']>;
  picturesUploadedOn?: Maybe<Scalars['String']>;
  providerEstimatedCompleteDate?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['String']>;
  refurbishmentType?: Maybe<Scalars['String']>;
  retailAdId?: Maybe<Scalars['UUID']>;
  retailReady?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  totalCostBudget?: Maybe<PriceDto>;
  totalCostBudgetMinorUnits?: Maybe<Scalars['Long']>;
};

export type RefurbishmentServiceCreateProjectionInput = {
  budgetMinorUnits?: InputMaybe<Scalars['Long']>;
  comment?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  retailAdDamageId?: InputMaybe<Scalars['UUID']>;
  serviceName?: InputMaybe<Scalars['String']>;
  serviceType?: InputMaybe<Scalars['String']>;
  sourceType?: InputMaybe<Scalars['String']>;
};

export type RefurbishmentServiceDto = {
  __typename?: 'RefurbishmentServiceDTO';
  budgetMinorUnits?: Maybe<Scalars['Long']>;
  comment?: Maybe<Scalars['String']>;
  consumableCostMinorUnits?: Maybe<Scalars['Long']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  isAutoCreate?: Maybe<Scalars['Boolean']>;
  isDoneBeforeEntryCheck?: Maybe<Scalars['Boolean']>;
  laborRateMinorUnits?: Maybe<Scalars['Long']>;
  laborTimeHours?: Maybe<Scalars['Float']>;
  partnerWorkshopConnectionId?: Maybe<Scalars['UUID']>;
  refurbishmentDamageId?: Maybe<Scalars['UUID']>;
  refurbishmentId?: Maybe<Scalars['UUID']>;
  refurbishmentTransitionId?: Maybe<Scalars['UUID']>;
  relatedServiceId?: Maybe<Scalars['UUID']>;
  retailAdDamageId?: Maybe<Scalars['UUID']>;
  serviceName?: Maybe<Scalars['String']>;
  serviceType?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
  sparePart?: Maybe<SparePartDto>;
  sparePartCostMinorUnits?: Maybe<Scalars['Long']>;
  state?: Maybe<Scalars['String']>;
  stateComment?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RefurbishmentServiceUpdateProjectionInput = {
  budgetMinorUnits?: InputMaybe<Scalars['Long']>;
  comment?: InputMaybe<Scalars['String']>;
  serviceName?: InputMaybe<Scalars['String']>;
  sourceType?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type RefurbishmentTransitionDto = {
  __typename?: 'RefurbishmentTransitionDTO';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  refurbishmentId?: Maybe<Scalars['UUID']>;
  sourceType?: Maybe<Scalars['String']>;
  stateFrom?: Maybe<Scalars['String']>;
  stateTo?: Maybe<Scalars['String']>;
  transitionDate?: Maybe<Scalars['String']>;
};

export type RefurbishmentUpdateProjectionInput = {
  beautyShotsTakenOn?: InputMaybe<Scalars['String']>;
  branchId?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  estimatedCompleteDate?: InputMaybe<Scalars['String']>;
  maximumBudgetMinorUnits?: InputMaybe<Scalars['Long']>;
  partnerId?: InputMaybe<Scalars['Int']>;
  picturesReceivedOn?: InputMaybe<Scalars['String']>;
  picturesUploadedOn?: InputMaybe<Scalars['String']>;
  retailReady?: InputMaybe<Scalars['Boolean']>;
  videoUploadedOn?: InputMaybe<Scalars['String']>;
};

export type RefurbishmentUpdateServicesProjectionInput = {
  refurbishmentServiceUpdateProjection?: InputMaybe<RefurbishmentServiceUpdateProjectionInput>;
  serviceId?: InputMaybe<Scalars['UUID']>;
};

export enum RegistrationUserSource {
  AhApp = 'AH_APP',
  AhBackofficeCheckout = 'AH_BACKOFFICE_CHECKOUT',
  AhBudgetCalculator = 'AH_BUDGET_CALCULATOR',
  AhCheckout = 'AH_CHECKOUT',
  AhComingSoonAdSubscribe = 'AH_COMING_SOON_AD_SUBSCRIBE',
  AhFacebook = 'AH_FACEBOOK',
  AhFavourites = 'AH_FAVOURITES',
  AhFinanceFlow = 'AH_FINANCE_FLOW',
  AhGeneral = 'AH_GENERAL',
  AhGoogle = 'AH_GOOGLE',
  AhGoogleOneTap = 'AH_GOOGLE_ONE_TAP',
  AhMakretingRealmadridBootcampRaffle = 'AH_MAKRETING_REALMADRID_BOOTCAMP_RAFFLE',
  AhMakretingRealmadridRaffles = 'AH_MAKRETING_REALMADRID_RAFFLES',
  AhMarketingDtm = 'AH_MARKETING_DTM',
  AhMarketingHertha = 'AH_MARKETING_HERTHA',
  AhMarketingPsgRaffle = 'AH_MARKETING_PSG_RAFFLE',
  AhOnlineCheckout = 'AH_ONLINE_CHECKOUT',
  AhPreAccountFavorites = 'AH_PRE_ACCOUNT_FAVORITES',
  AhPreAccountSavedSearch = 'AH_PRE_ACCOUNT_SAVED_SEARCH',
  AhReservedAdSubscribe = 'AH_RESERVED_AD_SUBSCRIBE',
  AhSavedSearch = 'AH_SAVED_SEARCH',
  AhSearch = 'AH_SEARCH',
  AhSellOnly = 'AH_SELL_ONLY',
  AhSellOnlyFunnel = 'AH_SELL_ONLY_FUNNEL',
  AhTradeIn = 'AH_TRADE_IN',
  AhTradeInSelfEvaluation = 'AH_TRADE_IN_SELF_EVALUATION',
  Newsletter = 'NEWSLETTER',
  NewsletterVoucher = 'NEWSLETTER_VOUCHER',
  RetailAdSearchQuestionnaire = 'RETAIL_AD_SEARCH_QUESTIONNAIRE',
  RetailAutoheroCenterAppointment = 'RETAIL_AUTOHERO_CENTER_APPOINTMENT',
  RetailGuestDownPrice = 'RETAIL_GUEST_DOWN_PRICE',
  RetailSurvey = 'RETAIL_SURVEY',
  SimilarCarsNotification = 'SIMILAR_CARS_NOTIFICATION'
}

export type RemainingRefundAmountProjection = {
  __typename?: 'RemainingRefundAmountProjection';
  amountMinorUnits?: Maybe<Scalars['Long']>;
};

export type RepairPartnerDto = {
  __typename?: 'RepairPartnerDTO';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  email1?: Maybe<Scalars['String']>;
  email2?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  partnerEmail?: Maybe<Scalars['String']>;
  partnerType?: Maybe<PartnerType>;
  phone?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  refurbishmentPartnerId?: Maybe<Scalars['Int']>;
};

export type RepeatHappinessCallTaskManagementProjection = {
  __typename?: 'RepeatHappinessCallTaskManagementProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  carHandoverOnDate?: Maybe<Scalars['String']>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderContractSignedOn?: Maybe<Scalars['String']>;
  orderHandoverOn?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  retailCountry?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type RequestBankDetailsForOutboundPaymentProjectionInput = {
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  paymentType?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<RetailOutboundPaymentPurpose>;
};

export type RequestBankDetailsProjectionInput = {
  orderId?: InputMaybe<Scalars['UUID']>;
  refundAmount?: InputMaybe<PriceDtoInput>;
  source?: InputMaybe<OrderAccountDetailsRequestSource>;
};

export type RequestExtFinBankDetailsProjectionInput = {
  orderId?: InputMaybe<Scalars['UUID']>;
};

export type ReschedulingCommentProjectionInput = {
  channel?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
};

export type ResultConnection_DocumentInventoryItem = {
  __typename?: 'ResultConnection_DocumentInventoryItem';
  nodes: Array<Maybe<DocumentInventoryItem>>;
  totalCount: Scalars['Long'];
};

export type ResultConnection_DocumentInventoryPackageProjection = {
  __typename?: 'ResultConnection_DocumentInventoryPackageProjection';
  nodes: Array<Maybe<DocumentInventoryPackageProjection>>;
  totalCount: Scalars['Long'];
};

export type ResultConnection_DocumentLocation = {
  __typename?: 'ResultConnection_DocumentLocation';
  nodes: Array<Maybe<DocumentLocation>>;
  totalCount: Scalars['Long'];
};

export type ResultConnection_ShippingProviderProjection = {
  __typename?: 'ResultConnection_ShippingProviderProjection';
  nodes: Array<Maybe<ShippingProviderProjection>>;
  totalCount: Scalars['Long'];
};

export type RetailAbTestingConditionVariableProjection = {
  __typename?: 'RetailAbTestingConditionVariableProjection';
  type?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  variable?: Maybe<Scalars['String']>;
};

export type RetailAbTestingExperimentCountersProjection = {
  __typename?: 'RetailAbTestingExperimentCountersProjection';
  counters: Array<Maybe<Scalars['Long']>>;
  totalHits: Scalars['Long'];
};

export type RetailAbTestingExperimentProjection = {
  __typename?: 'RetailAbTestingExperimentProjection';
  condition?: Maybe<Scalars['String']>;
  createdBy?: Maybe<AbTestingExperimentAuthor>;
  createdOn?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  environment?: Maybe<Scalars['String']>;
  expireAt?: Maybe<Scalars['String']>;
  gaExperimentId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  multiStep: Scalars['Boolean'];
  name: Scalars['String'];
  nextStepVariants?: Maybe<Array<Maybe<RetailAbTestingNextStepVariantProjection>>>;
  startAt: Scalars['String'];
  trafficPercents: Scalars['Int'];
  updatedBy?: Maybe<AbTestingExperimentAuthor>;
  updatedOn?: Maybe<Scalars['String']>;
  variants?: Maybe<Array<Maybe<RetailAbTestingVariantProjection>>>;
  viewNames: Array<Maybe<Scalars['String']>>;
};

export type RetailAbTestingNextStepVariantProjection = {
  __typename?: 'RetailAbTestingNextStepVariantProjection';
  gaExperimentId?: Maybe<Scalars['String']>;
  gaVariationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  nextStep?: Maybe<Scalars['String']>;
  skipSteps?: Maybe<Array<Maybe<Scalars['String']>>>;
  weightPercents?: Maybe<Scalars['Int']>;
};

export type RetailAbTestingNextStepVariantProjectionInput = {
  gaExperimentId?: InputMaybe<Scalars['String']>;
  gaVariationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  nextStep?: InputMaybe<Scalars['String']>;
  skipSteps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  weightPercents?: InputMaybe<Scalars['Int']>;
};

export type RetailAbTestingParticipantProjection = {
  __typename?: 'RetailAbTestingParticipantProjection';
  adId?: Maybe<Scalars['UUID']>;
  checkoutId?: Maybe<Scalars['UUID']>;
  experimentId: Scalars['UUID'];
  experimentName: Scalars['String'];
  gaExperimentId: Scalars['String'];
  gaVariationId: Scalars['String'];
  orderId?: Maybe<Scalars['UUID']>;
  participatedOn: Scalars['String'];
  userId?: Maybe<Scalars['UUID']>;
  visitorId: Scalars['String'];
};

export type RetailAbTestingVariantProjection = {
  __typename?: 'RetailAbTestingVariantProjection';
  gaExperimentId?: Maybe<Scalars['String']>;
  gaVariationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  viewParams?: Maybe<Scalars['String']>;
  viewVariant?: Maybe<Scalars['String']>;
  weightPercents?: Maybe<Scalars['Int']>;
};

export type RetailAbTestingVariantProjectionInput = {
  gaExperimentId?: InputMaybe<Scalars['String']>;
  gaVariationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  viewParams?: InputMaybe<Scalars['String']>;
  viewVariant?: InputMaybe<Scalars['String']>;
  weightPercents?: InputMaybe<Scalars['Int']>;
};

export type RetailAccessParamDto = {
  __typename?: 'RetailAccessParamDTO';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type RetailAccountDataCreateProjectionInput = {
  bankName?: InputMaybe<Scalars['String']>;
  bic?: InputMaybe<Scalars['String']>;
  blz?: InputMaybe<Scalars['String']>;
  holder?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
};

export type RetailAccountDetailsCreateProjection = {
  __typename?: 'RetailAccountDetailsCreateProjection';
  accountDataId?: Maybe<Scalars['UUID']>;
  bic?: Maybe<Scalars['String']>;
  holder?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
};

export type RetailAccountDetailsCreateProjectionInput = {
  accountDataId?: InputMaybe<Scalars['UUID']>;
  bic?: InputMaybe<Scalars['String']>;
  holder?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
};

export type RetailAccountDetailsForPaymentProjection = {
  __typename?: 'RetailAccountDetailsForPaymentProjection';
  accountType?: Maybe<Scalars['String']>;
  accounts?: Maybe<Array<Maybe<RetailAccountDetailsWithPaymentsProjection>>>;
};

export type RetailAccountDetailsWithPaymentsProjection = {
  __typename?: 'RetailAccountDetailsWithPaymentsProjection';
  payments?: Maybe<Array<Maybe<RetailPaymentWrapperProjection>>>;
  retailAccount?: Maybe<BankAccountDetailsWithDocumentsProjection>;
  source?: Maybe<Scalars['String']>;
  uploadSource?: Maybe<Scalars['String']>;
};

export enum RetailAdDamageCancellationReason {
  IncorrectVehiclePart = 'INCORRECT_VEHICLE_PART',
  NotFound = 'NOT_FOUND'
}

export type RetailAdDamageDto = {
  __typename?: 'RetailAdDamageDTO';
  area?: Maybe<Scalars['String']>;
  cancelComment?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<RetailAdDamageCancellationReason>;
  comment?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  customerDisplay: Scalars['Boolean'];
  damageExtendedId?: Maybe<Scalars['Long']>;
  displayToCustomerFrontend?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  part?: Maybe<Scalars['String']>;
  photoId?: Maybe<Scalars['Int']>;
  retailAdId?: Maybe<Scalars['UUID']>;
  severities?: Maybe<Array<Maybe<Scalars['String']>>>;
  sourceType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subArea?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailAdDamageImageDto = {
  __typename?: 'RetailAdDamageImageDTO';
  displayToCustomerFrontend: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  image?: Maybe<DamageImageDto>;
  part?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RetailAdDamageProjection = {
  __typename?: 'RetailAdDamageProjection';
  area?: Maybe<Scalars['String']>;
  areaTranslation?: Maybe<Scalars['String']>;
  customerDisplay?: Maybe<Scalars['Boolean']>;
  damageService?: Maybe<RefurbishmentServiceDto>;
  id?: Maybe<Scalars['UUID']>;
  image?: Maybe<ImageProjection>;
  isSecondaryWheel?: Maybe<Scalars['Boolean']>;
  part?: Maybe<Scalars['String']>;
  partTranslation?: Maybe<Scalars['String']>;
  retailAdId?: Maybe<Scalars['UUID']>;
  service?: Maybe<RefurbishmentServiceDto>;
  severities?: Maybe<Array<Maybe<Scalars['String']>>>;
  severityTranslations?: Maybe<Array<Maybe<SeverityProjection>>>;
  sourceType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  subArea?: Maybe<Scalars['String']>;
  subAreaTranslation?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeTranslation?: Maybe<Scalars['String']>;
};

export type RetailAdDeliveryAddressDetailsProjection = {
  __typename?: 'RetailAdDeliveryAddressDetailsProjection';
  customerExpectedEta?: Maybe<RetailCustomerExpectedEtaDto>;
  deliveryEta?: Maybe<DeliveryEtaProjection>;
  deliveryOption?: Maybe<Scalars['String']>;
  deliveryPrice?: Maybe<DeliveryPrice>;
  deliveryType?: Maybe<Scalars['String']>;
  estimationId?: Maybe<Scalars['UUID']>;
  homeDeliveryExtraMileage?: Maybe<Scalars['Int']>;
  hubId?: Maybe<Scalars['Int']>;
};

export type RetailAdDeliveryBranchDetailsDto = {
  __typename?: 'RetailAdDeliveryBranchDetailsDTO';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  daysForDelivery?: Maybe<Scalars['Int']>;
  deliveryETADate?: Maybe<Scalars['String']>;
  deliveryEtaRange?: Maybe<DeliveryEtaRangeDto>;
  deliveryEtaType?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  deliveryPrice?: Maybe<DeliveryPrice>;
  deliveryType?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  estimationId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['Int']>;
  logisticsEtaInCalendarDays?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Maybe<Scalars['String']>>>;
  street?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type RetailAdDeliveryBranchDetailsProjection = {
  __typename?: 'RetailAdDeliveryBranchDetailsProjection';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  deliveryETADate?: Maybe<Scalars['String']>;
  deliveryEta?: Maybe<DeliveryEtaProjection>;
  deliveryPrice?: Maybe<DeliveryPrice>;
  distance?: Maybe<Scalars['Float']>;
  estimationId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Maybe<Scalars['String']>>>;
  street?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type RetailAdDeliveryBranchesDetailsProjection = {
  __typename?: 'RetailAdDeliveryBranchesDetailsProjection';
  branches?: Maybe<Array<Maybe<RetailAdDeliveryBranchDetailsDto>>>;
  cursor?: Maybe<Scalars['Int']>;
  deliveryEta?: Maybe<DeliveryEtaProjection>;
  distanceWhereBranchesFound?: Maybe<Scalars['Int']>;
  estimationId?: Maybe<Scalars['UUID']>;
  fetchSize?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type RetailAdDeliveryOptionProjection = {
  __typename?: 'RetailAdDeliveryOptionProjection';
  deliveryOption?: Maybe<Scalars['String']>;
  deliveryType?: Maybe<Scalars['String']>;
  retailAdDeliveryOptionDetails?: Maybe<RetailAdDeliveryOptionDetails>;
  retailAdId?: Maybe<Scalars['UUID']>;
};

export type RetailAdPnlDataProjection = {
  __typename?: 'RetailAdPnlDataProjection';
  classifiedAd?: Maybe<AdProjection>;
  costItems?: Maybe<Array<Maybe<PnlItemProjection>>>;
  order?: Maybe<OrderProjection>;
  retailAd?: Maybe<RetailAdProjection>;
  revenueItems?: Maybe<Array<Maybe<PnlItemProjection>>>;
};

export type RetailAdPriceChangeDto = {
  __typename?: 'RetailAdPriceChangeDTO';
  actionType?: Maybe<Scalars['String']>;
  changeReason?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['String']>;
  newPrice?: Maybe<Scalars['String']>;
  previousPrice?: Maybe<Scalars['String']>;
  priceChangeType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type RetailAdProjection = {
  __typename?: 'RetailAdProjection';
  adId?: Maybe<Scalars['UUID']>;
  auto1BuyPrice?: Maybe<Scalars['Int']>;
  auto1BuyPriceDto?: Maybe<PriceDto>;
  auto1ReturnOn?: Maybe<Scalars['String']>;
  auto1ReturnReason?: Maybe<Scalars['String']>;
  auto1Status?: Maybe<Scalars['Int']>;
  autoheroPurchaseOn?: Maybe<Scalars['String']>;
  carUrlTitle?: Maybe<Scalars['String']>;
  contractDetails?: Maybe<Array<Maybe<Scalars['ObjectScalar']>>>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  documentLocation?: Maybe<Scalars['String']>;
  expectedSalesPriceGrossMinorUnits?: Maybe<Scalars['Long']>;
  financedPrice?: Maybe<PriceDto>;
  firstImportOn?: Maybe<Scalars['String']>;
  firstPurchasedOn?: Maybe<Scalars['String']>;
  grossPrice?: Maybe<PriceDto>;
  grossPriceMinorUnits?: Maybe<Scalars['Long']>;
  hidingOn?: Maybe<Scalars['String']>;
  hidingReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastKnownLocation?: Maybe<Scalars['String']>;
  locationInfo?: Maybe<LocationInfoDto>;
  netPrice?: Maybe<PriceDto>;
  netPriceMinorUnits?: Maybe<Scalars['Long']>;
  retailReady: Scalars['Boolean'];
  retailReadyDate?: Maybe<Scalars['String']>;
  secondaryWheels?: Maybe<RetailAdSecondaryWheelsProjection>;
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  taxAdvantage?: Maybe<RetailAdTaxAdvantageProjection>;
  test: Scalars['Boolean'];
  updatedOn?: Maybe<Scalars['String']>;
  vatAmount?: Maybe<PriceDto>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatType?: Maybe<Scalars['Int']>;
};

export type RetailAdPublishingBlockerProjection = {
  __typename?: 'RetailAdPublishingBlockerProjection';
  blockers?: Maybe<Array<Maybe<PublishingBlockerProjection>>>;
  retailAdId?: Maybe<Scalars['UUID']>;
};

export type RetailAdSecondaryWheelsCreateProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  costPnlVatKey?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  damageCondition?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<ImageProjectionInput>>>;
  internalRefName?: InputMaybe<Scalars['String']>;
  invoiceGrossPrice?: InputMaybe<RetailFinancePriceProjectionInput>;
  invoiceNetPrice?: InputMaybe<RetailFinancePriceProjectionInput>;
  invoicePriceVatKey?: InputMaybe<Scalars['String']>;
  invoicePriceVatRate?: InputMaybe<Scalars['Float']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isShippingRequired?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<SecondaryWheelsLocation>;
  locationDescription?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  partnerName?: InputMaybe<Scalars['String']>;
  pnlCostGrossPrice?: InputMaybe<RetailFinancePriceProjectionInput>;
  pnlCostNetPrice?: InputMaybe<RetailFinancePriceProjectionInput>;
  pnlCostVatRate?: InputMaybe<Scalars['Float']>;
  publishedScope?: InputMaybe<Scalars['String']>;
  rimsDetails?: InputMaybe<Scalars['Map_WheelPlacementType_RimDetailsProjectionScalar']>;
  sku?: InputMaybe<Scalars['String']>;
  tiresDetails?: InputMaybe<Scalars['Map_WheelPlacementType_TireDetailsProjectionScalar']>;
};

export type RetailAdSecondaryWheelsDetailsUpdateProjectionInput = {
  damageCondition?: InputMaybe<Scalars['String']>;
  invoiceGrossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  invoicePriceVatRate?: InputMaybe<Scalars['Float']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isShippingRequired?: InputMaybe<Scalars['Boolean']>;
};

export type RetailAdSecondaryWheelsProjection = {
  __typename?: 'RetailAdSecondaryWheelsProjection';
  adId?: Maybe<Scalars['UUID']>;
  adSecondaryWheelsDamagesImages?: Maybe<Array<Maybe<ImageProjection>>>;
  adSecondaryWheelsImages?: Maybe<Array<Maybe<ImageProjection>>>;
  costPnlVatKey?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  damageCondition?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  images?: Maybe<Array<Maybe<ImageProjection>>>;
  internalRefName?: Maybe<Scalars['String']>;
  invoiceGrossPrice?: Maybe<RetailFinancePriceProjection>;
  invoiceNetPrice?: Maybe<RetailFinancePriceProjection>;
  invoicePriceVatKey?: Maybe<Scalars['String']>;
  invoicePriceVatRate?: Maybe<Scalars['Float']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isShippingRequired?: Maybe<Scalars['Boolean']>;
  location?: Maybe<SecondaryWheelsLocation>;
  locationDescription?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  partnerName?: Maybe<Scalars['String']>;
  pnlCostGrossPrice?: Maybe<RetailFinancePriceProjection>;
  pnlCostNetPrice?: Maybe<RetailFinancePriceProjection>;
  pnlCostVatRate?: Maybe<Scalars['Float']>;
  publishedScope?: Maybe<Scalars['String']>;
  rimsDetails?: Maybe<Scalars['Map_WheelPlacementType_RimDetailsProjectionScalar']>;
  sku?: Maybe<Scalars['String']>;
  tiresDetails?: Maybe<Scalars['Map_WheelPlacementType_TireDetailsProjectionScalar']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailAdTaxAdvantageProjection = {
  __typename?: 'RetailAdTaxAdvantageProjection';
  actualAmount?: Maybe<PriceDto>;
  actualAmountMinorUnits?: Maybe<Scalars['Int']>;
  costAmount?: Maybe<PriceDto>;
  costAmountMinorUnits?: Maybe<Scalars['Int']>;
  currentCountryFirstRegDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  internationalFirstRegDate?: Maybe<Scalars['String']>;
  lastActualAmountCalcDate?: Maybe<Scalars['String']>;
  lastCostAmountCalcDate?: Maybe<Scalars['String']>;
  originalAmount?: Maybe<PriceDto>;
  originalAmountMinorUnits?: Maybe<Scalars['Int']>;
  retailAdId?: Maybe<Scalars['UUID']>;
  taxAdvantageSource?: Maybe<TaxAdvantageSource>;
  taxAdvantageType?: Maybe<ExportTaxAdvantageType>;
  warning?: Maybe<Scalars['String']>;
  warningLevel?: Maybe<Scalars['String']>;
};

export type RetailAdTaxAdvantageUpdateProjectionInput = {
  actualAmountMinorUnits?: InputMaybe<Scalars['Int']>;
  costAmountMinorUnits?: InputMaybe<Scalars['Int']>;
  warning?: InputMaybe<Scalars['String']>;
  warningLevel?: InputMaybe<Scalars['String']>;
};

export type RetailAdUpdateProjectionInput = {
  auto1BuyPrice?: InputMaybe<Scalars['Int']>;
  auto1Status?: InputMaybe<Scalars['Int']>;
  contractDetails?: InputMaybe<Scalars['String']>;
  grossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  netPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  retailCountry?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  stockNumber?: InputMaybe<Scalars['String']>;
  vatAmountMinorUnits?: InputMaybe<Scalars['Long']>;
  vatType?: InputMaybe<Scalars['Int']>;
};

export type RetailAddressCreateProjectionInput = {
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hasPrivateParking?: InputMaybe<Scalars['Boolean']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  houseNumberAdditive?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  taxDetails?: InputMaybe<AddressTaxDetailsProjectionInput>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type RetailAddressDto = {
  __typename?: 'RetailAddressDto';
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasPrivateParking?: Maybe<Scalars['Boolean']>;
  houseNumber?: Maybe<Scalars['String']>;
  houseNumberAdditive?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  taxDetails?: Maybe<AddressTaxDetailsDto>;
  updatedOn?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type RetailAddressProjection = {
  __typename?: 'RetailAddressProjection';
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasPrivateParking?: Maybe<Scalars['Boolean']>;
  houseNumber?: Maybe<Scalars['String']>;
  houseNumberAdditive?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  taxDetails?: Maybe<AddressTaxDetailsProjection>;
  updatedOn?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type RetailAddressUpdateProjectionInput = {
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hasPrivateParking?: InputMaybe<Scalars['Boolean']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  houseNumberAdditive?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  taxDetails?: InputMaybe<AddressTaxDetailsProjectionInput>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type RetailAutoheroLiveChatTemplateTrackingProjectionInput = {
  action?: InputMaybe<Scalars['String']>;
  conversationId?: InputMaybe<Scalars['UUID']>;
  liveChatId?: InputMaybe<Scalars['UUID']>;
  templateId?: InputMaybe<Scalars['UUID']>;
};

export type RetailAxaPolicyNotificationProjection = {
  __typename?: 'RetailAxaPolicyNotificationProjection';
  exportedOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  notificationType?: Maybe<Scalars['String']>;
  policyType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type RetailBackofficeNotificationProjection = {
  __typename?: 'RetailBackofficeNotificationProjection';
  content?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  isRead?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
};

export type RetailBaseProductDto = {
  __typename?: 'RetailBaseProductDTO';
  active?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customDiscount?: Maybe<ProductPriceDto>;
  customDiscountType?: Maybe<Scalars['String']>;
  deactivatedOn?: Maybe<Scalars['String']>;
  defaulted?: Maybe<Scalars['Boolean']>;
  discount?: Maybe<ProductPriceDto>;
  id?: Maybe<Scalars['UUID']>;
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  partnerName?: Maybe<Scalars['String']>;
  pnlCostPrice?: Maybe<PriceDetailsDto>;
  price?: Maybe<PriceDetailsDto>;
  priceAfterDiscount?: Maybe<ProductPriceDto>;
  productType?: Maybe<Scalars['String']>;
  publishedScope?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  recommended?: Maybe<Scalars['Boolean']>;
  refId?: Maybe<Scalars['String']>;
  shippingRequired?: Maybe<Scalars['Boolean']>;
  subProductDetails?: Maybe<RetailBaseProductDtoSubProductDetails>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailBaseProductDtoSubProductDetails = {
  __typename?: 'RetailBaseProductDTOSubProductDetails';
  childProducts?: Maybe<Array<Maybe<RetailBaseProductDto>>>;
  primaryProduct?: Maybe<RetailBaseProductDto>;
};

export type RetailBaseProductProjection = {
  __typename?: 'RetailBaseProductProjection';
  active: Scalars['Boolean'];
  country: Scalars['String'];
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode: Scalars['String'];
  customDiscount?: Maybe<PriceProjection>;
  customDiscountType?: Maybe<Scalars['String']>;
  deactivatedOn?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  discount?: Maybe<PriceProjection>;
  id: Scalars['UUID'];
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceDescription: Scalars['String'];
  invoiceName?: Maybe<Scalars['String']>;
  partnerName: Scalars['String'];
  pnlCostPrice?: Maybe<PriceDetailsProjection>;
  price: PriceDetailsProjection;
  priceAfterDiscount?: Maybe<PriceProjection>;
  priceDetails?: Maybe<Array<Maybe<PriceDetailsProjection>>>;
  productType: Scalars['String'];
  publishedScope: PublishedScopeType;
  quantity: Scalars['Int'];
  recommended?: Maybe<Scalars['Boolean']>;
  refId: Scalars['String'];
  shippingRequired: Scalars['Boolean'];
  subProductDetails?: Maybe<RetailSubProductDetailsProjection>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailBatchDto_UuidInput = {
  entities?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
};

export type RetailBatchPriceUpdateValidationSummaryDto = {
  __typename?: 'RetailBatchPriceUpdateValidationSummaryDTO';
  processableEntityCount?: Maybe<Scalars['Int']>;
  processableErrorlessEntityCount?: Maybe<Scalars['Int']>;
};

export type RetailCarRegistrationCreateProjectionInput = {
  active: Scalars['Boolean'];
  country: Scalars['String'];
  currencyCode: Scalars['String'];
  defaulted: Scalars['Boolean'];
  grossPriceMinorUnits: Scalars['Int'];
  internalReferenceName?: InputMaybe<Scalars['String']>;
  invoiceDescription: Scalars['String'];
  invoiceName?: InputMaybe<Scalars['String']>;
  partnerName: Scalars['String'];
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Int']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  publishedScope: PublishedScopeType;
  quantity: Scalars['Int'];
  recommended?: InputMaybe<Scalars['Boolean']>;
  refId: Scalars['String'];
  registrationInAnyCountry?: InputMaybe<Scalars['Boolean']>;
  registrationInRetailCountry?: InputMaybe<Scalars['Boolean']>;
  registrationType: CarRegistrationType;
  shippingRequired: Scalars['Boolean'];
  vatKey: VatKeyType;
  vatPercentage: Scalars['Float'];
};

export type RetailCarRegistrationProjection = {
  __typename?: 'RetailCarRegistrationProjection';
  active: Scalars['Boolean'];
  country: Scalars['String'];
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode: Scalars['String'];
  customDiscount?: Maybe<PriceProjection>;
  customDiscountType?: Maybe<Scalars['String']>;
  deactivatedOn?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  discount?: Maybe<PriceProjection>;
  id: Scalars['UUID'];
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceDescription: Scalars['String'];
  invoiceName?: Maybe<Scalars['String']>;
  partnerName: Scalars['String'];
  pnlCostPrice?: Maybe<PriceDetailsProjection>;
  price: PriceDetailsProjection;
  priceAfterDiscount?: Maybe<PriceProjection>;
  priceDetails?: Maybe<Array<Maybe<PriceDetailsProjection>>>;
  productType: Scalars['String'];
  publishedScope: PublishedScopeType;
  quantity: Scalars['Int'];
  recommended?: Maybe<Scalars['Boolean']>;
  refId: Scalars['String'];
  registrationInAnyCountry?: Maybe<Scalars['Boolean']>;
  registrationInRetailCountry?: Maybe<Scalars['Boolean']>;
  registrationType: CarRegistrationType;
  shippingRequired: Scalars['Boolean'];
  subProductDetails?: Maybe<RetailSubProductDetailsProjection>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailCarRegistrationSearchProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  country?: InputMaybe<Scalars['String']>;
  customerType?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
  onlyAvailable?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  priceModifiers?: InputMaybe<Scalars['Map_String_PriceModifierPayloadScalar']>;
  registrationCountry?: InputMaybe<Scalars['String']>;
  sorts?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type RetailCarRegistrationUpdateProjectionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  defaulted?: InputMaybe<Scalars['Boolean']>;
  grossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  internalReferenceName?: InputMaybe<Scalars['String']>;
  invoiceDescription?: InputMaybe<Scalars['String']>;
  invoiceName?: InputMaybe<Scalars['String']>;
  partnerName?: InputMaybe<Scalars['String']>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  publishedScope?: InputMaybe<PublishedScopeType>;
  quantity?: InputMaybe<Scalars['Int']>;
  recommended?: InputMaybe<Scalars['Boolean']>;
  registrationInAnyCountry?: InputMaybe<Scalars['Boolean']>;
  registrationInRetailCountry?: InputMaybe<Scalars['Boolean']>;
  registrationType?: InputMaybe<CarRegistrationType>;
  shippingRequired?: InputMaybe<Scalars['Boolean']>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
};

export type RetailCheckoutAllStepTypesProjection = {
  __typename?: 'RetailCheckoutAllStepTypesProjection';
  steps?: Maybe<Array<Maybe<RetailCheckoutStepTypeProjection>>>;
};

export type RetailCheckoutCreateProjectionInput = {
  completedSteps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  orderId?: InputMaybe<Scalars['UUID']>;
  source?: InputMaybe<CheckoutSource>;
};

export type RetailCheckoutProjection = {
  __typename?: 'RetailCheckoutProjection';
  completedSteps?: Maybe<Array<Maybe<RetailCheckoutStepProjection>>>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  source?: Maybe<CheckoutSource>;
};

export type RetailCheckoutStepCreateProjection = {
  __typename?: 'RetailCheckoutStepCreateProjection';
  checkoutId?: Maybe<Scalars['UUID']>;
  correctionReason?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  source?: Maybe<CheckoutSource>;
  state?: Maybe<Scalars['String']>;
  step?: Maybe<Scalars['String']>;
};

export type RetailCheckoutStepCreateRequestProjectionInput = {
  source?: InputMaybe<CheckoutSource>;
  step?: InputMaybe<Scalars['String']>;
};

export type RetailCheckoutStepProjection = {
  __typename?: 'RetailCheckoutStepProjection';
  step?: Maybe<Scalars['String']>;
};

export type RetailCheckoutStepTypeProjection = {
  __typename?: 'RetailCheckoutStepTypeProjection';
  stepName?: Maybe<Scalars['String']>;
  viewName?: Maybe<Scalars['String']>;
};

export type RetailCheckoutUpdateProjectionInput = {
  completedSteps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<CheckoutSource>;
};

export type RetailClaimAllowedStatesProjection = {
  __typename?: 'RetailClaimAllowedStatesProjection';
  allowedNextStates?: Maybe<Array<Maybe<Scalars['String']>>>;
  currentState?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
};

export type RetailClaimAreaProjection = {
  __typename?: 'RetailClaimAreaProjection';
  carParts?: Maybe<Array<Maybe<RetailClaimCarPartProjection>>>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type RetailClaimAssigneeDto = {
  __typename?: 'RetailClaimAssigneeDTO';
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type RetailClaimCarPartProjection = {
  __typename?: 'RetailClaimCarPartProjection';
  damages?: Maybe<Array<Maybe<RetailClaimDamageProjection>>>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type RetailClaimComplaintCommentProjection = {
  __typename?: 'RetailClaimComplaintCommentProjection';
  comment?: Maybe<Scalars['String']>;
  complaintId?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
};

export type RetailClaimComplaintDocumentProjection = {
  __typename?: 'RetailClaimComplaintDocumentProjection';
  complaintId?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSizeKb?: Maybe<Scalars['Int']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  type?: Maybe<Scalars['String']>;
};

export type RetailClaimComplaintProjection = {
  __typename?: 'RetailClaimComplaintProjection';
  area?: Maybe<Scalars['String']>;
  claimId?: Maybe<Scalars['UUID']>;
  comments?: Maybe<Array<Maybe<RetailClaimComplaintCommentProjection>>>;
  complaintArea?: Maybe<Scalars['String']>;
  complaintDamage?: Maybe<Scalars['String']>;
  complaintNumber?: Maybe<Scalars['Int']>;
  complaintPart?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  damage?: Maybe<Scalars['String']>;
  damageSeverity?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Maybe<RetailClaimComplaintDocumentProjection>>>;
  id?: Maybe<Scalars['UUID']>;
  namingVersion?: Maybe<Scalars['Int']>;
};

export type RetailClaimCostCommentCreateProjectionInput = {
  approvalLevel1Comment?: InputMaybe<Scalars['String']>;
  approvalLevel2Comment?: InputMaybe<Scalars['String']>;
  approvalLevel3Comment?: InputMaybe<Scalars['String']>;
  customerDecisionComment?: InputMaybe<Scalars['String']>;
};

export type RetailClaimCostCommentProjection = {
  __typename?: 'RetailClaimCostCommentProjection';
  approvalLevel1Comment?: Maybe<Scalars['String']>;
  approvalLevel2Comment?: Maybe<Scalars['String']>;
  approvalLevel3Comment?: Maybe<Scalars['String']>;
  claimCostId?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  customerDecisionComment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailClaimCostCreateProjectionInput = {
  cost?: InputMaybe<Scalars['Long']>;
  costType?: InputMaybe<Scalars['String']>;
  orderItemId?: InputMaybe<Scalars['UUID']>;
  warranty?: InputMaybe<Scalars['String']>;
};

export type RetailClaimCostPaymentProjection = {
  __typename?: 'RetailClaimCostPaymentProjection';
  claimCostId?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  paymentId?: Maybe<Scalars['UUID']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  pnlId?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailClaimCostProjection = {
  __typename?: 'RetailClaimCostProjection';
  approvalLevel1?: Maybe<Scalars['String']>;
  approvalLevel1SetBy?: Maybe<Scalars['UUID']>;
  approvalLevel1SetByProjection?: Maybe<UserProjection>;
  approvalLevel1SetOn?: Maybe<Scalars['String']>;
  approvalLevel2?: Maybe<Scalars['String']>;
  approvalLevel2SetBy?: Maybe<Scalars['UUID']>;
  approvalLevel2SetByProjection?: Maybe<UserProjection>;
  approvalLevel2SetOn?: Maybe<Scalars['String']>;
  approvalLevel3?: Maybe<Scalars['String']>;
  approvalLevel3SetBy?: Maybe<Scalars['UUID']>;
  approvalLevel3SetByProjection?: Maybe<UserProjection>;
  approvalLevel3SetOn?: Maybe<Scalars['String']>;
  claimId?: Maybe<Scalars['UUID']>;
  comments?: Maybe<Array<Maybe<RetailClaimCostCommentProjection>>>;
  costCurrencyCode?: Maybe<Scalars['String']>;
  costMinorUnits?: Maybe<Scalars['Long']>;
  costType?: Maybe<Scalars['String']>;
  costTypeCategory?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  customerDecision?: Maybe<Scalars['String']>;
  customerDecisionSetBy?: Maybe<Scalars['UUID']>;
  customerDecisionSetByProjection?: Maybe<UserProjection>;
  customerDecisionSetOn?: Maybe<Scalars['String']>;
  editState?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  number?: Maybe<Scalars['String']>;
  orderItemId?: Maybe<Scalars['UUID']>;
  partialRefund?: Maybe<ClaimPartialRefundProjection>;
  payment?: Maybe<RetailClaimCostPaymentProjection>;
  status?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  warrantyCoverageType?: Maybe<Scalars['String']>;
};

export type RetailClaimCostUpdateProjectionInput = {
  cost?: InputMaybe<Scalars['Long']>;
  costType?: InputMaybe<Scalars['String']>;
  orderItemId?: InputMaybe<Scalars['UUID']>;
  warranty?: InputMaybe<Scalars['String']>;
};

export type RetailClaimCreateProjectionV2Input = {
  assignTo?: InputMaybe<Scalars['UUID']>;
  claimGroupId?: InputMaybe<Scalars['UUID']>;
  subClaim?: InputMaybe<RetailSubClaimCreateProjectionInput>;
};

export type RetailClaimDamageProjection = {
  __typename?: 'RetailClaimDamageProjection';
  deleted: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type RetailClaimGroupProjection = {
  __typename?: 'RetailClaimGroupProjection';
  areas?: Maybe<Array<Maybe<RetailClaimAreaProjection>>>;
  id?: Maybe<Scalars['UUID']>;
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  liableParties?: Maybe<Array<Maybe<RetailClaimLiablePartyProjection>>>;
  name?: Maybe<Scalars['String']>;
};

export type RetailClaimLiablePartyProjection = {
  __typename?: 'RetailClaimLiablePartyProjection';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type RetailClaimProjection = {
  __typename?: 'RetailClaimProjection';
  ad?: Maybe<AdProjection>;
  assignTo?: Maybe<Scalars['UUID']>;
  claimGroupId?: Maybe<Scalars['UUID']>;
  closedDescription?: Maybe<Scalars['String']>;
  closedOn?: Maybe<Scalars['String']>;
  closedReason?: Maybe<Scalars['String']>;
  compensationType?: Maybe<Scalars['String']>;
  complaints?: Maybe<Array<Maybe<RetailClaimComplaintProjection>>>;
  completedOn?: Maybe<Scalars['String']>;
  costs?: Maybe<Array<Maybe<RetailClaimCostProjection>>>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  number?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  proofRequests?: Maybe<Array<Maybe<RetailClaimProofRequestProjection>>>;
  sourceType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateComment?: Maybe<Scalars['String']>;
  stateDueDate?: Maybe<Scalars['String']>;
  subClaims?: Maybe<Array<Maybe<RetailSubClaimProjection>>>;
  trialExpiredOn?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  workshopDetails?: Maybe<RetailClaimWorkshopDetailsProjection>;
};

export type RetailClaimProofRequestProjection = {
  __typename?: 'RetailClaimProofRequestProjection';
  claimId?: Maybe<Scalars['UUID']>;
  comment?: Maybe<Scalars['String']>;
  complaintId?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RetailClaimStateDueDaysConfigBatchUpdateProjectionInput = {
  configUpdates?: InputMaybe<Array<InputMaybe<RetailClaimStateDueDaysConfigUpdateProjectionInput>>>;
};

export type RetailClaimStateDueDaysConfigProjection = {
  __typename?: 'RetailClaimStateDueDaysConfigProjection';
  claimState?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  dueDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  updatedBy?: Maybe<UserProjection>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailClaimStateDueDaysConfigUpdateProjectionInput = {
  dueDays?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
};

export type RetailClaimUpdateGroupProjectionInput = {
  claimGroupId?: InputMaybe<Scalars['UUID']>;
};

export type RetailClaimUpdateStateProjectionInput = {
  closedReason?: InputMaybe<ClosedReasonProjectionInput>;
  state?: InputMaybe<Scalars['String']>;
};

export type RetailClaimUpdateUserAssignProjectionInput = {
  assignTo?: InputMaybe<Scalars['UUID']>;
};

export type RetailClaimWorkshopDetailsCreateProjectionInput = {
  address?: InputMaybe<Scalars['String']>;
  carPickedUp?: InputMaybe<Scalars['Boolean']>;
  carPickedUpOn?: InputMaybe<Scalars['String']>;
};

export type RetailClaimWorkshopDetailsProjection = {
  __typename?: 'RetailClaimWorkshopDetailsProjection';
  address?: Maybe<Scalars['String']>;
  carPickedUp?: Maybe<Scalars['Boolean']>;
  carPickedUpOn?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailClaimWorkshopDetailsUpdateProjectionInput = {
  address?: InputMaybe<Scalars['String']>;
  carPickedUp?: InputMaybe<Scalars['Boolean']>;
  carPickedUpOn?: InputMaybe<Scalars['String']>;
};

export type RetailCreateDocumentProjectionInput = {
  retailDocumentCreateDTO?: InputMaybe<RetailDocumentCreateDtoInput>;
};

export type RetailCreditScoreDataProjection = {
  __typename?: 'RetailCreditScoreDataProjection';
  calculatedOn?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdByUser?: Maybe<UserProjection>;
  creditRiskRate?: Maybe<Scalars['Float']>;
  creditScore?: Maybe<Scalars['Int']>;
  creditScoreProviderType?: Maybe<Scalars['String']>;
  creditScoreRange?: Maybe<Scalars['String']>;
  monthlyNetIncomeMinorUnits?: Maybe<Scalars['Long']>;
};

export type RetailCreditScoreDataProjectionV2 = {
  __typename?: 'RetailCreditScoreDataProjectionV2';
  calculatedOn?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdByUser?: Maybe<UserProjection>;
  creditRiskRate?: Maybe<Scalars['Float']>;
  creditScore?: Maybe<Scalars['Int']>;
  creditScoreProviderType?: Maybe<Scalars['String']>;
  creditScoreRange?: Maybe<Scalars['String']>;
  monthlyAvgNetIncomeMinorUnits?: Maybe<Scalars['Long']>;
  monthlyNetIncomePerMonth?: Maybe<Array<Maybe<MonthlyNetIncomeProjection>>>;
};

export type RetailCustomerCreateProjectionInput = {
  cityOfBirth?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  customerType?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  departmentOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  relation?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  taxCode?: InputMaybe<Scalars['String']>;
  taxCodeValidationState?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vatId?: InputMaybe<Scalars['String']>;
};

export type RetailCustomerExpectedEtaDto = {
  __typename?: 'RetailCustomerExpectedEtaDTO';
  daysForDelivery?: Maybe<Scalars['Int']>;
  deliveryEtaRange?: Maybe<DeliveryEtaRangeDto>;
  deliveryEtaType?: Maybe<Scalars['String']>;
  estimationId?: Maybe<Scalars['UUID']>;
  logisticsEtaInCalendarDays?: Maybe<Scalars['Int']>;
};

export type RetailCustomerProjection = {
  __typename?: 'RetailCustomerProjection';
  cityOfBirth?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  customerType?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  departmentOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  kvkNumber?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  phone?: Maybe<Scalars['String']>;
  relation?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  ssn?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  taxCodeValidationState?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
  verification?: Maybe<RetailCustomerVerificationProjection>;
};

export type RetailCustomerUpdateProjectionInput = {
  cityOfBirth?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  customerType?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  departmentOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  kvkNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  relation?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  ssn?: InputMaybe<Scalars['String']>;
  taxCode?: InputMaybe<Scalars['String']>;
  taxCodeValidationState?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vatId?: InputMaybe<Scalars['String']>;
};

export type RetailCustomerVerificationProjection = {
  __typename?: 'RetailCustomerVerificationProjection';
  customerId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  reusesVerificationFromOrderId?: Maybe<Scalars['UUID']>;
  reusesVerificationFromOrderNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  verificationSteps?: Maybe<Array<Maybe<RetailCustomerVerificationStepProjection>>>;
};

export type RetailCustomerVerificationStepProjection = {
  __typename?: 'RetailCustomerVerificationStepProjection';
  reuseType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RetailCustomersByOrderProjection = {
  __typename?: 'RetailCustomersByOrderProjection';
  customers?: Maybe<Array<Maybe<RetailCustomerProjection>>>;
  orderId?: Maybe<Scalars['UUID']>;
};

export type RetailDataImportConfigDto = {
  __typename?: 'RetailDataImportConfigDTO';
  config?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export type RetailDataImportConfigUpdateProjectionInput = {
  config?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
};

export type RetailDataImportDto = {
  __typename?: 'RetailDataImportDTO';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  creationSource?: Maybe<Scalars['String']>;
  dataImportType?: Maybe<RetailDataImportType>;
  errorRows?: Maybe<Scalars['Int']>;
  errorsResourceLocation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  provider?: Maybe<RetailDataImportProvider>;
  resourceLocation?: Maybe<Scalars['String']>;
  state?: Maybe<RetailDataImportState>;
  successRows?: Maybe<Scalars['Int']>;
  totalRows?: Maybe<Scalars['Int']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailDataImportProjection = {
  __typename?: 'RetailDataImportProjection';
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  creationSource?: Maybe<Scalars['String']>;
  errorRows?: Maybe<Scalars['Int']>;
  errorsResourceLocation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  provider?: Maybe<RetailDataImportProvider>;
  resourceLocation?: Maybe<Scalars['String']>;
  state?: Maybe<RetailDataImportState>;
  successRows?: Maybe<Scalars['Int']>;
  totalRows?: Maybe<Scalars['Int']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export enum RetailDataImportProvider {
  AwsS3 = 'AWS_S3'
}

export enum RetailDataImportState {
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Validated = 'VALIDATED',
  Validating = 'VALIDATING',
  Voided = 'VOIDED'
}

export enum RetailDataImportType {
  BulkPriceUpdate = 'BULK_PRICE_UPDATE',
  CarRegistrationAction = 'CAR_REGISTRATION_ACTION',
  ChatbotQuestion = 'CHATBOT_QUESTION',
  DadCarRegistration = 'DAD_CAR_REGISTRATION',
  InventorySourcing = 'INVENTORY_SOURCING',
  Pnl = 'PNL',
  RetailDocumentInventory = 'RETAIL_DOCUMENT_INVENTORY',
  RetailDocumentPackage = 'RETAIL_DOCUMENT_PACKAGE',
  RetailOfflineLeadsNewsletterSubscription = 'RETAIL_OFFLINE_LEADS_NEWSLETTER_SUBSCRIPTION',
  RetailPayment = 'RETAIL_PAYMENT',
  ReturnToAuto1 = 'RETURN_TO_AUTO1'
}

export type RetailDebtPriceDto = {
  __typename?: 'RetailDebtPriceDTO';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export type RetailDeliveryRouteItemDto = {
  __typename?: 'RetailDeliveryRouteItemDTO';
  city?: Maybe<Scalars['String']>;
  completedOn?: Maybe<Scalars['String']>;
  itemType?: Maybe<RetailDeliveryRouteItemType>;
  state?: Maybe<RetailDeliveryRouteState>;
};

export enum RetailDeliveryRouteItemType {
  DeliveryToLogisticsCenter = 'DELIVERY_TO_LOGISTICS_CENTER',
  DeliveryToTargetBranch = 'DELIVERY_TO_TARGET_BRANCH',
  PickupAppointmentScheduling = 'PICKUP_APPOINTMENT_SCHEDULING',
  PreparationForShipment = 'PREPARATION_FOR_SHIPMENT',
  Shipment = 'SHIPMENT'
}

export enum RetailDeliveryRouteState {
  Completed = 'COMPLETED',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED'
}

export type RetailDigitalCarRegistrationAssigneeDto = {
  __typename?: 'RetailDigitalCarRegistrationAssigneeDTO';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type RetailDiscountItemCreateDtoInput = {
  country?: InputMaybe<Scalars['String']>;
  grossAmount?: InputMaybe<DiscountItemPriceDtoInput>;
  reason?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['UUID']>;
  refParentId?: InputMaybe<Scalars['UUID']>;
  refType?: InputMaybe<Scalars['String']>;
};

export type RetailDiscountItemDto = {
  __typename?: 'RetailDiscountItemDTO';
  country?: Maybe<Scalars['String']>;
  creationSource?: Maybe<Scalars['String']>;
  grossAmount?: Maybe<DiscountItemPriceDto>;
  id?: Maybe<Scalars['UUID']>;
  reason?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['UUID']>;
  refType?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RetailDiscountItemOptionsProjection = {
  __typename?: 'RetailDiscountItemOptionsProjection';
  options?: Maybe<Array<Maybe<DiscountItemOptions>>>;
};

export type RetailDiscountItemProjection = {
  __typename?: 'RetailDiscountItemProjection';
  grossAmount?: Maybe<DiscountItemPriceDto>;
  type?: Maybe<Scalars['String']>;
};

export type RetailDiscountItemProjectionInput = {
  grossAmount?: InputMaybe<DiscountItemPriceDtoInput>;
  type?: InputMaybe<Scalars['String']>;
};

export type RetailDiscountItemUpdateProjectionInput = {
  grossAmountMinorUnits?: InputMaybe<Scalars['Long']>;
};

export type RetailDisputeProjection = {
  __typename?: 'RetailDisputeProjection';
  amount?: Maybe<RetailFinancePriceProjection>;
  disputeReason?: Maybe<Scalars['String']>;
  disputeStatus?: Maybe<Scalars['String']>;
  eventDate?: Maybe<Scalars['String']>;
  eventSuccess?: Maybe<Scalars['Boolean']>;
};

export type RetailDisputeProjectionInput = {
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  disputeReason?: InputMaybe<Scalars['String']>;
  disputeStatus?: InputMaybe<Scalars['String']>;
  eventDate?: InputMaybe<Scalars['String']>;
  eventSuccess?: InputMaybe<Scalars['Boolean']>;
};

export type RetailDocumentCategoryDto = {
  __typename?: 'RetailDocumentCategoryDTO';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  topCategory?: Maybe<Scalars['String']>;
};

export type RetailDocumentCategoryProjection = {
  __typename?: 'RetailDocumentCategoryProjection';
  documentTypes?: Maybe<Array<Maybe<RetailDocumentTypeProjection>>>;
  name?: Maybe<Scalars['String']>;
};

export type RetailDocumentCreateDtoInput = {
  attachedTo?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  documentSubTypeKey?: InputMaybe<Scalars['String']>;
  documentTypeId?: InputMaybe<Scalars['UUID']>;
  documentTypeKey?: InputMaybe<Scalars['String']>;
  documentUploadSourceType?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  fullUrl?: InputMaybe<Scalars['String']>;
};

export type RetailDocumentGeneratePdfResponseProjection = {
  __typename?: 'RetailDocumentGeneratePdfResponseProjection';
  presignedUrl?: Maybe<Scalars['String']>;
};

export type RetailDocumentInventoryImportCreateProjectionInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export type RetailDocumentPackageUpdateImportProjectionInput = {
  fileName: Scalars['String'];
};

export type RetailDocumentProjection = {
  __typename?: 'RetailDocumentProjection';
  attachedTo?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['UUID']>;
  deletedByUser?: Maybe<UserProjection>;
  deletedOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documentType?: Maybe<RetailDocumentTypeDto>;
  documentUploadSourceType?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  notificationId?: Maybe<Scalars['UUID']>;
  rotationDegree?: Maybe<Scalars['Int']>;
  signatureExternalId?: Maybe<Scalars['UUID']>;
  signatureProvider?: Maybe<Scalars['String']>;
  signed?: Maybe<Scalars['Boolean']>;
  signedBy?: Maybe<Scalars['UUID']>;
  signedOn?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  verifiedBy?: Maybe<Scalars['UUID']>;
  verifiedByUser?: Maybe<UserProjection>;
  verifiedOn?: Maybe<Scalars['String']>;
};

export type RetailDocumentRequestCreateProjectionInput = {
  attachedTo?: InputMaybe<Scalars['UUID']>;
  comment?: InputMaybe<Scalars['String']>;
  documentSubTypeKey?: InputMaybe<Scalars['String']>;
  documentTypeKey?: InputMaybe<Scalars['String']>;
  internalComment?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  requestReason?: InputMaybe<Scalars['String']>;
};

export type RetailDocumentRequestProjection = {
  __typename?: 'RetailDocumentRequestProjection';
  attachedTo?: Maybe<Scalars['UUID']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. It will be removed later */
  closedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is stateReason */
  closedReason?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  documentType?: Maybe<RetailPlainDocumentTypeProjection>;
  id?: Maybe<Scalars['UUID']>;
  internalComment?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is requestReason */
  reason?: Maybe<Scalars['String']>;
  requestReason?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateReason?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<UserProjection>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailDocumentSubTypeDto = {
  __typename?: 'RetailDocumentSubTypeDTO';
  id?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type RetailDocumentSubTypeProjection = {
  __typename?: 'RetailDocumentSubTypeProjection';
  id?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type RetailDocumentTopCategoryProjection = {
  __typename?: 'RetailDocumentTopCategoryProjection';
  categories?: Maybe<Array<Maybe<RetailDocumentCategoryProjection>>>;
  topCategory?: Maybe<Scalars['String']>;
};

export type RetailDocumentTypeDto = {
  __typename?: 'RetailDocumentTypeDTO';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  retailDocumentCategoryDTO?: Maybe<RetailDocumentCategoryDto>;
  retailDocumentSubType?: Maybe<RetailDocumentSubTypeDto>;
};

export type RetailDocumentTypeProjection = {
  __typename?: 'RetailDocumentTypeProjection';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subTypes?: Maybe<Array<Maybe<RetailDocumentSubTypeProjection>>>;
};

export type RetailDocumentUpdateProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  documentUploadSourceType?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  fullUrl?: InputMaybe<Scalars['String']>;
  rotationDegree?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type RetailDownPaymentCreateProjectionInput = {
  active: Scalars['Boolean'];
  country: Scalars['String'];
  currencyCode: Scalars['String'];
  defaulted: Scalars['Boolean'];
  grossPriceMinorUnits: Scalars['Int'];
  internalReferenceName?: InputMaybe<Scalars['String']>;
  invoiceDescription: Scalars['String'];
  invoiceName?: InputMaybe<Scalars['String']>;
  partnerName: Scalars['String'];
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Int']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  publishedScope: PublishedScopeType;
  quantity: Scalars['Int'];
  recommended?: InputMaybe<Scalars['Boolean']>;
  refId: Scalars['String'];
  shippingRequired: Scalars['Boolean'];
  vatKey: VatKeyType;
  vatPercentage: Scalars['Float'];
};

export type RetailExperianDataProjection = {
  __typename?: 'RetailExperianDataProjection';
  calculatedOn?: Maybe<Scalars['String']>;
  experianCreditScore?: Maybe<Scalars['Int']>;
  monthlyNetIncomeMinorUnits?: Maybe<Scalars['Long']>;
};

export type RetailExternalFinancingBankDetailsPendingTaskProjection = {
  __typename?: 'RetailExternalFinancingBankDetailsPendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  externalFinancingRefNumber?: Maybe<Scalars['String']>;
  financingApplication?: Maybe<FinancingApplicationProjection>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentDetails?: Maybe<OrderPaymentDetailsProjection>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type RetailFeatureProjection = {
  __typename?: 'RetailFeatureProjection';
  condition?: Maybe<Scalars['Map_String_ObjectScalar']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdByUser?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  environment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedByUser?: Maybe<RetailUserDto>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailFeatureStatusContextDtoInput = {
  country?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  featureUsageDate?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type RetailFeatureStatusDto = {
  __typename?: 'RetailFeatureStatusDTO';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type RetailFeatureStatusProjectionV2 = {
  __typename?: 'RetailFeatureStatusProjectionV2';
  enabled: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
};

export type RetailFeeCreateProjectionInput = {
  active: Scalars['Boolean'];
  country: Scalars['String'];
  currencyCode: Scalars['String'];
  defaulted: Scalars['Boolean'];
  feeType?: InputMaybe<Scalars['String']>;
  grossPriceMinorUnits: Scalars['Int'];
  internalReferenceName?: InputMaybe<Scalars['String']>;
  invoiceDescription: Scalars['String'];
  invoiceName?: InputMaybe<Scalars['String']>;
  partnerName: Scalars['String'];
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Int']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  publishedScope: PublishedScopeType;
  quantity: Scalars['Int'];
  recommended?: InputMaybe<Scalars['Boolean']>;
  refId: Scalars['String'];
  shippingRequired: Scalars['Boolean'];
  title?: InputMaybe<Scalars['String']>;
  vatKey: VatKeyType;
  vatPercentage: Scalars['Float'];
};

export type RetailFeeOptionsProjection = {
  __typename?: 'RetailFeeOptionsProjection';
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RetailFeeOptionsRequestProjectionInput = {
  country?: InputMaybe<Scalars['String']>;
};

export type RetailFeeProjection = {
  __typename?: 'RetailFeeProjection';
  active: Scalars['Boolean'];
  country: Scalars['String'];
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode: Scalars['String'];
  customDiscount?: Maybe<PriceProjection>;
  customDiscountType?: Maybe<Scalars['String']>;
  deactivatedOn?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  discount?: Maybe<PriceProjection>;
  feeType?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceDescription: Scalars['String'];
  invoiceName?: Maybe<Scalars['String']>;
  partnerName: Scalars['String'];
  pnlCostPrice?: Maybe<PriceDetailsProjection>;
  price: PriceDetailsProjection;
  priceAfterDiscount?: Maybe<PriceProjection>;
  priceDetails?: Maybe<Array<Maybe<PriceDetailsProjection>>>;
  productType: Scalars['String'];
  publishedScope: PublishedScopeType;
  quantity: Scalars['Int'];
  recommended?: Maybe<Scalars['Boolean']>;
  refId: Scalars['String'];
  shippingRequired: Scalars['Boolean'];
  subProductDetails?: Maybe<RetailSubProductDetailsProjection>;
  title?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailFeeSearchProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  country?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
  onlyAvailable?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  priceModifiers?: InputMaybe<Scalars['Map_String_PriceModifierPayloadScalar']>;
  sorts?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type RetailFeeUpdateProjectionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  defaulted?: InputMaybe<Scalars['Boolean']>;
  grossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  internalReferenceName?: InputMaybe<Scalars['String']>;
  invoiceDescription?: InputMaybe<Scalars['String']>;
  invoiceName?: InputMaybe<Scalars['String']>;
  partnerName?: InputMaybe<Scalars['String']>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  publishedScope?: InputMaybe<PublishedScopeType>;
  quantity?: InputMaybe<Scalars['Int']>;
  recommended?: InputMaybe<Scalars['Boolean']>;
  shippingRequired?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
};

export type RetailFinancePriceProjection = {
  __typename?: 'RetailFinancePriceProjection';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export type RetailFinancePriceProjectionInput = {
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  currencyCode?: InputMaybe<Scalars['String']>;
};

export type RetailFinancingAmountVerificationTaskProjection = {
  __typename?: 'RetailFinancingAmountVerificationTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingAmount?: Maybe<Scalars['Long']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Long']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export enum RetailFinancingApplicationFinancingType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL'
}

export enum RetailFinancingApplicationFlowType {
  AhDigital = 'AH_DIGITAL',
  AhManual = 'AH_MANUAL',
  Manual = 'MANUAL'
}

export enum RetailFinancingApplicationFlowVersion {
  V1 = 'V1',
  V2 = 'V2'
}

export enum RetailFinancingApplicationType {
  ApplicationBasedOnCompleteDocumentCheck = 'APPLICATION_BASED_ON_COMPLETE_DOCUMENT_CHECK',
  FastTrackApplication = 'FAST_TRACK_APPLICATION'
}

export type RetailFinancingPlanBreakdownProjection = {
  __typename?: 'RetailFinancingPlanBreakdownProjection';
  balloonPayment?: Maybe<Scalars['Long']>;
  balloonPaymentNoRounding?: Maybe<Scalars['Long']>;
  cashAndFinancingWithInterest?: Maybe<Scalars['Long']>;
  cashAndFinancingWithoutInterest?: Maybe<Scalars['Long']>;
  installmentsSum?: Maybe<Scalars['Long']>;
  interestSum?: Maybe<Scalars['Long']>;
  monthlyPayments?: Maybe<Array<Maybe<RetailFinancingPlanMonthlyPaymentProjection>>>;
  monthlyRate?: Maybe<Scalars['Long']>;
  totalRepayment?: Maybe<Scalars['Long']>;
};

export type RetailFinancingPlanCalculationProjection = {
  __typename?: 'RetailFinancingPlanCalculationProjection';
  createdOn?: Maybe<Scalars['String']>;
  financingAmountMinorUnits?: Maybe<Scalars['Long']>;
  id?: Maybe<Scalars['UUID']>;
  isSelectedForRetailRiskCalculation?: Maybe<Scalars['Boolean']>;
  maturity?: Maybe<Scalars['Int']>;
  monthlyPaymentAmountMinorUnits?: Maybe<Scalars['Long']>;
  retailFinancingTiers?: Maybe<Array<Maybe<RetailFinancingTierProjection>>>;
};

export type RetailFinancingPlanMonthlyPaymentProjection = {
  __typename?: 'RetailFinancingPlanMonthlyPaymentProjection';
  dueDate?: Maybe<Scalars['String']>;
  installmentRepaymentAmount?: Maybe<Scalars['Long']>;
  interestRateAmount?: Maybe<Scalars['Long']>;
  number?: Maybe<Scalars['Int']>;
  rateAmount?: Maybe<Scalars['Long']>;
  remainingDebtAmount?: Maybe<Scalars['Long']>;
};

export type RetailFinancingPlanProjection = {
  __typename?: 'RetailFinancingPlanProjection';
  debitInterest?: Maybe<Scalars['BigDecimal']>;
  effectiveAnnualInterest?: Maybe<Scalars['BigDecimal']>;
  monthlyInstalmentRate?: Maybe<Scalars['Long']>;
  totalLoanAmountWithInterest?: Maybe<Scalars['Long']>;
};

export type RetailFinancingTierProductProjection = {
  __typename?: 'RetailFinancingTierProductProjection';
  id: Scalars['UUID'];
  monthlyPayment: Scalars['Long'];
  price: Scalars['Long'];
  priceInterestFree: Scalars['Long'];
  tierId: Scalars['UUID'];
  type: Scalars['String'];
};

export type RetailFinancingTierProjection = {
  __typename?: 'RetailFinancingTierProjection';
  additionalCash?: Maybe<Scalars['Long']>;
  additionalCashFromInsurance?: Maybe<Scalars['Long']>;
  apr?: Maybe<Scalars['Float']>;
  balloonPayment?: Maybe<Scalars['Long']>;
  balloonPercent?: Maybe<Scalars['Int']>;
  downPayment?: Maybe<Scalars['Long']>;
  financingAmountMinorUnits?: Maybe<Scalars['Long']>;
  id?: Maybe<Scalars['UUID']>;
  initialLoanAmount?: Maybe<Scalars['Long']>;
  interestRate?: Maybe<Scalars['Float']>;
  isEligible?: Maybe<Scalars['Boolean']>;
  isSelected?: Maybe<Scalars['Boolean']>;
  maturity?: Maybe<Scalars['Int']>;
  maxLoanAmount?: Maybe<Scalars['Long']>;
  monthlyPayment?: Maybe<Scalars['Long']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  parentTierId?: Maybe<Scalars['UUID']>;
  presentValue?: Maybe<Scalars['Long']>;
  products: Array<Maybe<RetailFinancingTierProductProjection>>;
  riskScore?: Maybe<Scalars['Float']>;
  tag?: Maybe<Scalars['String']>;
  totalPaymentAmount?: Maybe<Scalars['Long']>;
};

export type RetailFtsRiskDataProjection = {
  __typename?: 'RetailFtsRiskDataProjection';
  accountHolder?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  finishedOn?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  riskData?: Maybe<RiskData>;
  transactionId?: Maybe<Scalars['String']>;
};

export type RetailFullFtsDataProjection = {
  __typename?: 'RetailFullFtsDataProjection';
  accountHolder?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  creditRiskDataDTO?: Maybe<CreditRiskDataDto>;
  finishedOn?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type RetailGroupDto = {
  __typename?: 'RetailGroupDTO';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<RetailRoleDto>>>;
  updatedOn?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<RetailUserDto>>>;
};

export type RetailImageTagCreateProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  parentImageId?: InputMaybe<Scalars['UUID']>;
  position?: InputMaybe<PositionDtoInput>;
  type?: InputMaybe<TagType>;
};

export type RetailImageTagProjection = {
  __typename?: 'RetailImageTagProjection';
  adId?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  imageId?: Maybe<Scalars['UUID']>;
  parentImageId?: Maybe<Scalars['UUID']>;
  position?: Maybe<PositionDto>;
  type?: Maybe<TagType>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailImageTagUpdateProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  parentImageId?: InputMaybe<Scalars['UUID']>;
  position?: InputMaybe<PositionDtoInput>;
  type?: InputMaybe<TagType>;
};

export type RetailInsuranceCreateProjectionInput = {
  active: Scalars['Boolean'];
  country: Scalars['String'];
  currencyCode: Scalars['String'];
  defaulted: Scalars['Boolean'];
  grossPriceMinorUnits: Scalars['Int'];
  internalReferenceName?: InputMaybe<Scalars['String']>;
  invoiceDescription: Scalars['String'];
  invoiceName?: InputMaybe<Scalars['String']>;
  partnerName: Scalars['String'];
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Int']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  publishedScope: PublishedScopeType;
  quantity: Scalars['Int'];
  recommended?: InputMaybe<Scalars['Boolean']>;
  refId: Scalars['String'];
  shippingRequired: Scalars['Boolean'];
  vatKey: VatKeyType;
  vatPercentage: Scalars['Float'];
};

export type RetailInsuranceProjection = {
  __typename?: 'RetailInsuranceProjection';
  active: Scalars['Boolean'];
  country: Scalars['String'];
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode: Scalars['String'];
  customDiscount?: Maybe<PriceProjection>;
  customDiscountType?: Maybe<Scalars['String']>;
  deactivatedOn?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  discount?: Maybe<PriceProjection>;
  externalProviderPrice?: Maybe<Scalars['Long']>;
  id: Scalars['UUID'];
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceDescription: Scalars['String'];
  invoiceName?: Maybe<Scalars['String']>;
  partnerName: Scalars['String'];
  pnlCostPrice?: Maybe<PriceDetailsProjection>;
  price: PriceDetailsProjection;
  priceAfterDiscount?: Maybe<PriceProjection>;
  priceDetails?: Maybe<Array<Maybe<PriceDetailsProjection>>>;
  productType: Scalars['String'];
  publishedScope: PublishedScopeType;
  quantity: Scalars['Int'];
  recommended?: Maybe<Scalars['Boolean']>;
  refId: Scalars['String'];
  shippingRequired: Scalars['Boolean'];
  subProductDetails?: Maybe<RetailSubProductDetailsProjection>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailInsuranceSearchProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  country?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
  onlyAvailable?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  priceModifiers?: InputMaybe<Scalars['Map_String_PriceModifierPayloadScalar']>;
  sorts?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type RetailInsuranceUpdateProjectionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  defaulted?: InputMaybe<Scalars['Boolean']>;
  grossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  internalReferenceName?: InputMaybe<Scalars['String']>;
  invoiceDescription?: InputMaybe<Scalars['String']>;
  invoiceName?: InputMaybe<Scalars['String']>;
  partnerName?: InputMaybe<Scalars['String']>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  publishedScope?: InputMaybe<PublishedScopeType>;
  quantity?: InputMaybe<Scalars['Int']>;
  recommended?: InputMaybe<Scalars['Boolean']>;
  shippingRequired?: InputMaybe<Scalars['Boolean']>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
};

export enum RetailInternalTransferPaymentCategory {
  Chargeback = 'CHARGEBACK',
  ChequePaymentsBatch = 'CHEQUE_PAYMENTS_BATCH',
  DuplicateFinancingAmount = 'DUPLICATE_FINANCING_AMOUNT',
  FinancingKickback = 'FINANCING_KICKBACK',
  Internal = 'INTERNAL',
  Legal = 'LEGAL',
  Logistic = 'LOGISTIC',
  NotApplicablePayment = 'NOT_APPLICABLE_PAYMENT',
  OnHold = 'ON_HOLD',
  OutgoingRefund = 'OUTGOING_REFUND',
  Overpayment = 'OVERPAYMENT',
  PenaltyFees = 'PENALTY_FEES',
  RatenkaufFinancing = 'RATENKAUF_FINANCING',
  Refund = 'REFUND',
  RejectedOutboundPayment = 'REJECTED_OUTBOUND_PAYMENT',
  Test = 'TEST'
}

export type RetailInternalTransferPaymentProjection = {
  __typename?: 'RetailInternalTransferPaymentProjection';
  accountData?: Maybe<BankAccountDetailsProjection>;
  accountingDate?: Maybe<Scalars['String']>;
  amount?: Maybe<RetailFinancePriceProjection>;
  category?: Maybe<RetailInternalTransferPaymentCategory>;
  comments?: Maybe<Array<Maybe<RetailPaymentCommentProjection>>>;
  countryCode?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  creationSource?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  importDetails?: Maybe<RetailPaymentImportDetailsProjection>;
  retailTransactionId?: Maybe<Scalars['UUID']>;
  status?: Maybe<RetailPaymentStatus>;
  type?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailInvoiceCreateProjectionInput = {
  currencyCode?: InputMaybe<Scalars['String']>;
  invoicedOn?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  vatKey?: InputMaybe<Scalars['String']>;
};

export type RetailLeadCreateProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  auxUserId?: InputMaybe<Scalars['UUID']>;
  contactType?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstContactAt?: InputMaybe<Scalars['String']>;
  leadType?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['UUID']>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  platform?: InputMaybe<Scalars['String']>;
  rejectType?: InputMaybe<Scalars['String']>;
  requestedTime?: InputMaybe<Scalars['String']>;
  salesNote?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type RetailLeadProjection = {
  __typename?: 'RetailLeadProjection';
  adId?: Maybe<Scalars['UUID']>;
  auxUserId?: Maybe<Scalars['UUID']>;
  contactType?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstContactAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  leadType?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['UUID']>;
  phoneNumber?: Maybe<PhoneNumber>;
  platform?: Maybe<Scalars['String']>;
  rejectType?: Maybe<Scalars['String']>;
  requestedTime?: Maybe<Scalars['String']>;
  salesNote?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type RetailLeadProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  auxUserId?: InputMaybe<Scalars['UUID']>;
  contactType?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstContactAt?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  leadType?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['UUID']>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  platform?: InputMaybe<Scalars['String']>;
  rejectType?: InputMaybe<Scalars['String']>;
  requestedTime?: InputMaybe<Scalars['String']>;
  salesNote?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type RetailManualFullEvaluationProjectionV2Input = {
  currency?: InputMaybe<Scalars['String']>;
  hasCreditCard: Scalars['Boolean'];
  monthlyNetIncomePerMonth?: InputMaybe<Array<InputMaybe<MonthlyNetIncomeProjectionInput>>>;
  riskProvider?: InputMaybe<Scalars['String']>;
  riskScore?: InputMaybe<Scalars['String']>;
};

export type RetailOrderAccountDetailsCreateProjectionInput = {
  account?: InputMaybe<RetailAccountDetailsCreateProjectionInput>;
  description?: InputMaybe<Scalars['String']>;
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  typedAccount?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
};

export type RetailOrderAccountDetailsProjection = {
  __typename?: 'RetailOrderAccountDetailsProjection';
  account?: Maybe<BankAccountDetailsProjection>;
  description?: Maybe<Scalars['String']>;
  documentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  typedAccount?: Maybe<BankTypedAccountDetailsProjection>;
  uploadSource?: Maybe<Scalars['String']>;
};

export enum RetailOrderBasedTaskAction {
  AddedByCustomerInDashboard = 'ADDED_BY_CUSTOMER_IN_DASHBOARD',
  AdditionalInformationIsNeeded = 'ADDITIONAL_INFORMATION_IS_NEEDED',
  ApplicationApproved = 'APPLICATION_APPROVED',
  ApplicationCancelled = 'APPLICATION_CANCELLED',
  ApplicationPreApproved = 'APPLICATION_PRE_APPROVED',
  ApplicationRejected = 'APPLICATION_REJECTED',
  ApplicationSentToTheBank = 'APPLICATION_SENT_TO_THE_BANK',
  Approved = 'APPROVED',
  ApprovedPaymentShifting = 'APPROVED_PAYMENT_SHIFTING',
  ApprovedRefund = 'APPROVED_REFUND',
  BankDetailsAdded = 'BANK_DETAILS_ADDED',
  BankDetailsNoLongerNeeded = 'BANK_DETAILS_NO_LONGER_NEEDED',
  Canceled = 'CANCELED',
  CanceledAutoCloseBySystem = 'CANCELED_AUTO_CLOSE_BY_SYSTEM',
  CancellationRequestConfirmed = 'CANCELLATION_REQUEST_CONFIRMED',
  CancellationRequestRevoked = 'CANCELLATION_REQUEST_REVOKED',
  CancelledAutomaticallyDueToAutomaticVerification = 'CANCELLED_AUTOMATICALLY_DUE_TO_AUTOMATIC_VERIFICATION',
  CarDroppedOffByCustomer = 'CAR_DROPPED_OFF_BY_CUSTOMER',
  CarPickedUpByAh = 'CAR_PICKED_UP_BY_AH',
  ClaimCostNoLongerCompleted = 'CLAIM_COST_NO_LONGER_COMPLETED',
  ClaimPartialRefundCompleted = 'CLAIM_PARTIAL_REFUND_COMPLETED',
  Close = 'CLOSE',
  ClosedDueToOrderCancellationReversal = 'CLOSED_DUE_TO_ORDER_CANCELLATION_REVERSAL',
  Completed = 'COMPLETED',
  ConfirmFinContract = 'CONFIRM_FIN_CONTRACT',
  CustomerAddedProof = 'CUSTOMER_ADDED_PROOF',
  CustomerDidNotAddProof = 'CUSTOMER_DID_NOT_ADD_PROOF',
  DamageCompensationApproved = 'DAMAGE_COMPENSATION_APPROVED',
  DeregistrationDone = 'DEREGISTRATION_DONE',
  DocsSubmitted = 'DOCS_SUBMITTED',
  DocumentsReturned = 'DOCUMENTS_RETURNED',
  InitialFinContractSent = 'INITIAL_FIN_CONTRACT_SENT',
  NewBankDetailsRequired = 'NEW_BANK_DETAILS_REQUIRED',
  Open = 'OPEN',
  ProofWasReviewed = 'PROOF_WAS_REVIEWED',
  Rejected = 'REJECTED',
  Reopen = 'REOPEN',
  RequestDocs = 'REQUEST_DOCS',
  Success = 'SUCCESS',
  SuccessAutoCloseBySystem = 'SUCCESS_AUTO_CLOSE_BY_SYSTEM',
  UpdatedFinContractSent = 'UPDATED_FIN_CONTRACT_SENT'
}

export type RetailOrderCarRegistrationDto = {
  __typename?: 'RetailOrderCarRegistrationDTO';
  id?: Maybe<Scalars['UUID']>;
  status?: Maybe<Scalars['String']>;
};

export type RetailOrderContractAdProjection = {
  __typename?: 'RetailOrderContractAdProjection';
  id?: Maybe<Scalars['UUID']>;
  price?: Maybe<Price>;
  source?: Maybe<Source>;
  vatRate?: Maybe<Scalars['Float']>;
  vehicle?: Maybe<RetailOrderContractVehicleProjection>;
};

export type RetailOrderContractFeatureStatusProjection = {
  __typename?: 'RetailOrderContractFeatureStatusProjection';
  contractDsaRemovalAutohero?: Maybe<Scalars['Boolean']>;
  displayInsuranceTaxNotice?: Maybe<Scalars['Boolean']>;
  millageInLocalUnitsAH?: Maybe<Scalars['Boolean']>;
  showVatAutohero?: Maybe<Scalars['Boolean']>;
  showVatBackOffice?: Maybe<Scalars['Boolean']>;
};

export type RetailOrderContractOrderItemProjection = {
  __typename?: 'RetailOrderContractOrderItemProjection';
  currencyCode?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type RetailOrderContractProductProjection = {
  __typename?: 'RetailOrderContractProductProjection';
  id?: Maybe<Scalars['UUID']>;
  insuranceTax?: Maybe<Scalars['String']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  itemDiscountNet?: Maybe<PriceDto>;
  itemPriceGross?: Maybe<PriceDto>;
  itemPriceNet?: Maybe<PriceDto>;
  level?: Maybe<Scalars['String']>;
  monthsCovered?: Maybe<Scalars['Int']>;
  productType?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  refId?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type RetailOrderContractProjection = {
  __typename?: 'RetailOrderContractProjection';
  ad?: Maybe<RetailOrderContractAdProjection>;
  billingAddress?: Maybe<RetailOrderContractRetailAddressProjection>;
  createReason?: Maybe<CreateReason>;
  deliveryHandover?: Maybe<RetailOrderContractRetailOrderHandoverProjection>;
  documentShippingAddress?: Maybe<RetailOrderContractRetailAddressProjection>;
  features?: Maybe<RetailOrderContractFeatureStatusProjection>;
  financingDataPDF?: Maybe<Array<Maybe<RetailOrderContractRetailFinancingPlanBreakdownProjection>>>;
  financingPlan?: Maybe<RetailOrderContractRetailFinancingPlanProjection>;
  linkedOrders?: Maybe<Array<Maybe<RetailOrderContractRetailOrderProjection>>>;
  order?: Maybe<RetailOrderContractRetailOrderProjection>;
  orderCustomer?: Maybe<RetailOrderContractRetailCustomerProjection>;
  orderType?: Maybe<OrderType>;
  products?: Maybe<Array<Maybe<RetailOrderContractProductProjection>>>;
  retailAd?: Maybe<RetailOrderContractRetailAdProjection>;
  shippingAddress?: Maybe<RetailOrderContractRetailAddressProjection>;
  taxBPM?: Maybe<RetailTaxBpmProjection>;
  tradeInItem?: Maybe<RetailOrderContractOrderItemProjection>;
};

export type RetailOrderContractRetailAdProjection = {
  __typename?: 'RetailOrderContractRetailAdProjection';
  contractDetails?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  retailCountryLicencePlate?: Maybe<Scalars['String']>;
  secondaryWheels?: Maybe<RetailOrderContractSecondaryWheelsProjection>;
};

export type RetailOrderContractRetailAddressProjection = {
  __typename?: 'RetailOrderContractRetailAddressProjection';
  branchName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type RetailOrderContractRetailCustomerProjection = {
  __typename?: 'RetailOrderContractRetailCustomerProjection';
  customerId?: Maybe<Scalars['String']>;
  customerType?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  ssn?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
};

export type RetailOrderContractRetailFinancingPlanBreakdownProjection = {
  __typename?: 'RetailOrderContractRetailFinancingPlanBreakdownProjection';
  cashAndFinancingWithInterest?: Maybe<Scalars['Long']>;
  cashAndFinancingWithoutInterest?: Maybe<Scalars['Long']>;
  installmentsSum?: Maybe<Scalars['Long']>;
  interestSum?: Maybe<Scalars['Long']>;
  monthlyRate?: Maybe<Scalars['Long']>;
  totalRepayment?: Maybe<Scalars['Long']>;
};

export type RetailOrderContractRetailFinancingPlanProjection = {
  __typename?: 'RetailOrderContractRetailFinancingPlanProjection';
  financingProducts?: Maybe<Array<Maybe<RetailOrderContractRetailFinancingTierProductProjection>>>;
  id?: Maybe<Scalars['UUID']>;
};

export type RetailOrderContractRetailFinancingTierProductProjection = {
  __typename?: 'RetailOrderContractRetailFinancingTierProductProjection';
  id: Scalars['UUID'];
  monthlyPayment: Scalars['Long'];
  price: Scalars['Long'];
  priceInterestFree: Scalars['Long'];
  tierId: Scalars['UUID'];
  type: Scalars['String'];
};

export type RetailOrderContractRetailOrderHandoverProjection = {
  __typename?: 'RetailOrderContractRetailOrderHandoverProjection';
  id?: Maybe<Scalars['UUID']>;
  onDeliveryPaymentType?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
};

export type RetailOrderContractRetailOrderProjection = {
  __typename?: 'RetailOrderContractRetailOrderProjection';
  amountInWords?: Maybe<Scalars['String']>;
  contractSignedOn?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  creditAmount?: Maybe<PriceDto>;
  depositAmount?: Maybe<PriceDto>;
  depositDueDate?: Maybe<Scalars['String']>;
  editable?: Maybe<Scalars['Boolean']>;
  financingType?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  items?: Maybe<Array<Maybe<RetailOrderContractOrderItemProjection>>>;
  locale?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  remainingCashAmount?: Maybe<PriceDto>;
  retailCountry?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  totalPriceGross?: Maybe<PriceDto>;
  totalPriceNet?: Maybe<PriceDto>;
  totalTaxAdvantage?: Maybe<PriceDto>;
  totalVat?: Maybe<PriceDto>;
  type?: Maybe<Scalars['String']>;
  verifiedOn?: Maybe<Scalars['String']>;
};

export type RetailOrderContractRetailVehicleLicensePlateProjection = {
  __typename?: 'RetailOrderContractRetailVehicleLicensePlateProjection';
  licensePlate?: Maybe<Scalars['String']>;
};

export type RetailOrderContractSecondaryWheelsProjection = {
  __typename?: 'RetailOrderContractSecondaryWheelsProjection';
  damageCondition?: Maybe<SecondaryWheelsDamageCondition>;
  id?: Maybe<Scalars['UUID']>;
  rimsDetails?: Maybe<Scalars['Map_WheelPlacementType_RimDetailsScalar']>;
  tiresDetails?: Maybe<Scalars['Map_WheelPlacementType_TireDetailsScalar']>;
};

export type RetailOrderContractVehicleProjection = {
  __typename?: 'RetailOrderContractVehicleProjection';
  accidentCar?: Maybe<Scalars['Boolean']>;
  builtYear?: Maybe<Scalars['Int']>;
  carPreownerCount?: Maybe<Scalars['Int']>;
  countryOfOrigin?: Maybe<Scalars['String']>;
  emissionStandardCode?: Maybe<Scalars['Int']>;
  fuelType?: Maybe<Scalars['String']>;
  fuelTypeCode?: Maybe<Scalars['Int']>;
  handoverKeyCount?: Maybe<Scalars['Int']>;
  horsePower?: Maybe<Scalars['Int']>;
  inspectionExpiryDate?: Maybe<Scalars['String']>;
  kw?: Maybe<Scalars['BigDecimal']>;
  lastServiceOn?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  mileageDTO?: Maybe<MileageDto>;
  model?: Maybe<Scalars['String']>;
  modelApi?: Maybe<ModelDto>;
  originalEngine?: Maybe<Scalars['Boolean']>;
  registration?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  vatType?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['String']>;
  wasInCommercialUse?: Maybe<Scalars['Boolean']>;
};

export type RetailOrderDto = {
  __typename?: 'RetailOrderDTO';
  amountInWords?: Maybe<Scalars['String']>;
  auxUserId?: Maybe<Scalars['UUID']>;
  branchCheckupOn?: Maybe<Scalars['String']>;
  branchId?: Maybe<Scalars['Int']>;
  cancelDescription?: Maybe<Scalars['String']>;
  cancelPeriod?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelRequest?: Maybe<CancelRequestDto>;
  cancelRequestSubreason?: Maybe<Scalars['String']>;
  cancelType?: Maybe<Scalars['String']>;
  canceledOn?: Maybe<Scalars['String']>;
  carRegistration?: Maybe<RetailOrderCarRegistrationDto>;
  carRegistrationStatus?: Maybe<CarRegistrationStatusDictionaryDto>;
  carReservedOn?: Maybe<Scalars['String']>;
  checkoutBranchId?: Maybe<Scalars['Int']>;
  checkoutPaymentDetails?: Maybe<Scalars['ObjectScalar']>;
  completed?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  contractSentOn?: Maybe<Scalars['String']>;
  contractSigned?: Maybe<Scalars['Boolean']>;
  contractSignedOn?: Maybe<Scalars['String']>;
  conversionType?: Maybe<Scalars['String']>;
  createReason?: Maybe<Scalars['String']>;
  createSubreason?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  creditAmount?: Maybe<PriceDto>;
  creditAmountMinorUnits?: Maybe<Scalars['Long']>;
  customerCountry?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  delivery?: Maybe<RetailOrderDeliveryDto>;
  deliveryETA?: Maybe<RangeDto_Integer>;
  deliveryRoute?: Maybe<Array<Maybe<RetailDeliveryRouteItemDto>>>;
  depositAmount?: Maybe<PriceDto>;
  depositAmountMinorUnits?: Maybe<Scalars['Long']>;
  depositDueDate?: Maybe<Scalars['String']>;
  depositPaidOn?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  documentHandoverOn?: Maybe<Scalars['String']>;
  draftOn?: Maybe<Scalars['String']>;
  finalDocumentHandoverOn?: Maybe<Scalars['String']>;
  finalPaymentDueDate?: Maybe<Scalars['String']>;
  financing?: Maybe<OrderFinancingDto>;
  flowVersion?: Maybe<Scalars['String']>;
  fullPaymentDueDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  isAhCheckoutByAgent?: Maybe<Scalars['Boolean']>;
  isBranchCheckupSuccessful?: Maybe<Scalars['Boolean']>;
  isCarInBranch?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  isOracle?: Maybe<Scalars['Boolean']>;
  isTest?: Maybe<Scalars['Boolean']>;
  isTradeIn?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<RetailOrderItemDto>>>;
  leadSource?: Maybe<Scalars['String']>;
  linkedOrders?: Maybe<Array<Maybe<LinkedOrdersDto>>>;
  locale?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  overallDelayCalendarDays?: Maybe<Scalars['Int']>;
  ownerId?: Maybe<Scalars['UUID']>;
  paidCashAmount?: Maybe<PriceDto>;
  paidOn?: Maybe<Scalars['String']>;
  payment?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  pendingVerificationOn?: Maybe<Scalars['String']>;
  plannedCarHandoverLastDefinedOn?: Maybe<Scalars['String']>;
  plannedCarHandoverOn?: Maybe<Scalars['String']>;
  remainingAmount?: Maybe<PriceDto>;
  remainingCashAmount?: Maybe<PriceDto>;
  reservationEndOn?: Maybe<Scalars['String']>;
  retailCountry?: Maybe<Scalars['String']>;
  retailOrderLinks?: Maybe<Array<Maybe<RetailOrderLinkDto>>>;
  salesAgentAssignedTo?: Maybe<Scalars['UUID']>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  subCancelReason?: Maybe<Scalars['String']>;
  totalPriceGross?: Maybe<PriceDto>;
  totalPriceNet?: Maybe<PriceDto>;
  totalTaxAdvantage?: Maybe<PriceDto>;
  totalVat?: Maybe<PriceDto>;
  transportOrdered?: Maybe<Scalars['String']>;
  trialExpiredOn?: Maybe<Scalars['String']>;
  trialReturnOn?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vehicleDeliveredOn?: Maybe<Scalars['String']>;
  verifiedOn?: Maybe<Scalars['String']>;
  visibleForCustomerWhenCancelled?: Maybe<Scalars['Boolean']>;
  yellowPlatesRequired?: Maybe<Scalars['Boolean']>;
};

export type RetailOrderDebtCsvDataProjection = {
  __typename?: 'RetailOrderDebtCSVDataProjection';
  createdOn?: Maybe<Scalars['String']>;
  customerBillingAddress?: Maybe<Scalars['String']>;
  customerBillingAddressCity?: Maybe<Scalars['String']>;
  customerBillingAddressZipCode?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerName2?: Maybe<Scalars['String']>;
  customerPhoneNumber?: Maybe<Scalars['String']>;
  customerSalutation?: Maybe<Scalars['String']>;
  debtAmount?: Maybe<RetailDebtPriceDto>;
  debtType?: Maybe<Scalars['String']>;
  documentType?: Maybe<Scalars['String']>;
  dunningState?: Maybe<Scalars['Int']>;
  orderAmount?: Maybe<RetailDebtPriceDto>;
  orderCreatedOn?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
};

export type RetailOrderDebtCommentProjection = {
  __typename?: 'RetailOrderDebtCommentProjection';
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  debtId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
  text?: Maybe<Scalars['String']>;
};

export type RetailOrderDebtProjection = {
  __typename?: 'RetailOrderDebtProjection';
  comments?: Maybe<Array<Maybe<RetailOrderDebtCommentProjection>>>;
  createdOn?: Maybe<Scalars['String']>;
  debtAmount?: Maybe<RetailDebtPriceDto>;
  eligibleDays?: Maybe<Scalars['Int']>;
  eligibleOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderAmount?: Maybe<RetailDebtPriceDto>;
  orderCountry?: Maybe<Scalars['String']>;
  orderCreatedOn?: Maybe<Scalars['String']>;
  orderFinancingFlow?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderPaymentFlow?: Maybe<Scalars['String']>;
  orderVehicleDeliveredOn?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RetailOrderDebtUpdateProjectionInput = {
  paidOffOn?: InputMaybe<Scalars['String']>;
};

export type RetailOrderDeliveryDto = {
  __typename?: 'RetailOrderDeliveryDTO';
  branchDeliverySelectionCriteria?: Maybe<Scalars['String']>;
  branchPlannedCarHandoverLastDefinedOn?: Maybe<Scalars['String']>;
  branchPlannedCarHandoverOn?: Maybe<Scalars['String']>;
  delayReason?: Maybe<Scalars['String']>;
  deliveryAddressChangeApplied?: Maybe<Scalars['Boolean']>;
  deliveryEtaType?: Maybe<Scalars['String']>;
  deliveryLocationType?: Maybe<Scalars['String']>;
  deliveryType?: Maybe<Scalars['String']>;
  estimationId?: Maybe<Scalars['UUID']>;
  expressDelivery?: Maybe<Scalars['Boolean']>;
  handoverDateSource?: Maybe<Scalars['String']>;
  homeDeliveryExtraMileage?: Maybe<Scalars['Int']>;
  homeTransportationMethod?: Maybe<Scalars['String']>;
  hubId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  logisticsDeliveryOption?: Maybe<Scalars['String']>;
  logisticsEtaInCalendarDays?: Maybe<Scalars['Int']>;
  orderDeliveryEtaEntries?: Maybe<Array<Maybe<OrderDeliveryEtaEntryDto>>>;
  orderId?: Maybe<Scalars['UUID']>;
  plannedHandoverFrom?: Maybe<Scalars['String']>;
  plannedHandoverLastDefinedOn?: Maybe<Scalars['String']>;
  plannedHandoverTo?: Maybe<Scalars['String']>;
  retailDeliveryOption?: Maybe<Scalars['String']>;
  tradeInCompatible?: Maybe<Scalars['Boolean']>;
};

export type RetailOrderDeliveryDtoInput = {
  branchDeliverySelectionCriteria?: InputMaybe<Scalars['String']>;
  branchPlannedCarHandoverLastDefinedOn?: InputMaybe<Scalars['String']>;
  branchPlannedCarHandoverOn?: InputMaybe<Scalars['String']>;
  delayReason?: InputMaybe<Scalars['String']>;
  deliveryAddressChangeApplied?: InputMaybe<Scalars['Boolean']>;
  deliveryEtaType?: InputMaybe<Scalars['String']>;
  deliveryLocationType?: InputMaybe<Scalars['String']>;
  deliveryType?: InputMaybe<Scalars['String']>;
  estimationId?: InputMaybe<Scalars['UUID']>;
  expressDelivery?: InputMaybe<Scalars['Boolean']>;
  handoverDateSource?: InputMaybe<Scalars['String']>;
  homeDeliveryExtraMileage?: InputMaybe<Scalars['Int']>;
  homeTransportationMethod?: InputMaybe<Scalars['String']>;
  hubId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  logisticsDeliveryOption?: InputMaybe<Scalars['String']>;
  logisticsEtaInCalendarDays?: InputMaybe<Scalars['Int']>;
  orderDeliveryEtaEntries?: InputMaybe<Array<InputMaybe<OrderDeliveryEtaEntryDtoInput>>>;
  orderId?: InputMaybe<Scalars['UUID']>;
  plannedHandoverFrom?: InputMaybe<Scalars['String']>;
  plannedHandoverLastDefinedOn?: InputMaybe<Scalars['String']>;
  plannedHandoverTo?: InputMaybe<Scalars['String']>;
  retailDeliveryOption?: InputMaybe<Scalars['String']>;
  tradeInCompatible?: InputMaybe<Scalars['Boolean']>;
};

export type RetailOrderDeliveryDetailsProjection = {
  __typename?: 'RetailOrderDeliveryDetailsProjection';
  activeRouteBasedETA?: Maybe<Scalars['String']>;
  branchDeliverySelectionCriteria?: Maybe<Scalars['String']>;
  branchPlannedCarHandoverOn?: Maybe<Scalars['String']>;
  deliveryAddressChangeApplied?: Maybe<Scalars['Boolean']>;
  deliveryETAType?: Maybe<Scalars['String']>;
  deliveryLocationType?: Maybe<Scalars['String']>;
  deliveryType?: Maybe<Scalars['String']>;
  handoverDateSource?: Maybe<Scalars['String']>;
  homeDeliveryExtraMileage?: Maybe<Scalars['Int']>;
  orderDeliveryEtaEntries?: Maybe<Array<Maybe<OrderDeliveryEtaEntryDto>>>;
  plannedHandoverFrom?: Maybe<Scalars['String']>;
  plannedHandoverTo?: Maybe<Scalars['String']>;
  retailDeliveryOption?: Maybe<Scalars['String']>;
  tradeInCompatible?: Maybe<Scalars['Boolean']>;
};

export type RetailOrderDeliveryProjection = {
  __typename?: 'RetailOrderDeliveryProjection';
  branchDeliverySelectionCriteria?: Maybe<Scalars['String']>;
  branchPlannedCarHandoverOn?: Maybe<Scalars['String']>;
  deliveryAddressChangeApplied?: Maybe<Scalars['Boolean']>;
  deliveryETA?: Maybe<DeliveryEta>;
  deliveryLocationType?: Maybe<Scalars['String']>;
  homeDeliveryExtraMileage?: Maybe<Scalars['Int']>;
  orderDeliveryEtaEntries?: Maybe<Array<Maybe<OrderDeliveryEtaEntryDto>>>;
  plannedHandoverFrom?: Maybe<Scalars['String']>;
  plannedHandoverTo?: Maybe<Scalars['String']>;
  retailDeliveryOption?: Maybe<Scalars['String']>;
  tradeInCompatible?: Maybe<Scalars['Boolean']>;
};

export type RetailOrderDeliveryReschedulingReasonDto = {
  __typename?: 'RetailOrderDeliveryReschedulingReasonDTO';
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum RetailOrderFinanceCancelReason {
  ActiveFinApplicationAlreadyExists = 'ACTIVE_FIN_APPLICATION_ALREADY_EXISTS',
  Chargeback = 'CHARGEBACK',
  CustomerPayedWithCash = 'CUSTOMER_PAYED_WITH_CASH',
  Expired = 'EXPIRED',
  FinanceApplicationRevoked = 'FINANCE_APPLICATION_REVOKED',
  NewFinanceApplication = 'NEW_FINANCE_APPLICATION',
  NoDocumentsProvided = 'NO_DOCUMENTS_PROVIDED',
  OrderRevoked = 'ORDER_REVOKED',
  PreCheckoutFinancingPreApproved = 'PRE_CHECKOUT_FINANCING_PRE_APPROVED',
  Recreated = 'RECREATED',
  SwitchToExternalFlow = 'SWITCH_TO_EXTERNAL_FLOW',
  VehicleNoLongerAvailable = 'VEHICLE_NO_LONGER_AVAILABLE'
}

export enum RetailOrderFinanceCancelSubReason {
  NfaAddOrRemoveDiscount = 'NFA_ADD_OR_REMOVE_DISCOUNT',
  NfaAddOrRemoveProduct = 'NFA_ADD_OR_REMOVE_PRODUCT',
  NfaChangeAddressDobName = 'NFA_CHANGE_ADDRESS_DOB_NAME',
  NfaChangeCreditPeriod = 'NFA_CHANGE_CREDIT_PERIOD',
  NfaChangeDownpayment = 'NFA_CHANGE_DOWNPAYMENT',
  NfaChangeEmploymentDetails = 'NFA_CHANGE_EMPLOYMENT_DETAILS',
  NfaPayslipsNotMatchNetSalary = 'NFA_PAYSLIPS_NOT_MATCH_NET_SALARY'
}

export enum RetailOrderFinanceRejectReason {
  AccountOverdrawn = 'ACCOUNT_OVERDRAWN',
  CreditScoreTooLow = 'CREDIT_SCORE_TOO_LOW',
  CustomerNameMismatch = 'CUSTOMER_NAME_MISMATCH',
  CustomerUnemployed = 'CUSTOMER_UNEMPLOYED',
  DuplicateIban = 'DUPLICATE_IBAN',
  ExperianNoResult = 'EXPERIAN_NO_RESULT',
  ExperianTechnicalProblem = 'EXPERIAN_TECHNICAL_PROBLEM',
  Fraud = 'FRAUD',
  FraudIban = 'FRAUD_IBAN',
  GamblingExpenses = 'GAMBLING_EXPENSES',
  HighDebt = 'HIGH_DEBT',
  HighFinancingAmount = 'HIGH_FINANCING_AMOUNT',
  IncomeMonth = 'INCOME_MONTH',
  LengthOfEmployment = 'LENGTH_OF_EMPLOYMENT',
  LowDeposit = 'LOW_DEPOSIT',
  LowIncome = 'LOW_INCOME',
  LowSalary = 'LOW_SALARY',
  NonRetailCountryIban = 'NON_RETAIL_COUNTRY_IBAN',
  NoEligiblePlan = 'NO_ELIGIBLE_PLAN',
  RejectedByBank = 'REJECTED_BY_BANK',
  SchufaCustomerIsNotKnown = 'SCHUFA_CUSTOMER_IS_NOT_KNOWN',
  SchufaElectronicReportNotPossible = 'SCHUFA_ELECTRONIC_REPORT_NOT_POSSIBLE',
  SchufaInvalidUserData = 'SCHUFA_INVALID_USER_DATA',
  SchufaManualProcessingNotWanted = 'SCHUFA_MANUAL_PROCESSING_NOT_WANTED',
  SchufaManualProcessingRequired = 'SCHUFA_MANUAL_PROCESSING_REQUIRED',
  SchufaNoInformationForKnownCustomer = 'SCHUFA_NO_INFORMATION_FOR_KNOWN_CUSTOMER',
  SchufaNoResult = 'SCHUFA_NO_RESULT',
  SchufaScoreErrors = 'SCHUFA_SCORE_ERRORS',
  SchufaTechnicalProblem = 'SCHUFA_TECHNICAL_PROBLEM',
  SchufaUnknownError = 'SCHUFA_UNKNOWN_ERROR',
  TemporaryContract = 'TEMPORARY_CONTRACT',
  Unemployed = 'UNEMPLOYED',
  VehicleTooOld = 'VEHICLE_TOO_OLD'
}

export enum RetailOrderFinancingFlowType {
  AhDigital = 'AH_DIGITAL',
  AhManual = 'AH_MANUAL',
  Manual = 'MANUAL'
}

export enum RetailOrderFinancingFlowVersion {
  V1 = 'V1',
  V2 = 'V2'
}

export type RetailOrderHandoverProjection = {
  __typename?: 'RetailOrderHandoverProjection';
  allowedWithoutFullPayment?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  onDeliveryPaymentType?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  paymentShouldBeDoneOnDelivery?: Maybe<Scalars['Boolean']>;
};

export type RetailOrderItemDto = {
  __typename?: 'RetailOrderItemDTO';
  cartId?: Maybe<Scalars['UUID']>;
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customDiscountMinorUnits?: Maybe<Scalars['Long']>;
  customDiscountType?: Maybe<Scalars['String']>;
  disableAbTesting?: Maybe<Scalars['Boolean']>;
  discount?: Maybe<RetailOrderItemDiscountDto>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  obsolete?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['UUID']>;
  parentId?: Maybe<Scalars['UUID']>;
  pnlCostPriceGross?: Maybe<PriceDto>;
  pnlCostPriceNet?: Maybe<PriceDto>;
  pnlCostPriceVatAmount?: Maybe<PriceDto>;
  pnlCostVatRate?: Maybe<Scalars['Float']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  taxAdvantageAmountMinorUnits?: Maybe<Scalars['Long']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type RetailOrderItemDiscountDto = {
  __typename?: 'RetailOrderItemDiscountDTO';
  grossAmount?: Maybe<PriceDto>;
  type?: Maybe<Scalars['String']>;
};

export type RetailOrderLinkCreateDtoInput = {
  refId?: InputMaybe<Scalars['UUID']>;
};

export type RetailOrderLinkDto = {
  __typename?: 'RetailOrderLinkDTO';
  id?: Maybe<Scalars['UUID']>;
  linkType?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['UUID']>;
  refId?: Maybe<Scalars['UUID']>;
};

export type RetailOrderRefundsDistributeProjectionInput = {
  items?: InputMaybe<Array<InputMaybe<CreditNoteItemDistributionProjectionInput>>>;
  orderId?: InputMaybe<Scalars['UUID']>;
  reason?: InputMaybe<Scalars['String']>;
};

export type RetailOrderRefundsDistributionProjection = {
  __typename?: 'RetailOrderRefundsDistributionProjection';
  amount?: Maybe<RetailFinancePriceProjection>;
  claimDetails?: Maybe<ClaimDetailsProjection>;
  creditNoteOutboundPaymentRefs?: Maybe<Array<Maybe<CreditNoteOutboundPaymentRefDistributionProjection>>>;
  creditNotes?: Maybe<Array<Maybe<CreditNoteDistributionProjection>>>;
  inboundOutboundPaymentRefs?: Maybe<Array<Maybe<InboundOutboundPaymentRefDistributionProjection>>>;
  orderId?: Maybe<Scalars['UUID']>;
  refunds?: Maybe<Array<Maybe<RefundDistributionProjection>>>;
};

export type RetailOrderRefundsDistributionProjectionInput = {
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  claimDetails?: InputMaybe<ClaimDetailsProjectionInput>;
  creditNoteOutboundPaymentRefs?: InputMaybe<Array<InputMaybe<CreditNoteOutboundPaymentRefDistributionProjectionInput>>>;
  creditNotes?: InputMaybe<Array<InputMaybe<CreditNoteDistributionProjectionInput>>>;
  inboundOutboundPaymentRefs?: InputMaybe<Array<InputMaybe<InboundOutboundPaymentRefDistributionProjectionInput>>>;
  orderId?: InputMaybe<Scalars['UUID']>;
  refunds?: InputMaybe<Array<InputMaybe<RefundDistributionProjectionInput>>>;
};

export type RetailOrderReturnAppointmentProjection = {
  __typename?: 'RetailOrderReturnAppointmentProjection';
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderDeliveryId?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  plannedCarReturnDate?: Maybe<Scalars['String']>;
  returnDeliveryType?: Maybe<Scalars['String']>;
};

export type RetailOrderStatisticsDto = {
  __typename?: 'RetailOrderStatisticsDTO';
  ordersCTR?: Maybe<Scalars['Float']>;
  ordersPerDay?: Maybe<Scalars['Float']>;
  ordersPerWeek?: Maybe<Scalars['Float']>;
  totalOrders?: Maybe<Scalars['Long']>;
  totalOrdersCanceled?: Maybe<Scalars['Long']>;
  totalOrdersConfirmed?: Maybe<Scalars['Long']>;
};

export type RetailOrderVerificationPriorityScoringResultProjection = {
  __typename?: 'RetailOrderVerificationPriorityScoringResultProjection';
  adId?: Maybe<Scalars['UUID']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  priority?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['BigDecimal']>;
  scoringCriteriaId?: Maybe<Scalars['UUID']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailOutboundPaymentProjection = {
  __typename?: 'RetailOutboundPaymentProjection';
  accountDetails?: Maybe<BankAccountDetailsProjection>;
  accountingDate?: Maybe<Scalars['String']>;
  amount?: Maybe<PriceDto>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  creditNoteRefs?: Maybe<Array<Maybe<CreditNoteProjection>>>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  inboundPaymentRefs?: Maybe<Array<Maybe<RetailPaymentProjection>>>;
  oraclePaymentDetails?: Maybe<OutboundPaymentOracleDetailsProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  paymentType?: Maybe<Scalars['String']>;
  purpose?: Maybe<RetailOutboundPaymentPurpose>;
  reason?: Maybe<RetailOutboundPaymentReasonProjection>;
  status?: Maybe<RetailOutboundPaymentStatus>;
  typedAccountDetails?: Maybe<BankTypedAccountDetailsProjection>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export enum RetailOutboundPaymentPurpose {
  Chargeback = 'CHARGEBACK',
  CreditNoteRefund = 'CREDIT_NOTE_REFUND',
  DamageCompensation = 'DAMAGE_COMPENSATION',
  Overpayment = 'OVERPAYMENT',
  TradeInOverpayment = 'TRADE_IN_OVERPAYMENT'
}

export enum RetailOutboundPaymentReason {
  AdyenChargeback = 'ADYEN_CHARGEBACK',
  CompensationDelay = 'COMPENSATION_DELAY',
  CompensationGeneral = 'COMPENSATION_GENERAL',
  CustomerChangedBankAccount = 'CUSTOMER_CHANGED_BANK_ACCOUNT',
  DamageCompensation = 'DAMAGE_COMPENSATION',
  LawyerRequest = 'LAWYER_REQUEST',
  Other = 'OTHER',
  TradeInWithoutBankDetails = 'TRADE_IN_WITHOUT_BANK_DETAILS'
}

export type RetailOutboundPaymentReasonProjection = {
  __typename?: 'RetailOutboundPaymentReasonProjection';
  description?: Maybe<Scalars['String']>;
  type?: Maybe<RetailOutboundPaymentReason>;
};

export type RetailOutboundPaymentReasonProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<RetailOutboundPaymentReason>;
};

export enum RetailOutboundPaymentStatus {
  Failure = 'FAILURE',
  FailurePending = 'FAILURE_PENDING',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type RetailOutboundPaymentUpdateProjectionInput = {
  accountingDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type RetailPaymentCommentCreateProjectionInput = {
  paymentId?: InputMaybe<Scalars['UUID']>;
  value?: InputMaybe<Scalars['String']>;
};

export type RetailPaymentCommentProjection = {
  __typename?: 'RetailPaymentCommentProjection';
  createdBy?: Maybe<CreatedByProjection>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  paymentId?: Maybe<Scalars['UUID']>;
  paymentType?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type RetailPaymentCreateProjectionInput = {
  accountDetails?: InputMaybe<RetailAccountDetailsCreateProjectionInput>;
  accountingDate?: InputMaybe<Scalars['String']>;
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalToken?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  paymentDetails?: InputMaybe<PaymentDetailsProjectionInput>;
  type?: InputMaybe<Scalars['String']>;
  typedAccountDetails?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
};

export enum RetailPaymentCreationSource {
  AutomatedBatchImport = 'AUTOMATED_BATCH_IMPORT',
  AutomatedTradeInItem = 'AUTOMATED_TRADE_IN_ITEM',
  CitiBankApi = 'CITI_BANK_API',
  DeutscheBankApi = 'DEUTSCHE_BANK_API',
  ExternalPaymentSystem = 'EXTERNAL_PAYMENT_SYSTEM',
  InternalFinancingAutoCreation = 'INTERNAL_FINANCING_AUTO_CREATION',
  ManuallyCreated = 'MANUALLY_CREATED',
  ManualBatchImporter = 'MANUAL_BATCH_IMPORTER'
}

export type RetailPaymentDataProjection = {
  __typename?: 'RetailPaymentDataProjection';
  accountHolder?: Maybe<Scalars['String']>;
  accountingDate?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankReferenceNumber?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  codeOfTransaction?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<CurrencyCodeProjection>;
  customerPaymentReference?: Maybe<Scalars['String']>;
  endToEndReference?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  orderNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentDate?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  transactionCode?: Maybe<Scalars['Long']>;
};

export type RetailPaymentImportCreateProjectionInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export type RetailPaymentImportDetailsProjection = {
  __typename?: 'RetailPaymentImportDetailsProjection';
  customerPaymentReference?: Maybe<Scalars['String']>;
  endToEndReference?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['String']>;
  recipientBankAccount?: Maybe<Scalars['String']>;
  transactionCode?: Maybe<Scalars['String']>;
};

export type RetailPaymentImportDetailsProjectionInput = {
  customerPaymentReference?: InputMaybe<Scalars['String']>;
  endToEndReference?: InputMaybe<Scalars['String']>;
  paymentDate?: InputMaybe<Scalars['String']>;
  recipientBankAccount?: InputMaybe<Scalars['String']>;
  transactionCode?: InputMaybe<Scalars['String']>;
};

export type RetailPaymentOracleDetailsWrapperProjection = {
  __typename?: 'RetailPaymentOracleDetailsWrapperProjection';
  accountingDate?: Maybe<Scalars['String']>;
  paymentNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type RetailPaymentProjection = {
  __typename?: 'RetailPaymentProjection';
  accountDetails?: Maybe<BankAccountDetailsProjection>;
  accountingDate?: Maybe<Scalars['String']>;
  amount?: Maybe<RetailFinancePriceProjection>;
  comments?: Maybe<Array<Maybe<RetailPaymentCommentProjection>>>;
  countryCode?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  creationSource?: Maybe<RetailPaymentCreationSource>;
  disputes?: Maybe<Array<Maybe<RetailDisputeProjection>>>;
  externalId?: Maybe<Scalars['String']>;
  externalPaymentSystem?: Maybe<ExternalPaymentSystem>;
  externalToken?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  importDetails?: Maybe<RetailPaymentImportDetailsProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['String']>;
  remainingRefundAmount?: Maybe<RemainingRefundAmountProjection>;
  retailTransactionId?: Maybe<Scalars['UUID']>;
  retailTransactionStatus?: Maybe<Scalars['String']>;
  status?: Maybe<RetailPaymentStatus>;
  successDuplicateByAdyen?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  typedAccountDetails?: Maybe<Scalars['Map_String_ObjectScalar']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailPaymentProjectionInput = {
  accountDetails?: InputMaybe<BankAccountDetailsProjectionInput>;
  accountingDate?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<RetailFinancePriceProjectionInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['UUID']>;
  createdOn?: InputMaybe<Scalars['String']>;
  creationSource?: InputMaybe<RetailPaymentCreationSource>;
  disputes?: InputMaybe<Array<InputMaybe<RetailDisputeProjectionInput>>>;
  externalId?: InputMaybe<Scalars['String']>;
  externalPaymentSystem?: InputMaybe<ExternalPaymentSystem>;
  externalToken?: InputMaybe<Scalars['String']>;
  failureReason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  importDetails?: InputMaybe<RetailPaymentImportDetailsProjectionInput>;
  orderId?: InputMaybe<Scalars['UUID']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  paymentDate?: InputMaybe<Scalars['String']>;
  retailTransactionId?: InputMaybe<Scalars['UUID']>;
  retailTransactionStatus?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<RetailPaymentStatus>;
  successDuplicateByAdyen?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  typedAccountDetails?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
  updatedBy?: InputMaybe<Scalars['UUID']>;
  updatedOn?: InputMaybe<Scalars['String']>;
};

export enum RetailPaymentStatus {
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type RetailPaymentUpdateProjectionInput = {
  accountingDate?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type RetailPaymentWrapperProjection = {
  __typename?: 'RetailPaymentWrapperProjection';
  accountingDate?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['String']>;
  paymentOracleDetails?: Maybe<RetailPaymentOracleDetailsWrapperProjection>;
  paymentType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RetailPermissionDto = {
  __typename?: 'RetailPermissionDTO';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type RetailPlainDocumentCategoryProjection = {
  __typename?: 'RetailPlainDocumentCategoryProjection';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  topCategory?: Maybe<Scalars['String']>;
};

export type RetailPlainDocumentTypeProjection = {
  __typename?: 'RetailPlainDocumentTypeProjection';
  category?: Maybe<RetailPlainDocumentCategoryProjection>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subType?: Maybe<RetailDocumentSubTypeProjection>;
};

export type RetailPricingCostDto = {
  __typename?: 'RetailPricingCostDTO';
  amountMinorUnits?: Maybe<Scalars['Int']>;
  currencyCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  note?: Maybe<Scalars['String']>;
  retailAdId?: Maybe<Scalars['UUID']>;
  retailPricingCostType?: Maybe<RetailPricingCostType>;
};

export enum RetailPricingCostType {
  EstimatedRepairMaintenance = 'ESTIMATED_REPAIR_MAINTENANCE',
  EstimatedRepairMechanical = 'ESTIMATED_REPAIR_MECHANICAL',
  EstimatedRepairOptical = 'ESTIMATED_REPAIR_OPTICAL',
  EstimatedRepairOther = 'ESTIMATED_REPAIR_OTHER'
}

export enum RetailProductCategory {
  Car = 'CAR',
  CustomLicensePlate = 'CUSTOM_LICENSE_PLATE',
  Product = 'PRODUCT',
  Refurbishment = 'REFURBISHMENT',
  Service = 'SERVICE',
  Tax = 'TAX',
  Transport = 'TRANSPORT',
  Warranty = 'WARRANTY'
}

export type RetailProductDto = {
  __typename?: 'RetailProductDTO';
  active?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  defaulted?: Maybe<Scalars['Boolean']>;
  depositApplicable?: Maybe<Scalars['Boolean']>;
  depositPercentage?: Maybe<Scalars['Float']>;
  excludedFromInvoice?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceItemGross?: Maybe<ProductPriceDto>;
  invoiceItemGrossMinorUnits?: Maybe<Scalars['Long']>;
  invoiceItemNet?: Maybe<ProductPriceDto>;
  invoiceItemNetMinorUnits?: Maybe<Scalars['Long']>;
  invoiceItemVatAmount?: Maybe<ProductPriceDto>;
  invoiceItemVatAmountMinorUnits?: Maybe<Scalars['Long']>;
  paidService?: Maybe<Scalars['Boolean']>;
  partner?: Maybe<Scalars['String']>;
  pnlCostGross?: Maybe<ProductPriceDto>;
  pnlCostGrossMinorUnits?: Maybe<Scalars['Long']>;
  pnlCostNet?: Maybe<ProductPriceDto>;
  pnlCostNetMinorUnits?: Maybe<Scalars['Long']>;
  pnlCostVatAmount?: Maybe<ProductPriceDto>;
  pnlCostVatAmountMinorUnits?: Maybe<Scalars['Long']>;
  pnlVatApplicable?: Maybe<Scalars['Boolean']>;
  pnlVatRate?: Maybe<Scalars['Float']>;
  properties?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  shippingRequired?: Maybe<Scalars['Boolean']>;
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type RetailProductProjection = {
  __typename?: 'RetailProductProjection';
  active: Scalars['Boolean'];
  category?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  depositApplicable: Scalars['Boolean'];
  depositPercentage?: Maybe<Scalars['Float']>;
  excludedFromInvoice?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceItemGross?: Maybe<PriceDto>;
  invoiceItemGrossMinorUnits?: Maybe<Scalars['Long']>;
  invoiceItemNet?: Maybe<PriceDto>;
  invoiceItemNetMinorUnits?: Maybe<Scalars['Long']>;
  invoiceItemVatAmount?: Maybe<PriceDto>;
  invoiceItemVatAmountMinorUnits?: Maybe<Scalars['Long']>;
  paidService: Scalars['Boolean'];
  partner?: Maybe<Scalars['String']>;
  pnlCostGross?: Maybe<PriceDto>;
  pnlCostGrossMinorUnits?: Maybe<Scalars['Long']>;
  pnlCostNet?: Maybe<PriceDto>;
  pnlCostNetMinorUnits?: Maybe<Scalars['Long']>;
  pnlCostVatAmount?: Maybe<PriceDto>;
  pnlCostVatAmountMinorUnits?: Maybe<Scalars['Long']>;
  pnlVatApplicable: Scalars['Boolean'];
  pnlVatRate?: Maybe<Scalars['Float']>;
  properties?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  shippingRequired: Scalars['Boolean'];
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  vatKey?: Maybe<VatKeyType>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type RetailRefundCreateProjectionInput = {
  accountDetails?: InputMaybe<RetailAccountDetailsCreateProjectionInput>;
  accountingDate?: InputMaybe<Scalars['String']>;
  amountMinorUnits: Scalars['Long'];
  claimDetails?: InputMaybe<ClaimDetailsProjectionInput>;
  creditNoteId?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  paymentId?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<Scalars['String']>;
  typedAccountDetails?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
};

export type RetailRefundProjection = {
  __typename?: 'RetailRefundProjection';
  accountDetails?: Maybe<BankAccountDetailsProjection>;
  accountingDate?: Maybe<Scalars['String']>;
  amount?: Maybe<PriceDto>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  creditNoteId?: Maybe<Scalars['UUID']>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  paymentId?: Maybe<Scalars['UUID']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typedAccountDetails?: Maybe<BankTypedAccountDetailsProjection>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailRefundRetryProjectionInput = {
  amountMinorUnits: Scalars['Long'];
};

export type RetailRefundUpdateProjectionInput = {
  accountingDate?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
};

export type RetailRefurbishmentStateChangeDtoInput = {
  cancelDescription?: InputMaybe<Scalars['String']>;
  cancelReason?: InputMaybe<Scalars['String']>;
  completedReason?: InputMaybe<Scalars['String']>;
  lastTransitionId?: InputMaybe<Scalars['UUID']>;
  sourceType?: InputMaybe<Scalars['String']>;
  stateTo?: InputMaybe<Scalars['String']>;
  transitionDate?: InputMaybe<Scalars['String']>;
};

export type RetailRoleDto = {
  __typename?: 'RetailRoleDTO';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endpoint?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<RetailGroupDto>>>;
  id?: Maybe<Scalars['UUID']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<RetailPermissionDto>>>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailRouteAddressProjection = {
  __typename?: 'RetailRouteAddressProjection';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type RetailRouteItemProjection = {
  __typename?: 'RetailRouteItemProjection';
  city?: Maybe<Scalars['String']>;
  completedOn?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['Int']>;
  eta?: Maybe<Scalars['String']>;
  itemType?: Maybe<Scalars['String']>;
  plannedCarHandoverOn?: Maybe<Scalars['String']>;
  plannedHandoverFrom?: Maybe<Scalars['String']>;
  plannedHandoverTo?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type RetailSalesScoreboardConfigProjection = {
  __typename?: 'RetailSalesScoreboardConfigProjection';
  countriesConfig?: Maybe<Array<Maybe<RetailSalesScoreboardCountryConfigProjection>>>;
};

export type RetailSalesScoreboardConfigRequestProjectionInput = {
  countryCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RetailSalesScoreboardCountryConfigProjection = {
  __typename?: 'RetailSalesScoreboardCountryConfigProjection';
  cancelledSignings?: Maybe<RetailSalesScoreboardParameterConfigProjection>;
  countryCode?: Maybe<Scalars['String']>;
  financingSignings?: Maybe<RetailSalesScoreboardParameterConfigProjection>;
  gapInsuredFinancingSignings?: Maybe<RetailSalesScoreboardParameterConfigProjection>;
  netSignings?: Maybe<RetailSalesScoreboardParameterConfigProjection>;
  ppiInsuredFinancingSignings?: Maybe<RetailSalesScoreboardParameterConfigProjection>;
  premium12Warranties?: Maybe<RetailSalesScoreboardParameterConfigProjection>;
  premium24Warranties?: Maybe<RetailSalesScoreboardParameterConfigProjection>;
  premium36Warranties?: Maybe<RetailSalesScoreboardParameterConfigProjection>;
  premiumWarranties?: Maybe<RetailSalesScoreboardParameterConfigProjection>;
  secondaryWheels?: Maybe<RetailSalesScoreboardParameterConfigProjection>;
  tradeIns?: Maybe<RetailSalesScoreboardParameterConfigProjection>;
};

export type RetailSalesScoreboardParameterConfigProjection = {
  __typename?: 'RetailSalesScoreboardParameterConfigProjection';
  enabled?: Maybe<Scalars['Boolean']>;
  multiplier?: Maybe<Scalars['BigDecimal']>;
};

export type RetailSalesScoreboardResultProjection = {
  __typename?: 'RetailSalesScoreboardResultProjection';
  cancelledSignings?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  financingPercentOfNetSignings?: Maybe<Scalars['BigDecimal']>;
  financingSignings?: Maybe<Scalars['Int']>;
  gapInsuredFinancingSignings?: Maybe<Scalars['Int']>;
  gapInsuredPercentOfNetFinancingSignings?: Maybe<Scalars['BigDecimal']>;
  netSignings?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['Int']>;
  ppiInsuredFinancingSignings?: Maybe<Scalars['Int']>;
  ppiInsuredPercentOfNetFinancingSignings?: Maybe<Scalars['BigDecimal']>;
  premium12Warranties?: Maybe<Scalars['Int']>;
  premium12WarrantiesPercentOfNetSignings?: Maybe<Scalars['BigDecimal']>;
  premium24Warranties?: Maybe<Scalars['Int']>;
  premium24WarrantiesPercentOfNetSignings?: Maybe<Scalars['BigDecimal']>;
  premium36Warranties?: Maybe<Scalars['Int']>;
  premium36WarrantiesPercentOfNetSignings?: Maybe<Scalars['BigDecimal']>;
  premiumWarranties?: Maybe<Scalars['Int']>;
  premiumWarrantiesPercentOfNetSignings?: Maybe<Scalars['BigDecimal']>;
  salesAgent?: Maybe<UserProjection>;
  score?: Maybe<Scalars['BigDecimal']>;
  secondaryWheels?: Maybe<Scalars['Int']>;
  secondaryWheelsPercentOfNetSignings?: Maybe<Scalars['BigDecimal']>;
  tradeIns?: Maybe<Scalars['Int']>;
  tradeInsPercentOfNetSignings?: Maybe<Scalars['BigDecimal']>;
};

export type RetailSalesScoreboardSearchProjectionInput = {
  cohort?: InputMaybe<Scalars['String']>;
  countryCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  period?: InputMaybe<Scalars['Int']>;
  periodType?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type RetailSubClaimCommentCreateProjectionInput = {
  sourceType?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  visibleForCustomer?: InputMaybe<Scalars['Boolean']>;
};

export type RetailSubClaimCommentProjection = {
  __typename?: 'RetailSubClaimCommentProjection';
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  sourceType?: Maybe<Scalars['String']>;
  subClaimId?: Maybe<Scalars['UUID']>;
  text?: Maybe<Scalars['String']>;
  visibleForCustomer?: Maybe<Scalars['Boolean']>;
};

export type RetailSubClaimCreateDocumentProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  documentTypeId?: InputMaybe<Scalars['UUID']>;
  fileName?: InputMaybe<Scalars['String']>;
  fileSizeKb?: InputMaybe<Scalars['Int']>;
  fullUrl?: InputMaybe<Scalars['String']>;
  sourceType?: InputMaybe<Scalars['String']>;
  visibleForCustomer?: InputMaybe<Scalars['Boolean']>;
};

export type RetailSubClaimCreateProjectionInput = {
  areaId?: InputMaybe<Scalars['UUID']>;
  carPartId?: InputMaybe<Scalars['UUID']>;
  comment?: InputMaybe<RetailSubClaimCommentCreateProjectionInput>;
  comments?: InputMaybe<Array<InputMaybe<RetailSubClaimCommentCreateProjectionInput>>>;
  complaintId?: InputMaybe<Scalars['UUID']>;
  damageId?: InputMaybe<Scalars['UUID']>;
  liablePartyType?: InputMaybe<Scalars['String']>;
};

export type RetailSubClaimDocumentProjection = {
  __typename?: 'RetailSubClaimDocumentProjection';
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  documentTypeId?: Maybe<Scalars['UUID']>;
  fileName?: Maybe<Scalars['String']>;
  fileSizeKb?: Maybe<Scalars['Int']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  sourceType?: Maybe<Scalars['String']>;
  subClaimId?: Maybe<Scalars['UUID']>;
  visibleForCustomer?: Maybe<Scalars['Boolean']>;
};

export type RetailSubClaimProjection = {
  __typename?: 'RetailSubClaimProjection';
  areaId?: Maybe<Scalars['UUID']>;
  carPartId?: Maybe<Scalars['UUID']>;
  claimId?: Maybe<Scalars['UUID']>;
  comments?: Maybe<Array<Maybe<RetailSubClaimCommentProjection>>>;
  complaintId?: Maybe<Scalars['UUID']>;
  costCurrencyCode?: Maybe<Scalars['String']>;
  costMinorUnits?: Maybe<Scalars['Long']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  createdOnMillis?: Maybe<Scalars['Long']>;
  damageId?: Maybe<Scalars['UUID']>;
  documents?: Maybe<Array<Maybe<RetailSubClaimDocumentProjection>>>;
  id?: Maybe<Scalars['UUID']>;
  liablePartyType?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  root?: Maybe<Scalars['Boolean']>;
  sourceType?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailSubClaimUpdateProjectionInput = {
  areaId?: InputMaybe<Scalars['UUID']>;
  carPartId?: InputMaybe<Scalars['UUID']>;
  damageId?: InputMaybe<Scalars['UUID']>;
  liablePartyType?: InputMaybe<Scalars['String']>;
};

export type RetailSubProductDetailsProjection = {
  __typename?: 'RetailSubProductDetailsProjection';
  childProducts: Array<Maybe<RetailBaseProductProjection>>;
  primaryProduct: RetailBaseProductProjection;
};

export type RetailSurveyScoreProjection = {
  __typename?: 'RetailSurveyScoreProjection';
  score?: Maybe<Scalars['Int']>;
  surveyType?: Maybe<Scalars['String']>;
};

export type RetailTaxBpmProjection = {
  __typename?: 'RetailTaxBPMProjection';
  amount?: Maybe<PriceDto>;
  id?: Maybe<Scalars['UUID']>;
};

export type RetailTaxBpmUpdateProjectionInput = {
  amountMinorUnits?: InputMaybe<Scalars['BigInteger']>;
};

export type RetailTaxNoVaProjection = {
  __typename?: 'RetailTaxNoVAProjection';
  applicable: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  note?: Maybe<Scalars['String']>;
};

export type RetailTaxNoVaUpdateProjectionInput = {
  applicable?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
};

export type RetailTradeInCreateProjection = {
  __typename?: 'RetailTradeInCreateProjection';
  accountingDate?: Maybe<Scalars['String']>;
  carleadId?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  onsitePrice?: Maybe<Scalars['Long']>;
  price?: Maybe<RetailFinancePriceProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  subModel?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type RetailTradeInCreateProjectionInput = {
  accountingDate?: InputMaybe<Scalars['String']>;
  carleadId?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  onsitePrice?: InputMaybe<Scalars['Long']>;
  price?: InputMaybe<RetailFinancePriceProjectionInput>;
  stockNumber?: InputMaybe<Scalars['String']>;
  subModel?: InputMaybe<Scalars['String']>;
  vatKey?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
};

export type RetailTradeInExtendedProjection = {
  __typename?: 'RetailTradeInExtendedProjection';
  accountingDate?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  attachable?: Maybe<Scalars['Boolean']>;
  carleadId?: Maybe<Scalars['String']>;
  confirmedFinancingDueAmountMinorUnits?: Maybe<Scalars['Long']>;
  costGrossPNL?: Maybe<Scalars['Long']>;
  costPNLVatPercentage?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  grossPriceMinorUnits?: Maybe<Scalars['Long']>;
  id?: Maybe<Scalars['UUID']>;
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  isFinanced?: Maybe<Scalars['Boolean']>;
  netPriceMinorUnits?: Maybe<Scalars['Long']>;
  onsitePriceMinorUnits?: Maybe<Scalars['Long']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  outstandingFinancingDueAmountMinorUnits?: Maybe<Scalars['Long']>;
  partnerName?: Maybe<Scalars['String']>;
  price?: Maybe<PriceDto>;
  publishedScope?: Maybe<Scalars['String']>;
  sellPriceValidTill?: Maybe<Scalars['String']>;
  shippingRequired: Scalars['Boolean'];
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  validTill?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Scalars['String']>;
  vatPercentage?: Maybe<Scalars['Float']>;
  vin?: Maybe<Scalars['String']>;
};

export type RetailTradeInItemProjection = {
  __typename?: 'RetailTradeInItemProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customDiscountMinorUnits?: Maybe<Scalars['Long']>;
  customDiscountType?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  obsolete?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderItemRefund?: Maybe<Scalars['Long']>;
  parentId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  tradeIn?: Maybe<RetailTradeInProjection>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type RetailTradeInProjection = {
  __typename?: 'RetailTradeInProjection';
  accountingDate?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  carleadId?: Maybe<Scalars['String']>;
  confirmedFinancingDueAmountMinorUnits?: Maybe<Scalars['Long']>;
  costGrossPNL?: Maybe<Scalars['Long']>;
  costPNLVatPercentage?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  grossPriceMinorUnits?: Maybe<Scalars['Long']>;
  id?: Maybe<Scalars['UUID']>;
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  isFinanced?: Maybe<Scalars['Boolean']>;
  netPriceMinorUnits?: Maybe<Scalars['Long']>;
  onsitePriceMinorUnits?: Maybe<Scalars['Long']>;
  outstandingFinancingDueAmountMinorUnits?: Maybe<Scalars['Long']>;
  partnerName?: Maybe<Scalars['String']>;
  price?: Maybe<PriceDto>;
  publishedScope?: Maybe<Scalars['String']>;
  sellPriceValidTill?: Maybe<Scalars['String']>;
  shippingRequired: Scalars['Boolean'];
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['String']>;
  validTill?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Scalars['String']>;
  vatPercentage?: Maybe<Scalars['Float']>;
  vin?: Maybe<Scalars['String']>;
};

export type RetailTradeInUpSellOptionProjection = {
  __typename?: 'RetailTradeInUpSellOptionProjection';
  currencyCode?: Maybe<Scalars['String']>;
  grossPriceMinorUnits?: Maybe<Scalars['Long']>;
  id?: Maybe<Scalars['UUID']>;
  isFinanced?: Maybe<Scalars['Boolean']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  outstandingFinancingDueAmountMinorUnits?: Maybe<Scalars['Long']>;
  previewUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  validTill?: Maybe<Scalars['String']>;
};

export type RetailTradeInUpSellOptionsProjection = {
  __typename?: 'RetailTradeInUpSellOptionsProjection';
  activeTradeIns?: Maybe<Array<Maybe<RetailTradeInUpSellOptionProjection>>>;
  inactiveTradeIns?: Maybe<Array<Maybe<RetailTradeInUpSellOptionProjection>>>;
};

export type RetailTransitionInternalTransferDtoInput = {
  category?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
};

export type RetailTrustpilotCustomerReviewProjection = {
  __typename?: 'RetailTrustpilotCustomerReviewProjection';
  stars: Scalars['Int'];
};

export type RetailTrustpilotInvitationProjection = {
  __typename?: 'RetailTrustpilotInvitationProjection';
  consumerEmail?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  preferredSendTime?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
};

export type RetailTypedAccountDetailsForPaymentProjection = {
  __typename?: 'RetailTypedAccountDetailsForPaymentProjection';
  accountType?: Maybe<Scalars['String']>;
  accounts?: Maybe<Array<Maybe<RetailTypedAccountDetailsWithPaymentsProjection>>>;
};

export type RetailTypedAccountDetailsWithPaymentsProjection = {
  __typename?: 'RetailTypedAccountDetailsWithPaymentsProjection';
  payments?: Maybe<Array<Maybe<RetailPaymentWrapperProjection>>>;
  retailAccount?: Maybe<BankTypedAccountDetailsProjection>;
  source?: Maybe<Scalars['String']>;
  uploadSource?: Maybe<Scalars['String']>;
};

export type RetailUnidentifiedPaymentDistributionItemProjectionInput = {
  amountMinorUnits?: InputMaybe<Scalars['Long']>;
  category?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['String']>;
};

export type RetailUnidentifiedPaymentDistributionProjectionInput = {
  items?: InputMaybe<Array<InputMaybe<RetailUnidentifiedPaymentDistributionItemProjectionInput>>>;
  unidentifiedPaymentId?: InputMaybe<Scalars['UUID']>;
};

export type RetailUnidentifiedPaymentProjection = {
  __typename?: 'RetailUnidentifiedPaymentProjection';
  accountingDate?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Maybe<RetailPaymentCommentProjection>>>;
  creationSource?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  paymentData?: Maybe<RetailPaymentDataProjection>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uploadDate?: Maybe<Scalars['String']>;
};

export type RetailUnidentifiedPaymentUpdateProjectionInput = {
  accountHolder?: InputMaybe<Scalars['String']>;
  bic?: InputMaybe<Scalars['String']>;
  customerPaymentReference?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type RetailUserDto = {
  __typename?: 'RetailUserDTO';
  accessParameters?: Maybe<Array<Maybe<RetailAccessParamDto>>>;
  autoheroUserType?: Maybe<AutoheroUserType>;
  billingAddressId?: Maybe<Scalars['UUID']>;
  carRegistrationAddressId?: Maybe<Scalars['UUID']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryAddressId?: Maybe<Scalars['UUID']>;
  deviceId?: Maybe<Scalars['String']>;
  effectivePermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<RetailGroupDto>>>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  loginTime?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  registeredFrom?: Maybe<Scalars['Int']>;
  salutation?: Maybe<Scalars['Int']>;
  userRegistrationSource?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['Int']>;
  verified: Scalars['Boolean'];
};

export type RetailVehiclePreparationCreateProjectionInput = {
  active: Scalars['Boolean'];
  country: Scalars['String'];
  currencyCode: Scalars['String'];
  defaulted: Scalars['Boolean'];
  grossPriceMinorUnits: Scalars['Int'];
  internalReferenceName?: InputMaybe<Scalars['String']>;
  invoiceDescription: Scalars['String'];
  invoiceName?: InputMaybe<Scalars['String']>;
  partnerName: Scalars['String'];
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Int']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  publishedScope: PublishedScopeType;
  quantity: Scalars['Int'];
  recommended?: InputMaybe<Scalars['Boolean']>;
  refId: Scalars['String'];
  shippingRequired: Scalars['Boolean'];
  vatKey: VatKeyType;
  vatPercentage: Scalars['Float'];
};

export type RetailVehiclePreparationProjection = {
  __typename?: 'RetailVehiclePreparationProjection';
  active: Scalars['Boolean'];
  country: Scalars['String'];
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode: Scalars['String'];
  customDiscount?: Maybe<PriceProjection>;
  customDiscountType?: Maybe<Scalars['String']>;
  deactivatedOn?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  discount?: Maybe<PriceProjection>;
  id: Scalars['UUID'];
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceDescription: Scalars['String'];
  invoiceName?: Maybe<Scalars['String']>;
  partnerName: Scalars['String'];
  pnlCostPrice?: Maybe<PriceDetailsProjection>;
  price: PriceDetailsProjection;
  priceAfterDiscount?: Maybe<PriceProjection>;
  priceDetails?: Maybe<Array<Maybe<PriceDetailsProjection>>>;
  productType: Scalars['String'];
  publishedScope: PublishedScopeType;
  quantity: Scalars['Int'];
  recommended?: Maybe<Scalars['Boolean']>;
  refId: Scalars['String'];
  shippingRequired: Scalars['Boolean'];
  subProductDetails?: Maybe<RetailSubProductDetailsProjection>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailVehiclePreparationSearchProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  country?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
  onlyAvailable?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  priceModifiers?: InputMaybe<Scalars['Map_String_PriceModifierPayloadScalar']>;
  sorts?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type RetailVehiclePreparationUpdateProjectionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  defaulted?: InputMaybe<Scalars['Boolean']>;
  grossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  internalReferenceName?: InputMaybe<Scalars['String']>;
  invoiceDescription?: InputMaybe<Scalars['String']>;
  invoiceName?: InputMaybe<Scalars['String']>;
  partnerName?: InputMaybe<Scalars['String']>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  publishedScope?: InputMaybe<PublishedScopeType>;
  quantity?: InputMaybe<Scalars['Int']>;
  recommended?: InputMaybe<Scalars['Boolean']>;
  shippingRequired?: InputMaybe<Scalars['Boolean']>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
};

export type RetailWarrantyCreateProjectionInput = {
  active: Scalars['Boolean'];
  applicableCarVatKey?: InputMaybe<VatKeyType>;
  country: Scalars['String'];
  currencyCode: Scalars['String'];
  defaulted: Scalars['Boolean'];
  grossPriceMinorUnits: Scalars['Int'];
  insuranceTax?: InputMaybe<Scalars['BigDecimal']>;
  internalReferenceName?: InputMaybe<Scalars['String']>;
  invoiceDescription: Scalars['String'];
  invoiceName?: InputMaybe<Scalars['String']>;
  level: WarrantyLevelType;
  maxTotalMileage?: InputMaybe<MileageProjectionInput>;
  mileageCovered?: InputMaybe<MileageProjectionInput>;
  monthsCovered: Scalars['Int'];
  partnerName: Scalars['String'];
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Int']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  publishedScope: PublishedScopeType;
  quantity: Scalars['Int'];
  recommended?: InputMaybe<Scalars['Boolean']>;
  refId: Scalars['String'];
  shippingRequired: Scalars['Boolean'];
  source: WarrantySourceType;
  vatKey: VatKeyType;
  vatPercentage: Scalars['Float'];
};

export type RetailWarrantyProjection = {
  __typename?: 'RetailWarrantyProjection';
  active: Scalars['Boolean'];
  applicableCarVatKey?: Maybe<VatKeyType>;
  country: Scalars['String'];
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode: Scalars['String'];
  customDiscount?: Maybe<PriceProjection>;
  customDiscountType?: Maybe<Scalars['String']>;
  deactivatedOn?: Maybe<Scalars['String']>;
  defaulted: Scalars['Boolean'];
  discount?: Maybe<PriceProjection>;
  discountItem?: Maybe<RetailDiscountItemProjection>;
  id: Scalars['UUID'];
  insuranceTax?: Maybe<Scalars['BigDecimal']>;
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceDescription: Scalars['String'];
  invoiceName?: Maybe<Scalars['String']>;
  level: WarrantyLevelType;
  maxTotalMileage?: Maybe<MileageProjection>;
  mileageCovered?: Maybe<MileageProjection>;
  monthsCovered: Scalars['Int'];
  partnerName: Scalars['String'];
  pnlCostPrice?: Maybe<PriceDetailsProjection>;
  price: PriceDetailsProjection;
  priceAfterDiscount?: Maybe<PriceProjection>;
  priceDetails?: Maybe<Array<Maybe<PriceDetailsProjection>>>;
  productType: Scalars['String'];
  publishedScope: PublishedScopeType;
  quantity: Scalars['Int'];
  recommended?: Maybe<Scalars['Boolean']>;
  refId: Scalars['String'];
  shippingRequired: Scalars['Boolean'];
  source: WarrantySourceType;
  subProductDetails?: Maybe<RetailSubProductDetailsProjection>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RetailWarrantySearchProjectionInput = {
  adId?: InputMaybe<Scalars['UUID']>;
  country?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
  onlyAvailable?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  priceModifiers?: InputMaybe<Scalars['Map_String_PriceModifierPayloadScalar']>;
  sorts?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type RetailWarrantyUpdateProjectionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  applicableCarVatKey?: InputMaybe<VatKeyType>;
  country?: InputMaybe<Scalars['String']>;
  defaulted?: InputMaybe<Scalars['Boolean']>;
  grossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  insuranceTax?: InputMaybe<Scalars['BigDecimal']>;
  internalReferenceName?: InputMaybe<Scalars['String']>;
  invoiceDescription?: InputMaybe<Scalars['String']>;
  invoiceName?: InputMaybe<Scalars['String']>;
  partnerName?: InputMaybe<Scalars['String']>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  publishedScope?: InputMaybe<PublishedScopeType>;
  quantity?: InputMaybe<Scalars['Int']>;
  recommended?: InputMaybe<Scalars['Boolean']>;
  shippingRequired?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<WarrantySourceType>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
};

export type RetailYouSignSignatureRequestProjection = {
  __typename?: 'RetailYouSignSignatureRequestProjection';
  createdOn: Scalars['String'];
  expirationDate?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  orderId: Scalars['UUID'];
  retailExternalId: Scalars['UUID'];
  signedOn?: Maybe<Scalars['String']>;
  signerId?: Maybe<Scalars['UUID']>;
  status: SignatureRequestState;
  type: DocumentSignInProcessType;
  updatedOn?: Maybe<Scalars['String']>;
  yousignRequestId?: Maybe<Scalars['UUID']>;
  yousignSignatureLink?: Maybe<Scalars['String']>;
};

export enum RimMaterial {
  Alloy = 'ALLOY',
  AlloyOem = 'ALLOY_OEM',
  NoRims = 'NO_RIMS',
  Steel = 'STEEL',
  SteelCover = 'STEEL_COVER'
}

export type RimSize = {
  __typename?: 'RimSize';
  unit?: Maybe<Unit>;
  value?: Maybe<Scalars['Int']>;
};

export enum RimType {
  AluminiumSupplier = 'ALUMINIUM_SUPPLIER',
  Original = 'ORIGINAL',
  Steel = 'STEEL',
  SteelWithCover = 'STEEL_WITH_COVER'
}

export type RiskData = {
  __typename?: 'RiskData';
  arvatoScore?: Maybe<Scalars['Int']>;
  averageDaysOverdrawn?: Maybe<Scalars['Float']>;
  countryCode?: Maybe<Scalars['String']>;
  encashmentPayments?: Maybe<AmountInfo>;
  gamblingExpenses?: Maybe<AmountInfo>;
  hasCreditCard?: Maybe<Scalars['Boolean']>;
  incomeCheck?: Maybe<RiskDataIncomeCheck>;
  monthlyNetIncome?: Maybe<AmountInfo>;
  unemployedBenefits?: Maybe<AmountInfo>;
};

export type RiskDataIncomeCheck = {
  __typename?: 'RiskDataIncomeCheck';
  pensionCategory?: Maybe<IncomeCheckCategoryDto>;
  salaryCategory?: Maybe<IncomeCheckCategoryDto>;
};

export type RoleCreateProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  endpoint?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['String']>;
};

export type RoleDto = {
  __typename?: 'RoleDTO';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<PermissionDto>>>;
};

export type RoleProjection = {
  __typename?: 'RoleProjection';
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endpoint?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<GroupProjection>>>;
  id?: Maybe<Scalars['UUID']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<PermissionProjection>>>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type RoleUpdateProjectionInput = {
  description?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['String']>;
};

export enum RouteStatus {
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Finished = 'FINISHED',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED'
}

export type SePlusgiroBankAccountDetailsProjection = {
  __typename?: 'SEPlusgiroBankAccountDetailsProjection';
  TYPE?: Maybe<Scalars['String']>;
  accountDataId?: Maybe<Scalars['UUID']>;
  documents?: Maybe<Array<Maybe<RetailDocumentProjection>>>;
  holder?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SalesAgentProjection = {
  __typename?: 'SalesAgentProjection';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
};

export enum Salutation {
  Company = 'COMPANY',
  Mr = 'MR',
  Mrs = 'MRS'
}

export type SaveFinanceRiskEvaluationProjectionInput = {
  coBorrowerNeeded?: InputMaybe<Scalars['Boolean']>;
  contractType?: InputMaybe<Scalars['String']>;
  employedUnderLocalWorkContract?: InputMaybe<Scalars['Boolean']>;
  employmentPeriod?: InputMaybe<Scalars['String']>;
  employmentStatus?: InputMaybe<Scalars['String']>;
  meetMonthlyIncome?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['UUID']>;
  sameEmployerOverTimePeriod?: InputMaybe<Scalars['Boolean']>;
};

export type SearchProjection2Input = {
  filter?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sorts?: InputMaybe<Array<InputMaybe<SortOrderProjectionInput>>>;
};

export type SearchRetailDocumentProjection = {
  __typename?: 'SearchRetailDocumentProjection';
  attachedTo?: Maybe<Scalars['UUID']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. After front-end has stopped using it, it will be removed */
  createdBy?: Maybe<Scalars['UUID']>;
  createdByUser?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. After front-end has stopped using it, it will be removed */
  deletedBy?: Maybe<Scalars['UUID']>;
  deletedByUser?: Maybe<UserProjection>;
  deletedOn?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documentType?: Maybe<RetailDocumentTypeDto>;
  documentUploadSourceType?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  rotationDegree?: Maybe<Scalars['Int']>;
  signatureExternalId?: Maybe<Scalars['UUID']>;
  signatureProvider?: Maybe<Scalars['String']>;
  signed?: Maybe<Scalars['Boolean']>;
  signedBy?: Maybe<Scalars['UUID']>;
  signedByUser?: Maybe<UserProjection>;
  signedOn?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. After front-end has stopped using it, it will be removed */
  verifiedBy?: Maybe<Scalars['UUID']>;
  verifiedByUser?: Maybe<UserProjection>;
  verifiedOn?: Maybe<Scalars['String']>;
};

export enum SecondaryWheelsDamageCondition {
  Damage = 'DAMAGE',
  FullDamage = 'FULL_DAMAGE',
  MinorDamage = 'MINOR_DAMAGE',
  NoDamage = 'NO_DAMAGE'
}

export type SecondaryWheelsItemProjection = {
  __typename?: 'SecondaryWheelsItemProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customDiscountMinorUnits?: Maybe<Scalars['Long']>;
  customDiscountType?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  obsolete?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderItemRefund?: Maybe<Scalars['Long']>;
  parentId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  retailAdSecondaryWheels?: Maybe<RetailAdSecondaryWheelsProjection>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export enum SecondaryWheelsLocation {
  InBranch = 'IN_BRANCH',
  InCar = 'IN_CAR',
  Other = 'OTHER',
  TwoInCarTwoInBranch = 'TWO_IN_CAR_TWO_IN_BRANCH'
}

export type SecondaryWheelsOptionsProjection = {
  __typename?: 'SecondaryWheelsOptionsProjection';
  profileDepth?: Maybe<Array<Maybe<Scalars['Int']>>>;
  rimDamageTypes?: Maybe<Array<Maybe<DamageTypeProjection>>>;
  rimType?: Maybe<Array<Maybe<RimMaterial>>>;
  seasons?: Maybe<Array<Maybe<TireSeasonType>>>;
  tireDamageTypes?: Maybe<Array<Maybe<DamageTypeProjection>>>;
  wheelRadius?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type SelectProjection = {
  __typename?: 'SelectProjection';
  isMandatory?: Maybe<Scalars['Boolean']>;
  isMultiSelect?: Maybe<Scalars['Boolean']>;
  possibleValues?: Maybe<Array<Maybe<Scalars['String']>>>;
  preselectedValue?: Maybe<Scalars['String']>;
  variableName?: Maybe<Scalars['String']>;
};

export type SellerAddressCreateProjectionInput = {
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['Int']>;
  houseNumberAdditive?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<LocationInput>;
  street?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type SellerAddressDto = {
  __typename?: 'SellerAddressDTO';
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  displayPhone: Scalars['Boolean'];
  houseNumber?: Maybe<Scalars['Int']>;
  houseNumberAdditive?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  location?: Maybe<Location>;
  phone?: Maybe<Scalars['String']>;
  sellerId?: Maybe<Scalars['UUID']>;
  street?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type SellerAddressProjection = {
  __typename?: 'SellerAddressProjection';
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['Int']>;
  houseNumberAdditive?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  location?: Maybe<Location>;
  sellerId?: Maybe<Scalars['UUID']>;
  street?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type SellerAddressProjectionInput = {
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['Int']>;
  houseNumberAdditive?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  location?: InputMaybe<LocationInput>;
  sellerId?: InputMaybe<Scalars['UUID']>;
  street?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type SellerContactDto = {
  __typename?: 'SellerContactDTO';
  countryCode?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  formattedPhoneNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastname?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sellerId?: Maybe<Scalars['UUID']>;
  updated?: Maybe<Scalars['String']>;
};

export type SellerDto = {
  __typename?: 'SellerDTO';
  aboutMeDescription?: Maybe<Scalars['String']>;
  addresses?: Maybe<Array<Maybe<SellerAddressDto>>>;
  billingAddress?: Maybe<SellerAddressDto>;
  billingContact?: Maybe<SellerContactDto>;
  companyAddress?: Maybe<SellerAddressDto>;
  companyContact?: Maybe<SellerContactDto>;
  companyName?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<SellerContactDto>>>;
  defaultAddress?: Maybe<SellerAddressDto>;
  defaultContact?: Maybe<SellerContactDto>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['UUID']>;
  firstName?: Maybe<Scalars['String']>;
  headerImage?: Maybe<ImageDto>;
  id?: Maybe<Scalars['UUID']>;
  inventoryUpperBound?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<SellerAddressDto>;
  legalContact?: Maybe<SellerContactDto>;
  legalForm?: Maybe<Scalars['String']>;
  logoImage?: Maybe<ImageDto>;
  name?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['Map_String_OpeningHoursDTOScalar']>;
  pathTitle?: Maybe<Scalars['String']>;
  salutation?: Maybe<Salutation>;
  services?: Maybe<Scalars['String']>;
  type?: Maybe<SellerType>;
  typeCode?: Maybe<SellerType>;
  vehicleAddress?: Maybe<SellerAddressDto>;
  vehicleContact?: Maybe<SellerContactDto>;
  website?: Maybe<Scalars['String']>;
};

export enum SellerType {
  Aux = 'AUX',
  Merchant = 'MERCHANT',
  PrivateListing = 'PRIVATE_LISTING',
  PrivateSales = 'PRIVATE_SALES',
  Retail = 'RETAIL'
}

export type SendNewFinanceApplicationToCustomerProjectionInput = {
  reason?: InputMaybe<Scalars['String']>;
};

export type SepaMandateDuplicateProjection = {
  __typename?: 'SepaMandateDuplicateProjection';
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
};

export type SepaMandateUpdateInformHmcsTaskProjection = {
  __typename?: 'SepaMandateUpdateInformHMCSTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sepaMandate?: Maybe<FinancingSepaMandateProjection>;
  sepaMandateDocument?: Maybe<RetailDocumentProjection>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type SeverityProjection = {
  __typename?: 'SeverityProjection';
  severity?: Maybe<Scalars['String']>;
  translation?: Maybe<Scalars['String']>;
};

export type ShippingAddressDetails = {
  __typename?: 'ShippingAddressDetails';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type ShippingProviderProjection = {
  __typename?: 'ShippingProviderProjection';
  address?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  contactName?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  typesOfDelivery: Array<Maybe<Scalars['String']>>;
};

export enum SignatureRequestState {
  Approval = 'APPROVAL',
  Canceled = 'CANCELED',
  Deleted = 'DELETED',
  Done = 'DONE',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Ongoing = 'ONGOING',
  Rejected = 'REJECTED'
}

export type SortOrderProjectionInput = {
  direction?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
};

export type Source = {
  __typename?: 'Source';
  channel?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rawExtraInfo?: Maybe<Scalars['String']>;
  type?: Maybe<Type>;
};

export type SparePartDto = {
  __typename?: 'SparePartDTO';
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['LocalDateTime']>;
  id?: Maybe<Scalars['UUID']>;
  lastStatusUpdatedOn?: Maybe<Scalars['LocalDateTime']>;
  refurbishmentServiceId?: Maybe<Scalars['UUID']>;
  status?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['UUID']>;
  updatedOn?: Maybe<Scalars['LocalDateTime']>;
};

export type SurveyAggregationProjection = {
  __typename?: 'SurveyAggregationProjection';
  activePage?: Maybe<Scalars['Int']>;
  answerMap?: Maybe<Scalars['Map_String_SurveyAggregationAnswerProjectionScalar']>;
  id?: Maybe<Scalars['UUID']>;
  notes?: Maybe<Scalars['String']>;
  pages?: Maybe<Array<Maybe<SurveyPageProjection>>>;
  subTitle?: Maybe<Scalars['String']>;
  surveyStatus?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SurveyAnsweredQuestionProjection = {
  __typename?: 'SurveyAnsweredQuestionProjection';
  answerOptionTitles?: Maybe<Array<Maybe<Scalars['String']>>>;
  freeText?: Maybe<Scalars['String']>;
  questionTitle?: Maybe<Scalars['String']>;
  scores?: Maybe<Scalars['Int']>;
  subQuestions?: Maybe<Array<Maybe<SurveyAnsweredQuestionProjection>>>;
  type?: Maybe<Scalars['String']>;
};

export type SurveyAnsweredQuestionsProjection = {
  __typename?: 'SurveyAnsweredQuestionsProjection';
  questions?: Maybe<Array<Maybe<SurveyAnsweredQuestionProjection>>>;
  updated?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<UserProjection>;
};

export type SurveyPageProjection = {
  __typename?: 'SurveyPageProjection';
  pageNumber?: Maybe<Scalars['Int']>;
  surveyQuestions?: Maybe<Array<Maybe<SurveyQuestionProjection>>>;
};

export type SurveyQuestionOptionProjection = {
  __typename?: 'SurveyQuestionOptionProjection';
  id?: Maybe<Scalars['UUID']>;
  nextQuestionId?: Maybe<Scalars['UUID']>;
  order?: Maybe<Scalars['Int']>;
  scores?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SurveyQuestionProjection = {
  __typename?: 'SurveyQuestionProjection';
  id?: Maybe<Scalars['UUID']>;
  order?: Maybe<Scalars['Int']>;
  questionOptions?: Maybe<Array<Maybe<SurveyQuestionOptionProjection>>>;
  required: Scalars['Boolean'];
  subQuestions?: Maybe<Array<Maybe<SurveyQuestionProjection>>>;
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  visibilityDependsOnOptionId?: Maybe<Scalars['UUID']>;
};

export type Tuvdto = {
  __typename?: 'TUVDTO';
  color?: Maybe<Scalars['String']>;
  huCertificate?: Maybe<Scalars['String']>;
  initialRegistration?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  salesDescription?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type TuvdtoInput = {
  color?: InputMaybe<Scalars['String']>;
  huCertificate?: InputMaybe<Scalars['String']>;
  initialRegistration?: InputMaybe<Scalars['String']>;
  licensePlate?: InputMaybe<Scalars['String']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  salesDescription?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
};

export enum TagType {
  Imperfection = 'IMPERFECTION'
}

export type TaskAssigneeProjection = {
  __typename?: 'TaskAssigneeProjection';
  countryAccess?: Maybe<Array<Maybe<Scalars['String']>>>;
  taskName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
  userName?: Maybe<Scalars['String']>;
};

export type TaskCountProjection = {
  __typename?: 'TaskCountProjection';
  completedCount: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type TaskManagementBaseTaskProjection = {
  __typename?: 'TaskManagementBaseTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type TaskManagementStatusColorCodingProjection = {
  __typename?: 'TaskManagementStatusColorCodingProjection';
  colorCoding?: Maybe<TaskStatusColorCoding>;
  reason?: Maybe<TaskStatusColorCodingReason>;
};

export type TaskManagerAssigneeProjection = {
  __typename?: 'TaskManagerAssigneeProjection';
  countryAccess?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabled: Scalars['Boolean'];
  taskNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  userId?: Maybe<Scalars['UUID']>;
  userName?: Maybe<Scalars['String']>;
};

export type TaskManagerCommentCreateProjectionInput = {
  comment?: InputMaybe<Scalars['String']>;
  taskId?: InputMaybe<Scalars['UUID']>;
};

export type TaskManagerCommentProjection = {
  __typename?: 'TaskManagerCommentProjection';
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<RetailUserDto>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  orderId?: Maybe<Scalars['UUID']>;
  taskId?: Maybe<Scalars['UUID']>;
};

export type TaskSpecificActionsProjection = {
  __typename?: 'TaskSpecificActionsProjection';
  completeActions?: Maybe<Array<Maybe<Scalars['String']>>>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  updateActions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum TaskStatusColorCoding {
  Green = 'GREEN',
  Grey = 'GREY',
  Red = 'RED',
  Yellow = 'YELLOW'
}

export enum TaskStatusColorCodingReason {
  CancellationRequestedForOrder = 'CANCELLATION_REQUESTED_FOR_ORDER',
  Complete = 'COMPLETE',
  FinancingCanceled = 'FINANCING_CANCELED',
  FinancingRejectedOrCanceled = 'FINANCING_REJECTED_OR_CANCELED',
  Incomplete = 'INCOMPLETE',
  NonTopOrderPriority = 'NON_TOP_ORDER_PRIORITY',
  VerificationCallInstantReject = 'VERIFICATION_CALL_INSTANT_REJECT'
}

export type TasksManagerSearchOptionsProjection = {
  __typename?: 'TasksManagerSearchOptionsProjection';
  allowedToAssignTasks?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedToCommentTasks?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedToCompleteTasks?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedToSelfAssignTasks?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedToSetActionTasks?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowedToUnAssignTasks?: Maybe<Array<Maybe<Scalars['String']>>>;
  deliveryOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  financingStatuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderRetailCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderStates?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentStatuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  taskActions?: Maybe<Array<Maybe<RetailOrderBasedTaskAction>>>;
  taskManagerAssignees?: Maybe<Array<Maybe<TaskManagerAssigneeProjection>>>;
  taskNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  taskSpecificActions?: Maybe<Array<Maybe<TaskSpecificActionsProjection>>>;
};

export type TasksSearchOptionsProjection = {
  __typename?: 'TasksSearchOptionsProjection';
  orderRetailCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderStates?: Maybe<Array<Maybe<Scalars['String']>>>;
  taskNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  tasksAssignees?: Maybe<Array<Maybe<TaskAssigneeProjection>>>;
};

export enum TaxAdvantageSource {
  All = 'ALL',
  CarLead = 'CAR_LEAD',
  RetailAd = 'RETAIL_AD'
}

export type TemplateProjection = {
  __typename?: 'TemplateProjection';
  id?: Maybe<Scalars['UUID']>;
  locale?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  replyRequired?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum TireSeasonType {
  AllSeason = 'ALL_SEASON',
  Emergency = 'EMERGENCY',
  Summer = 'SUMMER',
  Winter = 'WINTER'
}

export enum TireType {
  AllWeather = 'ALL_WEATHER',
  Emergency = 'EMERGENCY',
  Summer = 'SUMMER',
  Winter = 'WINTER'
}

export type TokenStatsProjection = {
  __typename?: 'TokenStatsProjection';
  context?: Maybe<Scalars['Int']>;
  response?: Maybe<Scalars['Int']>;
  systemMessage?: Maybe<Scalars['Int']>;
  userMessage?: Maybe<Scalars['Int']>;
};

export type TradeInPurchasePendingTaskProjection = {
  __typename?: 'TradeInPurchasePendingTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
  tradeInStockNumber?: Maybe<Scalars['String']>;
};

export enum Type {
  AutomaticImport = 'AUTOMATIC_IMPORT',
  ManualImport = 'MANUAL_IMPORT'
}

export enum Unit {
  Cm = 'CM',
  In = 'IN',
  Mm = 'MM'
}

export enum UnpublishedReason {
  ConsignedToPrivateSale = 'CONSIGNED_TO_PRIVATE_SALE',
  Expired = 'EXPIRED',
  InventoryInvalidated = 'INVENTORY_INVALIDATED',
  Manual = 'MANUAL',
  SoldToAuto1 = 'SOLD_TO_AUTO1'
}

export type UpdateAgentAnswerTemplateProjectionInput = {
  assignToTeamIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  content?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  unassignFromTeamIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
};

export type UpdateConversionTypeProjectionInput = {
  checkoutBranchId?: InputMaybe<Scalars['Int']>;
  conversionType?: InputMaybe<Scalars['String']>;
};

export type UpdateDocumentPackageInput = {
  deliveredOn?: InputMaybe<Scalars['String']>;
  deliveryAddress: DocumentInventoryPackageAddressInput;
  documentCarItems: Array<InputMaybe<Scalars['UUID']>>;
  estimatedDeliveryDate?: InputMaybe<Scalars['String']>;
  packageId: Scalars['UUID'];
  pickedUpOn?: InputMaybe<Scalars['String']>;
  shippingProvider: Scalars['Int'];
  trackingCode?: InputMaybe<Scalars['String']>;
};

export type UpdateDocumentPackagePartialInput = {
  deliveredOn?: InputMaybe<Scalars['String']>;
  estimatedDeliveryDate?: InputMaybe<Scalars['String']>;
  packageId: Scalars['UUID'];
  pickedUpOn?: InputMaybe<Scalars['String']>;
  shippingProvider?: InputMaybe<Scalars['Int']>;
  trackingCode?: InputMaybe<Scalars['String']>;
};

export type UpdateDocumentPackagePartialResult = DocumentPackageNotOpenProblem | UpdateDocumentPackageProjection;

export type UpdateDocumentPackageProjection = {
  __typename?: 'UpdateDocumentPackageProjection';
  documentInventoryPackages: ResultConnection_DocumentInventoryPackageProjection;
};

export type UpdateDocumentPackageResult = DocumentItemsInPackageProblem | DocumentPackageConflictProblem | DocumentPackageEmptyBranchNameProblem | DocumentPackageEmptyFirstNameProblem | DocumentPackageEmptyLastNameProblem | DocumentPackageNoHouseNumAddressProblem | DocumentPackageNotOpenProblem | DocumentPackageZeroItemsProblem | DocumentPackagesGeneralProblem | UpdateDocumentPackageProjection;

export type UpdateExternalFinanceRiskEvaluationProjectionInput = {
  contractType?: InputMaybe<Scalars['String']>;
  employmentStatus?: InputMaybe<Scalars['String']>;
  monthlyNetIncomeMinorUnits?: InputMaybe<Scalars['Long']>;
  sameEmployerOverTimePeriod?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateRetailFeatureProjectionInput = {
  condition?: InputMaybe<Scalars['Map_String_ObjectScalar']>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateSurveyDtoInputInput = {
  answers?: InputMaybe<Array<InputMaybe<UpsertAnswerDtoInputInput>>>;
};

export type UpdateTestOrderDownPaymentProjectionInput = {
  depositAmount?: InputMaybe<Scalars['Int']>;
};

export type UpsertAnswerDtoInputInput = {
  freeText?: InputMaybe<Scalars['String']>;
  optionId?: InputMaybe<Scalars['UUID']>;
  questionId?: InputMaybe<Scalars['UUID']>;
};

export type UserCreateProjectionInput = {
  checkoutAdId?: InputMaybe<Scalars['UUID']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registeredFrom?: InputMaybe<Scalars['Int']>;
  salutation?: InputMaybe<Scalars['Int']>;
  userType?: InputMaybe<Scalars['Int']>;
};

export type UserOrdersInfoForVerificationProjection = {
  __typename?: 'UserOrdersInfoForVerificationProjection';
  auxUserId?: Maybe<Scalars['UUID']>;
  numberOfCanceledOrdersAfterHandover: Scalars['Long'];
  numberOfCanceledOrdersInLastSixMonths: Scalars['Long'];
  numberOfVerifiedOrders: Scalars['Long'];
};

export type UserPasswordlessProjectionInput = {
  checkoutAdId?: InputMaybe<Scalars['UUID']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registeredFrom?: InputMaybe<Scalars['Int']>;
  registrationUserSource?: InputMaybe<RegistrationUserSource>;
  salutation?: InputMaybe<Scalars['Int']>;
};

export type UserProjection = {
  __typename?: 'UserProjection';
  accessParameters?: Maybe<Array<Maybe<RetailAccessParamDto>>>;
  billingAddressId?: Maybe<Scalars['UUID']>;
  carRegistrationAddressId?: Maybe<Scalars['UUID']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserProjection>;
  createdOn?: Maybe<Scalars['String']>;
  deliveryAddressId?: Maybe<Scalars['UUID']>;
  effectivePermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<GroupProjection>>>;
  id?: Maybe<Scalars['UUID']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  loginTime?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Maybe<UserPropertyProjection>>>;
  registeredFrom?: Maybe<Scalars['Int']>;
  salutation?: Maybe<Scalars['Int']>;
  userType?: Maybe<Scalars['Int']>;
  verified: Scalars['Boolean'];
};

export type UserPropertyProjection = {
  __typename?: 'UserPropertyProjection';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type UserPropertyProjectionInput = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UserUpdateProjectionInput = {
  billingAddressId?: InputMaybe<Scalars['UUID']>;
  carRegistrationAddressId?: InputMaybe<Scalars['UUID']>;
  country?: InputMaybe<Scalars['String']>;
  deliveryAddressId?: InputMaybe<Scalars['UUID']>;
  email?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registeredFrom?: InputMaybe<Scalars['Int']>;
  salutation?: InputMaybe<Scalars['Int']>;
  userType?: InputMaybe<Scalars['Int']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export enum VatKeyType {
  D = 'D',
  E = 'E',
  I = 'I',
  M = 'M',
  R = 'R',
  S = 'S',
  V = 'V'
}

export type VatRateProjection = {
  __typename?: 'VatRateProjection';
  countryCode?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type VehicleInfoProjection = {
  __typename?: 'VehicleInfoProjection';
  adId?: Maybe<Scalars['UUID']>;
  model?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type VehiclePreparationDto = {
  __typename?: 'VehiclePreparationDTO';
  active?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customDiscount?: Maybe<ProductPriceDto>;
  customDiscountType?: Maybe<Scalars['String']>;
  deactivatedOn?: Maybe<Scalars['String']>;
  defaulted?: Maybe<Scalars['Boolean']>;
  discount?: Maybe<ProductPriceDto>;
  id?: Maybe<Scalars['UUID']>;
  internalReferenceName?: Maybe<Scalars['String']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  partnerName?: Maybe<Scalars['String']>;
  pnlCostPrice?: Maybe<PriceDetailsDto>;
  price?: Maybe<PriceDetailsDto>;
  priceAfterDiscount?: Maybe<ProductPriceDto>;
  productType?: Maybe<Scalars['String']>;
  publishedScope?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  recommended?: Maybe<Scalars['Boolean']>;
  refId?: Maybe<Scalars['String']>;
  shippingRequired?: Maybe<Scalars['Boolean']>;
  subProductDetails?: Maybe<RetailBaseProductDtoSubProductDetails>;
  updatedOn?: Maybe<Scalars['String']>;
};

export type VehiclePreparationItemProjection = {
  __typename?: 'VehiclePreparationItemProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customDiscountMinorUnits?: Maybe<Scalars['Long']>;
  customDiscountType?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  obsolete?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderItemRefund?: Maybe<Scalars['Long']>;
  parentId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  vehiclePreparationDTO?: Maybe<VehiclePreparationDto>;
};

export type VehicleProjection = {
  __typename?: 'VehicleProjection';
  acType?: Maybe<Scalars['Int']>;
  accidentCar?: Maybe<Scalars['Boolean']>;
  bodyForm?: Maybe<Scalars['Int']>;
  bodyType?: Maybe<Scalars['Int']>;
  builtYear?: Maybe<Scalars['Int']>;
  carAttrAccidentBool?: Maybe<Scalars['Boolean']>;
  carPreownerCount?: Maybe<Scalars['Int']>;
  carType?: Maybe<CarType>;
  categorizedCustomEquipment?: Maybe<Array<Maybe<CategorizedCustomEquipment>>>;
  ccm?: Maybe<Scalars['Int']>;
  certificates?: Maybe<Scalars['Map_String_ObjectScalar']>;
  co2Value?: Maybe<Scalars['Int']>;
  color?: Maybe<ColorDto>;
  commonFuelConsumption?: Maybe<Scalars['BigDecimal']>;
  countryOfOrigin?: Maybe<Scalars['String']>;
  cylinderCount?: Maybe<Scalars['Int']>;
  damageBool?: Maybe<Scalars['Boolean']>;
  damageHistory?: Maybe<Scalars['Int']>;
  doorCount?: Maybe<Scalars['Int']>;
  emissionClass?: Maybe<Scalars['String']>;
  emissionStandard?: Maybe<Scalars['Int']>;
  emissionSticker?: Maybe<Scalars['String']>;
  energyEfficiencyClass?: Maybe<Scalars['String']>;
  equipmentGroups?: Maybe<Array<Maybe<Scalars['Long']>>>;
  equipments?: Maybe<Array<Maybe<Scalars['Long']>>>;
  externalId?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<Feature>>>;
  fuelConsumption?: Maybe<FuelConsumptionDto>;
  fuelType?: Maybe<Scalars['Int']>;
  fuelTypeTranslation?: Maybe<Scalars['String']>;
  gearType?: Maybe<Scalars['Int']>;
  handoverKeyCount?: Maybe<Scalars['Int']>;
  hasAbs?: Maybe<Scalars['Boolean']>;
  hasAllWheelDrive?: Maybe<Scalars['Boolean']>;
  hasEsp?: Maybe<Scalars['Boolean']>;
  hasHandsFreeSystem?: Maybe<Scalars['Boolean']>;
  hasPdc?: Maybe<Scalars['Boolean']>;
  hasSeatHeating?: Maybe<Scalars['Boolean']>;
  hasTowHook?: Maybe<Scalars['Boolean']>;
  horsePower?: Maybe<Scalars['Int']>;
  huCertificate?: Maybe<Scalars['Int']>;
  inspectionExpiryDate?: Maybe<Scalars['String']>;
  isNewVehicle?: Maybe<Scalars['Boolean']>;
  kw?: Maybe<Scalars['BigDecimal']>;
  lastServiceMileage?: Maybe<MileageDto>;
  lastServiceOn?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  manufacturerCode?: Maybe<Scalars['String']>;
  mileageDTO?: Maybe<MileageDto>;
  model?: Maybe<Scalars['String']>;
  modelApi?: Maybe<ModelDto>;
  modelCode?: Maybe<Scalars['String']>;
  navigationSystem?: Maybe<Scalars['Int']>;
  originalEngine?: Maybe<Scalars['Boolean']>;
  radioSystem?: Maybe<Scalars['Int']>;
  recentlyInspected?: Maybe<Scalars['Boolean']>;
  recentlyServiced?: Maybe<Scalars['Boolean']>;
  registration?: Maybe<Scalars['String']>;
  seatCount?: Maybe<Scalars['Int']>;
  serviceBook?: Maybe<Scalars['Int']>;
  smoker?: Maybe<Scalars['Boolean']>;
  stockNumber?: Maybe<Scalars['String']>;
  subType?: Maybe<Scalars['String']>;
  subTypeExtra?: Maybe<Scalars['String']>;
  taxDeductible?: Maybe<Scalars['Boolean']>;
  taxRating?: Maybe<Scalars['Int']>;
  technicalInspectionCertificateIssueDate?: Maybe<Scalars['String']>;
  technicalInspectionCertificateIssueMileage?: Maybe<MileageDto>;
  testDrive?: Maybe<Scalars['String']>;
  tuv?: Maybe<Tuvdto>;
  type?: Maybe<Scalars['Int']>;
  upholstery?: Maybe<Scalars['Int']>;
  upholsteryOriginal?: Maybe<Scalars['Boolean']>;
  vatType?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['String']>;
  wasInCommercialUse?: Maybe<Scalars['Boolean']>;
  weight?: Maybe<Scalars['Int']>;
  wheelSets?: Maybe<WheelSetsDto>;
  xenonLight?: Maybe<Scalars['Int']>;
};

export type VerificationCallTaskProjection = {
  __typename?: 'VerificationCallTaskProjection';
  action?: Maybe<Scalars['String']>;
  assignedOn?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is assignee */
  assignedTo?: Maybe<UserProjection>;
  assignee?: Maybe<UserProjection>;
  closedBy?: Maybe<UserProjection>;
  closedOn?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedBy */
  completedBy?: Maybe<UserProjection>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is closedOn */
  completedOn?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  customerLeadId?: Maybe<Scalars['UUID']>;
  customerName?: Maybe<Scalars['String']>;
  customerPhoneNumber?: Maybe<Scalars['String']>;
  deliveryOption?: Maybe<Scalars['String']>;
  financingStatus?: Maybe<Scalars['String']>;
  flowVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderProjection>;
  orderId?: Maybe<Scalars['UUID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderState?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  repeatAt?: Maybe<Scalars['String']>;
  resolutionTime?: Maybe<Scalars['Long']>;
  retailCountry?: Maybe<Scalars['String']>;
  sequenceIndex: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  statusColorCoding?: Maybe<TaskManagementStatusColorCodingProjection>;
  stockNumber?: Maybe<Scalars['String']>;
  taskDefinitionKey?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is state */
  taskStatus?: Maybe<Scalars['String']>;
  /** @deprecated This field is kept for backwards compatibility with front-end. New field to use is taskDefinitionKey */
  taskType?: Maybe<Scalars['String']>;
  taskUpdatedAt?: Maybe<Scalars['String']>;
};

export type VerificationDocumentFieldsCreateProjectionInput = {
  customerId: Scalars['UUID'];
  idType?: InputMaybe<Scalars['String']>;
  nationality: Scalars['String'];
  nationalityType: Scalars['String'];
};

export type VerificationDocumentFieldsProjection = {
  __typename?: 'VerificationDocumentFieldsProjection';
  customerId: Scalars['UUID'];
  idType?: Maybe<Scalars['String']>;
  nationality: Scalars['String'];
  nationalityType: Scalars['String'];
};

export type VoucherDiscountProjection = {
  __typename?: 'VoucherDiscountProjection';
  amountMinorUnits?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
};

export type VoucherProjection = {
  __typename?: 'VoucherProjection';
  code?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  voucherDiscount?: Maybe<VoucherDiscountProjection>;
};

export type WarningLightsDto = {
  __typename?: 'WarningLightsDTO';
  absWarning?: Maybe<Scalars['Boolean']>;
  airbagWarning?: Maybe<Scalars['Boolean']>;
  batteryWarning?: Maybe<Scalars['Boolean']>;
  breakPadWarning?: Maybe<Scalars['Boolean']>;
  breakWarning?: Maybe<Scalars['Boolean']>;
  coolantTemperatureWarning?: Maybe<Scalars['Boolean']>;
  engineOilWarning?: Maybe<Scalars['Boolean']>;
  engineWarning?: Maybe<Scalars['Boolean']>;
  lowTirePressureWarning?: Maybe<Scalars['Boolean']>;
  tractionControlSystemWarning?: Maybe<Scalars['Boolean']>;
};

export type WarningLightsDtoInput = {
  absWarning?: InputMaybe<Scalars['Boolean']>;
  airbagWarning?: InputMaybe<Scalars['Boolean']>;
  batteryWarning?: InputMaybe<Scalars['Boolean']>;
  breakPadWarning?: InputMaybe<Scalars['Boolean']>;
  breakWarning?: InputMaybe<Scalars['Boolean']>;
  coolantTemperatureWarning?: InputMaybe<Scalars['Boolean']>;
  engineOilWarning?: InputMaybe<Scalars['Boolean']>;
  engineWarning?: InputMaybe<Scalars['Boolean']>;
  lowTirePressureWarning?: InputMaybe<Scalars['Boolean']>;
  tractionControlSystemWarning?: InputMaybe<Scalars['Boolean']>;
};

export type WarrantyCarPricePriceModifierProjection = {
  __typename?: 'WarrantyCarPricePriceModifierProjection';
  active: Scalars['Boolean'];
  category: Scalars['String'];
  createdBy: Scalars['UUID'];
  createdOn?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  pnlCostPrice?: Maybe<PriceDetailsProjection>;
  price?: Maybe<PriceDetailsProjection>;
  priceMaxMinorUnits: Scalars['Long'];
  priceMinMinorUnits: Scalars['Long'];
  priceModifierInstance: Scalars['String'];
  productId: Scalars['UUID'];
  updatedOn?: Maybe<Scalars['String']>;
};

export type WarrantyCarPricePriceModifierUpdateProjectionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  grossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  priceMaxMinorUnits?: InputMaybe<Scalars['Long']>;
  priceMinMinorUnits?: InputMaybe<Scalars['Long']>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
};

export type WarrantyCarYearsPriceModifierProjection = {
  __typename?: 'WarrantyCarYearsPriceModifierProjection';
  active: Scalars['Boolean'];
  category: Scalars['String'];
  createdBy: Scalars['UUID'];
  createdOn?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  pnlCostPrice?: Maybe<PriceDetailsProjection>;
  price?: Maybe<PriceDetailsProjection>;
  priceModifierInstance: Scalars['String'];
  productId: Scalars['UUID'];
  updatedOn?: Maybe<Scalars['String']>;
  yearsMax: Scalars['Int'];
  yearsMin: Scalars['Int'];
};

export type WarrantyCarYearsPriceModifierUpdateProjectionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  grossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlCostGrossPriceMinorUnits?: InputMaybe<Scalars['Long']>;
  pnlCostVatKey?: InputMaybe<VatKeyType>;
  pnlCostVatPercentage?: InputMaybe<Scalars['Float']>;
  vatKey?: InputMaybe<VatKeyType>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
  yearsMax?: InputMaybe<Scalars['Long']>;
  yearsMin?: InputMaybe<Scalars['Long']>;
};

export type WarrantyDetailsProjection = {
  __typename?: 'WarrantyDetailsProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customDiscountMinorUnits?: Maybe<Scalars['Long']>;
  customDiscountType?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  expiryDate?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  maxAllowedMileage?: Maybe<MileageDto>;
  obsolete?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderItemRefund?: Maybe<Scalars['Long']>;
  parentId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  warranty?: Maybe<RetailWarrantyProjection>;
};

export type WarrantyItemProjection = {
  __typename?: 'WarrantyItemProjection';
  created?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customDiscountMinorUnits?: Maybe<Scalars['Long']>;
  customDiscountType?: Maybe<Scalars['String']>;
  discountGrossMinorUnits?: Maybe<Scalars['Long']>;
  discountMinorUnits?: Maybe<Scalars['Long']>;
  externalId?: Maybe<Scalars['UUID']>;
  externalType?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['UUID']>;
  invoiceDescription?: Maybe<Scalars['String']>;
  invoiceName?: Maybe<Scalars['String']>;
  obsolete?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['UUID']>;
  orderItemRefund?: Maybe<Scalars['Long']>;
  parentId?: Maybe<Scalars['UUID']>;
  priceDiscountGross?: Maybe<PriceDto>;
  priceDiscountNet?: Maybe<PriceDto>;
  priceDiscountVat?: Maybe<PriceDto>;
  priceGross?: Maybe<PriceDto>;
  priceGrossMinorUnits?: Maybe<Scalars['Long']>;
  priceNet?: Maybe<PriceDto>;
  priceNetMinorUnits?: Maybe<Scalars['Long']>;
  priceTaxAdvantageAmount?: Maybe<PriceDto>;
  priceVatAmount?: Maybe<PriceDto>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  vatAmountMinorUnits?: Maybe<Scalars['Long']>;
  vatKey?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  warranty?: Maybe<RetailWarrantyProjection>;
};

export enum WarrantyLevelType {
  Basic = 'BASIC',
  Bronze = 'BRONZE',
  Gold = 'GOLD',
  Premium = 'PREMIUM',
  Silver = 'SILVER'
}

export enum WarrantySourceType {
  Autohero = 'AUTOHERO',
  Partner = 'PARTNER'
}

export type WheelDtoInput = {
  rimType?: InputMaybe<Scalars['String']>;
  tireType?: InputMaybe<Scalars['String']>;
};

export type WheelPositionProjection = {
  __typename?: 'WheelPositionProjection';
  position?: Maybe<Scalars['String']>;
  wheels?: Maybe<Array<Maybe<WheelProjection>>>;
};

export type WheelProjection = {
  __typename?: 'WheelProjection';
  dot?: Maybe<Scalars['String']>;
  loadIndex?: Maybe<Scalars['Int']>;
  manufacturer?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
  rimSize?: Maybe<RimSize>;
  rimType?: Maybe<Scalars['String']>;
  season?: Maybe<Scalars['String']>;
  seasonLabel?: Maybe<Scalars['String']>;
  speedIndex?: Maybe<Scalars['String']>;
  wheelSize?: Maybe<WheelSize>;
};

export enum WheelSetDeleteReason {
  NotLicensedForTheCar = 'NOT_LICENSED_FOR_THE_CAR',
  WheelsCannotBeSold = 'WHEELS_CANNOT_BE_SOLD',
  WheelsDoNotExist = 'WHEELS_DO_NOT_EXIST'
}

export type WheelSetsDto = {
  __typename?: 'WheelSetsDTO';
  primary?: Maybe<Scalars['Map_String_List_WheelScalar']>;
  primaryTranslations?: Maybe<Array<Maybe<WheelPositionProjection>>>;
  secondary?: Maybe<Scalars['Map_String_List_WheelScalar']>;
  secondaryTranslations?: Maybe<Array<Maybe<WheelPositionProjection>>>;
};

export type WheelSetsDtoInput = {
  primary?: InputMaybe<Scalars['Map_String_List_WheelScalar']>;
  secondary?: InputMaybe<Scalars['Map_String_List_WheelScalar']>;
};

export type WheelSize = {
  __typename?: 'WheelSize';
  height?: Maybe<Scalars['Int']>;
  unit?: Maybe<Unit>;
  value?: Maybe<Scalars['Int']>;
};

export type WorkshopDto = {
  __typename?: 'WorkshopDTO';
  branchId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['UUID']>;
  createdOn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
};

export type BankTypedAccountDetailsProjection = BankTransferBankAccountDetailsProjection | IbanBankAccountDetailsProjection | SePlusgiroBankAccountDetailsProjection;

export type DeleteDocumentInventoryItemResult = DeleteDocumentInventoryItemPayload | DocumentItemInPackageProblem;

export type RetailAdDeliveryOptionDetails = RetailAdDeliveryAddressDetailsProjection | RetailAdDeliveryBranchDetailsProjection | RetailAdDeliveryBranchesDetailsProjection;

export type TaskManagementProjection = AccountingDatePendingTaskProjection | BpmTaxTaskProjection | BankDetailsPendingTaskProjection | CancellationRequestedTaskProjection | CancellationTaskProjection | CarDeregistrationTaskProjection | CarReturnHandoverAppointmentTaskProjection | CarReturnedTaskProjection | CareCallTaskProjection | CashOutRefundApprovalPendingTaskProjection | ClaimPartialRefundPendingTaskProjection | ClaimProofPendingTaskProjection | ClaimProofSubmittedTaskProjection | CreditNoteNumberPendingTaskProjection | CreditNotePendingTaskProjection | DamageCompensationPendingTaskProjection | DocumentsReturnedDeregistrationTaskProjection | DocumentsReturnedTaskProjection | EmailReplyPendingTaskProjection | EmailReplyReviewTaskProjection | FinancingApplicationPendingBankDecisionTaskProjection | FinancingApplicationValidationTaskProjection | FinancingBillingAddressVerificationTaskProjection | FinancingInternalApprovalTaskProjection | FinancingMonthlyPaymentDayUpdateTaskProjection | FinancingSepaMandateVerificationTaskProjection | HappinessCallTaskManagementProjection | LicensePlateTaskProjection | OrderReadyForVerificationTaskProjection | OrderShippingDetailsUpdateTaskProjection | OverpaymentRefundApprovalTaskProjection | OverpaymentRefundPendingTaskProjection | RefundPendingTaskProjection | RepeatHappinessCallTaskManagementProjection | RetailExternalFinancingBankDetailsPendingTaskProjection | RetailFinancingAmountVerificationTaskProjection | SepaMandateUpdateInformHmcsTaskProjection | TaskManagementBaseTaskProjection | TradeInPurchasePendingTaskProjection | VerificationCallTaskProjection;

export type RetailFeaturesStatusV2QueryVariables = Exact<{
  names: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  context: RetailFeatureStatusContextDtoInput;
}>;


export type RetailFeaturesStatusV2Query = { __typename?: 'Query', statuses?: Array<{ __typename?: 'RetailFeatureStatusProjectionV2', name?: string | null, enabled: boolean } | null> | null };

export type ImportChatbotQuestionsMutationVariables = Exact<{
  entities?: InputMaybe<Array<InputMaybe<ChatbotQuestionImportCreateProjectionInput>> | InputMaybe<ChatbotQuestionImportCreateProjectionInput>>;
}>;


export type ImportChatbotQuestionsMutation = { __typename?: 'Mutation', importChatbotQuestions?: Array<{ __typename?: 'RetailDataImportDTO', id?: any | null } | null> | null };

export type PresignedUrlsQueryVariables = Exact<{
  entities?: InputMaybe<Array<InputMaybe<AwsS3PresignedUrlRequestDtoInput>> | InputMaybe<AwsS3PresignedUrlRequestDtoInput>>;
}>;


export type PresignedUrlsQuery = { __typename?: 'Query', data?: Array<{ __typename?: 'AwsS3PresignedUrlDTO', fileName?: string | null, httpMethod?: string | null, presignedUrl?: string | null } | null> | null };

export type SearchRetailDataImportQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;


export type SearchRetailDataImportQuery = { __typename?: 'Query', data?: { __typename?: 'PageDTO_RetailDataImportProjection', page?: number | null, pageSize?: number | null, totalPageCount?: number | null, totalEntityCount?: any | null, entities?: Array<{ __typename?: 'RetailDataImportProjection', id?: any | null, createdOn?: string | null, errorRows?: number | null, errorsResourceLocation?: string | null, resourceLocation?: string | null, state?: RetailDataImportState | null, successRows?: number | null, creationSource?: string | null, createdBy?: { __typename?: 'RetailUserDTO', id?: any | null, firstName?: string | null, lastName?: string | null } | null } | null> | null } | null };

export type LiveChatDashboardAgentFragment = { __typename?: 'AutoheroChatBotLiveAgentProjection', id?: any | null, fullName?: string | null, status?: string | null, localisedCounters?: Array<{ __typename?: 'AutoheroChatBotLiveAgentLocalisedCountersProjection', locale?: string | null, agentReplyPendingChatsCount?: number | null, customerReplyPendingChatsCount?: number | null } | null> | null };

export type SearchLiveChatDashboardAgentsQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;


export type SearchLiveChatDashboardAgentsQuery = { __typename?: 'Query', search?: { __typename?: 'PageDTO_AutoheroChatBotLiveAgentProjection', entities?: Array<{ __typename?: 'AutoheroChatBotLiveAgentProjection', id?: any | null, fullName?: string | null, status?: string | null, localisedCounters?: Array<{ __typename?: 'AutoheroChatBotLiveAgentLocalisedCountersProjection', locale?: string | null, agentReplyPendingChatsCount?: number | null, customerReplyPendingChatsCount?: number | null } | null> | null } | null> | null } | null };

export type CreateLiveChatAgentAnswerTemplateMutationVariables = Exact<{
  createProjection: CreateAgentAnswerTemplateProjectionInput;
}>;


export type CreateLiveChatAgentAnswerTemplateMutation = { __typename?: 'Mutation', newTemplate?: { __typename?: 'AgentAnswerTemplateProjection', id?: any | null, name?: string | null, content?: string | null, teams?: Array<{ __typename?: 'LiveChatAgentTeamProjection', id?: any | null, name?: string | null } | null> | null } | null };

export type CreateLiveChatAgentsMutationVariables = Exact<{
  locales: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  boUserIds: Array<InputMaybe<Scalars['UUID']>> | InputMaybe<Scalars['UUID']>;
}>;


export type CreateLiveChatAgentsMutation = { __typename?: 'Mutation', createLiveChatAgents?: Array<{ __typename?: 'LiveChatAgentProjection', email?: string | null, firstName?: string | null, id?: any | null, lastName?: string | null } | null> | null };

export type DeleteLiveChatAgentMutationVariables = Exact<{
  agentId: Scalars['UUID'];
  locale: Scalars['String'];
}>;


export type DeleteLiveChatAgentMutation = { __typename?: 'Mutation', deleteLiveChatAgent: boolean };

export type DeleteLiveChatAgentAnswerTemplateMutationVariables = Exact<{
  templateId: Scalars['UUID'];
}>;


export type DeleteLiveChatAgentAnswerTemplateMutation = { __typename?: 'Mutation', deleteLiveChatAgentAnswerTemplate: boolean };

export type LiveChatAgentFragment = { __typename?: 'LiveChatAgentProjection', email?: string | null, id?: any | null, boUserId?: any | null };

export type LiveChatAgentAnswerTemplateFragment = { __typename?: 'AgentAnswerTemplateProjection', id?: any | null, name?: string | null, content?: string | null, teams?: Array<{ __typename?: 'LiveChatAgentTeamProjection', id?: any | null, name?: string | null } | null> | null };

export type LiveChatAgentTeamFragment = { __typename?: 'LiveChatAgentTeamProjection', id?: any | null, name?: string | null, agents?: Array<{ __typename?: 'LiveChatAgentProjection', id?: any | null } | null> | null, categories?: Array<{ __typename?: 'LiveChatCategoryProjection', id?: any | null } | null> | null };

export type LiveChatCategoryFragment = { __typename?: 'LiveChatCategoryProjection', id?: any | null, name?: string | null };

export type LiveChatAgentTeamsQueryVariables = Exact<{
  locale: Scalars['String'];
}>;


export type LiveChatAgentTeamsQuery = { __typename?: 'Query', liveChatAgentTeams?: Array<{ __typename?: 'LiveChatAgentTeamProjection', id?: any | null, name?: string | null, agents?: Array<{ __typename?: 'LiveChatAgentProjection', id?: any | null } | null> | null, categories?: Array<{ __typename?: 'LiveChatCategoryProjection', id?: any | null } | null> | null } | null> | null };

export type LiveChatAgentsQueryVariables = Exact<{
  locale: Scalars['String'];
}>;


export type LiveChatAgentsQuery = { __typename?: 'Query', liveChatAgents?: Array<{ __typename?: 'LiveChatAgentProjection', email?: string | null, id?: any | null, boUserId?: any | null } | null> | null };

export type LiveChatAnswerTemplatesForAgentQueryVariables = Exact<{
  locale: Scalars['String'];
  agentId: Scalars['UUID'];
}>;


export type LiveChatAnswerTemplatesForAgentQuery = { __typename?: 'Query', templates?: Array<{ __typename?: 'AgentAnswerTemplateProjection', id?: any | null, name?: string | null, content?: string | null, teams?: Array<{ __typename?: 'LiveChatAgentTeamProjection', id?: any | null, name?: string | null } | null> | null } | null> | null };

export type LiveChatCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type LiveChatCategoriesQuery = { __typename?: 'Query', liveChatCategories?: Array<{ __typename?: 'LiveChatCategoryProjection', id?: any | null, name?: string | null } | null> | null };

export type SearchBoUsersQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;


export type SearchBoUsersQuery = { __typename?: 'Query', searchUsers?: { __typename?: 'PageDTO_RetailUserDTO', entities?: Array<{ __typename?: 'RetailUserDTO', id?: any | null, email?: string | null, country?: string | null, locale?: string | null } | null> | null } | null };

export type SearchLiveChatAgentAnswerTemplatesQueryVariables = Exact<{
  search: SearchProjection2Input;
}>;


export type SearchLiveChatAgentAnswerTemplatesQuery = { __typename?: 'Query', liveChatAgentAnswerTemplates?: { __typename?: 'PageDTO_AgentAnswerTemplateProjection', entities?: Array<{ __typename?: 'AgentAnswerTemplateProjection', id?: any | null, name?: string | null, content?: string | null, teams?: Array<{ __typename?: 'LiveChatAgentTeamProjection', id?: any | null, name?: string | null } | null> | null } | null> | null } | null };

export type UpdateLiveChatAgentAnswerTemplateMutationVariables = Exact<{
  templateId: Scalars['UUID'];
  updateProjection: UpdateAgentAnswerTemplateProjectionInput;
}>;


export type UpdateLiveChatAgentAnswerTemplateMutation = { __typename?: 'Mutation', updatedTemplate?: { __typename?: 'AgentAnswerTemplateProjection', id?: any | null, name?: string | null, content?: string | null, teams?: Array<{ __typename?: 'LiveChatAgentTeamProjection', id?: any | null, name?: string | null } | null> | null } | null };

export type UpdateLiveChatAgentTeamAgentsMutationVariables = Exact<{
  request: LiveChatUpdateTeamAgentsRequestProjectionInput;
}>;


export type UpdateLiveChatAgentTeamAgentsMutation = { __typename?: 'Mutation', updateLiveChatAgentTeamAgents: boolean };

export type UpdateLiveChatAgentTeamCategoriesMutationVariables = Exact<{
  request: LiveChatUpdateTeamCategoriesRequestProjectionInput;
}>;


export type UpdateLiveChatAgentTeamCategoriesMutation = { __typename?: 'Mutation', updateLiveChatAgentTeamCategories: boolean };

export type LiveAgentFragment = { __typename?: 'AutoheroChatBotLiveAgentProjection', boUserId?: any | null, id?: any | null, locale?: string | null, replyTimeoutMinutes?: number | null, replyWhatsAppTimeoutMinutes?: number | null, status?: string | null };

export type LiveChatFragment = { __typename?: 'AutoheroChatBotLiveChatProjection', chatType?: string | null, conversationId?: any | null, customerEmail?: string | null, customerFullName?: string | null, id?: any | null, readByAgent: boolean, latestAgentActivityTs?: string | null, latestUserActivityTs?: string | null, locale?: string | null, orderId?: any | null, phoneNumber?: string | null, dialogProperties?: any | null, status?: string | null, selectedTopics?: Array<{ __typename?: 'AutoheroLiveChatTopicProjection', id?: any | null, name?: string | null } | null> | null };

export type LiveChatHistoryItemFragment = { __typename?: 'DialogProjection', conversationAction?: string | null, id?: any | null, liveChatId?: any | null, properties?: any | null, ts?: string | null, userText?: string | null, completionText?: { __typename?: 'CompletionTextProjection', id?: any | null, text?: string | null } | null, dialogAnswer?: { __typename?: 'DialogAnswerProjection', context?: any | null, display?: string | null, id?: any | null, text?: string | null } | null };

export type LiveChatTopicFragment = { __typename?: 'AutoheroLiveChatTopicProjection', id?: any | null, name?: string | null };

export type AddLiveChatTopicMutationVariables = Exact<{
  request?: InputMaybe<LiveChatAddTopicRequestProjectionInput>;
}>;


export type AddLiveChatTopicMutation = { __typename?: 'Mutation', addLiveChatTopic: boolean };

export type ChangeAgentStatusMutationVariables = Exact<{
  status?: InputMaybe<Scalars['String']>;
}>;


export type ChangeAgentStatusMutation = { __typename?: 'Mutation', changeAgentStatus: boolean };

export type EndLiveChatMutationVariables = Exact<{
  request?: InputMaybe<LiveChatEndRequestProjectionInput>;
}>;


export type EndLiveChatMutation = { __typename?: 'Mutation', endLiveChat: boolean };

export type GenerateAiSuggestionMutationVariables = Exact<{
  request: LiveChatAiSuggestionRequestProjectionInput;
}>;


export type GenerateAiSuggestionMutation = { __typename?: 'Mutation', generateAiSuggestion?: { __typename?: 'LiveChatAiSuggestionResponseProjection', success?: boolean | null, text?: string | null } | null };

export type MarkLiveChatAsReadMutationVariables = Exact<{
  request?: InputMaybe<LiveChatAgentMarkReadRequestProjectionInput>;
}>;


export type MarkLiveChatAsReadMutation = { __typename?: 'Mutation', markLiveChatAsRead: boolean };

export type RemoveLiveChatTopicMutationVariables = Exact<{
  request?: InputMaybe<LiveChatRemoveTopicRequestProjectionInput>;
}>;


export type RemoveLiveChatTopicMutation = { __typename?: 'Mutation', removeLiveChatTopic: boolean };

export type SendAgentAnswerMutationVariables = Exact<{
  request?: InputMaybe<LiveChatAgentAnswerRequestProjectionInput>;
}>;


export type SendAgentAnswerMutation = { __typename?: 'Mutation', sendAgentAnswer?: { __typename?: 'LiveChatAgentAnswerResponseProjection', acknowledgedAt?: string | null } | null };

export type SendLiveChatTemplateTrackingMutationVariables = Exact<{
  liveChatId?: InputMaybe<Scalars['UUID']>;
  conversationId?: InputMaybe<Scalars['UUID']>;
  templateId?: InputMaybe<Scalars['UUID']>;
  action?: InputMaybe<Scalars['String']>;
}>;


export type SendLiveChatTemplateTrackingMutation = { __typename?: 'Mutation', sendLiveChatTemplateTracking: boolean };

export type TrackAiSuggestionMutationVariables = Exact<{
  request?: InputMaybe<LiveChatTrackAiSuggestionRequestProjectionInput>;
}>;


export type TrackAiSuggestionMutation = { __typename?: 'Mutation', trackAiSuggestion: boolean };

export type UpdateLiveChatDetailsMutationVariables = Exact<{
  id: Scalars['UUID'];
  request?: InputMaybe<LiveChatUpdateDetailsRequestInput>;
}>;


export type UpdateLiveChatDetailsMutation = { __typename?: 'Mutation', updateLiveChatDetails: boolean };

export type LiveChatAgentDataQueryVariables = Exact<{
  request?: InputMaybe<ChatbotLiveChatAgentDataRequestProjectionInput>;
}>;


export type LiveChatAgentDataQuery = { __typename?: 'Query', liveChatAgentData?: { __typename?: 'ChatbotLiveChatAgentDataProjection', agent?: { __typename?: 'AutoheroChatBotLiveAgentProjection', boUserId?: any | null, id?: any | null, locale?: string | null, replyTimeoutMinutes?: number | null, replyWhatsAppTimeoutMinutes?: number | null, status?: string | null } | null, activeChats?: Array<{ __typename?: 'AutoheroChatBotLiveChatProjection', chatType?: string | null, conversationId?: any | null, customerEmail?: string | null, customerFullName?: string | null, id?: any | null, readByAgent: boolean, latestAgentActivityTs?: string | null, latestUserActivityTs?: string | null, locale?: string | null, orderId?: any | null, phoneNumber?: string | null, dialogProperties?: any | null, status?: string | null, selectedTopics?: Array<{ __typename?: 'AutoheroLiveChatTopicProjection', id?: any | null, name?: string | null } | null> | null } | null> | null, archivedChats?: Array<{ __typename?: 'AutoheroChatBotLiveChatProjection', chatType?: string | null, conversationId?: any | null, customerEmail?: string | null, customerFullName?: string | null, id?: any | null, readByAgent: boolean, latestAgentActivityTs?: string | null, latestUserActivityTs?: string | null, locale?: string | null, orderId?: any | null, phoneNumber?: string | null, dialogProperties?: any | null, status?: string | null, selectedTopics?: Array<{ __typename?: 'AutoheroLiveChatTopicProjection', id?: any | null, name?: string | null } | null> | null } | null> | null, availableTopics?: Array<{ __typename?: 'AutoheroLiveChatTopicProjection', id?: any | null, name?: string | null } | null> | null } | null };

export type LiveChatHistoryQueryVariables = Exact<{
  request?: InputMaybe<DialogHistoryRequestProjectionInput>;
}>;


export type LiveChatHistoryQuery = { __typename?: 'Query', liveChatHistory?: Array<{ __typename?: 'DialogProjection', conversationAction?: string | null, id?: any | null, liveChatId?: any | null, properties?: any | null, ts?: string | null, userText?: string | null, completionText?: { __typename?: 'CompletionTextProjection', id?: any | null, text?: string | null } | null, dialogAnswer?: { __typename?: 'DialogAnswerProjection', context?: any | null, display?: string | null, id?: any | null, text?: string | null } | null } | null> | null };

export type AddAiSettingsMutationVariables = Exact<{
  aiSettings: AiSettingsCreateProjectionInput;
}>;


export type AddAiSettingsMutation = { __typename?: 'Mutation', addAiSettings?: { __typename?: 'AiSettingProjection', id?: any | null } | null };

export type SearchAiSettingsQueryVariables = Exact<{
  search: AiSettingsSearchProjectionInput;
}>;


export type SearchAiSettingsQuery = { __typename?: 'Query', data?: { __typename?: 'PageDTO_AiSettingProjection', page?: number | null, pageSize?: number | null, totalEntityCount?: any | null, totalPageCount?: number | null, entities?: Array<{ __typename?: 'AiSettingProjection', id?: any | null, contextQuestionLimit?: number | null, locale?: string | null, maxTokens?: number | null, similarityScoreThreshold?: number | null, systemMessage?: string | null, temperature?: number | null, topP?: number | null, versionName?: string | null, model?: string | null, useLastPredefinedMessage?: boolean | null, aiHistoryMessageLimit?: number | null, userMessageMaxTokens?: number | null, contextQuestionMaxTokens?: number | null, createdAt?: string | null, createdBy?: { __typename?: 'RetailUserDTO', id?: any | null, firstName?: string | null, lastName?: string | null, email?: string | null } | null } | null> | null } | null };

export type CompleteAiQuestionQueryVariables = Exact<{
  question: CompleteQuestionRequestProjectionInput;
}>;


export type CompleteAiQuestionQuery = { __typename?: 'Query', data?: { __typename?: 'CompleteQuestionResponseProjection', aiResponse?: string | null, completionContext?: Array<{ __typename?: 'CompletionContextProjection', context?: string | null, derivedTopic?: string | null, similarityScore?: number | null } | null> | null, tokenStats?: { __typename?: 'TokenStatsProjection', context?: number | null, response?: number | null, userMessage?: number | null, systemMessage?: number | null } | null } | null };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', me?: { __typename?: 'RetailUserDTO', id?: any | null, effectivePermissions?: Array<string | null> | null, locale?: string | null, accessParameters?: Array<{ __typename?: 'RetailAccessParamDTO', key?: string | null, value?: string | null } | null> | null } | null };

export const LiveChatDashboardAgentFragmentDoc = gql`
    fragment LiveChatDashboardAgent on AutoheroChatBotLiveAgentProjection {
  id
  fullName
  status
  localisedCounters {
    locale
    agentReplyPendingChatsCount
    customerReplyPendingChatsCount
  }
}
    `;
export const LiveChatAgentFragmentDoc = gql`
    fragment LiveChatAgent on LiveChatAgentProjection {
  email
  id
  boUserId
}
    `;
export const LiveChatAgentAnswerTemplateFragmentDoc = gql`
    fragment LiveChatAgentAnswerTemplate on AgentAnswerTemplateProjection {
  id
  name
  content
  teams {
    id
    name
  }
}
    `;
export const LiveChatAgentTeamFragmentDoc = gql`
    fragment LiveChatAgentTeam on LiveChatAgentTeamProjection {
  id
  name
  agents {
    id
  }
  categories {
    id
  }
}
    `;
export const LiveChatCategoryFragmentDoc = gql`
    fragment LiveChatCategory on LiveChatCategoryProjection {
  id
  name
}
    `;
export const LiveAgentFragmentDoc = gql`
    fragment LiveAgent on AutoheroChatBotLiveAgentProjection {
  boUserId
  id
  locale
  replyTimeoutMinutes
  replyWhatsAppTimeoutMinutes
  status
}
    `;
export const LiveChatTopicFragmentDoc = gql`
    fragment LiveChatTopic on AutoheroLiveChatTopicProjection {
  id
  name
}
    `;
export const LiveChatFragmentDoc = gql`
    fragment LiveChat on AutoheroChatBotLiveChatProjection {
  chatType
  conversationId
  customerEmail
  customerFullName
  id
  readByAgent
  latestAgentActivityTs
  latestUserActivityTs
  locale
  orderId
  phoneNumber
  dialogProperties
  selectedTopics {
    ...LiveChatTopic
  }
  status
}
    ${LiveChatTopicFragmentDoc}`;
export const LiveChatHistoryItemFragmentDoc = gql`
    fragment LiveChatHistoryItem on DialogProjection {
  completionText {
    id
    text
  }
  conversationAction
  dialogAnswer {
    context
    display
    id
    text
  }
  id
  liveChatId
  properties
  ts
  userText
}
    `;
export const RetailFeaturesStatusV2Document = gql`
    query retailFeaturesStatusV2($names: [String]!, $context: RetailFeatureStatusContextDTOInput!) {
  statuses: retailFeaturesStatusV2(names: $names, context: $context) {
    name
    enabled
  }
}
    `;

/**
 * __useRetailFeaturesStatusV2Query__
 *
 * To run a query within a React component, call `useRetailFeaturesStatusV2Query` and pass it any options that fit your needs.
 * When your component renders, `useRetailFeaturesStatusV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailFeaturesStatusV2Query({
 *   variables: {
 *      names: // value for 'names'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useRetailFeaturesStatusV2Query(baseOptions: Apollo.QueryHookOptions<RetailFeaturesStatusV2Query, RetailFeaturesStatusV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetailFeaturesStatusV2Query, RetailFeaturesStatusV2QueryVariables>(RetailFeaturesStatusV2Document, options);
      }
export function useRetailFeaturesStatusV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetailFeaturesStatusV2Query, RetailFeaturesStatusV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetailFeaturesStatusV2Query, RetailFeaturesStatusV2QueryVariables>(RetailFeaturesStatusV2Document, options);
        }
export type RetailFeaturesStatusV2QueryHookResult = ReturnType<typeof useRetailFeaturesStatusV2Query>;
export type RetailFeaturesStatusV2LazyQueryHookResult = ReturnType<typeof useRetailFeaturesStatusV2LazyQuery>;
export type RetailFeaturesStatusV2QueryResult = Apollo.QueryResult<RetailFeaturesStatusV2Query, RetailFeaturesStatusV2QueryVariables>;
export const ImportChatbotQuestionsDocument = gql`
    mutation importChatbotQuestions($entities: [ChatbotQuestionImportCreateProjectionInput]) {
  importChatbotQuestions(request: {entities: $entities}) {
    id
  }
}
    `;
export type ImportChatbotQuestionsMutationFn = Apollo.MutationFunction<ImportChatbotQuestionsMutation, ImportChatbotQuestionsMutationVariables>;

/**
 * __useImportChatbotQuestionsMutation__
 *
 * To run a mutation, you first call `useImportChatbotQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportChatbotQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importChatbotQuestionsMutation, { data, loading, error }] = useImportChatbotQuestionsMutation({
 *   variables: {
 *      entities: // value for 'entities'
 *   },
 * });
 */
export function useImportChatbotQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<ImportChatbotQuestionsMutation, ImportChatbotQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportChatbotQuestionsMutation, ImportChatbotQuestionsMutationVariables>(ImportChatbotQuestionsDocument, options);
      }
export type ImportChatbotQuestionsMutationHookResult = ReturnType<typeof useImportChatbotQuestionsMutation>;
export type ImportChatbotQuestionsMutationResult = Apollo.MutationResult<ImportChatbotQuestionsMutation>;
export type ImportChatbotQuestionsMutationOptions = Apollo.BaseMutationOptions<ImportChatbotQuestionsMutation, ImportChatbotQuestionsMutationVariables>;
export const PresignedUrlsDocument = gql`
    query presignedUrls($entities: [AwsS3PresignedUrlRequestDTOInput]) {
  data: presignedUrls(request: {entities: $entities}) {
    fileName
    httpMethod
    presignedUrl
  }
}
    `;

/**
 * __usePresignedUrlsQuery__
 *
 * To run a query within a React component, call `usePresignedUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePresignedUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePresignedUrlsQuery({
 *   variables: {
 *      entities: // value for 'entities'
 *   },
 * });
 */
export function usePresignedUrlsQuery(baseOptions?: Apollo.QueryHookOptions<PresignedUrlsQuery, PresignedUrlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PresignedUrlsQuery, PresignedUrlsQueryVariables>(PresignedUrlsDocument, options);
      }
export function usePresignedUrlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PresignedUrlsQuery, PresignedUrlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PresignedUrlsQuery, PresignedUrlsQueryVariables>(PresignedUrlsDocument, options);
        }
export type PresignedUrlsQueryHookResult = ReturnType<typeof usePresignedUrlsQuery>;
export type PresignedUrlsLazyQueryHookResult = ReturnType<typeof usePresignedUrlsLazyQuery>;
export type PresignedUrlsQueryResult = Apollo.QueryResult<PresignedUrlsQuery, PresignedUrlsQueryVariables>;
export const SearchRetailDataImportDocument = gql`
    query searchRetailDataImport($search: SearchProjection2Input!) {
  data: searchRetailDataImport(search: $search) {
    entities {
      id
      createdBy {
        id
        firstName
        lastName
      }
      createdOn
      errorRows
      errorsResourceLocation
      resourceLocation
      state
      successRows
      creationSource
    }
    page
    pageSize
    totalPageCount
    totalEntityCount
  }
}
    `;

/**
 * __useSearchRetailDataImportQuery__
 *
 * To run a query within a React component, call `useSearchRetailDataImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchRetailDataImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchRetailDataImportQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchRetailDataImportQuery(baseOptions: Apollo.QueryHookOptions<SearchRetailDataImportQuery, SearchRetailDataImportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchRetailDataImportQuery, SearchRetailDataImportQueryVariables>(SearchRetailDataImportDocument, options);
      }
export function useSearchRetailDataImportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchRetailDataImportQuery, SearchRetailDataImportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchRetailDataImportQuery, SearchRetailDataImportQueryVariables>(SearchRetailDataImportDocument, options);
        }
export type SearchRetailDataImportQueryHookResult = ReturnType<typeof useSearchRetailDataImportQuery>;
export type SearchRetailDataImportLazyQueryHookResult = ReturnType<typeof useSearchRetailDataImportLazyQuery>;
export type SearchRetailDataImportQueryResult = Apollo.QueryResult<SearchRetailDataImportQuery, SearchRetailDataImportQueryVariables>;
export const SearchLiveChatDashboardAgentsDocument = gql`
    query searchLiveChatDashboardAgents($search: SearchProjection2Input!) {
  search: searchLiveChatAgents(search: $search) {
    entities {
      ...LiveChatDashboardAgent
    }
  }
}
    ${LiveChatDashboardAgentFragmentDoc}`;

/**
 * __useSearchLiveChatDashboardAgentsQuery__
 *
 * To run a query within a React component, call `useSearchLiveChatDashboardAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLiveChatDashboardAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLiveChatDashboardAgentsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchLiveChatDashboardAgentsQuery(baseOptions: Apollo.QueryHookOptions<SearchLiveChatDashboardAgentsQuery, SearchLiveChatDashboardAgentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchLiveChatDashboardAgentsQuery, SearchLiveChatDashboardAgentsQueryVariables>(SearchLiveChatDashboardAgentsDocument, options);
      }
export function useSearchLiveChatDashboardAgentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchLiveChatDashboardAgentsQuery, SearchLiveChatDashboardAgentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchLiveChatDashboardAgentsQuery, SearchLiveChatDashboardAgentsQueryVariables>(SearchLiveChatDashboardAgentsDocument, options);
        }
export type SearchLiveChatDashboardAgentsQueryHookResult = ReturnType<typeof useSearchLiveChatDashboardAgentsQuery>;
export type SearchLiveChatDashboardAgentsLazyQueryHookResult = ReturnType<typeof useSearchLiveChatDashboardAgentsLazyQuery>;
export type SearchLiveChatDashboardAgentsQueryResult = Apollo.QueryResult<SearchLiveChatDashboardAgentsQuery, SearchLiveChatDashboardAgentsQueryVariables>;
export const CreateLiveChatAgentAnswerTemplateDocument = gql`
    mutation createLiveChatAgentAnswerTemplate($createProjection: CreateAgentAnswerTemplateProjectionInput!) {
  newTemplate: createLiveChatAgentAnswerTemplate(
    createProjection: $createProjection
  ) {
    ...LiveChatAgentAnswerTemplate
  }
}
    ${LiveChatAgentAnswerTemplateFragmentDoc}`;
export type CreateLiveChatAgentAnswerTemplateMutationFn = Apollo.MutationFunction<CreateLiveChatAgentAnswerTemplateMutation, CreateLiveChatAgentAnswerTemplateMutationVariables>;

/**
 * __useCreateLiveChatAgentAnswerTemplateMutation__
 *
 * To run a mutation, you first call `useCreateLiveChatAgentAnswerTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLiveChatAgentAnswerTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLiveChatAgentAnswerTemplateMutation, { data, loading, error }] = useCreateLiveChatAgentAnswerTemplateMutation({
 *   variables: {
 *      createProjection: // value for 'createProjection'
 *   },
 * });
 */
export function useCreateLiveChatAgentAnswerTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateLiveChatAgentAnswerTemplateMutation, CreateLiveChatAgentAnswerTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLiveChatAgentAnswerTemplateMutation, CreateLiveChatAgentAnswerTemplateMutationVariables>(CreateLiveChatAgentAnswerTemplateDocument, options);
      }
export type CreateLiveChatAgentAnswerTemplateMutationHookResult = ReturnType<typeof useCreateLiveChatAgentAnswerTemplateMutation>;
export type CreateLiveChatAgentAnswerTemplateMutationResult = Apollo.MutationResult<CreateLiveChatAgentAnswerTemplateMutation>;
export type CreateLiveChatAgentAnswerTemplateMutationOptions = Apollo.BaseMutationOptions<CreateLiveChatAgentAnswerTemplateMutation, CreateLiveChatAgentAnswerTemplateMutationVariables>;
export const CreateLiveChatAgentsDocument = gql`
    mutation createLiveChatAgents($locales: [String]!, $boUserIds: [UUID]!) {
  createLiveChatAgents(locales: $locales, boUserIds: $boUserIds) {
    email
    firstName
    id
    lastName
  }
}
    `;
export type CreateLiveChatAgentsMutationFn = Apollo.MutationFunction<CreateLiveChatAgentsMutation, CreateLiveChatAgentsMutationVariables>;

/**
 * __useCreateLiveChatAgentsMutation__
 *
 * To run a mutation, you first call `useCreateLiveChatAgentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLiveChatAgentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLiveChatAgentsMutation, { data, loading, error }] = useCreateLiveChatAgentsMutation({
 *   variables: {
 *      locales: // value for 'locales'
 *      boUserIds: // value for 'boUserIds'
 *   },
 * });
 */
export function useCreateLiveChatAgentsMutation(baseOptions?: Apollo.MutationHookOptions<CreateLiveChatAgentsMutation, CreateLiveChatAgentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLiveChatAgentsMutation, CreateLiveChatAgentsMutationVariables>(CreateLiveChatAgentsDocument, options);
      }
export type CreateLiveChatAgentsMutationHookResult = ReturnType<typeof useCreateLiveChatAgentsMutation>;
export type CreateLiveChatAgentsMutationResult = Apollo.MutationResult<CreateLiveChatAgentsMutation>;
export type CreateLiveChatAgentsMutationOptions = Apollo.BaseMutationOptions<CreateLiveChatAgentsMutation, CreateLiveChatAgentsMutationVariables>;
export const DeleteLiveChatAgentDocument = gql`
    mutation deleteLiveChatAgent($agentId: UUID!, $locale: String!) {
  deleteLiveChatAgent(agentId: $agentId, locale: $locale)
}
    `;
export type DeleteLiveChatAgentMutationFn = Apollo.MutationFunction<DeleteLiveChatAgentMutation, DeleteLiveChatAgentMutationVariables>;

/**
 * __useDeleteLiveChatAgentMutation__
 *
 * To run a mutation, you first call `useDeleteLiveChatAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLiveChatAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLiveChatAgentMutation, { data, loading, error }] = useDeleteLiveChatAgentMutation({
 *   variables: {
 *      agentId: // value for 'agentId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useDeleteLiveChatAgentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLiveChatAgentMutation, DeleteLiveChatAgentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLiveChatAgentMutation, DeleteLiveChatAgentMutationVariables>(DeleteLiveChatAgentDocument, options);
      }
export type DeleteLiveChatAgentMutationHookResult = ReturnType<typeof useDeleteLiveChatAgentMutation>;
export type DeleteLiveChatAgentMutationResult = Apollo.MutationResult<DeleteLiveChatAgentMutation>;
export type DeleteLiveChatAgentMutationOptions = Apollo.BaseMutationOptions<DeleteLiveChatAgentMutation, DeleteLiveChatAgentMutationVariables>;
export const DeleteLiveChatAgentAnswerTemplateDocument = gql`
    mutation deleteLiveChatAgentAnswerTemplate($templateId: UUID!) {
  deleteLiveChatAgentAnswerTemplate(templateId: $templateId)
}
    `;
export type DeleteLiveChatAgentAnswerTemplateMutationFn = Apollo.MutationFunction<DeleteLiveChatAgentAnswerTemplateMutation, DeleteLiveChatAgentAnswerTemplateMutationVariables>;

/**
 * __useDeleteLiveChatAgentAnswerTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteLiveChatAgentAnswerTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLiveChatAgentAnswerTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLiveChatAgentAnswerTemplateMutation, { data, loading, error }] = useDeleteLiveChatAgentAnswerTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useDeleteLiveChatAgentAnswerTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLiveChatAgentAnswerTemplateMutation, DeleteLiveChatAgentAnswerTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLiveChatAgentAnswerTemplateMutation, DeleteLiveChatAgentAnswerTemplateMutationVariables>(DeleteLiveChatAgentAnswerTemplateDocument, options);
      }
export type DeleteLiveChatAgentAnswerTemplateMutationHookResult = ReturnType<typeof useDeleteLiveChatAgentAnswerTemplateMutation>;
export type DeleteLiveChatAgentAnswerTemplateMutationResult = Apollo.MutationResult<DeleteLiveChatAgentAnswerTemplateMutation>;
export type DeleteLiveChatAgentAnswerTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteLiveChatAgentAnswerTemplateMutation, DeleteLiveChatAgentAnswerTemplateMutationVariables>;
export const LiveChatAgentTeamsDocument = gql`
    query liveChatAgentTeams($locale: String!) {
  liveChatAgentTeams: getLiveChatAgentTeams(locale: $locale) {
    ...LiveChatAgentTeam
  }
}
    ${LiveChatAgentTeamFragmentDoc}`;

/**
 * __useLiveChatAgentTeamsQuery__
 *
 * To run a query within a React component, call `useLiveChatAgentTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveChatAgentTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveChatAgentTeamsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useLiveChatAgentTeamsQuery(baseOptions: Apollo.QueryHookOptions<LiveChatAgentTeamsQuery, LiveChatAgentTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LiveChatAgentTeamsQuery, LiveChatAgentTeamsQueryVariables>(LiveChatAgentTeamsDocument, options);
      }
export function useLiveChatAgentTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiveChatAgentTeamsQuery, LiveChatAgentTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LiveChatAgentTeamsQuery, LiveChatAgentTeamsQueryVariables>(LiveChatAgentTeamsDocument, options);
        }
export type LiveChatAgentTeamsQueryHookResult = ReturnType<typeof useLiveChatAgentTeamsQuery>;
export type LiveChatAgentTeamsLazyQueryHookResult = ReturnType<typeof useLiveChatAgentTeamsLazyQuery>;
export type LiveChatAgentTeamsQueryResult = Apollo.QueryResult<LiveChatAgentTeamsQuery, LiveChatAgentTeamsQueryVariables>;
export const LiveChatAgentsDocument = gql`
    query liveChatAgents($locale: String!) {
  liveChatAgents: getLiveChatAgents(locale: $locale) {
    ...LiveChatAgent
  }
}
    ${LiveChatAgentFragmentDoc}`;

/**
 * __useLiveChatAgentsQuery__
 *
 * To run a query within a React component, call `useLiveChatAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveChatAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveChatAgentsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useLiveChatAgentsQuery(baseOptions: Apollo.QueryHookOptions<LiveChatAgentsQuery, LiveChatAgentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LiveChatAgentsQuery, LiveChatAgentsQueryVariables>(LiveChatAgentsDocument, options);
      }
export function useLiveChatAgentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiveChatAgentsQuery, LiveChatAgentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LiveChatAgentsQuery, LiveChatAgentsQueryVariables>(LiveChatAgentsDocument, options);
        }
export type LiveChatAgentsQueryHookResult = ReturnType<typeof useLiveChatAgentsQuery>;
export type LiveChatAgentsLazyQueryHookResult = ReturnType<typeof useLiveChatAgentsLazyQuery>;
export type LiveChatAgentsQueryResult = Apollo.QueryResult<LiveChatAgentsQuery, LiveChatAgentsQueryVariables>;
export const LiveChatAnswerTemplatesForAgentDocument = gql`
    query liveChatAnswerTemplatesForAgent($locale: String!, $agentId: UUID!) {
  templates: getLiveChatAnswerTemplatesForAgent(
    locale: $locale
    agentId: $agentId
  ) {
    ...LiveChatAgentAnswerTemplate
  }
}
    ${LiveChatAgentAnswerTemplateFragmentDoc}`;

/**
 * __useLiveChatAnswerTemplatesForAgentQuery__
 *
 * To run a query within a React component, call `useLiveChatAnswerTemplatesForAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveChatAnswerTemplatesForAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveChatAnswerTemplatesForAgentQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      agentId: // value for 'agentId'
 *   },
 * });
 */
export function useLiveChatAnswerTemplatesForAgentQuery(baseOptions: Apollo.QueryHookOptions<LiveChatAnswerTemplatesForAgentQuery, LiveChatAnswerTemplatesForAgentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LiveChatAnswerTemplatesForAgentQuery, LiveChatAnswerTemplatesForAgentQueryVariables>(LiveChatAnswerTemplatesForAgentDocument, options);
      }
export function useLiveChatAnswerTemplatesForAgentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiveChatAnswerTemplatesForAgentQuery, LiveChatAnswerTemplatesForAgentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LiveChatAnswerTemplatesForAgentQuery, LiveChatAnswerTemplatesForAgentQueryVariables>(LiveChatAnswerTemplatesForAgentDocument, options);
        }
export type LiveChatAnswerTemplatesForAgentQueryHookResult = ReturnType<typeof useLiveChatAnswerTemplatesForAgentQuery>;
export type LiveChatAnswerTemplatesForAgentLazyQueryHookResult = ReturnType<typeof useLiveChatAnswerTemplatesForAgentLazyQuery>;
export type LiveChatAnswerTemplatesForAgentQueryResult = Apollo.QueryResult<LiveChatAnswerTemplatesForAgentQuery, LiveChatAnswerTemplatesForAgentQueryVariables>;
export const LiveChatCategoriesDocument = gql`
    query liveChatCategories {
  liveChatCategories: getLiveChatCategories {
    ...LiveChatCategory
  }
}
    ${LiveChatCategoryFragmentDoc}`;

/**
 * __useLiveChatCategoriesQuery__
 *
 * To run a query within a React component, call `useLiveChatCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveChatCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveChatCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLiveChatCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<LiveChatCategoriesQuery, LiveChatCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LiveChatCategoriesQuery, LiveChatCategoriesQueryVariables>(LiveChatCategoriesDocument, options);
      }
export function useLiveChatCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiveChatCategoriesQuery, LiveChatCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LiveChatCategoriesQuery, LiveChatCategoriesQueryVariables>(LiveChatCategoriesDocument, options);
        }
export type LiveChatCategoriesQueryHookResult = ReturnType<typeof useLiveChatCategoriesQuery>;
export type LiveChatCategoriesLazyQueryHookResult = ReturnType<typeof useLiveChatCategoriesLazyQuery>;
export type LiveChatCategoriesQueryResult = Apollo.QueryResult<LiveChatCategoriesQuery, LiveChatCategoriesQueryVariables>;
export const SearchBoUsersDocument = gql`
    query SearchBoUsers($search: SearchProjection2Input!) {
  searchUsers(search: $search) {
    entities {
      id
      email
      country
      locale
    }
  }
}
    `;

/**
 * __useSearchBoUsersQuery__
 *
 * To run a query within a React component, call `useSearchBoUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBoUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBoUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchBoUsersQuery(baseOptions: Apollo.QueryHookOptions<SearchBoUsersQuery, SearchBoUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchBoUsersQuery, SearchBoUsersQueryVariables>(SearchBoUsersDocument, options);
      }
export function useSearchBoUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBoUsersQuery, SearchBoUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchBoUsersQuery, SearchBoUsersQueryVariables>(SearchBoUsersDocument, options);
        }
export type SearchBoUsersQueryHookResult = ReturnType<typeof useSearchBoUsersQuery>;
export type SearchBoUsersLazyQueryHookResult = ReturnType<typeof useSearchBoUsersLazyQuery>;
export type SearchBoUsersQueryResult = Apollo.QueryResult<SearchBoUsersQuery, SearchBoUsersQueryVariables>;
export const SearchLiveChatAgentAnswerTemplatesDocument = gql`
    query searchLiveChatAgentAnswerTemplates($search: SearchProjection2Input!) {
  liveChatAgentAnswerTemplates: searchLiveChatAgentAnswerTemplate(search: $search) {
    entities {
      ...LiveChatAgentAnswerTemplate
    }
  }
}
    ${LiveChatAgentAnswerTemplateFragmentDoc}`;

/**
 * __useSearchLiveChatAgentAnswerTemplatesQuery__
 *
 * To run a query within a React component, call `useSearchLiveChatAgentAnswerTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLiveChatAgentAnswerTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLiveChatAgentAnswerTemplatesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchLiveChatAgentAnswerTemplatesQuery(baseOptions: Apollo.QueryHookOptions<SearchLiveChatAgentAnswerTemplatesQuery, SearchLiveChatAgentAnswerTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchLiveChatAgentAnswerTemplatesQuery, SearchLiveChatAgentAnswerTemplatesQueryVariables>(SearchLiveChatAgentAnswerTemplatesDocument, options);
      }
export function useSearchLiveChatAgentAnswerTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchLiveChatAgentAnswerTemplatesQuery, SearchLiveChatAgentAnswerTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchLiveChatAgentAnswerTemplatesQuery, SearchLiveChatAgentAnswerTemplatesQueryVariables>(SearchLiveChatAgentAnswerTemplatesDocument, options);
        }
export type SearchLiveChatAgentAnswerTemplatesQueryHookResult = ReturnType<typeof useSearchLiveChatAgentAnswerTemplatesQuery>;
export type SearchLiveChatAgentAnswerTemplatesLazyQueryHookResult = ReturnType<typeof useSearchLiveChatAgentAnswerTemplatesLazyQuery>;
export type SearchLiveChatAgentAnswerTemplatesQueryResult = Apollo.QueryResult<SearchLiveChatAgentAnswerTemplatesQuery, SearchLiveChatAgentAnswerTemplatesQueryVariables>;
export const UpdateLiveChatAgentAnswerTemplateDocument = gql`
    mutation updateLiveChatAgentAnswerTemplate($templateId: UUID!, $updateProjection: UpdateAgentAnswerTemplateProjectionInput!) {
  updatedTemplate: updateLiveChatAgentAnswerTemplate(
    templateId: $templateId
    updateProjection: $updateProjection
  ) {
    ...LiveChatAgentAnswerTemplate
  }
}
    ${LiveChatAgentAnswerTemplateFragmentDoc}`;
export type UpdateLiveChatAgentAnswerTemplateMutationFn = Apollo.MutationFunction<UpdateLiveChatAgentAnswerTemplateMutation, UpdateLiveChatAgentAnswerTemplateMutationVariables>;

/**
 * __useUpdateLiveChatAgentAnswerTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateLiveChatAgentAnswerTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLiveChatAgentAnswerTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLiveChatAgentAnswerTemplateMutation, { data, loading, error }] = useUpdateLiveChatAgentAnswerTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      updateProjection: // value for 'updateProjection'
 *   },
 * });
 */
export function useUpdateLiveChatAgentAnswerTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLiveChatAgentAnswerTemplateMutation, UpdateLiveChatAgentAnswerTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLiveChatAgentAnswerTemplateMutation, UpdateLiveChatAgentAnswerTemplateMutationVariables>(UpdateLiveChatAgentAnswerTemplateDocument, options);
      }
export type UpdateLiveChatAgentAnswerTemplateMutationHookResult = ReturnType<typeof useUpdateLiveChatAgentAnswerTemplateMutation>;
export type UpdateLiveChatAgentAnswerTemplateMutationResult = Apollo.MutationResult<UpdateLiveChatAgentAnswerTemplateMutation>;
export type UpdateLiveChatAgentAnswerTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateLiveChatAgentAnswerTemplateMutation, UpdateLiveChatAgentAnswerTemplateMutationVariables>;
export const UpdateLiveChatAgentTeamAgentsDocument = gql`
    mutation updateLiveChatAgentTeamAgents($request: LiveChatUpdateTeamAgentsRequestProjectionInput!) {
  updateLiveChatAgentTeamAgents(request: $request)
}
    `;
export type UpdateLiveChatAgentTeamAgentsMutationFn = Apollo.MutationFunction<UpdateLiveChatAgentTeamAgentsMutation, UpdateLiveChatAgentTeamAgentsMutationVariables>;

/**
 * __useUpdateLiveChatAgentTeamAgentsMutation__
 *
 * To run a mutation, you first call `useUpdateLiveChatAgentTeamAgentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLiveChatAgentTeamAgentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLiveChatAgentTeamAgentsMutation, { data, loading, error }] = useUpdateLiveChatAgentTeamAgentsMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateLiveChatAgentTeamAgentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLiveChatAgentTeamAgentsMutation, UpdateLiveChatAgentTeamAgentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLiveChatAgentTeamAgentsMutation, UpdateLiveChatAgentTeamAgentsMutationVariables>(UpdateLiveChatAgentTeamAgentsDocument, options);
      }
export type UpdateLiveChatAgentTeamAgentsMutationHookResult = ReturnType<typeof useUpdateLiveChatAgentTeamAgentsMutation>;
export type UpdateLiveChatAgentTeamAgentsMutationResult = Apollo.MutationResult<UpdateLiveChatAgentTeamAgentsMutation>;
export type UpdateLiveChatAgentTeamAgentsMutationOptions = Apollo.BaseMutationOptions<UpdateLiveChatAgentTeamAgentsMutation, UpdateLiveChatAgentTeamAgentsMutationVariables>;
export const UpdateLiveChatAgentTeamCategoriesDocument = gql`
    mutation updateLiveChatAgentTeamCategories($request: LiveChatUpdateTeamCategoriesRequestProjectionInput!) {
  updateLiveChatAgentTeamCategories(request: $request)
}
    `;
export type UpdateLiveChatAgentTeamCategoriesMutationFn = Apollo.MutationFunction<UpdateLiveChatAgentTeamCategoriesMutation, UpdateLiveChatAgentTeamCategoriesMutationVariables>;

/**
 * __useUpdateLiveChatAgentTeamCategoriesMutation__
 *
 * To run a mutation, you first call `useUpdateLiveChatAgentTeamCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLiveChatAgentTeamCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLiveChatAgentTeamCategoriesMutation, { data, loading, error }] = useUpdateLiveChatAgentTeamCategoriesMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateLiveChatAgentTeamCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLiveChatAgentTeamCategoriesMutation, UpdateLiveChatAgentTeamCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLiveChatAgentTeamCategoriesMutation, UpdateLiveChatAgentTeamCategoriesMutationVariables>(UpdateLiveChatAgentTeamCategoriesDocument, options);
      }
export type UpdateLiveChatAgentTeamCategoriesMutationHookResult = ReturnType<typeof useUpdateLiveChatAgentTeamCategoriesMutation>;
export type UpdateLiveChatAgentTeamCategoriesMutationResult = Apollo.MutationResult<UpdateLiveChatAgentTeamCategoriesMutation>;
export type UpdateLiveChatAgentTeamCategoriesMutationOptions = Apollo.BaseMutationOptions<UpdateLiveChatAgentTeamCategoriesMutation, UpdateLiveChatAgentTeamCategoriesMutationVariables>;
export const AddLiveChatTopicDocument = gql`
    mutation AddLiveChatTopic($request: LiveChatAddTopicRequestProjectionInput) {
  addLiveChatTopic(request: $request)
}
    `;
export type AddLiveChatTopicMutationFn = Apollo.MutationFunction<AddLiveChatTopicMutation, AddLiveChatTopicMutationVariables>;

/**
 * __useAddLiveChatTopicMutation__
 *
 * To run a mutation, you first call `useAddLiveChatTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLiveChatTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLiveChatTopicMutation, { data, loading, error }] = useAddLiveChatTopicMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAddLiveChatTopicMutation(baseOptions?: Apollo.MutationHookOptions<AddLiveChatTopicMutation, AddLiveChatTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLiveChatTopicMutation, AddLiveChatTopicMutationVariables>(AddLiveChatTopicDocument, options);
      }
export type AddLiveChatTopicMutationHookResult = ReturnType<typeof useAddLiveChatTopicMutation>;
export type AddLiveChatTopicMutationResult = Apollo.MutationResult<AddLiveChatTopicMutation>;
export type AddLiveChatTopicMutationOptions = Apollo.BaseMutationOptions<AddLiveChatTopicMutation, AddLiveChatTopicMutationVariables>;
export const ChangeAgentStatusDocument = gql`
    mutation changeAgentStatus($status: String) {
  changeAgentStatus(status: $status)
}
    `;
export type ChangeAgentStatusMutationFn = Apollo.MutationFunction<ChangeAgentStatusMutation, ChangeAgentStatusMutationVariables>;

/**
 * __useChangeAgentStatusMutation__
 *
 * To run a mutation, you first call `useChangeAgentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAgentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAgentStatusMutation, { data, loading, error }] = useChangeAgentStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useChangeAgentStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeAgentStatusMutation, ChangeAgentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeAgentStatusMutation, ChangeAgentStatusMutationVariables>(ChangeAgentStatusDocument, options);
      }
export type ChangeAgentStatusMutationHookResult = ReturnType<typeof useChangeAgentStatusMutation>;
export type ChangeAgentStatusMutationResult = Apollo.MutationResult<ChangeAgentStatusMutation>;
export type ChangeAgentStatusMutationOptions = Apollo.BaseMutationOptions<ChangeAgentStatusMutation, ChangeAgentStatusMutationVariables>;
export const EndLiveChatDocument = gql`
    mutation EndLiveChat($request: LiveChatEndRequestProjectionInput) {
  endLiveChat(request: $request)
}
    `;
export type EndLiveChatMutationFn = Apollo.MutationFunction<EndLiveChatMutation, EndLiveChatMutationVariables>;

/**
 * __useEndLiveChatMutation__
 *
 * To run a mutation, you first call `useEndLiveChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndLiveChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endLiveChatMutation, { data, loading, error }] = useEndLiveChatMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useEndLiveChatMutation(baseOptions?: Apollo.MutationHookOptions<EndLiveChatMutation, EndLiveChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndLiveChatMutation, EndLiveChatMutationVariables>(EndLiveChatDocument, options);
      }
export type EndLiveChatMutationHookResult = ReturnType<typeof useEndLiveChatMutation>;
export type EndLiveChatMutationResult = Apollo.MutationResult<EndLiveChatMutation>;
export type EndLiveChatMutationOptions = Apollo.BaseMutationOptions<EndLiveChatMutation, EndLiveChatMutationVariables>;
export const GenerateAiSuggestionDocument = gql`
    mutation generateAiSuggestion($request: LiveChatAiSuggestionRequestProjectionInput!) {
  generateAiSuggestion(request: $request) {
    success
    text
  }
}
    `;
export type GenerateAiSuggestionMutationFn = Apollo.MutationFunction<GenerateAiSuggestionMutation, GenerateAiSuggestionMutationVariables>;

/**
 * __useGenerateAiSuggestionMutation__
 *
 * To run a mutation, you first call `useGenerateAiSuggestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAiSuggestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAiSuggestionMutation, { data, loading, error }] = useGenerateAiSuggestionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGenerateAiSuggestionMutation(baseOptions?: Apollo.MutationHookOptions<GenerateAiSuggestionMutation, GenerateAiSuggestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateAiSuggestionMutation, GenerateAiSuggestionMutationVariables>(GenerateAiSuggestionDocument, options);
      }
export type GenerateAiSuggestionMutationHookResult = ReturnType<typeof useGenerateAiSuggestionMutation>;
export type GenerateAiSuggestionMutationResult = Apollo.MutationResult<GenerateAiSuggestionMutation>;
export type GenerateAiSuggestionMutationOptions = Apollo.BaseMutationOptions<GenerateAiSuggestionMutation, GenerateAiSuggestionMutationVariables>;
export const MarkLiveChatAsReadDocument = gql`
    mutation markLiveChatAsRead($request: LiveChatAgentMarkReadRequestProjectionInput) {
  markLiveChatAsRead(request: $request)
}
    `;
export type MarkLiveChatAsReadMutationFn = Apollo.MutationFunction<MarkLiveChatAsReadMutation, MarkLiveChatAsReadMutationVariables>;

/**
 * __useMarkLiveChatAsReadMutation__
 *
 * To run a mutation, you first call `useMarkLiveChatAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkLiveChatAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markLiveChatAsReadMutation, { data, loading, error }] = useMarkLiveChatAsReadMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useMarkLiveChatAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkLiveChatAsReadMutation, MarkLiveChatAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkLiveChatAsReadMutation, MarkLiveChatAsReadMutationVariables>(MarkLiveChatAsReadDocument, options);
      }
export type MarkLiveChatAsReadMutationHookResult = ReturnType<typeof useMarkLiveChatAsReadMutation>;
export type MarkLiveChatAsReadMutationResult = Apollo.MutationResult<MarkLiveChatAsReadMutation>;
export type MarkLiveChatAsReadMutationOptions = Apollo.BaseMutationOptions<MarkLiveChatAsReadMutation, MarkLiveChatAsReadMutationVariables>;
export const RemoveLiveChatTopicDocument = gql`
    mutation RemoveLiveChatTopic($request: LiveChatRemoveTopicRequestProjectionInput) {
  removeLiveChatTopic(request: $request)
}
    `;
export type RemoveLiveChatTopicMutationFn = Apollo.MutationFunction<RemoveLiveChatTopicMutation, RemoveLiveChatTopicMutationVariables>;

/**
 * __useRemoveLiveChatTopicMutation__
 *
 * To run a mutation, you first call `useRemoveLiveChatTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLiveChatTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLiveChatTopicMutation, { data, loading, error }] = useRemoveLiveChatTopicMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useRemoveLiveChatTopicMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLiveChatTopicMutation, RemoveLiveChatTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveLiveChatTopicMutation, RemoveLiveChatTopicMutationVariables>(RemoveLiveChatTopicDocument, options);
      }
export type RemoveLiveChatTopicMutationHookResult = ReturnType<typeof useRemoveLiveChatTopicMutation>;
export type RemoveLiveChatTopicMutationResult = Apollo.MutationResult<RemoveLiveChatTopicMutation>;
export type RemoveLiveChatTopicMutationOptions = Apollo.BaseMutationOptions<RemoveLiveChatTopicMutation, RemoveLiveChatTopicMutationVariables>;
export const SendAgentAnswerDocument = gql`
    mutation sendAgentAnswer($request: LiveChatAgentAnswerRequestProjectionInput) {
  sendAgentAnswer(request: $request) {
    acknowledgedAt
  }
}
    `;
export type SendAgentAnswerMutationFn = Apollo.MutationFunction<SendAgentAnswerMutation, SendAgentAnswerMutationVariables>;

/**
 * __useSendAgentAnswerMutation__
 *
 * To run a mutation, you first call `useSendAgentAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAgentAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAgentAnswerMutation, { data, loading, error }] = useSendAgentAnswerMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSendAgentAnswerMutation(baseOptions?: Apollo.MutationHookOptions<SendAgentAnswerMutation, SendAgentAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAgentAnswerMutation, SendAgentAnswerMutationVariables>(SendAgentAnswerDocument, options);
      }
export type SendAgentAnswerMutationHookResult = ReturnType<typeof useSendAgentAnswerMutation>;
export type SendAgentAnswerMutationResult = Apollo.MutationResult<SendAgentAnswerMutation>;
export type SendAgentAnswerMutationOptions = Apollo.BaseMutationOptions<SendAgentAnswerMutation, SendAgentAnswerMutationVariables>;
export const SendLiveChatTemplateTrackingDocument = gql`
    mutation sendLiveChatTemplateTracking($liveChatId: UUID, $conversationId: UUID, $templateId: UUID, $action: String) {
  sendLiveChatTemplateTracking(
    model: {liveChatId: $liveChatId, conversationId: $conversationId, templateId: $templateId, action: $action}
  )
}
    `;
export type SendLiveChatTemplateTrackingMutationFn = Apollo.MutationFunction<SendLiveChatTemplateTrackingMutation, SendLiveChatTemplateTrackingMutationVariables>;

/**
 * __useSendLiveChatTemplateTrackingMutation__
 *
 * To run a mutation, you first call `useSendLiveChatTemplateTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendLiveChatTemplateTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendLiveChatTemplateTrackingMutation, { data, loading, error }] = useSendLiveChatTemplateTrackingMutation({
 *   variables: {
 *      liveChatId: // value for 'liveChatId'
 *      conversationId: // value for 'conversationId'
 *      templateId: // value for 'templateId'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useSendLiveChatTemplateTrackingMutation(baseOptions?: Apollo.MutationHookOptions<SendLiveChatTemplateTrackingMutation, SendLiveChatTemplateTrackingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendLiveChatTemplateTrackingMutation, SendLiveChatTemplateTrackingMutationVariables>(SendLiveChatTemplateTrackingDocument, options);
      }
export type SendLiveChatTemplateTrackingMutationHookResult = ReturnType<typeof useSendLiveChatTemplateTrackingMutation>;
export type SendLiveChatTemplateTrackingMutationResult = Apollo.MutationResult<SendLiveChatTemplateTrackingMutation>;
export type SendLiveChatTemplateTrackingMutationOptions = Apollo.BaseMutationOptions<SendLiveChatTemplateTrackingMutation, SendLiveChatTemplateTrackingMutationVariables>;
export const TrackAiSuggestionDocument = gql`
    mutation trackAiSuggestion($request: LiveChatTrackAiSuggestionRequestProjectionInput) {
  trackAiSuggestion(request: $request)
}
    `;
export type TrackAiSuggestionMutationFn = Apollo.MutationFunction<TrackAiSuggestionMutation, TrackAiSuggestionMutationVariables>;

/**
 * __useTrackAiSuggestionMutation__
 *
 * To run a mutation, you first call `useTrackAiSuggestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackAiSuggestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackAiSuggestionMutation, { data, loading, error }] = useTrackAiSuggestionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useTrackAiSuggestionMutation(baseOptions?: Apollo.MutationHookOptions<TrackAiSuggestionMutation, TrackAiSuggestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrackAiSuggestionMutation, TrackAiSuggestionMutationVariables>(TrackAiSuggestionDocument, options);
      }
export type TrackAiSuggestionMutationHookResult = ReturnType<typeof useTrackAiSuggestionMutation>;
export type TrackAiSuggestionMutationResult = Apollo.MutationResult<TrackAiSuggestionMutation>;
export type TrackAiSuggestionMutationOptions = Apollo.BaseMutationOptions<TrackAiSuggestionMutation, TrackAiSuggestionMutationVariables>;
export const UpdateLiveChatDetailsDocument = gql`
    mutation updateLiveChatDetails($id: UUID!, $request: LiveChatUpdateDetailsRequestInput) {
  updateLiveChatDetails(id: $id, request: $request)
}
    `;
export type UpdateLiveChatDetailsMutationFn = Apollo.MutationFunction<UpdateLiveChatDetailsMutation, UpdateLiveChatDetailsMutationVariables>;

/**
 * __useUpdateLiveChatDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateLiveChatDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLiveChatDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLiveChatDetailsMutation, { data, loading, error }] = useUpdateLiveChatDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateLiveChatDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLiveChatDetailsMutation, UpdateLiveChatDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLiveChatDetailsMutation, UpdateLiveChatDetailsMutationVariables>(UpdateLiveChatDetailsDocument, options);
      }
export type UpdateLiveChatDetailsMutationHookResult = ReturnType<typeof useUpdateLiveChatDetailsMutation>;
export type UpdateLiveChatDetailsMutationResult = Apollo.MutationResult<UpdateLiveChatDetailsMutation>;
export type UpdateLiveChatDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateLiveChatDetailsMutation, UpdateLiveChatDetailsMutationVariables>;
export const LiveChatAgentDataDocument = gql`
    query liveChatAgentData($request: ChatbotLiveChatAgentDataRequestProjectionInput) {
  liveChatAgentData: getLiveChatAgentData(request: $request) {
    agent {
      ...LiveAgent
    }
    activeChats {
      ...LiveChat
    }
    archivedChats {
      ...LiveChat
    }
    availableTopics {
      ...LiveChatTopic
    }
  }
}
    ${LiveAgentFragmentDoc}
${LiveChatFragmentDoc}
${LiveChatTopicFragmentDoc}`;

/**
 * __useLiveChatAgentDataQuery__
 *
 * To run a query within a React component, call `useLiveChatAgentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveChatAgentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveChatAgentDataQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useLiveChatAgentDataQuery(baseOptions?: Apollo.QueryHookOptions<LiveChatAgentDataQuery, LiveChatAgentDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LiveChatAgentDataQuery, LiveChatAgentDataQueryVariables>(LiveChatAgentDataDocument, options);
      }
export function useLiveChatAgentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiveChatAgentDataQuery, LiveChatAgentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LiveChatAgentDataQuery, LiveChatAgentDataQueryVariables>(LiveChatAgentDataDocument, options);
        }
export type LiveChatAgentDataQueryHookResult = ReturnType<typeof useLiveChatAgentDataQuery>;
export type LiveChatAgentDataLazyQueryHookResult = ReturnType<typeof useLiveChatAgentDataLazyQuery>;
export type LiveChatAgentDataQueryResult = Apollo.QueryResult<LiveChatAgentDataQuery, LiveChatAgentDataQueryVariables>;
export const LiveChatHistoryDocument = gql`
    query liveChatHistory($request: DialogHistoryRequestProjectionInput) {
  liveChatHistory: getLiveChatHistory(request: $request) {
    ...LiveChatHistoryItem
  }
}
    ${LiveChatHistoryItemFragmentDoc}`;

/**
 * __useLiveChatHistoryQuery__
 *
 * To run a query within a React component, call `useLiveChatHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveChatHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveChatHistoryQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useLiveChatHistoryQuery(baseOptions?: Apollo.QueryHookOptions<LiveChatHistoryQuery, LiveChatHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LiveChatHistoryQuery, LiveChatHistoryQueryVariables>(LiveChatHistoryDocument, options);
      }
export function useLiveChatHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiveChatHistoryQuery, LiveChatHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LiveChatHistoryQuery, LiveChatHistoryQueryVariables>(LiveChatHistoryDocument, options);
        }
export type LiveChatHistoryQueryHookResult = ReturnType<typeof useLiveChatHistoryQuery>;
export type LiveChatHistoryLazyQueryHookResult = ReturnType<typeof useLiveChatHistoryLazyQuery>;
export type LiveChatHistoryQueryResult = Apollo.QueryResult<LiveChatHistoryQuery, LiveChatHistoryQueryVariables>;
export const AddAiSettingsDocument = gql`
    mutation addAiSettings($aiSettings: AiSettingsCreateProjectionInput!) {
  addAiSettings(aiSettings: $aiSettings) {
    id
  }
}
    `;
export type AddAiSettingsMutationFn = Apollo.MutationFunction<AddAiSettingsMutation, AddAiSettingsMutationVariables>;

/**
 * __useAddAiSettingsMutation__
 *
 * To run a mutation, you first call `useAddAiSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAiSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAiSettingsMutation, { data, loading, error }] = useAddAiSettingsMutation({
 *   variables: {
 *      aiSettings: // value for 'aiSettings'
 *   },
 * });
 */
export function useAddAiSettingsMutation(baseOptions?: Apollo.MutationHookOptions<AddAiSettingsMutation, AddAiSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAiSettingsMutation, AddAiSettingsMutationVariables>(AddAiSettingsDocument, options);
      }
export type AddAiSettingsMutationHookResult = ReturnType<typeof useAddAiSettingsMutation>;
export type AddAiSettingsMutationResult = Apollo.MutationResult<AddAiSettingsMutation>;
export type AddAiSettingsMutationOptions = Apollo.BaseMutationOptions<AddAiSettingsMutation, AddAiSettingsMutationVariables>;
export const SearchAiSettingsDocument = gql`
    query searchAiSettings($search: AiSettingsSearchProjectionInput!) {
  data: searchAiSettings(search: $search) {
    entities {
      id
      contextQuestionLimit
      createdBy {
        id
        firstName
        lastName
        email
      }
      locale
      maxTokens
      similarityScoreThreshold
      systemMessage
      temperature
      topP
      versionName
      model
      useLastPredefinedMessage
      aiHistoryMessageLimit
      userMessageMaxTokens
      contextQuestionMaxTokens
      createdAt
    }
    page
    pageSize
    totalEntityCount
    totalPageCount
  }
}
    `;

/**
 * __useSearchAiSettingsQuery__
 *
 * To run a query within a React component, call `useSearchAiSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAiSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAiSettingsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchAiSettingsQuery(baseOptions: Apollo.QueryHookOptions<SearchAiSettingsQuery, SearchAiSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAiSettingsQuery, SearchAiSettingsQueryVariables>(SearchAiSettingsDocument, options);
      }
export function useSearchAiSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAiSettingsQuery, SearchAiSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAiSettingsQuery, SearchAiSettingsQueryVariables>(SearchAiSettingsDocument, options);
        }
export type SearchAiSettingsQueryHookResult = ReturnType<typeof useSearchAiSettingsQuery>;
export type SearchAiSettingsLazyQueryHookResult = ReturnType<typeof useSearchAiSettingsLazyQuery>;
export type SearchAiSettingsQueryResult = Apollo.QueryResult<SearchAiSettingsQuery, SearchAiSettingsQueryVariables>;
export const CompleteAiQuestionDocument = gql`
    query completeAiQuestion($question: CompleteQuestionRequestProjectionInput!) {
  data: completeAiQuestion(question: $question) {
    aiResponse
    completionContext {
      context
      derivedTopic
      similarityScore
    }
    tokenStats {
      context
      response
      userMessage
      systemMessage
    }
  }
}
    `;

/**
 * __useCompleteAiQuestionQuery__
 *
 * To run a query within a React component, call `useCompleteAiQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompleteAiQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompleteAiQuestionQuery({
 *   variables: {
 *      question: // value for 'question'
 *   },
 * });
 */
export function useCompleteAiQuestionQuery(baseOptions: Apollo.QueryHookOptions<CompleteAiQuestionQuery, CompleteAiQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompleteAiQuestionQuery, CompleteAiQuestionQueryVariables>(CompleteAiQuestionDocument, options);
      }
export function useCompleteAiQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompleteAiQuestionQuery, CompleteAiQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompleteAiQuestionQuery, CompleteAiQuestionQueryVariables>(CompleteAiQuestionDocument, options);
        }
export type CompleteAiQuestionQueryHookResult = ReturnType<typeof useCompleteAiQuestionQuery>;
export type CompleteAiQuestionLazyQueryHookResult = ReturnType<typeof useCompleteAiQuestionLazyQuery>;
export type CompleteAiQuestionQueryResult = Apollo.QueryResult<CompleteAiQuestionQuery, CompleteAiQuestionQueryVariables>;
export const UserDocument = gql`
    query User {
  me {
    id
    effectivePermissions
    accessParameters {
      key
      value
    }
    locale
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;