import { useMemo, Context, createContext, PropsWithChildren } from 'react';
import { toValidLocale } from '@retail/i18n';

import { RetailAccessParamDto, useUserQuery } from '~/apollo/gql-types';
import { DOMAINS } from '~/constants/permissions';

export interface IUserContext {
  isLoaded: boolean;
  permissions: Array<string> | null;
  accessParameters: Array<RetailAccessParamDto> | null;
  locale: string | null;
}

export const UserContext: Context<IUserContext> = createContext({
  isLoaded: false,
  permissions: null,
  accessParameters: null,
  locale: null
});

// eslint-disable-next-line @typescript-eslint/ban-types
export const UserProvider = ({ children }: PropsWithChildren<{}>) => {
  const { data } = useUserQuery({
    context: {
      headers: { 'access-domain': DOMAINS.USER_MANAGEMENT }
    }
  });
  const user = data?.me;
  const isLoaded = user?.id ?? false;
  const validLocale = useMemo(
    () => user?.locale && toValidLocale(user.locale),
    [user?.locale]
  );

  if (!isLoaded) {
    return null;
  }

  return (
    <UserContext.Provider
      value={{
        isLoaded,
        permissions: user.effectivePermissions,
        accessParameters: user.accessParameters,
        locale: validLocale
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
