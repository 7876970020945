import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  ServerParseError,
  ServerError,
  defaultDataIdFromObject
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { notification } from 'antd';
import { getConf } from '@retail/config';

const API_URL = getConf('API_URL');
const SCHEMA_ACCESS_DOMAIN = getConf('SCHEMA_ACCESS_DOMAIN');

export function getCookieByName(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
}

const HTTP_LINK = createHttpLink({
  uri: `/${API_URL}`
});

// NOTE Prepare common headers for all requests
const AUTH_LINK = setContext((operation, { headers }) => {
  // NOTE get the authentication access token from cookies if it exists
  const accessToken = getCookieByName('t');
  // NOTE return the headers to the context so httpLink can read them
  return {
    headers: {
      accept: 'application/json, text/plain, */*',
      'access-domain': SCHEMA_ACCESS_DOMAIN,
      'content-type': 'application/json;charset=UTF-8',
      authorization: `Bearer ${accessToken}`,
      ...headers
    }
  };
});

const ERROR_LINK = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if ((networkError as ServerParseError | ServerError)?.statusCode === 401) {
      const accessToken = getCookieByName('t');

      operation.setContext({
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return forward(operation);
    }

    if (graphQLErrors?.length) {
      notification.warning({
        message: 'GraphQLErrors',
        description: (
          <ul style={{ margin: 0, padding: 0 }}>
            {graphQLErrors
              .filter(({ message }) => !!message)
              .map(({ message }, idx) => (
                <li key={idx}>{message}</li>
              ))}
          </ul>
        )
      });
    } else if (networkError) {
      notification.warning({
        message: 'NetworkError',
        description: networkError.message
      });
    }
  }
);

interface DialogAnswerProjectionContext {
  orderId: string;
  categoryId: string;
}

interface DialogAnswerProjectionResponseObject {
  id: string;
  context: DialogAnswerProjectionContext;
}

// NOTE Apollo client
export const GQL_CLIENT = new ApolloClient({
  link: ApolloLink.from([AUTH_LINK, ERROR_LINK, HTTP_LINK]),
  cache: new InMemoryCache({
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'DialogAnswerProjection': {
          const { id, context } =
            responseObject as unknown as DialogAnswerProjectionResponseObject;

          const { orderId, categoryId } = context ?? {};

          /**
           * There are no unique fields in DialogAnswerProjection entity, this is the reason why we need to generate ID.
           * it has the same id for few different entities, example in ticket RKT-5757
           * If we do not have unique identificators on BE side - we need to generate on FE side
           */
          return `DialogAnswerProjectionGeneratedId:${
            id + orderId + categoryId
          }`;
        }

        default:
          return defaultDataIdFromObject(responseObject);
      }
    }
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only'
    }
  }
});
